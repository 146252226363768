import React, { useState, useMemo, useEffect } from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import editIconImage from "../../../assests/images/Icons/editIcon.svg";

import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';


const SortCodeReportTable = ({
    filteredAndSortedNodes,
    columns,
    handleEdit,
    footerTotals,
}) => {
    const { t,i18n } = useTranslation();
    const direction = i18n.dir();
    // Pagination state
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Reset page when data changes
    useEffect(() => {
        setCurrentPage(0);
    }, [filteredAndSortedNodes]);

    // Memoized page data
    const pageData = useMemo(() => {
        const start = currentPage * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredAndSortedNodes.slice(start, end);
    }, [filteredAndSortedNodes, currentPage, rowsPerPage]);

    // Pagination handlers
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    // Styles
    const headerStyle = {
        backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
        opacity: '0.6',
        color: 'var(--Foundation-Blue-Normal, #304FFF)',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        height: '56px',
        padding: '0',
    };

    return (
        <div className={`${direction}`} style={{ width: '100%', height: '65vh' }}>
            <TableContainer
                className="rtl-table-container"
                sx={{ maxHeight: '60vh', overflow: 'auto' }}
                component={Paper}
            >
                <Table
                    aria-label="Sort Code Report"
                    stickyHeader
                    className="sticky-header"
                    style={{ zIndex: '1' }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell style={headerStyle}></TableCell>
                            {columns.map((col) => (
                                <TableCell key={col.field} style={headerStyle}>
                                    <strong>{col.header}</strong>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageData.map((node) => (
                            <React.Fragment key={node.key}>
                                <TableRow hover>
                                    <TableCell>
                                        <IconButton
                                            className="row-buttons"
                                            onClick={() => handleEdit(node)}
                                        >
                                          <img src={editIconImage} alt="Edit" />
                                        </IconButton>
                                    </TableCell>
                                    {columns.map((col) => (
                                        <TableCell
                                            key={col.field}
                                            align="left"
                                        >
                                            {node.data[col.field]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </React.Fragment>
                        ))}

                        {/* Total Row */}
                        <TableRow sx={{ backgroundColor: '#e8e8e8', position: 'sticky', bottom: 0, zIndex: 1 }}>
                            <TableCell />
                            <TableCell ><strong>{t("Total")}</strong></TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell ><strong>{beautifyNumber(footerTotals['t-amount-vat'])}</strong></TableCell>
                            <TableCell ><strong>{beautifyNumber(footerTotals['t-amount-no-vat'])}</strong></TableCell>
                            <TableCell ><strong>{beautifyNumber(footerTotals['t-vat'])}</strong></TableCell>
                            <TableCell />


                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredAndSortedNodes.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page')}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t('of')} ${count !== -1
                        ? count
                        : `${t('more than')} ${to}`
                    }`
                }
            />
        </div>
    );
};

export default SortCodeReportTable;
