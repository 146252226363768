import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    Autocomplete,
    Popper,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import EnumsService from '../../../Services/enums.service';
import { toast } from 'react-toastify';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <div {...props} />
        </Draggable>
    );
}

const AddAccountCardModal = ({
    open,
    onClose,
    editedItem,
    handleEditAddAccountChange,
    isRange,
    isClosedReportingMonth,
    palCodes,
    propPalCode,
    direction,
    isAdd,
    client,
    token,
    setLoading,
    accountCardOptions,
    handleChangeAfterAdd,
    fetchEnums,
    type = 'main'
}) => {
    const { t } = useTranslation();

    // State for form fields
    const [accountCaseId, setAccountCaseId] = useState('');
    const [accountCodeName, setAccountCodeName] = useState('');
    const [accountCodeId, setAccountCodeId] = useState('');
    const [palCode, setPalCode] = useState('');

    // Initialize state with editedItem data when modal opens
    useEffect(() => {
        if (editedItem) {
            setAccountCaseId(editedItem.accountCaseId || '');
            setAccountCodeName(editedItem.accountCodeName || '');
            setAccountCodeId(editedItem.accountCodeId || '');
            setPalCode(propPalCode);
        }
    }, [editedItem]);

    // Input styles
    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    // Format palCodes options for Autocomplete
    const formattedPalCodeOptions = palCodes?.map((option) => ({
        value: option.value,
        label: option.label,
    }));

    const checkIfExistingAccountCard = (accountCardOptions, cardId, accountCaseId, edit = false) => {
        const existingAccountCard = accountCardOptions.filter(card =>
            (card.accountCaseId === accountCaseId && accountCaseId) ||
            (card.cardId === cardId && cardId)
        );
        const isExist = existingAccountCard && (edit
            ? existingAccountCard.length > 1
            : existingAccountCard.length > 0);
        if (isExist) {
            toast.info(t('AccountCardExists', { accountCodeId: existingAccountCard.cardId }));
            return true;
        }
    }

    const handleAdd = async () => {
        if (checkIfExistingAccountCard(accountCardOptions, accountCodeId, accountCaseId)) {
            return;
        }
        try {
            setLoading(true);
            const response = await EnumsService.AddAccountCard(client.caseId, accountCodeId.toString(), accountCodeName, accountCaseId, palCode, token);
            if (response.ok) {
                await fetchEnums(null,token,client)
                handleChangeAfterAdd(accountCodeId.toString(),type);
                toast.success(t('CustomerSupplierAddedSuccessfully'));
            } else {
                const errorText = await response.text();
                console.error('Failed to edit user', errorText);
                toast.error(t('FailedToAddCustomerSupplier'));
            }
        } catch (error) {
            console.error('Error edit user:', error);
            toast.error(t('FailedToAddCustomerSupplier'));
        }
        setLoading(false);
        onClose()
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <div className={`${direction}`} style={{ backgroundColor: 'white' }}>

                <DialogTitle
                    style={{ cursor: 'move', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    id="draggable-dialog-title"
                >
                    {t('AddAccountCard')}
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={`form-container ${direction}`}>
                        {/* Company ID */}
                        <div className="form-row">
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('CompanyId')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('CompanyId')}
                                    value={accountCaseId}
                                    onChange={(e) => setAccountCaseId(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                />
                            </div>
                        </div>
                        {/* Card Name */}
                        <div className="form-row">
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Card Name')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Card Name')}
                                    value={accountCodeName}
                                    onChange={(e) => setAccountCodeName(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                />
                            </div>
                        </div>
                        {/* Card ID */}
                        <div className="form-row">
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('Card ID')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Card ID')}
                                    value={accountCodeId}
                                    onChange={(e) => setAccountCodeId(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                />
                            </div>
                        </div>
                        {/* PalCode */}
                        <div className="form-row">
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">
                                    {t('PalCode')}
                                </div>
                                <Autocomplete
                                    options={formattedPalCodeOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={formattedPalCodeOptions.find((option) => option.value === palCode) || null}
                                    onChange={(event, newValue) => setPalCode(newValue ? newValue.value : '')}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('PalCode')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper {...props} style={{ ...props.style, direction: direction }} />
                                    )}
                                />
                            </div>
                        </div>
                        {/* Buttons */}
                        <div className="button-container">
                            <div onClick={onClose} className="edit-cancel-button">
                                {t('Cancel')}
                            </div>
                            <div onClick={handleAdd} className="edit-save-button">
                                {t('Save')}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
};

export default AddAccountCardModal;
