import React, { useState, useEffect, useRef, useMemo, forwardRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu.jsx';
import Select, { components } from 'react-select';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import UserService from '../../Services/user.service.js';
import '../OtherDocuments/OtherDocument.css';
import '../BankMatching/BankMatching.css';
import backIcon from '../../assests/images/left-arrow.png';
import EnumsService from '../../Services/enums.service.js';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal.js'
import { ToastContainer, toast } from "react-toastify";
import PuffLoader from "react-spinners/PuffLoader";

import SaveIcon from '@mui/icons-material/Save'; // Save icon
import ClearIcon from '@mui/icons-material/Clear';
import AdvancedDropdown from '../AdvancedDropdown/AdvancedDropdown.js';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CardMatchService from '../../Services/cardMatch.service.js';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Constants from '../../assests/Constants/constants.js';
import { DataGrid, useGridApiRef, GridToolbar } from '@mui/x-data-grid';
import { styled, darken, lighten } from '@mui/material/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { arSD, heIL, enUS } from '@mui/x-data-grid/locales';
import CustomMonthDatePicker from '../CustomMonthDatePicker/CustomMonthDatePicker.js';
import CustomDropdown from '../CustomDropdown/CustomDropdown.js';
import EditModal from '../EditModal/EditModal.js';
//table imports
import { Edit as EditIcon, Delete as DeleteIcon, Download as DownloadIconMui, Search as SearchIcon, CancelRounded } from '@mui/icons-material';
import {
    Button, Dialog, DialogTitle, DialogContent, Box, DialogActions, Typography, TextField, Checkbox,
    Tooltip, DialogContentText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem,
    ToggleButton, ToggleButtonGroup, Switch, CircularProgress, IconButton, Autocomplete, FormControlLabel, Card, CardContent, Grid
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { beautifyNumber } from '../../Utils/FormatNumber.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ImportDialog from '../BankMatching/ImportDialog.js';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DocumentService from '../../Services/documents.service.js';
import ExternalTransactionsService from '../../Services/externalTransaction.service.js';
import NoDocumentsPdf from "../../assests/pdf/NoDocument.pdf";
import ClientService from '../../Services/client.service.js';
import ClientDocumentCountService from '../../Services/ClientDocumentCount.service.js';
import EditPaymentAcceptanceModal from '../EditPaymentAcceptanceModal/EditPaymentAcceptanceModal.js';
import EditPaymentAcceptanceModalSpecificItem from '../EditPaymentAcceptanceModal/EditPaymentAcceptanceSpecificItem.js';


import BottomDockBar from '../BankMatching/BottomDockBar.js';
import CustomMonthDatePickerMui from '../CustomMonthDatePicker/CustomMonthDatePickerMui.js';
import AddExternalTransactionDialog from '../BankMatching/AddExternalTransactionDialog.js';
import AddOrEditBankDialog from '../BankMatching/AddOrEditBankDialog.js';
import DenseTable from '../DenseTable/DenseTable.js';
import AddAccountCardDialog from '../AddAccountCardDialog/AddAccountCardDialog.js';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails.jsx';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect.jsx';
import editIconImage from "../../assests/images/Icons/editIcon.svg";
import PaymentAcceptanceCreateEditModal from "../PaymentAcceptance/SubComponents/PaymentAcceptanceCreateEditModal.jsx";

registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it





// Create rtl cache
const cacheRtl = createCache({
    key: 'data-grid-rtl-demo',
    stylisPlugins: [prefixer, rtlPlugin],
});
// Utility to generate n number of absolutely distinct colors
const generateDistinctColors = (n, alpha = 0.5) => {
    const colors = [];
    const goldenRatio = 0.618033988749895;  // To generate distinct hues
    let hue = Math.random();  // Start with a random initial hue

    for (let i = 0; i < n; i++) {
        hue += goldenRatio;  // Spread hues using golden ratio for maximum distinctness
        hue %= 1;  // Keep hue within [0, 1] range
        const saturation = 50 + Math.random() * 50;  // Saturation between 50% and 100%
        const lightness = 40 + Math.random() * 30;  // Lightness between 40% and 70%

        // Add opacity to the color by switching to hsla
        colors.push(`hsla(${hue * 360}, ${saturation}%, ${lightness}%, ${alpha})`);
    }

    return colors;
};

// Usage example:
const numberOfColors = 50;
const matchColors = generateDistinctColors(numberOfColors);

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);






const StyledDataGrid = styled(DataGrid)(({ theme }) => {
    // Generate styles for matched rows as an object
    const matchedRowStyles = matchColors.reduce((styles, color, index) => {
        styles[`& .MuiDataGrid-row.super-app-theme--matched-${index}`] = {
            backgroundColor: color,
            '&:hover': {
                backgroundColor: color,

            },
        };
        return styles;
    }, {});

    return {
        '& *': {
            boxSizing: 'border-box !important',
        },
        // Include the matched row styles
        ...matchedRowStyles,
        '& .super-app-theme--UnMatched': {
            backgroundColor: getBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode
            ),
            '&:hover': {
                backgroundColor: getBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode
                ),
            },
        },
        '& .super-app-theme--Matched': {
            backgroundColor: getBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
            ),
            '&:hover': {
                backgroundColor: getBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode
                ),
            },
        },
        // Style for main matched rows (Level 0)
        '& .super-app-theme--MainRow': {
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
                backgroundColor: getBackgroundColor(
                    theme.palette.action.hover,
                    theme.palette.mode
                ),
            },
        },
        // Style for header rows (Level 1)
        '& .super-app-theme--Header': {
            backgroundColor: theme.palette.grey[200],
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: getBackgroundColor(
                    theme.palette.grey[300],
                    theme.palette.mode
                ),
            },
        },
        // Style for transaction sub-rows (Level 2)
        '& .super-app-theme--SubRow': {
            backgroundColor: theme.palette.grey[50],
            '&:hover': {
                backgroundColor: getBackgroundColor(
                    theme.palette.action.hover,
                    theme.palette.mode
                ),
            },
        },
        '& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal': {
            display: 'block !important',
        },
        '& .MuiDataGrid-columnHeaders': {
            opacity: 0.7,
            backgroundColor: '#BFC8FF',
        },
        '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#BFC8FF',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            color: '#304FFF',
            textAlign: 'center',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: 'normal',
        },
        '& .MuiDataGrid-cell': {
            borderTop: '1px solid rgba(191, 191, 193, 0.56)',
            backgroundColor: 'rgba(234, 237, 255, 0.32)',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(191, 200, 255, 0.3)',
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-cell': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center"
        },
    };
});
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme, width, height }) => ({

    marginBottom: '20px',
    '& .MuiToggleButton-root': {
        width: '150px',

        backgroundColor: '#ffffff', // Button background color
        transition: 'background-color 0.3s, transform 0.3s', // Smooth transition effects
        '&:hover': {
            backgroundColor: '#e0e0e0', // Change background color on hover
            transform: 'scale(1.05)', // Slightly increase size on hover
        },
        '&.Mui-selected': {
            backgroundColor: '#304FFF', // Selected button background color
            color: '#fff', // Selected button text color
            borderColor: '#304FFF', // Selected button border color
            '&:hover': {
                backgroundColor: '#0069d9', // Darker shade on hover when selected
            },
        },
    },
}));

const formatMonthYearDate = dateString => {
    const date = new Date(dateString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${month}/${year}`;
};

// Utility function to convert DD-MM-YYYY to YYYY-MM-DD
const parseCustomDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`; // Return as YYYY-MM-DD
};






function CardMatching() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const location = useLocation();
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');
    const [loading, setLoading] = useState(false);
    const [creditMatchedRows, setCreditMatchedRows] = useState([]);
    const [debitMatchedRows, setDebitMatchedRows] = useState([]);

    const [creditRows, setCreditRows] = useState([]);
    const [displayedRows, setDisplayedRows] = useState([]);
    const [debitRows, setDebitRows] = useState([]);
    const [isClientDataFetched, setIsClientDataFetched] = useState(false);
    const [shouldMatch, setShouldMatch] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'cardId', direction: 'descending', type: 'number' });
    const [focused, setFocused] = useState(false);
    const customScrollbarRef = useRef();
    const tableContainerRef = useRef();
    const [calculatedWidth, setCalculatedWidth] = useState('70%');
    // const [matchedDisplayedRows, setMatchedDisplayedRows] = useState([]);
    const [openRemoveMatchDialog, setOpenRemoveMatchDialog] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const apiRef = useGridApiRef();
    const [sortedTransactions, setSortedTransactions] = useState([]);

    //account cards
    const [accountCardsData, setAccountCardsData] = useState([]);
    const [startDate, setStartDate] = useState(() => new Date(0)); // Minimum date is set to Unix epoch (January 1, 1970)

    const [endDate, setEndDate] = useState(() => {
        const storedDateString = localStorage.getItem('BankTransactions_EndDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // End date for range


    const [isMatchDate, setIsMatchDate] = useState(() => {
        const storedIsRange = localStorage.getItem('BankTransactions_IsRange');
        return storedIsRange === "true";
    });;

    const [fetchType, setFetchType] = useState('NotMatched');

    const handleFetchTypeChange = async (event, newAlignment) => {
        console.log(newAlignment);

        // Prevent deselecting both options
        if (newAlignment !== null) {
            // Reset the rows before fetching new data
            setCreditRows([]);
            setDebitRows([]);
            setFetchType(newAlignment);
        }


    };
    useEffect(() => {
        if (fetchType) {
            FetchData();
        }
    }, [fetchType]);
    const existingTheme = useTheme();
    useEffect(() => {
        const currentLanguage = localStorage.getItem('language') || 'he';
        setSelectedLanguage(currentLanguage);
    }, [i18n.language]);

    // useEffect(() => {
    //    console.log(sortedTransactions);
    // }, [sortedTransactions]);


    const [oldSelectedAccountCardItem, setOldSelectedAccountCardItem] = useState({
        cardId: "",
        caseId: "",
        accountCaseId: "",
        name: "",
        palCode: '',
        client: null
    });

    const fileInputRef1 = useRef(null); // Reference to the first hidden file input


    const handleCustomScroll = (e) => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollLeft = e.target.scrollLeft;
        }
    };


    const [denseTableHeader, setDenseTableHeader] = useState([t("BankNumber"), t("BankName"), t("BankBranch"), t("BankAccount"), t("AccountCardNumber")]);
    const [denseTableRows, setDenseTableRows] = useState([]);
    const [tBanksData, setTBanksData] = useState([]);

    const [processedCardsData, setProcessedCardsData] = useState([]);
    const [selectedFormattedAccountCard, setSelectedFormattedAccountCard] = useState(null);

    const [mainCard, setMainCard] = useState('');
    const [cardForNewBank, setCardForNewBank] = useState('');
    const [selectedCardId, setSelectedCardId] = useState('');
    const [selectedTBankIndex, setSelectedTBankIndex] = useState(-1); //used for dense table
    const [selectedTBank, setSelectedTBank] = useState('');
    // Inside your component's render/return method:
    const [openImportDialog, setOpenImportDialog] = useState(false);

    const [matchData, setMatchData] = useState([]);
    const [sortModel, setSortModel] = useState([]);
    const [addBankTransactionDialog, setAddBankTransactionDialog] = useState(false);
    const [newBankEditableItem, setNewBankEditableItem] = useState({
        caseId: '',
        accountCodeId: '',
        accountCodeName: '',
        description: '',
        reference: '',
        amount: '',
        transactionDate: ''
    });

    const changeNewBankTransaction = (field, value) => {
        setNewBankEditableItem(prevState => ({
            ...prevState,
            [field]: value
        }));

    };

    const handleAddBankTransaction = async () => {
        try {
            const updatedBankItem = {
                ...newBankEditableItem,
                caseId: client.caseId,
                accountCodeId: mainCard.cardId,
                accountCodeName: mainCard.name,
            };



            const response = await ExternalTransactionsService.CreateTransaction(updatedBankItem, token);

            if (response.ok) {

                setNewBankEditableItem({
                    caseId: '',
                    accountCodeId: '',
                    accountCodeName: '',
                    description: '',
                    reference: '',
                    amount: '',
                    transactionDate: ''
                });
                handleCloseBankAddTransactionDialog();
                await FetchData();
            } else {
                const errorMessage = await response.text();

                if (errorMessage === "A transaction with the same reference already exists.") {
                    toast.error(t('TransactionWithSameReferenceExists'));
                }
                toast.error(t('TransactionWithSameReferenceExists'));
            }
        } catch (error) {

            toast.error(t('FailedToCreateBankCommand'));
        }
    };




    const handleOpenBankAddTransactionDialog = () => {
        if (!selectedTBank) {
            toast.info(t('YouNeedToSelectABankFirst'));
            return;
        }
        setAddBankTransactionDialog(true);
    };

    const handleCloseBankAddTransactionDialog = () => {
        setAddBankTransactionDialog(false);
        setNewBankEditableItem({
            caseId: '',
            accountCodeId: '',
            accountCodeName: '',
            description: '',
            reference: '',
            amount: '',
            transactionDate: ''
        });
    };

    const handleOpenImportDialog = () => {
        if (!selectedTBank) {
            toast.info(t('YouNeedToSelectABankFirst'));
            return;
        }



        // Search for a matching bank entry in tBanks
        const bankEntry = tBanksData.find(bank => bank.cardId?.toString() === mainCard.cardId?.toString());


        // If a matching bank entry is found and it has a corresponding URL
        if (bankEntry && Constants.BankUrls[parseInt(bankEntry.bankCode)]) {
            // Open the bank's URL in a new tab
            window.open(Constants.BankUrls[parseInt(bankEntry.bankCode)], '_blank');
        }

        // Open the dialog
        setOpenImportDialog(true);
    };

    const handleCloseImportDialog = () => {
        setOpenImportDialog(false);
    };

    const [externalTransactions, setExternalTransactions] = useState([]);
    const handleImport = async (file) => {

        setLoading(true)
        // selectedTBank is the selected bank
        const bankType = Constants.BankTypes[parseInt(selectedTBank.bankCode)];
        const accountCard = accountCardsData.find(card => card.cardId === selectedCardId);


        try {
            // Call the service method to import transactions
            const response = await ExternalTransactionsService.ImportExternalTransactions(
                client.caseId,
                selectedCardId,
                accountCard.name,
                bankType,
                file,
                token
            );

            if (response.ok) {
                toast.success(t("BankDataImportSuccessful"));
                await FetchData();
            }
            else {
                toast.error(t("BankDataImportFailed"));

            }

        } catch (error) {
            toast.error(t("BankDataImportFailed"));

            console.error('Error importing file:', error);
        }
        setLoading(false)

    };

    //for opening create bank modal
    const [createBankModal, setCreateBankModal] = useState(false);
    const bankOptions = Object.keys(Constants.BankNames).map(key => ({
        value: key,
        label: `${Constants.BankNames[key]} - ${key}` // Format: BankName - BankNumber
    }));

    const [selectedBankForCreate, setSelectedBankForCreate] = useState(null);
    const [bankBranch, setBankBranch] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [selectedDebitRows, setSelectedDebitRows] = useState([]);
    const [selectedMatchedRows, setSelectedMatchedRows] = useState([]);
    const [selectedCreditRows, setSelectedCreditRows] = useState([]);
    const [expandedInternalRows, setExpandedInternalRows] = useState({});
    const [expandedCreditRows, setExpandedCreditRows] = useState({});
    const [externalSum, setExternalSum] = useState(0);
    const [internalSum, setInternalSum] = useState(0);
    const [createJournalEntries, setCreateJournalEntries] = useState(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);


    // Matching
    const [matchedPairs, setMatchedPairs] = useState([]);
    const [matchCounter, setMatchCounter] = useState(0);
    const [formValues, setFormValues] = useState({
        confirmationNumber: '',
        accountCaseId: '',
        reference: '',
        description: '',
        documentDate: '',
        reportingMonthDate: new Date(),
        amount: '',
        vat: '',
        sortCodeId: '3',
        creditAccount: '',
        debitAccount: '',
        sortCode: '3',
        accountCard: ''
    });


    const handleReportingMonthDateChange = (event) => {
        const value = event.target.value; // This will be in YYYY-MM format
        const [year, month] = value.split('-'); // Split into year and month

        // Reformat to MM/YYYY
        const formattedDate = `${month}/${year}`;
        setReportingMonthDate(formattedDate); // Update state with MM/YYYY format
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSelectChange = (field, newValue) => {


        setFormValues((prev) => ({
            ...prev,
            [field]: newValue,
        }));
    };

    // const creditAccountOptions = [
    //     { label: 'Credit Account 1', value: 1 },
    //     { label: 'Credit Account 2', value: 2 },
    //     // more options
    // ];

    // const debitAccountOptions = [
    //     { label: 'Debit Account 1', value: 1 },
    //     { label: 'Debit Account 2', value: 2 },
    //     // more options
    // ];

    const sortCodeOptions = [
        { label: 'Sort Code 1', value: 1 },
        { label: 'Sort Code 2', value: 2 },
        // more options
    ];

    const CustomPalCode = (props) => {
        return (
            <components.Option {...props}>
                <div style={{ textAlign: 'center', width: '100%' }}>{props.label}</div>
            </components.Option>
        );
    };

    // Edit Modal Vars
    const [editedItem, setEditedItem] = useState({});
    const [editedPopupItem, setEditedPopupItem] = useState(true);
    const [fade, setFade] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [transactions, setTransactions] = useState(null);
    const [dataForPdf, setDataForPdf] = useState(null);
    const [sortedDataForPdf, setSortedDataForPdf] = useState(null);
    const [transactionDetailsDictionary, setTransactionDetailsDictionary] = useState({});
    const [itemToDelete, setItemToDelete] = useState({});
    const [editedJournalItem, setEditedJournalItem] = useState(true);
    const [paymentModal, setPaymentModal] = useState(false);
    const [paymentAcceptanceSpecificType, setPaymentAcceptanceSpecificType] = useState('');
    const [paymentAcceptanceSpecificId, setPaymentAcceptanceSpecificId] = useState(-1);
    const [showType, setShowType] = useState({ type: 'all', index: -1 });




    const [openInvoiceCreatorModal, setOpenInvoiceCreatorModal] = useState(false);
    const [addEditableItem, setAddEditableItem] = useState({
        checks: [],
        creditCards: [],
        cash: [],
        bankTransactions: [],
        date: new Date()
    });
    const [responseData, setResponseData] = useState({});
    const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] = useState([]);
    const [originalAccountCardId, setOriginalAccountCardId] = useState('');
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [isRange, setIsRange] = useState(false);
    const [isClosedReportingMonth, setIsClosedReportingMonth] = useState(false);
    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [editModalsAccountCardOptions, setEditModalsAccountCardOptions] = useState([]);

    const [sortCodeIdOptions, setSortCodeIdOptions] = useState([]);
    const [sortCodeIdOptionsForEditModals, setSortCodeIdOptionsForEditModals] = useState([]);

    const [statusCheck, setStatusCheck] = useState(false);
    const [creditAccountOptions, setCreditAccountOptions] = useState([]);
    const [debitAccountOptions, setDebitAccountOptions] = useState([]);
    const [originalAccountOptions, setOriginalDebitAccountOptions] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [palCodeName, setPalCodeName] = useState(null);
    const [accountCardName, setAccountCardName] = useState(null);
    const [accountCardId, setAccountCardId] = useState(null);
    const [finalBalance, setFinalBalance] = useState('');
    const [isMonthly, setIsMonthly] = useState(false);
    const [firstRowDetails, setFirstRowDetails] = useState(null);
    const [palCodeDetails, setPalCodeDetails] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [formattedPalCodeOptions, setFormattedPalCodeOptions] = useState([]);
    const [journalEntriesList, setJournalEntriesList] = useState([]);
    const [isJournalEntriesListOpen, setIsJournalEntriesListOpen] = useState(false);
    const [expandedMatchedRows, setExpandedMatchedRows] = useState({});
    const [showingMatches, setShowingMatches] = useState(false);


    // Handle expand/collapse
    const handleMatchedExpandClick = (id) => {
        setExpandedMatchedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="document-custom-input1" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const handleAccountEdit = (option) => {
        setShowAccountCardInputsEdit(true);
        setAccountCodeName(option.name);
        setAccountCodeId(option.cardId);
        setCompanyId(option.caseId);
        const account = accountCardOptions.find(account => account.cardId === option.cardId);
        setPalCode(account ? account.palCode : '');
        setOldSelectedAccountCardItem({
            cardId: option.cardId,
            caseId: option.caseId,
            accountCaseId: option.accountCaseId,
            name: option.name,
            palCode: '',
            client: null

        })
        setAddNewAccountCardDocument(true);
    };

    const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="edit-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const handleCancel = () => {
        setEditingRow(null);
        setEditedItem({});
        setEditedAddAccountCard(false);
    };
    const [itemIdToDelete, setItemIdToDelete] = useState('');
    const [itemTypeToDelete, setItemTypeToDelete] = useState('');
    const handleEditDeleteClick = (itemId) => {

        setItemTypeToDelete(editedItem.docType);

        setItemIdToDelete(itemId);
        setEditedPopupItem(false);
        setEditedItem({});
        handleDeleteClick();
    };
    const handleDeleteClick = () => {
        setIsModalVisible(true);
    };

    const handleDoneClick = async (itemId, type) => {

        setLoading(true);
        try {
            const response = await ClientDocumentCountService.removeDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token)
            if (response.ok) {
                // await fetchDocumentsNotification(client, token);
                toast.success(t('DocumentProcessed'), {
                    position: "top-left"
                });
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            // toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleNotDoneClick = async (itemId, type) => {


        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token)
            if (response.ok) {
                // await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'), {
                    position: "top-left"
                });
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            // toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleUpdate = async (itemId) => {
        setLoading(true);
        let finalResponse = null;
        setEditedPopupItem(false);
        try {
            editedItem.accountCaseId = client.accountCaseId;
            delete editedItem.documentUrl; // Ensure the document URL is not part of the update if present


            if (editedItem.docType === 'Incomes') {
                finalResponse = await DocumentService.UpdateDocument("Incomes", token, editedItem);
            }
            if (editedItem.docType === 'Expenses') {
                // If the income update fails, attempt the expense update
                finalResponse = await DocumentService.UpdateDocument("Expenses", token, editedItem);
            }
            if (editedItem.docType === 'JournalEntries') {
                finalResponse = await DocumentService.UpdateDocument("JournalEntries", token, editedItem);
            }


            if (finalResponse && finalResponse.ok) {
                if (editedItem.status === 'done') {
                    await handleDoneClick(editedItem.id, editedItem.docType);
                    // handleSortChange('id', 'newest', 'descending');
                } else if (editedItem.status === 'notDone') {
                    await handleNotDoneClick(editedItem.id, editedItem.docType);
                }

                if (editedAddAccountCard) {
                    await handleAccountCardSave(editedItem.accountCodeId, editedItem.accountCodeName, editedItem.accountCaseId);
                    await fetchEnums(user, token, client);
                }

                setWholeDataTransactions((prevData) => {
                    const newData = { ...prevData };

                    // Check if editedItem.id exists in the previous data
                    if (newData[editedItem.id]) {
                        // Delete the existing entry
                        delete newData[editedItem.id];
                    }

                    // Return the updated object without the removed entry
                    return newData;
                });

                toast.success(t("documentUpdateSuccess"));
            } else {
                const errorText = await finalResponse.text();
                toast.error(t("documentUpdateError") + ": " + errorText);
            }
        } catch (error) {
            // This block catches unexpected errors such as network issues, not HTTP errors which are handled above
            console.error("Unexpected error occurred: ", error);
            toast.error(t("documentUpdateError"));
        }
        setEditedItem({});
        setEditingRow(null);
        setEditedAddAccountCard(false);

        await fetchEnums(user, token, client);
        await FetchData();
        setLoading(false); // Always stop loading at the end of the operation
    };

    const handleEditAddAccountChange = (selectedOption, field) => {

        if (field === 'AccountCodeId') {
            type === 'Incomes' ? setEditedItem({ ...editedItem, accountCodeId: selectedOption, debitAccount: selectedOption }) : setEditedItem({ ...editedItem, accountCodeId: selectedOption, creditAccount: selectedOption });
        }
        else if (field === 'AccountCodeName') {
            setEditedItem({ ...editedItem, accountCodeName: selectedOption })
        }
        else {
            setEditedItem({ ...editedItem, accountCaseId: selectedOption })

        }
    };

    const handleCloseEditAddNewAccountCardDocument = () => {
        setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '' })
        setEditedAddAccountCard(false);
    };

    const handleRadioChange = (e) => {
        if (e.target.value == 'done') {

            if (!editedItem.sortCodeId) {
                toast.error();
                return
            }
            setStatusCheck(true);
        }
        else if (e.target.value == 'notDone') {
            setStatusCheck(false);
        }
        setEditedItem({ ...editedItem, ["status"]: e.target.value });
    };



    const handleAccountCardSave = async (AccountCodeId, AccountCodeName, AccountCaseId) => {
        const response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, AccountCaseId, token);
        if (!response2.ok) {
            throw new Error(`HTTP error! status: ${response2.status}`);
        }
    };

    const handleChange = (field, value) => {
        console.log(field, value);

        if (field === 'sortCodeId') {
            setEditedItem({ ...editedItem, [field]: value });
            const sortCode = sortCodeIdOptionsForEditModals.filter(sc => sc.sortCodeId === value)[0];

            let fieldName = field;
            if (Constants.SortCodesWith66Vat.includes(fieldName)) {
                setEditedItem({ ...editedItem, vat: 66, [field]: value });
            } else if (Constants.SortCodesWithZeroVat.includes(fieldName) || sortCode.sortCodeVatType === 0) {
                setEditedItem({ ...editedItem, vat: 0, [field]: value });
            } else {
                setEditedItem({ ...editedItem, vat: 100, [field]: value });
            }
        }
        else {
            console.log(sortCodeIdOptions);
            // const sortCodeName = sortCodeIdOptions.map(option => parseInt(option.sortCodeId) === e.target.value)
            setEditedItem({ ...editedItem, [field]: value });
        }
    };

    const [reportingMonthDate, setReportingMonthDate] = useState(new Date());

    const handleVatRadioChange = (e) => {
        setEditedItem({ ...editedItem, ["vat"]: parseFloat(e.target.value) });
    };

    const handleDateChange = (e, field) => {
        setEditedItem({ ...editedItem, [field]: e });
    };

    const getAccountCardName = (accountId) => {
        const accountCard = accountCardOptions.find(option => option.value === accountId);
        return accountCard ? accountCard.label : '--'; // Fallback to 'Unknown Account' if not found
    };
    const getSortCodeName = (sortCodeId) => {
        const sortCode = sortCodeIdOptions.find(option => option.value === sortCodeId);
        return sortCode ? sortCode.label : '--'; // Fallback to 'Unknown Account' if not found
    };
    const handleReopenEdit = async (item, index) => {
        await fetchEnums(user, token, client);
        item.key = item.id;
        handleEdit(item);
    };

    const handleAccountChange = (selectedOption, field) => {
        if (field === 'debitAccount' || field === 'creditAccount') {
            const accountCardEditDownload = accountCardEditDownloadOptions.find(option => option.cardId === selectedOption.value);
            const name = accountCardEditDownload?.name;
            const accountCaseId = accountCardEditDownload?.accountCaseId;
            if (selectedOption.value < 0) {

                setOriginalAccountCardId(editedItem.cardId)
            }
            if (editedItem.docType === 'Incomes') {
                setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: selectedOption.value, debitAccount: selectedOption.value, accountCaseId: accountCaseId })
            }
            else if (editedItem.docType === 'Expenses') {
                setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: selectedOption.value, creditAccount: selectedOption.value, accountCaseId: accountCaseId });
            }
            else if (editedItem.docType === 'JournalEntries') {
                setEditedItem({
                    ...editedItem,
                    [field]: selectedOption.value
                });
            }
        } else if (field === 'accountCodeName') {
            if (selectedOption.value1 === 'AddItem') {
                setEditedAddAccountCard(true);
                setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '', accountCaseId: '' })
            }
            else {
                const accountCardEdit = accountCardEditDownloadOptions.find(option =>
                    option.cardId === selectedOption.value
                );
                const name = accountCardEdit?.name;
                const accountCaseId = accountCardEdit?.accountCaseId;
                const cardId = selectedOption.value;
                if (editedItem.docType === 'Incomes') {
                    setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: cardId, debitAccount: cardId, accountCaseId: accountCaseId })
                }
                else if (editedItem.docType === 'Expenses') {
                    setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: cardId, creditAccount: cardId, accountCaseId: accountCaseId });
                }
                else if (editedItem.docType === 'JournalEntries') {
                    setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: cardId, accountCaseId: accountCaseId })
                }
            }
        }
    };


    // Function to load the surrounding items (previous and next)
    const loadSurroundingItems = (rowIndex, type, transactionArray) => {
        return new Promise((resolve, reject) => {
            const updatedDictionary = { ...transactionDetailsDictionary };
            const promises = [];

            // Load the previous transaction if it exists and is not already loaded
            if (rowIndex > 0 && !transactionDetailsDictionary[transactionArray[rowIndex - 1].id]) {
                const previousPromise = DocumentService.GetDocumentById(transactionArray[rowIndex - 1].type, client.caseId, transactionArray[rowIndex - 1].id, token)
                    .then(response => response.ok ? response.json() : null)
                    .then(data => {
                        if (data) {
                            data.docType = transactionArray[rowIndex - 1].type;
                            updatedDictionary[transactionArray[rowIndex - 1].id] = data;
                            setTransactionDetailsDictionary(updatedDictionary);
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching previous transaction:', error);
                        reject(error);
                    });

                promises.push(previousPromise);
            }

            // Load the next transaction if it exists and is not already loaded
            if (rowIndex < transactionArray.length - 1 && !transactionDetailsDictionary[transactionArray[rowIndex + 1].id]) {
                const nextPromise = DocumentService.GetDocumentById(transactionArray[rowIndex + 1].type, client.caseId, transactionArray[rowIndex + 1].id, token)
                    .then(response => response.ok ? response.json() : null)
                    .then(data => {
                        if (data) {
                            data.docType = transactionArray[rowIndex + 1].type;
                            updatedDictionary[transactionArray[rowIndex + 1].id] = data;
                            setTransactionDetailsDictionary(updatedDictionary);
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching next transaction:', error);
                        reject(error);
                    });

                promises.push(nextPromise);
            }

            // Resolve when all promises are complete
            Promise.all(promises).then(() => resolve()).catch(error => reject(error));
        });
    };
    const handleFinalizeEntries = async () => {
        setLoading(true);

        setIsJournalEntriesListOpen(false);
        try {
            const payload = journalEntriesList.map((entry) => ({
                caseId: client.caseId,
                accountantId: client.accountantId,
                sortCodeId: entry.sortCodeId,
                reference: entry.reference,
                description: entry.description,
                amount: Math.abs(entry.amount),
                documentDate: new Date(entry.documentDate).toISOString(),
                reportingMonthDate: reportingMonthDate // Forward the selected reportingMonthDate
                    ? new Date(reportingMonthDate).toISOString()
                    : null,
                reportingMonthDate: new Date(entry.reportingMonthDate).toISOString(),
                creditAccount: entry.creditAccount,
                debitAccount: entry.debitAccount,
                vat: 100, // Assuming this is fixed
            }));


            const response = await DocumentService.UploadDocumentPayloads("JournalEntries", payload, token);

            if (response.ok) {
                setShouldMatch(true); // Set flag to trigger handleMatch
                const data = await response.json(); // Parse the response as JSON
                const newJournalEntryIds = data.journalEntryIds; // Extract the new journal entry IDs


                // Update the state for selectedInternalRows with new IDs
                setSelectedCreditRows(prevRows => {
                    const updatedRows = [...prevRows, ...newJournalEntryIds];
                    afterStateUpdate(updatedRows); // Call the afterStateUpdate function
                    return updatedRows;
                });

                toast.success(t('documentUploadSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentUploadError', { errorText: 'Error uploading documents' }));
        }

        setLoading(false);
    };

    const getMatchedDisplayedRows = () => {
        const result = [];

        // Determine which column and direction to sort by
        let sortField = null;
        let sortDirection = 'asc'; // Default sort direction

        if (sortModel && sortModel.length > 0) {
            sortField = sortModel[0].field;
            sortDirection = sortModel[0].sort;
        }

        // Fields that are part of the parent rows
        const parentSortFields = ['matchNumber', 'transactionDate', 'matchDate'];

        // Is the column being sorted a parent row field?
        const isParentSortField = parentSortFields.includes(sortField);

        // Sort parent rows if the selected column belongs to them
        const sortedMatchData = [...matchData].sort((a, b) => {
            if (isParentSortField && sortField) {
                const valueA = a[sortField];
                const valueB = b[sortField];

                if (valueA === undefined) return 1;
                if (valueB === undefined) return -1;

                if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
                if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
                return 0;
            } else {
                // Keep the original order if sorting by a sub-row field
                return 0;
            }
        });



        // Process each parent row
        sortedMatchData.forEach((row) => {
            // Add parent row to the result
            result.push({ ...row, level: 0, isSubRow: false });

            // Check if the row is expanded, then process sub-rows
            if (expandedMatchedRows[row.id]) {
                // Handle external transactions (sub-rows)
                if (row.externalTransactions && row.externalTransactions.length > 0) {
                    // Header for external transactions
                    result.push({
                        id: `${row.id}-external-header`,
                        parentId: row.id,
                        isSubRow: true,
                        isHeader: true,
                        level: 1,
                        transactionType: t('BankCommands'),
                    });

                    // Sort sub-rows if the selected column belongs to sub-rows
                    let sortedExternalTransactions = [...row.externalTransactions];
                    if (!isParentSortField && sortField) {
                        sortedExternalTransactions.sort((a, b) => {
                            const valueA = a[sortField];
                            const valueB = b[sortField];

                            if (valueA === undefined) return 1;
                            if (valueB === undefined) return -1;

                            if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
                            if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }

                    // Add sorted external transactions (sub-rows)
                    sortedExternalTransactions.forEach((transaction) => {
                        result.push({
                            ...transaction,
                            id: transaction.id,
                            parentId: row.id,
                            isSubRow: true,
                            isHeader: false,
                            level: 2,
                            transactionType: 'External',
                        });
                    });
                }

                // Handle internal transactions (sub-rows)
                if (row.internalTransactions && row.internalTransactions.length > 0) {
                    // Header for internal transactions
                    result.push({
                        id: `${row.id}-internal-header`,
                        parentId: row.id,
                        isSubRow: true,
                        isHeader: true,
                        level: 1,
                        transactionType: t('BookCommands'),
                    });

                    // Sort sub-rows if needed
                    let sortedInternalTransactions = [...row.internalTransactions];
                    if (!isParentSortField && sortField) {
                        sortedInternalTransactions.sort((a, b) => {
                            const valueA = a[sortField];
                            const valueB = b[sortField];

                            if (valueA === undefined) return 1;
                            if (valueB === undefined) return -1;

                            if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
                            if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }

                    // Add sorted internal transactions (sub-rows)
                    sortedInternalTransactions.forEach((transaction) => {
                        result.push({
                            ...transaction,
                            id: transaction.id,
                            parentId: row.id,
                            isSubRow: true,
                            isHeader: false,
                            level: 2,
                            transactionType: 'Internal',
                        });
                    });
                }
            }
        });

        return result;
    };

    const matchedDisplayedRows = useMemo(() => getMatchedDisplayedRows(), [matchData, expandedMatchedRows, sortModel]);


    const setCurrentItem = async (transaction, rowIndex) => {
        try {

            let type = transaction.type1


            let myItem;

            // Check if transactionDetailsDictionary has the item with the transaction ID
            if (transactionDetailsDictionary[transaction.id]) {
                myItem = transactionDetailsDictionary[transaction.id];
            } else {
                // If not, make an API call to fetch the item
                let response;
                if (transaction.type1 === 'JournalEntry') {
                    response = await DocumentService.GetDocumentById('JournalEntries', client.caseId, transaction.id, token);
                    type = transaction.type1;
                } else {
                    response = await DocumentService.GetDocumentById(type, client.caseId, transaction.id, token);
                }

                if (response.ok) {
                    myItem = await response.json();
                    myItem.docType = type;
                    // Store the fetched item in transactionDetailsDictionary with the transaction ID as key
                    const updatedDictionary = { ...transactionDetailsDictionary };
                    updatedDictionary[transaction.id] = myItem;
                    setTransactionDetailsDictionary(updatedDictionary);
                } else {
                    console.error('Error fetching document details');
                    return; // Exit the function if the API call fails
                }
            }

            // Load the previous and next items asynchronously without await
            loadSurroundingItems(rowIndex, type, filteredAndSortedTransactions);

            if (!type.includes("PaymentAcceptances")) {
                if (transaction.type1 !== 'JournalEntries') {
                    setCurrentIndex(rowIndex);
                    setItemToDelete({ id: transaction.id, type: transaction.docType });
                    setEditedItem({ ...myItem });
                    setEditedPopupItem(true);
                    setEditedJournalItem(false);
                    setPaymentModal(false);
                } else {
                    setCurrentIndex(rowIndex);
                    setItemToDelete({ id: transaction.id, type: transaction.docType });
                    setEditedItem({ ...myItem });
                    setEditedPopupItem(true);
                    setEditedJournalItem(true);
                    setPaymentModal(false);
                }
            } else {
                setCurrentIndex(rowIndex);
                setOpenInvoiceCreatorModal(true);
                setAddEditableItem({ ...myItem });
                setPaymentModal(true);
                setEditedJournalItem(false);
                setEditedPopupItem(false);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const getItemAtIndex = (transactions, newIndex) => {
        // Convert the object to an array of its values
        const transactionsArray = Object.values(transactions);

        // Check if the index is within the bounds of the array
        if (newIndex >= 0 && newIndex < transactionsArray.length) {
            return transactionsArray[newIndex];
        } else {
            console.error('Index out of bounds');
            return null;
        }
    };

    const filteredAndSortedTransactions = useMemo(() => {
        if (!transactions || transactions.length === 0) {
            return [];
        }

        // Filtering the transactions based on searchQuery
        const filteredData = transactions.filter((transaction) => {
            const query = searchQuery.toLowerCase();
            return (
                transaction?.description?.toLowerCase().includes(query) ||
                transaction?.reference1?.toLowerCase().includes(query) ||
                transaction?.reference2?.toLowerCase().includes(query) ||
                transaction?.debitAccount?.toString().includes(query) ||
                transaction?.creditAccount?.toString().includes(query) ||
                transaction?.balance?.toString().includes(query) ||
                new Date(transaction.documentDate).toLocaleDateString().includes(query) ||
                new Date(transaction.uploadDate).toLocaleDateString().includes(query)
            );
        });

        // Sorting the filtered transactions based on sortConfig
        if (sortConfig.key) {
            filteredData.sort((a, b) => {
                const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
                const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined

                if (sortConfig.type === 'number') {
                    return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                } else if (sortConfig.type === 'date') {
                    return sortConfig.direction === 'ascending'
                        ? new Date(aValue) - new Date(bValue)
                        : new Date(bValue) - new Date(aValue);
                } else { // default to string comparison
                    return sortConfig.direction === 'ascending'
                        ? aValue?.localeCompare(bValue)
                        : bValue?.localeCompare(aValue);
                }
            });
        }
        const updatedDataForPdf = {
            ...dataForPdf,
            transactions: filteredData,
        };
        setSortedDataForPdf(updatedDataForPdf);

        return filteredData;
    }, [sortedTransactions, searchQuery, sortConfig]);

    const handleNext = () => {
        let newIndex = currentIndex;
        if (creditOrDebitEdit === 'credit') {
            while (newIndex < creditRows.length - 1) {
                newIndex += 1;
                const nextItem = creditRows[newIndex];
                const currentItem = creditRows[currentIndex];
                if (nextItem.id !== currentItem.id) {
                    handleEdit(nextItem);
                    break;
                }
            }
        } else {
            while (newIndex < debitRows.length - 1) {
                newIndex += 1;
                const nextItem = debitRows[newIndex];
                const currentItem = debitRows[currentIndex];
                if (nextItem.id !== currentItem.id) {
                    handleEdit(nextItem);
                    break;
                }
            }
        }

    };

    const handlePrevious = () => {
        let newIndex = currentIndex;

        if (creditOrDebitEdit === 'credit') {
            while (newIndex > 0) {
                newIndex -= 1;
                const previousItem = creditRows[newIndex];
                const currentItem = creditRows[currentIndex];

                // Check if the previous item has a different id
                if (previousItem.id !== currentItem.id) {
                    handleEdit(previousItem);
                    break;
                }
            }
        } else {
            while (newIndex > 0) {
                newIndex -= 1;
                const previousItem = debitRows[newIndex];
                const currentItem = debitRows[currentIndex];

                // Check if the previous item has a different id
                if (previousItem.id !== currentItem.id) {
                    handleEdit(previousItem);
                    break;
                }
            }
        }

    };

    const handleInternalExpandClick = (id) => {
        setExpandedInternalRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const InternalRows = [
        {
            id: 1,
            matchNumber: 1001,
            description: 'Value 1',
            reference: 'Value 2',
            documentDate: '2023-10-15', // Replace 'Value 3' with a valid date
            amount: 100, // Add amount field
            status: 'Filled',
            subRows: [
                {
                    id: 2,
                    matchNumber: 2001,
                    description: 'Sub Value 1',
                    reference: 'Sub Value 2',
                    documentDate: '2023-10-14', // Replace 'Sub Value 3' with a valid date
                    amount: 50, // Add amount field
                    status: 'Filled',
                },
                {
                    id: 3,
                    matchNumber: 2002,
                    description: 'Sub Value 4',
                    reference: 'Sub Value 5',
                    documentDate: '2023-10-13', // Replace 'Sub Value 6' with a valid date
                    amount: 75, // Add amount field
                    status: 'PartiallyFilled',
                },
            ],
        },
        {
            id: 4,
            matchNumber: 1002,
            description: 'Value 4',
            reference: 'Value 5',
            documentDate: '2023-10-12', // Replace 'Value 6' with a valid date
            amount: 200, // Add amount field
            status: 'Rejected',
        },
        // Add more rows if needed
    ];

    const CreditColumns = [
        // Data columns in reverse order for RTL
        {
            field: 'description',
            headerName: t('Description'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'reference',
            headerName: t('Reference'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'debitAccount',
            headerName: t('ContraAccount'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {
                const accountCard = accountCardsData.find(c => c.cardId === params);
                return accountCard ? accountCard.name : "";
            }
        },
        {
            field: 'documentDate',
            headerName: t('Document Date'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (v1, v2, param1, param2) => {
                const date1 = new Date(parseCustomDate(v1));
                const date2 = new Date(parseCustomDate(v2));
                return date1 - date2;
            },
        },
        {
            field: 'amount',
            headerName: t('Amount'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (v1, v2, param1, param2) => {
                // Helper function to parse the amount string
                const parseAmount = (value) => {
                    if (!value) return 0; // Return 0 for empty or null values

                    // Check if the value has parentheses (indicating a negative number)
                    const isNegative = value.includes('(');

                    // Remove commas and parentheses, then convert to a float
                    let cleanedValue = value.replace(/[(),]/g, '');

                    // Convert to a float. If parsing fails, return 0 as fallback
                    let amount = parseFloat(cleanedValue);
                    if (isNaN(amount)) amount = 0;

                    // Return negative if the value had parentheses
                    return isNegative ? -amount : amount;
                };

                const amount1 = parseAmount(v1);
                const amount2 = parseAmount(v2);

                // Debugging: log the parsed amounts


                // Ensure consistent sorting: return -1, 0, or 1
                return amount1 === amount2 ? 0 : amount1 < amount2 ? -1 : 1;
            },
        },
        // 'actions' column before 'expand' column
        {
            field: 'actions',
            headerName: t('Actions'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const handleDeleteClick = () => {
                    const matchedRow = matchedDisplayedRows.find(
                        (match) => match.matchNumber === params.row.cardMatchNumber
                    );
                    if (matchedRow) {
                        handleRemoveMatchClick(matchedRow);
                    }
                };
                const hasValidCardMatchNumber = params.row.cardMatchNumber !== undefined && params.row.cardMatchNumber !== -1 && params.row.cardMatchNumber !== 0;
                return !hasValidCardMatchNumber ? (
                    <IconButton
                        size="small"
                        onClick={() => handleEdit(params.row)}
                    >
                        <img src={editIconImage} alt="Edit" />
                    </IconButton>
                ) : (
                    <Tooltip title={t("RemoveMatch")}>
                        <IconButton size="small" onClick={handleDeleteClick}>
                            <CancelRounded style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>
                );;
            },
        },
    ];
    if (showingMatches) {
        CreditColumns.push({
            field: 'cardMatchNumber',
            headerName: t('MatchNumber'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {

                const value = params;
                return value === 0 || value === -1 ? "-" : value;
            }
        });
    }

    const DebitColumns = [
        // Data columns in reverse order for RTL
        {
            field: 'description',
            headerName: t('Description'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'reference',
            headerName: t('Reference'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'creditAccount',
            headerName: t('ContraAccount'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {
                // Find the matching account in accountCardsData by creditAccount ID
                const accountCard = accountCardsData.find(c => c.cardId === params);
                return accountCard ? accountCard.name : "";
            }
        },
        {
            field: 'documentDate',
            headerName: t('Document Date'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (v1, v2, param1, param2) => {
                const date1 = new Date(parseCustomDate(v1));
                const date2 = new Date(parseCustomDate(v2));
                return date1 - date2;
            },
        },
        {
            field: 'amount',
            headerName: t('Amount'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (v1, v2, param1, param2) => {
                // Helper function to parse the amount string
                const parseAmount = (value) => {
                    if (!value) return 0; // Return 0 for empty or null values

                    // Check if the value has parentheses (indicating a negative number)
                    const isNegative = value.includes('(');

                    // Remove commas and parentheses, then convert to a float
                    let cleanedValue = value.replace(/[(),]/g, '');

                    // Convert to a float. If parsing fails, return 0 as fallback
                    let amount = parseFloat(cleanedValue);
                    if (isNaN(amount)) amount = 0;

                    // Return negative if the value had parentheses
                    return isNegative ? -amount : amount;
                };

                const amount1 = parseAmount(v1);
                const amount2 = parseAmount(v2);

                // Debugging: log the parsed amounts


                // Ensure consistent sorting: return -1, 0, or 1
                return amount1 === amount2 ? 0 : amount1 < amount2 ? -1 : 1;
            },
        },
        // 'actions' column before 'expand' column
        {
            field: 'actions',
            headerName: t('Actions'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const handleDeleteClick = () => {
                    const matchedRow = matchedDisplayedRows.find(
                        (match) => match.matchNumber === params.row.cardMatchNumber
                    );
                    if (matchedRow) {
                        handleRemoveMatchClick(matchedRow);
                    }
                };
                const hasValidCardMatchNumber = params.row.cardMatchNumber !== undefined && params.row.cardMatchNumber !== -1 && params.row.cardMatchNumber !== 0;
                return !hasValidCardMatchNumber ? (
                    <IconButton
                        size="small"
                        onClick={() => handleEdit(params.row)}
                    >
                        <img src={editIconImage} alt="Edit" />
                    </IconButton>
                ) : (
                    <Tooltip title={t("RemoveMatch")}>
                        <IconButton size="small" onClick={handleDeleteClick}>
                            <CancelRounded style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>
                );
            },
        },

    ];

    if (showingMatches) {
        DebitColumns.push({
            field: 'cardMatchNumber',
            headerName: t('MatchNumber'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {

                const value = params;
                return value === 0 || value === -1 ? "-" : value;
            }
        });
    }



    // Function to get the displayed rows based on expanded state
    const getDisplayedRows = () => {
        const result = [];

        const addRowAndSubRows = (row, level = 0) => {
            result.push({ ...row, level });
            if (expandedInternalRows[row.id] && row.subRows) {
                row.subRows.forEach((subRow) => {
                    addRowAndSubRows(subRow, level + 1);
                });
            }
        };

        if(fetchType === 'Matched'){
            console.log(creditMatchedRows);
            creditMatchedRows.forEach((row) => {
                addRowAndSubRows(row);
            });
        }else{
            console.log(creditRows);
            
            creditRows.forEach((row) => {
                addRowAndSubRows(row);
            });
        }



        return creditRows;
    };

    // useEffect to update displayedRows whenever internalRows or expandedInternalRows change
    useEffect(() => {
        
        const updatedDisplayedRows = getDisplayedRows();
        console.log('here',updatedDisplayedRows);

        setDisplayedRows(updatedDisplayedRows);
    }, [creditRows, creditMatchedRows]);

    const creditDisplayedRows = getDisplayedRows();


    

    const getFilteredSortedRows = () => {
        // Get the sorted rows
        const sortedRows = apiRef.current.getSortedRows();

        // Access the filteredRowsLookup from the grid state
        const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup;

        if (filteredRowsLookup) {
            // Create a Set of IDs of rows that pass the filter
            const filteredRowIdsSet = new Set(
                Object.entries(filteredRowsLookup)
                    .filter(([_, isVisible]) => isVisible)
                    .map(([id]) => id)
            );

            // Filter the sorted rows to include only those that are in the filteredRowIdsSet
            const filteredSortedRows = sortedRows.filter((row) =>
                filteredRowIdsSet.has(row.id)
            );

            return filteredSortedRows;
        } else {
            // If no filtering is applied, return the sorted rows
            return sortedRows;
        }
    };

    const handleSortModelChange = () => {
        const sortedRows = getFilteredSortedRows();
        console.log(sortedRows);

        setSortedTransactions(sortedRows);
    };



    // const DebitColumns = [

    //     {
    //         field: 'description',
    //         headerName: t('Description'),
    //         flex:1,
    //         headerAlign: 'center',
    //         align: 'center',
    //     },
    //     {
    //         field: 'reference',
    //         headerName: t('Reference'),
    //         flex:1,
    //         headerAlign: 'center',
    //         align: 'center',
    //     },
    //     {
    //         field: 'transactionDate',
    //         headerName: t('Document Date'),
    //         flex:1,
    //         headerAlign: 'center',
    //         align: 'center',
    //         sortComparator: (v1, v2, param1, param2) => {
    //             const date1 = new Date(parseCustomDate(v1));
    //             const date2 = new Date(parseCustomDate(v2));
    //             return date1 - date2;
    //         },
    //     },
    //     {
    //         field: 'amount',
    //         headerName: t('Amount'),
    //         flex:1,
    //         headerAlign: 'center',
    //         align: 'center',
    //         sortComparator: (v1, v2, param1, param2) => {
    //             // Helper function to parse the amount string
    //             const parseAmount = (value) => {
    //                 if (!value) return 0; // Return 0 for empty or null values

    //                 // Check if the value has parentheses (indicating a negative number)
    //                 const isNegative = value.includes('(');

    //                 // Remove commas and parentheses, then convert to a float
    //                 let cleanedValue = value.replace(/[(),]/g, '');

    //                 // Convert to a float. If parsing fails, return 0 as fallback
    //                 let amount = parseFloat(cleanedValue);
    //                 if (isNaN(amount)) amount = 0;

    //                 // Return negative if the value had parentheses
    //                 return isNegative ? -amount : amount;
    //             };

    //             const amount1 = parseAmount(v1);
    //             const amount2 = parseAmount(v2);

    //             // Debugging: log the parsed amounts


    //             // Ensure consistent sorting: return -1, 0, or 1
    //             return amount1 === amount2 ? 0 : amount1 < amount2 ? -1 : 1;
    //         },
    //     },
    // ];
    const handleCreditExpandClick = (id) => {
        setExpandedCreditRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };


    const getDebitDisplayedRows = () => {
        const result = [];

        const addRowAndSubRows = (row, level = 0) => {
            result.push({ ...row, level });
            if (expandedCreditRows[row.id] && row.subRows) {
                row.subRows.forEach((subRow) => {
                    addRowAndSubRows(subRow, level + 1);
                });
            }
        };
        
        if(fetchType === 'Matched'){
            debitMatchedRows.forEach((row) => {
                addRowAndSubRows(row);
            });
        }else{
            debitRows.forEach((row) => {
                addRowAndSubRows(row);
            });
        }
        

        return result;
    };

    const debitDisplayedRows = getDebitDisplayedRows();

    // Function to handle sorting, collapse all rows before sorting, and restore after sorting
    const handleSort = (columnField) => {
        // Store the current expanded rows
        const previouslyExpandedRows = { ...expandedMatchedRows };

        // Collapse all rows before sorting
        setExpandedMatchedRows({});

        // Perform the sorting
        const sortedRows = [...getMatchedDisplayedRows()].sort((a, b) => {
            const valueA = a[columnField];
            const valueB = b[columnField];

            // Example of comparing values (you can modify it based on the field type)
            if (valueA === undefined) return 1;
            if (valueB === undefined) return -1;
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        });

        // After sorting, update matchData with sorted rows
        setMatchData(sortedRows);

        // Restore the expanded rows after sorting
        setTimeout(() => {
            setExpandedMatchedRows(previouslyExpandedRows);
        }, 0);
    };

    // Matched Columns
    const MatchedColumns = [
        {
            field: 'matchNumber',
            headerName: t('MatchNumber'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (!params.row.isSubRow ? params.value : ''),
            sortComparator: (v1, v2, param1, param2) => {
                const row1 = param1.row || {};
                const row2 = param2.row || {};

                if (row1.isSubRow && row2.isSubRow) {
                    return 0;
                }
                if (row1.isSubRow && !row2.isSubRow) {
                    return 1;
                }
                if (!row1.isSubRow && row2.isSubRow) {
                    return -1;
                }

                const matchNumber1 = v1 !== undefined ? v1 : 0;
                const matchNumber2 = v2 !== undefined ? v2 : 0;
                return matchNumber1 === matchNumber2 ? 0 : matchNumber1 < matchNumber2 ? -1 : 1;
            }
        },
        {
            field: 'transactionDate',
            headerName: t('TransactionDate'),
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const row = params.row;
                if (row.isSubRow && !row.isHeader) {
                    return formatDate(row.documentDate);
                } else if (!row.isSubRow) {
                    return formatDate(row.transactionDate);
                } else {
                    return '';
                }
            },
            sortComparator: (v1, v2, param1, param2) => {
                const date1 = new Date(v1);
                const date2 = new Date(v2);
                return date1 - date2;
            },
        },
        {
            field: 'matchDate',
            headerName: t('MatchDate'),
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (!params.row.isSubRow ? formatDate(params.value) : ''),
            sortComparator: (v1, v2, param1, param2) => {
                const date1 = new Date(v1);
                const date2 = new Date(v2);
                return date1 - date2;
            }
        },
        {
            field: 'description',
            headerName: t('Description'),
            width: 200,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => {
                const row = params.row;
                const indentation = row.level * 20;
                if (row.isHeader) {
                    return (
                        <div style={{ paddingLeft: `${indentation}px`, fontWeight: 'bold' }}>
                            {t(row.transactionType)}
                        </div>
                    );
                } else if (row.isSubRow) {
                    return <div style={{ paddingLeft: `${indentation}px` }}>{row.description}</div>;
                } else {
                    return '';
                }
            },
            sortable: true,
            sortComparator: () => 0, // Disable default sorting
        },
        {
            field: 'reference',
            headerName: t('Reference'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const row = params.row;
                if (row.isSubRow && !row.isHeader) {
                    return row.reference || '';
                } else {
                    return '';
                }
            },
            sortable: true,
            sortComparator: () => 0, // Disable default sorting
        },
        {
            field: 'documentDate',
            headerName: t('Document Date'),
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const row = params.row;
                if (row.isSubRow && !row.isHeader) {
                    return formatDate(row.documentDate);
                } else {
                    return '';
                }
            },
            sortable: true,
            sortComparator: () => 0, // Disable default sorting
        },
        {
            field: 'amount',
            headerName: t('Amount'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const row = params.row;
                if (row.isSubRow && !row.isHeader) {
                    return beautifyNumber(row.amount);
                } else {
                    return '';
                }
            },
            sortable: true,
            sortComparator: () => 0, // Disable default sorting
        },
        {
            field: 'type',
            headerName: t('Type'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const row = params.row;
                if (row.isSubRow && !row.isHeader) {
                    const typeParts = row.type.split('-'); // Split the type by '-'
                    if (typeParts.length > 1) {
                        // Translate the second part (Checks, etc.)
                        const translatedSecondPart = t(typeParts[1]);
                        return translatedSecondPart;
                    } else {
                        return t(row.type); // If there is no hyphen, just return the type
                    }
                } else {
                    return '';
                }
            },
            sortable: true,
            sortComparator: () => 0, // Disable default sorting
        },
        {
            field: 'uploadNumber',
            headerName: t('UploadNumber'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const row = params.row;
                if (row.isSubRow && !row.isHeader) {
                    return beautifyNumber(row.uploadNumber);
                } else {
                    return '';
                }
            },
            sortable: true,
            sortComparator: () => 0, // Disable default sorting
        },
        {
            field: 'actions',
            headerName: t('Actions'),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return (
                        <Tooltip title={t("RemoveMatch")}>
                            <IconButton size="small" onClick={() => handleRemoveMatchClick(row)}>
                                <CancelRounded style={{ color: '#E57C22' }} />
                            </IconButton>
                        </Tooltip>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            field: 'expand',
            headerName: '',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    const isExpandable =
                        (row.externalTransactions && row.externalTransactions.length > 0) ||
                        (row.internalTransactions && row.internalTransactions.length > 0);
                    return isExpandable ? (
                        <IconButton size="small" onClick={() => handleMatchedExpandClick(row.id)}>
                            {expandedMatchedRows[row.id] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    ) : null;
                } else {
                    return null;
                }
            },
        },
    ];






    // Function to get displayed rows



    // const matchedDisplayedRows = getMatchedDisplayedRows();



    // Handle Create Journal Entries checkbox
    const handleCreateJournalEntries = (checked) => {
        // Filter the selected rows from externalDisplayedRows
        const selectedRows = debitDisplayedRows.filter(row => selectedDebitRows.includes(row.id));

        // Open the create journal entries dialog
        setCreateJournalEntries(checked);
        setIsCreateDialogOpen(true);


        if (!checked) {
            // Case 1: Checked is false (existing behavior)
            // Find the row with the oldest transactionDate
            const oldestRow = selectedRows.reduce((oldest, current) => {
                return new Date(parseCustomDate(current.transactionDate)) < new Date(parseCustomDate(oldest.transactionDate)) ? current : oldest;
            }, selectedRows[0]); // Initialize with the first row


            // Get the oldest transactionDate and description
            const oldestDate = new Date(parseCustomDate(oldestRow.documentDate)).toISOString().split('T')[0];
            const oldestDescription = oldestRow.description;

            // Calculate the total sum
            const Sum = externalSum - internalSum;

            // Handle the logic for assigning the creditAccount or debitAccount based on externalSum
            if (Sum < 0) {
                handleSelectChange('creditAccount', selectedCardId); // Set creditAccount if externalSum is negative
            } else {
                handleSelectChange('debitAccount', selectedCardId); // Set debitAccount if externalSum is positive
            }

            // Set form values
            handleSelectChange('amount', Sum);
            handleSelectChange('description', oldestDescription);
            handleSelectChange('documentDate', oldestDate);
            handleSelectChange('reportingMonthDate', new Date());
        } else {
            // Case 2: Checked is true (new behavior)
            // Reset form values
            setFormValues({
                confirmationNumber: '',
                accountCaseId: '',
                reference: '',
                description: '',
                documentDate: '',
                reportingMonthDate: new Date(),
                amount: '',
                vat: '',
                sortCodeId: '3',
                creditAccount: '',
                debitAccount: '',
                sortCode: '3',
                accountCard: ''
            });
        }
    };

    const handleMatchEntries = async () => {



        // const externalMatchedIds = matchedPairs.map(match => match.externalId);
        // const internalMatchedIds = matchedPairs.map(match => match.internalId);

        // Prepare the DTO for all matches
        const matchDTOs = matchedPairs.map(pair => {
            const debitRow = debitDisplayedRows.find(row => row.id === pair.externalId);
            const creditRow = displayedRows.find(row => row.id === pair.internalId);





            // Get the oldest transaction date from external rows
            const oldestTransaction = debitDisplayedRows.reduce((oldest, current) => {


                return new Date(parseCustomDate(current.documentDate)) < new Date(parseCustomDate(oldest.documentDate)) ? current : oldest;
            }, debitDisplayedRows[0]);
            console.log(oldestTransaction);
            
            const oldestTransactionDate = oldestTransaction
                ? new Date(parseCustomDate(oldestTransaction.documentDate))
                : new Date();

            return {
                CaseId: client.caseId,
                AccountCodeId: selectedCardId,
                TransactionDate: oldestTransactionDate?.toISOString(),
                DebitTransactionIds: [{ Id: pair.externalId, Type: debitRow.type }],
                CreditTransactionIds: [{ Id: pair.internalId, Type: creditRow.type }]
            };
        });

        try {


            // Call the API to create multiple matches


            await CardMatchService.CreateMultipleMatches(matchDTOs, token);

            handleClearMatches();
            await FetchData()
            toast.success(t('MatchesCreatedSuccessfully'));
        } catch (error) {
            console.error('Error creating matches:', error);
            toast.error(t('ErrorCreatingMatches'));
        }
    };




    // Define a set of colors for matched pairs
    const matchColors = [
        '#FFCDD2', // Light Red
        '#C8E6C9', // Light Green
        '#BBDEFB', // Light Blue
        '#FFF9C4', // Light Yellow
        '#D1C4E9', // Light Purple
        '#FFECB3', // Light Orange
        '#B2DFDB', // Light Teal
    ];

    // Function to get color based on matchId
    const getMatchColor = (matchId) => {
        const index = parseInt(matchId.split('-')[1], 10);
        return matchColors[index % matchColors.length];
    };

    // Function to handle automatic matching

    const computeSimilarity = (externalRow, internalRow, selectedColumns) => {
        let score = 1; // Start with full score assuming all selected columns match.
        // Iterate over the selected columns and check for exact match
        for (const column of selectedColumns) {
            let externalValue = externalRow[column];
            let internalValue = internalRow[column];

            // Special handling for 'amount' column
            if (column === 'amount') {
                const tolerance = 0.01; // Tolerance for amount comparison
                const externalAmount = parseFloat(externalRow.amount1);
                const internalAmount = parseFloat(internalRow.amount1);

                if (isNaN(externalAmount) || isNaN(internalAmount)) {
                    return 0; // If either amount is not a valid number, it's not a match.
                } else {
                    const diff = Math.abs(externalAmount - internalAmount);
                    if (diff > tolerance) {
                        return 0; // If the amounts differ more than the tolerance, it's not a match.
                    }
                }
            } else {
                // For other columns, perform case-insensitive string comparison
                externalValue = externalValue ? String(externalValue).toLowerCase() : '';
                internalValue = internalValue ? String(internalValue).toLowerCase() : '';

                if (externalValue !== internalValue) {
                    return 0; // If any column doesn't match exactly, return 0 (not a match).
                }
            }
        }

        return score; // If all selected columns match, return the full score (1).
    };



    const handleAutoMatchHeaders = (selectedColumns) => {


        if (!selectedColumns.includes('amount')) {
            alert('Please include the Amount column in your selection.');
            return;
        }

        const matches = [];
        const updatedExternalRows = [...debitRows];
        const updatedInternalRows = [...creditRows];
        let matchIdCounter = matchCounter;

        // Collect potential matches with similarity scores
        const potentialMatches = [];

        debitRows.forEach((externalRow, extIndex) => {
            creditRows.forEach((internalRow, intIndex) => {
                const score = computeSimilarity(externalRow, internalRow, selectedColumns);

                if (score > 0) {
                    potentialMatches.push({
                        externalRow,
                        internalRow,
                        score,
                        externalIndex: extIndex,
                        internalIndex: intIndex,
                    });
                }
            });
        });

        // Sort potential matches by descending similarity score
        potentialMatches.sort((a, b) => b.score - a.score);

        // Perform one-to-one matching
        const matchedExternalIds = new Set();  // To track matched external rows
        const matchedInternalIds = new Set();  // To track matched internal rows

        for (const match of potentialMatches) {
            const { externalRow, internalRow, score, externalIndex, internalIndex } = match;

            // Define a threshold for accepting matches
            const threshold = 0.5; // Adjust as needed

            if (
                !matchedExternalIds.has(externalRow.id) && // Check if the external row is already matched
                !matchedInternalIds.has(internalRow.id) && // Check if the internal row is already matched
                score >= threshold
            ) {
                const matchId = `match-${matchIdCounter++}`;
                const colorIndex = parseInt(matchId.split('-')[1], 10) % matchColors.length;
                const rowClassName = `super-app-theme--matched-${colorIndex}`;

                // Update external row
                updatedExternalRows[externalIndex] = {
                    ...externalRow,
                    matchId,
                    rowClassName,
                };

                // Update internal row
                updatedInternalRows[internalIndex] = {
                    ...internalRow,
                    matchId,
                    rowClassName,
                };

                matches.push({
                    externalId: externalRow.id,
                    internalId: internalRow.id,
                    matchId,
                });

                // Mark rows as matched
                matchedExternalIds.add(externalRow.id);
                matchedInternalIds.add(internalRow.id);
            }
        }

        // Sort the rows so that matched rows are placed at the top
        const reorderRows = (rows) => {
            return rows.sort((a, b) => {
                if (a.matchId && !b.matchId) return -1; // a is matched, b is not
                if (!a.matchId && b.matchId) return 1;  // b is matched, a is not
                return 0; // Both are matched or both are unmatched, keep original order
            });
        };

        const reorderedExternalRows = reorderRows(updatedExternalRows);
        const reorderedInternalRows = reorderRows(updatedInternalRows);

        setMatchedPairs(matches);
        setDebitRows(reorderedExternalRows);
        setCreditRows(reorderedInternalRows);

        setMatchCounter(matchIdCounter);

        // Update selection state
        const externalMatchedIds = matches.map((match) => match.externalId);
        const internalMatchedIds = matches.map((match) => match.internalId);

        setSelectedDebitRows(externalMatchedIds);
        setSelectedCreditRows(internalMatchedIds);
    };







    // Function to clear matches
    const handleClearMatches = () => {
        const clearedExternalRows = debitRows.map((row) => {
            const newRow = { ...row };
            delete newRow.matchId;
            delete newRow.rowClassName;
            return newRow;
        });

        const clearedInternalRows = creditRows.map((row) => {
            const newRow = { ...row };
            delete newRow.matchId;
            delete newRow.rowClassName;
            return newRow;
        });

        setDebitRows(clearedExternalRows);
        setCreditRows(clearedInternalRows);
        setSelectedDebitRows([]);
        setSelectedCreditRows([]);
        setMatchedPairs([]);
    };


    const addNewAccountCardData = (newAccountCard) => {


        if (accountCardsData && accountCardsData.length > 0) {
            // Transform accountCardsData
            const transformedData = accountCardsData.map(card => ({
                ...card,
                value: card.cardId, // Set value to cardId
                label: card.name,   // Set label to name

            }));

            // Prepend the "AddAccountCard" option
            const combinedData = [{ name: t('AddAccountCard'), value: 'AddItem' }, ...transformedData];

            // Add the newAccountCard to combinedData with value and label
            const newAccountCardWithProps = {
                ...newAccountCard,
                value: newAccountCard.cardId,  // Set value to cardId
                label: newAccountCard.name,     // Set label to name
                index: accountCardsData.length
            };
            combinedData.push(newAccountCardWithProps);

            // Update processedCardsData
            setAccountCardsData(prevData => [...prevData, newAccountCard]);
            setProcessedCardsData(combinedData);
            setMainCard(newAccountCardWithProps);
        }

    };

    useEffect(() => {


    }, [mainCard]);

    useEffect(() => {


    }, [matchedDisplayedRows]);


    // Function to handle Match action
    const handleMatch = async (selectedInternalRows) => {
        // This contains the list of selected external transaction IDs
        // This contains the list of selected internal transaction IDs

        // Filter the complete row objects from externalDisplayedRows based on selectedExternalRows IDs
        const selectedDebitRowsData = debitDisplayedRows.filter(row => selectedDebitRows.includes(row.id));

        // Filter the complete row objects from internalDisplayedRows based on selectedInternalRows IDs
        const selectedCreditRowsData = displayedRows.filter(row => selectedInternalRows.includes(row.id));

        // Extract external transactions and internal transactions from the filtered rows


        const debitTransactionIds = selectedDebitRowsData.map(row => ({ Id: row.id, Type: row.type }));
        const creditTransactions = selectedCreditRowsData.map(row => ({ Id: row.id, Type: row.type }));

        // Find the row with the oldest transactionDate in selectedExternalRowsData
        const oldestTransaction = selectedDebitRowsData.reduce((oldest, current) => {
            return new Date(parseCustomDate(current.documentDate)) < new Date(parseCustomDate(oldest.documentDate)) ? current : oldest;
        }, selectedDebitRowsData[0]);




        const oldestTransactionDate = oldestTransaction
            ? new Date(parseCustomDate(oldestTransaction.documentDate))
            : new Date();


        const matchDTO = {
            CaseId: client.caseId, // Replace with actual CaseId variable
            AccountCodeId: selectedCardId, // Replace with actual AccountCodeId variable
            TransactionDate: oldestTransactionDate.toISOString(), // Use the oldest transaction date
            DebitTransactionIds: debitTransactionIds,
            CreditTransactionIds: creditTransactions
        };

        try {
            // Send the request to create the match
            const response = await CardMatchService.CreateMatch(matchDTO, token); // Pass auth token if necessary
            if (response.ok) {

                // Optionally refresh data or update UI
                toast.success(t("MatchCreatedSuccessfully"));
                handleClearMatches();
                await FetchData()
            } else {
                console.error('Failed to create match:', response.statusText);
                toast.error(t("ErrorCreatingMatch"))
            }
        } catch (error) {
            console.error('Error creating match:', error);
            toast.error(t("ErrorCreatingMatch"))

        }
    };


    // Close dialog
    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false);

    };





    const handleEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('BankTransactions_EndDate', endDate)
        setEndDate(endDate);
        FetchData();

    };

    const handleChangeCreateBank = (field, value) => {
        if (field === 'bankBranch') {
            setBankBranch(value);
        } else if (field === 'bankAccountNumber') {
            setBankAccountNumber(value);
        } else if (field === 'itemName') {
            // setItemName(value);
        }
    };


    const handleRangeChange = async () => {

        FetchData();

        setIsMatchDate(!isMatchDate);
        localStorage.setItem('BankTransactions_IsRange', !isMatchDate);
    }



    async function fetchMatchDataOnDateRangeChange(startDate, endDate, isMatchDate, mixThemTogether = false) {
        setLoading(true);
        const minStartDate = new Date(0);
        const effectiveStartDate = startDate ? startDate : minStartDate;
        const formattedStartDate = effectiveStartDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        let sumCredit = 0;
        let sumDebit = 0;
        let balance = 0;

        try {
            const cardMatchResponse = await CardMatchService.GetMatchedByDateRange(
                client.caseId,
                formattedStartDate,
                formattedEndDate,
                selectedCardId,
                isMatchDate,
                token
            );


            if (cardMatchResponse.ok) {
                const externalMatchData = await cardMatchResponse.json();
                console.log(externalMatchData);
                

                // Extract all credit and debit transactions
                const allCreditTransactions = externalMatchData.flatMap(match =>
                    match.creditTransactions.map(transaction => ({
                        ...transaction,
                        cardMatchNumber: match.matchNumber // Add MatchNumber to each credit transaction
                    }))
                );
                const allDebitTransactions = externalMatchData.flatMap(match =>
                    match.debitTransactions.map(transaction => ({
                        ...transaction,
                        cardMatchNumber: match.matchNumber // Add MatchNumber to each debit transaction
                    }))
                );


                // Format the extracted credit and debit transactions
                const formattedCreditRows = allCreditTransactions
                    .filter(row => row.creditAccount === selectedCardId)
                    .map(row => ({
                        id: row.id,
                        paymentAcceptanceId: row.paymentAcceptanceId,
                        description: row.description,
                        reference: row.reference,
                        amount: beautifyNumber(row.amount),
                        amount1: row.amount,
                        documentDate: row.documentDate ? formatDate(row.documentDate) : '',
                        subRows: row.subRows || [],
                        creditAccount: row.creditAccount,
                        debitAccount: row.debitAccount,
                        type: row.type,
                        cardMatchNumber: row.cardMatchNumber ?? 0
                    }));

                const formattedDebitRows = allDebitTransactions
                    .filter(row => row.debitAccount === selectedCardId)
                    .map(row => ({
                        id: row.id,
                        description: row.description,
                        reference: row.reference,
                        amount: beautifyNumber(row.amount),
                        amount1: row.amount,
                        transactionDate: formatDate(row.transactionDate),
                        documentDate: row.documentDate ? formatDate(row.documentDate) : '',
                        subRows: row.subRows || [],
                        creditAccount: row.creditAccount,
                        debitAccount: row.debitAccount,
                        type: row.type,
                        cardMatchNumber: row.cardMatchNumber ?? 0
                    }));


                setCreditMatchedRows(formattedCreditRows);
                setDebitMatchedRows(formattedDebitRows);
                setMatchData(externalMatchData);
                // Calculate sums
                sumCredit = formattedCreditRows.reduce((total, row) => total + row.amount1, 0);
                sumDebit = formattedDebitRows.reduce((total, row) => total + row.amount1, 0);
                balance = sumDebit - sumCredit;
            }

        } catch (error) {
            console.error('Error fetching match data:', error);
        }
        // Return the calculated sums
        setLoading(false);
        return { sumCredit, sumDebit, balance };
    }


    async function fetchNotMatchDataOnDateRangeChange(startDate, endDate, mixThemTogether = false) {
        setLoading(true);
        const minStartDate = new Date(0);
        const effectiveStartDate = startDate ? startDate : minStartDate;
        const formattedStartDate = effectiveStartDate.toISOString();
        const formattedEndDate = endDate.toISOString();
        let sumCredit = 0;
        let sumDebit = 0;
        let balance = 0;

        try {
            const internalResponse = await DocumentService.GetInternalByAccountCodeIdDateRangeWithCreditAndDebit(
                client.caseId, formattedStartDate, formattedEndDate, selectedCardId, token
            );

            if (internalResponse.ok) {
                const internalData = await internalResponse.json();
                console.log(internalData);


                // Mapping internal rows
                const formattedCreditRows = internalData
                    .filter(row => row.creditAccount === selectedCardId)
                    .map((row, index) => ({
                        id: row.id,
                        paymentAcceptanceId: row.paymentAcceptanceId,
                        description: row.description,
                        reference: row.reference,
                        amount: beautifyNumber(row.amount),
                        amount1: row.amount,
                        documentDate: row.documentDate ? formatDate(row.documentDate) : '',
                        subRows: row.subRows || [],
                        creditAccount: row.creditAccount,
                        debitAccount: row.debitAccount,
                        type: row.type,
                        cardMatchNumber: row.cardMatchNumber ?? 0
                    }));


                const formattedDebitRows = internalData
                    .filter(row => row.debitAccount === selectedCardId)
                    .map(row => ({
                        id: row.id,
                        description: row.description,
                        reference: row.reference,
                        amount: beautifyNumber(row.amount),
                        amount1: row.amount,
                        transactionDate: formatDate(row.transactionDate),
                        documentDate: row.documentDate ? formatDate(row.documentDate) : '',
                        subRows: row.subRows || [],
                        creditAccount: row.creditAccount,
                        debitAccount: row.debitAccount,
                        type: row.type,
                        cardMatchNumber: row.cardMatchNumber ?? 0
                    }));





                setCreditRows(formattedCreditRows);
                setDebitRows(formattedDebitRows);
                console.log(formattedCreditRows);
                console.log(formattedDebitRows);
                setSortedTransactions([...formattedCreditRows, ...formattedDebitRows]);
                sumCredit = formattedCreditRows.reduce((total, row) => total + row.amount1, 0);
                sumDebit = formattedDebitRows.reduce((total, row) => total + row.amount1, 0);
                balance = sumDebit - sumCredit;
                console.log(sumCredit, sumDebit, balance);

            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
        setLoading(false);
        return { sumCredit, sumDebit, balance };
    }

    const handleChangeBankForCreate = (value) => {
        setSelectedBankForCreate(value);

    };

    const handleOpenCreateModal = () => {
        setCreateBankModal(true);
    };

    const handleCloseCreateModal = () => {
        setCreateBankModal(false);
        setSelectedBankForCreate(null);
        setBankBranch('');
        setBankAccountNumber('');
        setIsEditingBank(false);
        setCardForNewBank('');
    };

    const handleRemoveBank = async () => {
        try {

            const response = await EnumsService.DeleteTBank(client.caseId, bankAccountNumber, selectedBankForCreate.value, token);
            if (response.ok) {
                toast.success(t('BankDeletedSuccessfully'));
                fetchEnums(user, token, client);
                handleCloseCreateModal();
            } else {
                toast.error(t('FailedToAddBankAccount'));
            }
        } catch (error) {
            console.error('BankFailedToDelete');
        }
    };

    const handleAddBank = async () => {
        try {
            const bankName = Constants.BankNames[parseInt(selectedBankForCreate.value)];
            const bankCode = selectedBankForCreate.value;

            if (!selectedBankForCreate || !bankBranch || !bankAccountNumber) {
                toast.error(t('PleaseFillAllFields'));
                return;
            }

            const response = await EnumsService.AddTBank(
                client.caseId,
                bankName,
                bankCode,
                bankBranch,
                bankAccountNumber,
                cardForNewBank.cardId,
                token
            );
            if (response.ok) {
                toast.success(t('BankAccountAddedSuccessfully'));
                fetchEnums(user, token, client);
                handleCloseCreateModal();
            } else {
                toast.error(t('FailedToAddBankAccount'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('ErrorAddingBankAccount'));
        }
    };
    const [oldBank, setOldBank] = useState(null);

    const handleEditBank = async () => {
        try {

            const bankName = Constants.BankNames[parseInt(selectedBankForCreate.value)];
            const bankCode = selectedBankForCreate.value;

            if (!selectedBankForCreate || !bankBranch || !bankAccountNumber) {
                toast.error(t('PleaseFillAllFields'));
                return;
            }

            if (!oldBank) {
                toast.error(t('OldBankDataNotFound'));
                return;
            }

            const response = await EnumsService.EditTBank(
                client.caseId,                // Use the CaseId from the edited bank
                oldBank.bankCode,             // Old bank code
                oldBank.accountNumber,        // Old account number
                bankName,                     // New bank name
                bankCode,                     // New bank code
                bankBranch,                   // New branch number
                bankAccountNumber,            // New account number
                cardForNewBank.cardId,
                token                         // Authorization token
            );

            if (response.ok) {
                toast.success(t('BankAccountUpdatedSuccessfully'));
                fetchEnums(user, token, client);  // Fetch updated data
                handleCloseCreateModal();         // Close the modal
            } else {
                toast.error(t('FailedToUpdateBankAccount'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('ErrorUpdatingBankAccount'));
        }
    };


    const handleAddOrEditBank = () => {
        if (isEditingBank && editedBank) {
            handleEditBank()

        } else {
            handleAddBank();
        }
        handleCloseCreateModal(); // Close the modal after adding/editing
    };

    const handleConfirm = async () => {
        if (createJournalEntries === true) {
            // Case 2: Checked is true
            const selectedRows = debitDisplayedRows.filter(row => selectedDebitRows.includes(row.id));
            const journalEntries = selectedRows.map(row => {
                const amount = parseFloat(row.amount1);
                const isNegative = amount < 0;


                const transactionDate = new Date(parseCustomDate(row.transactionDate)).toISOString().split('T')[0];
                const description = row.description;
                const reference = row.reference || '';

                return {
                    amount: Math.abs(amount),
                    creditAccount: isNegative ? selectedCardId : formValues.accountCard,
                    debitAccount: isNegative ? formValues.accountCard : selectedCardId,
                    description,
                    reference,
                    documentDate: transactionDate,
                    reportingMonthDate: new Date(reportingMonthDate).toISOString().split('T')[0],
                    sortCodeId: formValues.sortCode || null,
                };
            });

            // Show the journal entries as card list
            setJournalEntriesList(journalEntries);
            setIsJournalEntriesListOpen(true);

            // Close the initial dialog
            setIsCreateDialogOpen(false);
        } else {
            setIsCreateDialogOpen(false);
            await handleCreateJournalEntry();
        }
    };

    const handleCreateJournalEntry = async () => {
        setLoading(true);
        // Prepare JSON payload
        const payload = {
            caseId: client.caseId,
            accountantId: client.accountantId,
            sortCodeId: formValues.sortCodeId,
            reference: formValues.reference,
            description: formValues.description,
            amount: Math.abs(formValues.amount),
            documentDate: new Date(formValues.documentDate).toISOString(),
            reportingMonthDate: new Date(formValues.reportingMonthDate).toISOString(),
            creditAccount: formValues.creditAccount,
            debitAccount: formValues.debitAccount,
            vat: 100,
        };

        try {
            const response = await DocumentService.UploadDocumentPayload("JournalEntries", payload, token);


            if (response.ok) {
                setShouldMatch(true);
                const data = await response.json(); // Parse the response as JSON
                const newJournalEntryId = data.journalEntryId; // Extract the ID from the response


                // Set the state for selectedInternalRows
                setSelectedCreditRows(prevRows => {
                    const updatedRows = [...prevRows, newJournalEntryId];
                    // After setting the state, trigger further actions
                    afterStateUpdate(updatedRows); // Trigger next steps
                    return updatedRows;
                });

                toast.success(t('documentUploadSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError', { errorText }));
                return false;
            }
        } catch (error) {
            toast.error(t('documentUploadError', { errorText: 'Error uploading document' }));
            return false;
        }
    };

    useEffect(() => {
        if (shouldMatch && displayedRows.length > 0) {
            handleMatch(selectedCreditRows); // Pass updated internal rows
            setShouldMatch(false); // Reset the flag after calling handleMatch
        }
    }, [displayedRows]);



    const [denseTableCreditDebitRows, setDenseTableCreditDebitRows] = useState([]);
    // useEffect(() => {
    //     
    //     if (externalRows && externalRows.length > 0) {
    //         const totalSum = externalRows.reduce((acc, row) => acc + row.amount1, 0);
    //         const positiveSum = externalRows.reduce((acc, row) => {
    //             return row.amount1 > 0 ? acc + row.amount1 : acc;
    //         }, 0);
    //         const negativeSum = externalRows.reduce((acc, row) => {
    //             return row.amount1 < 0 ? acc + row.amount1 : acc;
    //         }, 0);
    //         setDenseTableCreditDebitRows([totalSum, positiveSum, negativeSum]);
    //         

    //     }

    // }, [externalRows]);
    // Step 3: Function to handle what happens after state update
    const afterStateUpdate = async (updatedRows) => {

        setFormValues({
            confirmationNumber: '',
            accountCaseId: '',
            reference: '',
            description: '',
            documentDate: '',
            reportingMonthDate: '',
            amount: '',
            vat: '',
            sortCodeId: '3',
            creditAccount: '',
            debitAccount: '',
            sortCode: '3',
            accountCard: ''
        });

        // Fetch the data and wait for it to complete
        await FetchData();

        setLoading(false);
    };
    const selectedDenseTableRow = async (index) => {
        setSelectedTBank(tBanksData[index]);
        setSelectedTBankIndex(index);
        setSelectedCardId(tBanksData[index].cardId);


        const cardIndexForAdv = processedCardsData.findIndex(item => item.cardId === tBanksData[index].cardId);

        if (cardIndexForAdv !== -1) { // If cardId is found
            const updatedMainCard = { ...processedCardsData[cardIndexForAdv], index: cardIndexForAdv }; // Add the index to mainCard
            setMainCard(updatedMainCard);  // Set the updated mainCard with the index
            setSelectedFormattedAccountCard(processedCardsData[cardIndexForAdv])
        } else {
            // setMainCard(-1); 
            // toast.info(t('NonBankAccountCardSelected'));
        }

    };
    const handleFormattedAccountCardChange = (event, newValue) => {
        console.log(newValue);
        
        if(!newValue){
            return
        }
        setMainCard(newValue?.cardId);
        setSelectedFormattedAccountCard(newValue);
    };
    const [isEditingBank, setIsEditingBank] = useState(false);
    const [editedBank, setEditedBank] = useState(false);
    const [selectedBankAccount, setSelectedBankAccount] = useState(null);




    useEffect(() => {
        if (selectedCardId && client) {
            FetchData();
        }

    }, [selectedCardId]);

    const FetchData = async () => {
        setCreditRows([]);
        setDebitRows([]);
        try {

            let totalSumCredit = 0;
            let totalSumDebit = 0;
            let totalBalance = 0;
            if (fetchType === 'Matched') {
                setShowingMatches(true);
                const { sumCredit, sumDebit, balance } = await fetchMatchDataOnDateRangeChange(new Date(startDate), new Date(endDate), isMatchDate);
                totalSumCredit = sumCredit;
                totalSumDebit = sumDebit;
                totalBalance = balance;
            }
            if (fetchType === 'AllMatched') {
                setShowingMatches(true);
                // Fetch match data and get sums
                const matchSums = await fetchMatchDataOnDateRangeChange(new Date(startDate), new Date(endDate), isMatchDate, true);

                // Fetch not matched data and get sums
                const notMatchSums = await fetchNotMatchDataOnDateRangeChange(new Date(startDate), new Date(endDate), true);

                // Combine the sums
                totalSumCredit = matchSums.sumCredit + notMatchSums.sumCredit;
                totalSumDebit = matchSums.sumDebit + notMatchSums.sumDebit;
                totalBalance = totalSumCredit - totalSumDebit;
            }
            if (fetchType === 'NotMatched') {
                setShowingMatches(false);
                const { sumCredit, sumDebit, balance } = await fetchNotMatchDataOnDateRangeChange(new Date(startDate), new Date(endDate));
                totalSumCredit = sumCredit;
                totalSumDebit = sumDebit;
                totalBalance = balance;
            }

            console.log(beautifyNumber(totalBalance), beautifyNumber(totalSumCredit), beautifyNumber(totalSumDebit));
            setDenseTableCreditDebitRows([{ balance: beautifyNumber(totalBalance), credit: beautifyNumber(totalSumCredit), debit: beautifyNumber(totalSumDebit) }]);

        } catch (error) {
            console.error("Error in FetchData:", error);
        }
    };

    useEffect(() => {

        setSelectedCardId(mainCard);
        const cardIndex = tBanksData.findIndex(item => item.cardId === mainCard);
        if (cardIndex !== -1) {
            setSelectedTBankIndex(cardIndex);
        } else {
            setSelectedTBankIndex(-1);
            // toast.info(t('NonBankAccountCardSelected'));
        }
    }, [mainCard]);



    // Calculate the sum of amounts from selected rows
    const calculateSum = (rows, selectedIds) => {


        return rows
            .filter(row => selectedIds.includes(row.id))
            .reduce((sum, row) => sum + (row.amount1 || 0), 0);
    };

    // Effect to update sums when row selection changes
    useEffect(() => {




        const externalSumValue = calculateSum(debitDisplayedRows, selectedDebitRows);
        const internalSumValue = calculateSum(displayedRows, selectedCreditRows);
        setExternalSum(externalSumValue);
        setInternalSum(internalSumValue);
    }, [selectedDebitRows, selectedCreditRows]);





    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        try {
            const tBanks = await EnumsService.GetAllTBanksByCaseId(client.caseId, token);
            const accountCardsResponse = await EnumsService.GetAccountCardsByCaseId(client.caseId, token);
            const accountCardsData = await accountCardsResponse.json();



            const tBanksRows = tBanks.map(bank => [
                bank.bankCode || "-",
                bank.bankName || "-",
                bank.branchNumber || "-",
                bank.accountNumber || "-",
                bank.cardId || "-"
            ]);

            //processed account cards data
            if (accountCardsData && accountCardsData.length > 0) {
                const transformedData = accountCardsData.map(card => ({
                    ...card,
                    value: card.cardId, // Set value to cardId
                    label: `${card.name} - ${card.cardId}`,   // Set label to name
                }));

                const combinedData = [{ name: t('AddAccountCard'), value: 'AddItem' }, ...transformedData];
                setProcessedCardsData(combinedData);
            }


            setDenseTableRows(tBanksRows);
            setTBanksData(tBanks);
            setAccountCardsData(accountCardsData);

            // Fetching sortCodeIdOptions
            const codeIdResponse = await EnumsService.GetSortCodesByAccountantId(client.accountantId, token);
            if (codeIdResponse.ok) {
                const codeIdData = await codeIdResponse.json();

                const sortCodeOptions = codeIdData.map((sortCode) => ({
                    label: sortCode.sortCodeName,  // Display name in the dropdown
                    value: sortCode.sortCodeId, // Use cardId as the value
                }));
                setSortCodeIdOptionsForEditModals(codeIdData);
                setSortCodeIdOptions(sortCodeOptions); // Assuming codeIdData is already in the correct format
            }


            // Fetching creditAccountOptions and debitAccountOptions
            const accountsResponse = await EnumsService.GetAccountCardsByCaseId(client.caseId, token);
            if (accountsResponse.ok) {
                const accountsData = await accountsResponse.json();
                setOriginalDebitAccountOptions(accountsData)

                // Map accountsData to extract name and cardId
                const accountOptions = accountsData.map((account) => ({
                    label: account.name,  // Display name in the dropdown
                    value: account.cardId, // Use cardId as the value
                }));



                setCreditAccountOptions(accountOptions);
                setDebitAccountOptions(accountOptions); // Assuming both options are the same
                setAccountCardEditDownloadOptions(accountOptions);

                const accountCardOptionsEdit = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountsData];
                setEditModalsAccountCardOptions(accountCardOptionsEdit);
                console.log(accountOptions);

                setAccountCardOptions(accountOptions);
            }
            const palCodesResponse = await EnumsService.GetPalCodesByAccountantId(client.accountantId, token)
            // Process palCodes if response is okay
            if (palCodesResponse.ok) {
                const palCodesData = await palCodesResponse.json();
                const formattedPalCodes = palCodesData.map(palCode => ({
                    label: palCode.palCodeId,
                    value: palCode.palCodeId
                }));


                setFormattedPalCodeOptions(formattedPalCodes);
            }

            setIsClientDataFetched(true);

        } catch (error) {
            console.error(error);
            toast.error(t('fetchEnumsError'), error);
            setIsClientDataFetched(false)
        }
        setLoading(false);

    };

    const handleDeleteExternalRows = async () => {

        try {

            const response = await ExternalTransactionsService.DeleteMultipleExternalTransactions(client.caseId, selectedDebitRows, token);

            if (response.ok) {
                toast.info(t('BankTransactionsDeletedSuccessfully'));
                await FetchData();
            } else {
                const errorMessage = await response.text();
                console.error('Failed to delete external transactions:', errorMessage);
                toast.error(t('FailedToDeleteBankTransactions'));
            }
        } catch (error) {
            toast.error(t('FailedToDeleteBankTransactions'));
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    // Get client data from location.state
                    if (location.state && location.state.client && location.state.type) {
                        setType(location.state.type)
                        setClient(location.state.client);
                    }
                    if (location.state.card) {  
                        console.log(location.state.card);
                        
                    }
                    await fetchEnums(currentUser, currentToken, location.state.client);
                    // Fetch financial data for the current date on initial load only
                }
                else {
                    navigate("/login");
                }


            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }
            setLoading(false);
        };
        fetchUserData();
    }, []);




    useEffect(() => {
        if (user && token && client && type) {
            // fetchByDocumentType(documentTypeFetch || "");
        }
    }, [isClientDataFetched]);

    const formatDate = dateString => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const filteredAndSortedData = useMemo(() => {

        if (accountCardsData.length <= 0) {
            return [];
        }

        const query = searchQuery.toLowerCase();
        // Apply filtering and scoring
        if (accountCardsData.length <= 0) {
            return [];
        }
        const scoredData = accountCardsData
            .filter((item) => {
                // Basic filtering to remove irrelevant items
                return (
                    (item?.cardId?.toString()?.includes(query)) ||
                    (item?.caseId?.toString()?.includes(query)) ||
                    (item?.accountCaseId?.toString()?.includes(query)) ||
                    (item?.name?.toLowerCase()?.includes(query)) ||
                    (item?.palCode?.toLowerCase()?.includes(query)) ||
                    (item?.client?.toString()?.includes(query)) ||
                    (item?.phoneNumber?.toString()?.includes(query)) ||
                    (item?.location?.toLowerCase()?.includes(query)) ||
                    (item?.email?.toLowerCase()?.includes(query)) ||
                    (item?.sourceDeductionAssociation?.toString()?.includes(query))
                );
            })
            .map((item) => {
                // Calculate a relevance score based on the match
                let score = 0;

                if (item?.cardId?.toString() === query) score += 10; // Exact match on cardId
                else if (item?.cardId?.toString()?.includes(query)) score += 5; // Partial match

                if (item?.caseId?.toString() === query) score += 10; // Exact match on caseId
                else if (item?.caseId?.toString()?.includes(query)) score += 5;

                if (item?.accountCaseId?.toString() === query) score += 10;
                else if (item?.accountCaseId?.toString()?.includes(query)) score += 5;

                if (item?.name?.toLowerCase() === query) score += 15; // Give more weight to name exact match
                else if (item?.name?.toLowerCase()?.includes(query)) score += 7;

                if (item?.palCode?.toLowerCase() === query) score += 10;
                else if (item?.palCode?.toLowerCase()?.includes(query)) score += 5;

                if (item?.client?.toString() === query) score += 10;
                else if (item?.client?.toString()?.includes(query)) score += 5;

                if (item?.phoneNumber?.toString() === query) score += 10;
                else if (item?.phoneNumber?.toString()?.includes(query)) score += 5;

                if (item?.location?.toLowerCase() === query) score += 8;
                else if (item?.location?.toLowerCase()?.includes(query)) score += 4;

                if (item?.email?.toLowerCase() === query) score += 10;
                else if (item?.email?.toLowerCase()?.includes(query)) score += 5;

                if (item?.sourceDeductionAssociation?.toString() === query) score += 10;
                else if (item?.sourceDeductionAssociation?.toString()?.includes(query)) score += 5;

                // Return item along with its score
                return { ...item, score };
            });

        // Sort by relevance score (descending)
        const sortedData = scoredData.sort((a, b) => b.score - a.score);

        // Apply any additional sorting based on sortConfig
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
                const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined

                if (sortConfig.type === 'number') {
                    return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                } else if (sortConfig.type === 'date') {
                    return sortConfig.direction === 'ascending' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
                } else { // default to string comparison
                    return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }
            });
        }


        return sortedData;
    }, [accountCardsData, searchQuery, sortConfig]);






    const handleSortChange = (key, type, Direction = '') => {
        let direction = Direction || 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        setSortConfig({ key, direction, type });
    };

    // // table functions
    // const headerStyle = {
    //     backgroundColor: '#f7f7f7',
    //     color: 'black',
    //     fontWeight: 'bold'
    // }


    useEffect(() => {
        // Calculate the width as 70% of the container width minus 20px
        const containerWidth = document.querySelector('.table-container').offsetWidth;
        const newWidth = (containerWidth * 0.7) - 20;
        setCalculatedWidth(newWidth + 'px');
    }, []);

    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            navigate('/client-details', { state: { client } });
        }
    };

    const rowStyle = {
        padding: '6px',
        fontSize: '12px',
    };

    const headerStyle = {
        backgroundColor: '#ebebeb',
        padding: '8px',
        fontSize: '12px',
    };

    const [itemEditing, setItemEditing] = useState(true);
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [accountCodeName, setAccountCodeName] = useState('');
    const [accountCodeId, setAccountCodeId] = useState('');

    const [modalVisible, setModalVisible] = useState(false);
    const [addNewAccountCard, setAddNewAccountCard] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const isClosedReportingMonthAndIsRange = !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth;
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [paymentAcceptanceItems, setPaymentAcceptanceItems] = useState([]);
    const [invoiceClientCaseId, setInvoiceClientCaseId] = useState('');
    const [accountType, setAccountType] = useState(null);
    const [description, setDescription] = useState('');
    const [showAccountCardInputsEdit, setShowAccountCardInputsEdit] = useState(false);
    const [acceptedWithholdingTax, setAcceptedWithholdingTax] = useState(0);
    const [palCode, setPalCode] = useState('');
    const [paymentWithholdingTax, setPaymentWithholdingTax] = useState(0);
    const [newItem, setNewItem] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [actionType, setActionType] = useState('delete');
    const [accountCardIdToDelete, setAccountCardIdToDelete] = useState('');
    const [paymentBeingEdited, setPaymentBeingEdited] = useState(true);

    const checkIfExistingAccountCard = (accountCardOptions, cardId, accountCaseId, edit = false) => {

        const existingAccountCard = accountCardOptions.filter(card =>
            (card.accountCaseId === accountCaseId && accountCaseId) ||
            (card.cardId === cardId && cardId)
        );


        const isExist = existingAccountCard && (edit
            ? existingAccountCard.length > 1
            : existingAccountCard.length > 0);

        if (isExist) {
            toast.info(t('AccountCardExists', { accountCodeId: existingAccountCard[0].cardId }));
            return true;
        }
    }

    const handleSaveEditAccountCard = async () => {
        if (checkIfExistingAccountCard(accountCardOptions, accountCodeId, companyId, true)) {
            return;
        }
        const Item = addEditableItem;
        handleClose();
        try {
            setLoading(true)

            const response = await EnumsService.UpdateAccountCard(client.caseId, oldSelectedAccountCardItem.cardId, oldSelectedAccountCardItem.name, accountCodeId, accountCodeName, companyId, palCode, token);
            if (response.ok) {
                const newItem = {
                    ...Item,
                    accountCodeId: accountCodeId?.toString(),
                    accountCodeName: accountCodeName?.toString(),
                    accountCaseId: invoiceClientCaseId?.toString(),
                };
                setNewItem(newItem);
                let selectedAccountCard = {
                    cardId: accountCodeId,
                    caseId: client.caseId,
                    accountCaseId: companyId,
                    name: accountCodeName,
                    palCode: palCode,
                    client: null
                };
                setAccountCardOptions(prevCards => {
                    const updatedCards = prevCards.filter(card => card.cardId !== selectedAccountCard.cardId);
                    updatedCards.push(selectedAccountCard);
                    setIsUpdated(true);
                    return updatedCards;
                });
                // handleReopenEdit(editedItem);
                toast.success(t('CustomerSupplierUpdatedSuccessfully'));
                setSelectedOption(selectedAccountCard ? selectedAccountCard : null);

            } else {
                const errorText = await response.text();
                console.error('FailedToUpdateCustomerSupplier', errorText);
                toast.error(t('FailedToUpdateCustomerSupplier'));
            }
        } catch (error) {
            console.error('FailedToUpdateCustomerSupplier:', error);

            toast.error(t('FailedToUpdateCustomerSupplier'));

        }
        setLoading(false);
        setPalCode('');
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
        setShowAccountCardInputsEdit(false);
    };

    const handleAddAccountCard = async () => {
        if (checkIfExistingAccountCard(accountCardOptions, accountCodeId, companyId)) {
            return;
        }
        const Item = addEditableItem;
        handleClose();
        try {
            setLoading(true);
            const response = await EnumsService.AddAccountCard(client.caseId, accountCodeId, accountCodeName, companyId, palCode, token);
            if (response.ok) {
                const accountCodeId = await response.json();
                let selectedAccountCard = {
                    cardId: accountCodeId,
                    caseId: client.caseId,
                    accountCaseId: companyId,
                    name: accountCodeName,
                    palCode: palCode,
                    client: null
                };

                const newItem = {
                    ...Item,
                    accountCodeId: accountCodeId?.toString(),
                    accountCodeName: accountCodeName?.toString(),
                    accountCaseId: invoiceClientCaseId?.toString(),
                };
                setNewItem(newItem);
                setAccountCardOptions(prevCards => {
                    const updatedCards = [...prevCards, selectedAccountCard];
                    setIsUpdated(true);
                    return updatedCards;
                });

                toast.success(t('CustomerSupplierAddedSuccessfully'));
                setSelectedOption(selectedAccountCard ? selectedAccountCard : null);

            } else {
                const errorText = await response.text();
                console.error('Failed to edit user', errorText);
                toast.error(t('FailedToAddCustomerSupplier'));
            }
        } catch (error) {
            console.error('Error edit user:', error);
            toast.error(t('FailedToAddCustomerSupplier'));
        }
        setLoading(false);
        setPalCode('');
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
    };

    const onRefresh = async () => {
        setLoading(true);
        await fetchEnums(user, token, client);

    };

    const handleCloseAddNewAccountCardDocument = () => {
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
    };

    const handleMuiChange = (e) => {
        const value = e.value;
        if (value.value1 === 'AddItem') {
            setAccountCodeName("");
            setAccountCodeId("");
            setCompanyId("");
            setAddNewAccountCardDocument(true);
            setAddNewAccountCard(true);
        }
        else {
            setItemEditing(true);
            setAccountCodeName(value.name);
            setCompanyId(value.accountCaseId);
            // const accountCodeId = accountCardOptions.find(option => option.name === selectedOption.value);
            setAccountCodeId(value.cardId);
            setInvoiceClientCaseId(value.cardId);
            addEditableItem.accountCaseId = value.accountCaseId;
            addEditableItem.accountCodeId = value.cardId;
            addEditableItem.accountCodeName = value.name;
            // Conditionally set creditAccount or debitAccount based on paymentAcceptanceType
            if (addEditableItem.paymentAcceptanceType === 'תקבול') {
                addEditableItem.creditAccount = value.name;
                setSelectedOption({ ...value, disableCreditAccount: true, disableDebitAccount: false });
            } else if (addEditableItem.paymentAcceptanceType === 'תשלום') {
                addEditableItem.debitAccount = value.name;
                setSelectedOption({ ...value, disableDebitAccount: true, disableCreditAccount: false });
            }
        }
    };
    const handleAccountDelete = (option) => {
        setActionType('deleteAccountCard');
        setAccountCardIdToDelete(option.cardId);
        setIsModalVisible(true);
        // handleAccountCardDeleteClick(option.cardId);
    };

    const handleChangeAccountType = (selectedOption) => {
        setAccountType(selectedOption.value);
        setAddEditableItem((prevState) => ({
            ...prevState,
            paymentAcceptanceType: selectedOption ? selectedOption.value : ''
        }));
    };
    const customStyles = {
        valueContainer: (base, state) => ({
            ...base,
            justifyContent: "center"
        }),
    }

    const setPaymentDate = async (date) => {
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, date, token);
        const isClosedReportingMonth = await response1.json();
        if (isClosedReportingMonth) {
            toast.error(t('MonthReportAlreadyClosedTryAgain'));
            return
        }
        setAddEditableItem((prevItem) => ({
            ...prevItem,
            date: date.toISOString()
        }));
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        addEditableItem.description = event.target.value;
    };
    const handleChangeOriginalTaxClearance = (event) => {
        const value = event.target.value;
        addEditableItem.withholdingTax = value;
        // setOriginalTaxClearance(value);

    };

    const handleRemoveItem = (type, index) => {
        let count = 0;

        // Update paymentAcceptanceItems
        const newItems = paymentAcceptanceItems.filter(item => {
            if (item.type === type) {
                if (count === index) {
                    count++;
                    return false;
                }
                count++;
            }
            return true;
        });
        setPaymentAcceptanceItems(newItems);

        // Update addEditableItem
        setAddEditableItem(prevItem => {
            const updatedTypeItems = prevItem[type].filter((_, idx) => idx !== index);
            return { ...prevItem, [type]: updatedTypeItems };
        });
    };

    const handleAddItem = (theType) => {
        const newItem = {
            type: theType,
            description: '',
            amount: '',
            date: new Date(),
            paymentDate: new Date(),
            bankName: '',
            bankCode: '',
            bankBranchCode: '',
            supplierAccountNumber: '',
            bankTransactionsCode: Constants.PaymentAcceptanceConstantsAccountcards.GeneralBank,
            creditAccount: "",
            debitAccount: "",
        };

        // Update paymentAcceptanceItems
        setPaymentAcceptanceItems(prevItems => [...prevItems, newItem]);

        // Update addEditableItem
        setAddEditableItem(prevItem => ({
            ...prevItem,
            [theType]: [...prevItem[theType], newItem]
        }));

    };

    const handleAddEditableItemChange = (name, value) => {
        setAddEditableItem(prev => ({ ...prev, [name]: value }));

    };

    const handleSave = async () => {


        try {
            setModalVisible(false);
            setOpenInvoiceCreatorModal(false);
            setLoading(true);
            // Prepare the data to be sent in the request body
            const updatedDocument = {
                id: addEditableItem.id,
                caseId: addEditableItem.caseId,
                accountCodeName: addEditableItem.accountCodeName,
                accountCodeId: addEditableItem.accountCodeId,
                reference: addEditableItem.reference,
                amount: addEditableItem.amount,
                uploadDate: addEditableItem.uploadDate,
                paymentAcceptanceType: addEditableItem.paymentAcceptanceType,
                description: addEditableItem.description,
                withholdingTax: addEditableItem.withholdingTax,
                accountCaseId: addEditableItem.accountCaseId,
                checks: addEditableItem.checks,
                creditCards: addEditableItem.creditCards,
                cash: addEditableItem.cash,
                bankTransactions: addEditableItem.bankTransactions,
                date: addEditableItem.date ? new Date(addEditableItem.date).toISOString() : new Date().toISOString(),
                reportingMonthDate: new Date(addEditableItem.reportingMonthDate).toISOString(),
                confirmationNumber: addEditableItem.confirmationNumber,
                creditAccount: addEditableItem.creditAccount,
                creditAccountAmount: addEditableItem.creditAccountAmount,
                debitAccount: addEditableItem.debitAccount,
                debitAccountAmount: addEditableItem.debitAccountAmount,
            };




            // Call the UpdateDocument function from DocumentService
            const response = await DocumentService.UpdateDocument("PaymentAcceptances", token, updatedDocument);

            if (response.ok) {
                // Handle successful update
                if (addEditableItem.status === 'done') {
                    await handleDoneClick(addEditableItem.id, addEditableItem.type);
                } else if (addEditableItem.status === 'notDone') {
                    await handleNotDoneClick(addEditableItem.id, addEditableItem.type);
                }
                toast.success(t('documentUpdateSuccess'));
                await FetchData();
            } else {
                // Handle any errors or display an error message
                toast.error(t('documentUpdateError'));
            }
        } catch (error) {
            console.error('documentUpdateError:', error);
            // Show an error toast message
            toast.error(t('documentUpdateError'));
        }
        await onRefresh();
        handleClose(); // Function to revert or exit the edit mode
        setLoading(false);

    };

    const handleItemChange = (type, index, field, value) => {



        setPaymentAcceptanceItems(prevItems => {
            let typeIndex = -1;
            const updatedItems = prevItems.map((item, idx) => {
                if (item.type === type) {
                    typeIndex += 1;
                    if (typeIndex === index) {
                        return { ...item, [field]: value };
                    }
                }
                return item;
            });

            // Update the corresponding field in addEditableItem

            setAddEditableItem(prevItem => {
                const updatedTypeItems = prevItem[type].map((item, idx) => {
                    if (idx === index) {
                        return { ...item, [field]: value };
                    }
                    return item;
                });

                return { ...prevItem, [type]: updatedTypeItems };
            });

            return updatedItems;
        });
    };

    const setAccount = (field, input) => {
        //switch case on field
        switch (field) {
            case 'companyId':
                setCompanyId(input);
                setInvoiceClientCaseId(input);

                break;
            case 'accountCodeName':
                setAccountCodeName(input);

                break;
            case 'accountCodeId':
                setAccountCodeId(input);

                break;
            default:

        }
    }
    const handleClose = async (clearDocuments = true) => {
        setOpenInvoiceCreatorModal(false);
        setPaymentModal(false);
        setAddEditableItem({
            checks: [],
            creditCards: [],
            cash: [],
            bankTransactions: [],
            date: new Date()
        });
    }


    const [wholeDataTransactions, setWholeDataTransactions] = useState([]);
    // const handleEdit = async (row) => {



    //     const currentIndex = sortedTransactions.findIndex(
    //         (transaction) => transaction.id === row.id
    //     );

    //     if (currentIndex > sortedTransactions.length - 1) {

    //     }
    //     setCurrentIndex(currentIndex);

    //     //to get in smart way

    //     const overAllType = row.type.split('-')[0].trim();


    //     const getDataForRow = async (row) => {
    //         if (wholeDataTransactions[row.id]) {
    //             return wholeDataTransactions[row.id];
    //         } else {
    //             // Fetch data and update the cache
    //             try {
    //                 let response;
    //                 if (row.type !== overAllType) { // meaning if payment acceptance
    //                     response = await DocumentService.GetDocumentById(
    //                         overAllType,
    //                         client.caseId,
    //                         row.paymentAcceptanceId,
    //                         token
    //                     );
    //                 } else {
    //                     response = await DocumentService.GetDocumentById(
    //                         row.type,
    //                         client.caseId,
    //                         row.id,
    //                         token
    //                     );
    //                 }

    //                 const data = await response.json();
    //                 const dataWithDocType = { ...data, docType: overAllType };


    //                 setWholeDataTransactions((prevData) => ({
    //                     ...prevData,
    //                     [row.id]: dataWithDocType,
    //                 }));

    //                 // Update wholeDataTransactions state



    //                 return dataWithDocType;
    //             } catch (error) {
    //                 console.error('Error fetching data for row:', row.id, error);
    //                 throw error;
    //             }
    //         }
    //     };
    //     //-----------------

    //     try {
    //         const data = await getDataForRow(row);
    //         setEditedItem(data);


    //         // Get data for the previous row if it exists
    //         const previousRow = sortedTransactions[currentIndex - 1];
    //         // if (previousRow) {
    //         //     await getDataForRow(previousRow);
    //         // }

    //         // Get data for the next row if it exists
    //         const nextRow = sortedTransactions[currentIndex + 1];
    //         // if (nextRow) {
    //         //     await getDataForRow(nextRow);
    //         // }

    //         let selectedAccountCard = {
    //             cardId: accountCodeId,
    //             caseId: client.caseId,
    //             accountCaseId: companyId,
    //             name: accountCodeName,
    //             palCode: palCode,
    //             client: null
    //         };

    //         setSelectedOption(selectedAccountCard ? selectedAccountCard : null);


    //         // Handle different cases for row types
    //         switch (row.type.split('-')[0].trim()) {
    //             case 'Incomes':
    //                 setEditedPopupItem(true);
    //                 setPaymentModal(false);
    //                 setEditedJournalItem(false);
    //                 break;

    //             case 'Expenses':
    //                 setEditedPopupItem(true);
    //                 setPaymentModal(false);
    //                 setEditedJournalItem(false);
    //                 break;

    //             case 'PaymentAcceptances':
    //                 setPaymentAcceptanceSpecificType(row.type.split('-')[1].trim().toLowerCase());

    //                 setPaymentAcceptanceSpecificId(row.id);
    //                 setOpenInvoiceCreatorModal(true);
    //                 setAddEditableItem({ ...data });
    //                 setPaymentModal(true);
    //                 setEditedJournalItem(false);
    //                 setEditedPopupItem(false);
    //                 break;

    //             case 'JournalEntries':
    //                 setEditedPopupItem(true);
    //                 setPaymentModal(false);
    //                 setEditedJournalItem(true);
    //                 break;

    //             default:
    //                 // Fallback if the row type does not match any case
    //                 setEditedPopupItem(false);
    //                 setPaymentModal(false);
    //                 setEditedJournalItem(false);
    //                 break;
    //         }
    //     } catch (error) {
    //         console.error('Error during edit handling:', error);
    //         // Handle error (you can show a message to the user or log the error)
    //     }
    // };


    const [creditOrDebitEdit, setCreditOrDebitEdit] = useState(null);
    const handleEdit = async (row) => {
        console.log(mainCard);
        const isCredit = row.creditAccount === mainCard;
        if (isCredit) {
            setCreditOrDebitEdit('credit');
        } else {
            setCreditOrDebitEdit('debit');
        }
        console.log(row);
        console.log(row.id);

        let currentIndex = -1;

        if (isCredit) {
            currentIndex = creditRows.findIndex(
                (transaction) => transaction.id === row.id
            );
        } else {
            currentIndex = debitRows.findIndex(
                (transaction) => transaction.id === row.id
            );
        }
        console.log(currentIndex);


        if (currentIndex === -1) {
            console.error('Transaction not found in sortedTransactions');
            return; // Exit if the transaction is not found
        }

        setCurrentIndex(currentIndex);

        // Extract the overall type from the row
        const overAllType = row.type.split('-')[0].trim();

        // Helper function to fetch data for a given row
        const getDataForRow = async (row, overAllType) => {
            // Check if the data is already cached
            if (wholeDataTransactions[row.id]) {
                return wholeDataTransactions[row.id];
            }

            try {
                const isPaymentAcceptance = row.type !== overAllType;
                const documentId = isPaymentAcceptance ? row.paymentAcceptanceId : row.id;

                // Fetch the document data
                const response = await DocumentService.GetDocumentById(
                    overAllType,
                    client.caseId,
                    documentId,
                    token
                );

                if (!response.ok) {
                    throw new Error(`Failed to fetch data for document ID: ${documentId}`);
                }

                const data = await response.json();
                const dataWithDocType = { ...data, docType: overAllType };

                // Cache the fetched data
                setWholeDataTransactions((prevData) => ({
                    ...prevData,
                    [row.id]: dataWithDocType,
                }));

                return dataWithDocType;
            } catch (error) {
                console.error('Error fetching data for row:', row.id, error);
                throw error;
            }
        };

        try {
            // Fetch the data for the current row
            const data = await getDataForRow(row, overAllType);
            setEditedItem(data);

            // Set the selected account card
            const selectedAccountCard = {
                cardId: accountCodeId,
                caseId: client.caseId,
                accountCaseId: companyId,
                name: accountCodeName,
                palCode: palCode,
                client: null,
            };
            setSelectedOption(selectedAccountCard);
            console.log(overAllType);

            // Handle different cases based on the overall type
            switch (overAllType) {
                case 'Incomes':
                case 'Expenses':
                    setEditedPopupItem(true);
                    setPaymentModal(false);
                    setEditedJournalItem(false);
                    break;

                case 'PaymentAcceptances':
                    handlePaymentAcceptance(row, data);
                    break;

                case 'JournalEntries':
                    setEditedPopupItem(true);
                    setPaymentModal(false);
                    setEditedJournalItem(true);
                    break;

                default:
                    // Fallback if the row type does not match any case
                    setEditedPopupItem(false);
                    setPaymentModal(false);
                    setEditedJournalItem(false);
                    break;
            }
        } catch (error) {
            console.error('Error during edit handling:', error);
            // Handle error (e.g., show a message to the user)
        }
    };

    // Helper function to handle PaymentAcceptances
    const handlePaymentAcceptance = (row, data) => {
        const itemType = row.type.split('-')[1].trim();
        const itemId = row.id;

        // Get the array corresponding to the itemType (e.g., 'checks', 'creditCards')
        const itemsArray = data[itemType];

        if (Array.isArray(itemsArray)) {
            // Find the index of the item with the matching ID
            const indexInArray = itemsArray.findIndex((item) => item.id === itemId);

            if (indexInArray !== -1) {
                setShowType({ type: itemType, index: indexInArray });
            } else {
                console.error('Item with the specified ID not found in the array.');
                setShowType({ type: itemType, index: -1 });
            }
        } else {
            console.error(`No array found for type "${itemType}" in data.`);
            setShowType({ type: itemType, index: -1 });
        }

        // Update state variables for the modal
        setOpenInvoiceCreatorModal(true);
        setAddEditableItem({ ...data });
        setPaymentModal(true);
        setEditedJournalItem(false);
        setEditedPopupItem(false);
    };

    const handleRemoveMatchClick = (match) => {
        match.accountCardName = mainCard.name
        setSelectedMatch(match);
        setOpenRemoveMatchDialog(true);
    };

    // handleDeleteMatchClick = 

    const handleConfirmRemove = async () => {
        try {
            const deleteDTO = {
                CaseId: selectedMatch.caseId,
                Id: selectedMatch.id
            };

            // Call the delete service
            const response = await CardMatchService.DeleteMatch(deleteDTO, token);
            if (response.ok) {


                toast.success(t("MatchDeletedSuccessfully"));
                // Close the dialog after confirming
                await FetchData()
            }
            else {
                toast.error(t("ErrorDeletingMatch"));

            }
        } catch (error) {
            console.error("Error deleting match:", error);
            toast.error(t("ErrorDeletingMatch"));

        }
        setOpenRemoveMatchDialog(false);

    };

    const handleCloseRemoveMatchDialog = () => {
        setOpenRemoveMatchDialog(false);
    };


    const getTitleMessage = () => {
        switch (actionType) {

            case 'closeMonth':
                return t('ConfirmCloseMonth');
            case 'openMonth':
                return t('ConfirmOpenMonth');
            default:
                return t('confirmDeleteTitle');
        }
    };
    const getConfirmMessage = () => {
        switch (actionType) {
            case 'closeMonth':
                return t('AreYouSureCloseMonth');
            case 'openMonth':
                return t('AreYouSureOpenMonth');
            default:
                return t('confirmDeleteMessage');

        }
    };
    const handleConfirmDelete = async () => {
        // Perform delete operation
        setLoading(true);
        setIsModalVisible(false);


        try {
            const response = await DocumentService.DeleteDocument(itemTypeToDelete, itemIdToDelete, token);
            if (response.ok) {

                await FetchData();
                toast.success(t("documentDeleteSuccess"), {
                    position: "top-left"
                });
            } else {
                // Handle any errors or display an error toast
                const errorResponse = await response.json();
                console.error('Error deleting document:', errorResponse);

                // Show error toast
                toast.error(t("documentDeleteError"));
            }
        } catch (error) {
            console.error('Error deleting document:', error);

            // Show error toast
            toast.error(t("documentDeleteError"));
        }
        setLoading(false);
    };
    const handleConfirmDeletion = async () => {
        if (actionType === 'delete') {
            await handleConfirmDelete();
        }
    };

    useEffect(() => {
        console.log(displayedRows);
        
    }, [displayedRows]);
    // Memoize the Credit Table
    const memoizedCreditTable = useMemo(() => (
        <Box sx={{ height: '100%', width: '100%', boxSizing: 'border-box', backgroundColor: "#fff" }}>
            <StyledDataGrid
                apiRef={apiRef}
                rows={fetchType==='Matched' ? creditMatchedRows : debitRows}
                columns={CreditColumns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                checkboxSelection={fetchType !== 'AllMatched'}
                disableRowSelectionOnClick
                onRowSelectionModelChange={(ids) => setSelectedCreditRows(ids)}
                getRowClassName={(params) => params.row.rowClassName || ''}
                getRowId={(row) => row.id}
                onCellEditCommit={(row) => handleEdit(row)}
                rowSelectionModel={selectedCreditRows}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                pagination
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={handleSortModelChange}
            />
        </Box>
    ), [displayedRows, CreditColumns, selectedCreditRows, fetchType, creditRows, debitRows]); // Add dependencies as necessary

    // Memoize the Debit Table
    const memoizedDebitTable = useMemo(() => (
        <Box dir="rtl" sx={{ height: '100%', width: '100%', boxSizing: 'border-box', backgroundColor: "#fff" }}>
            <StyledDataGrid
                rows={debitDisplayedRows}
                columns={DebitColumns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                checkboxSelection={fetchType !== 'AllMatched'}
                disableRowSelectionOnClick
                onRowSelectionModelChange={(ids) => setSelectedDebitRows(ids)}
                getRowClassName={(params) => params.row.rowClassName || ''}
                getRowId={(row) => row.id}
                onCellEditCommit={(row) => handleEdit(row)}
                rowSelectionModel={selectedDebitRows}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                pagination
            />
        </Box>
    ), [debitDisplayedRows, DebitColumns, selectedDebitRows, fetchType, creditRows, debitRows]); // Add dependencies as necessary


    useEffect(() => {
        console.log(creditOrDebitEdit);
    }, [creditOrDebitEdit]);
    return (
        <>
            {/* adding bank transaction */}
            <AddExternalTransactionDialog
                addBankTransactionDialog={addBankTransactionDialog}
                handleCloseBankAddTransactionDialog={handleCloseBankAddTransactionDialog}
                t={t}
                newBankEditableItem={newBankEditableItem}
                changeNewBankTransaction={changeNewBankTransaction}
                handleAddBankTransaction={handleAddBankTransaction}
            />

            <CustomDeleteConfirmationModal
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onDelete={handleConfirmDeletion}
                confirmMessage={getConfirmMessage()}
                titleMessage={getTitleMessage()}
            />

            <AddOrEditBankDialog
                open={createBankModal}
                onClose={handleCloseCreateModal}
                isEditingBank={isEditingBank}
                selectedBankForCreate={selectedBankForCreate}
                bankOptions={bankOptions}  // Add actual bank options
                handleChangeBankForCreate={handleChangeBankForCreate}
                bankBranch={bankBranch}
                handleChangeCreateBank={handleChangeCreateBank}
                bankAccountNumber={bankAccountNumber}
                cardForNewBank={cardForNewBank}
                processedCardsData={processedCardsData}
                setCardForNewBank={setCardForNewBank}
                handleAddOrEditBank={handleAddOrEditBank}
                handleRemoveBank={handleRemoveBank}
                t={t}  // Replace with actual translation function
            />

            <div className="template-page-withoutHeight">
                <div className={`template-container ${direction}`}>
                    <UpperPanelDetails />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className={`main-title ${direction}`}>{t(type)}</div>
                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                    </div>

                    <div className={`searchFilter-bar__container ${direction}`} >
                        {
                            fetchType === 'Matched' ?

                                isMatchDate ? (
                                    <>

                                        <CustomMonthDatePicker
                                            onDateChange={(date) => handleEndDateChange(date)}
                                            date={dayjs(endDate)}
                                            label={t("EndDate")}
                                        />
                                        <FormControlLabel
                                            value={isMatchDate}
                                            control={<Switch
                                                className="custom-input-box-sizing-toggle"
                                                color="primary"
                                                checked={isMatchDate}
                                                onChange={handleRangeChange}
                                            />}
                                            label={t("MatchDate")}
                                            labelPlacement="bottom"
                                        />

                                    </>
                                ) : (
                                    <>

                                        <CustomMonthDatePicker
                                            onDateChange={(date) => handleEndDateChange(date)}
                                            date={dayjs(endDate)}
                                            label={t("EndDate")}
                                        />
                                        <FormControlLabel
                                            value={!isMatchDate}
                                            control={<Switch
                                                className="custom-input-box-sizing-toggle"
                                                color="primary"
                                                checked={isMatchDate}
                                                onChange={handleRangeChange}

                                            />}
                                            label={t("TransactionDate")}
                                            labelPlacement="bottom"
                                        />

                                    </>
                                )
                                :
                                <div style={{ columnGap: '10px', display: 'flex' }}>
                                    <CustomMonthDatePicker
                                        onDateChange={(date) => handleEndDateChange(date)}
                                        date={dayjs(endDate)}
                                        label={t("EndDate")}
                                    />
                                </div>
                        }
                    </div>

                    <div className='document-Dense-container'  >



                        <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                            <AutocompleteSelect
                                options={processedCardsData}
                                selectedValues={selectedFormattedAccountCard}
                                onChange={handleFormattedAccountCardChange}
                                label={t("Select AccountCard")}
                                placeholder={t("Select AccountCard")}
                                isMultiple={false}
                                width="100%"
                            />

                        </div>
                        <DenseTable headers={[t('Balance'), t('Credit'), t('Debit')]}
                            rows={denseTableCreditDebitRows} width='30%'
                            headerStyle={{
                                opacity: 0.6,
                                background: 'var(--orange, #F9AA2A)',
                                textAlign: 'center',
                                minHeight: '50px'
                            }}
                            rowStyle={{
                                borderTop: '1px solid rgba(191, 191, 193, 0.56)',
                                background: 'var(--secondary-12, rgba(254, 247, 234, 0.12))',
                                minHeight: '50px'
                            }}

                        />

                    </div>
                    {/* current table */}
                    <div className='table-container' style={{ overflowX: 'visible', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >


                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                            <StyledToggleButtonGroup
                                color="primary"
                                value={fetchType}
                                exclusive
                                onChange={handleFetchTypeChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="Matched">{t("Matched")}</ToggleButton>
                                <ToggleButton value="NotMatched">{t("NotMatched")}</ToggleButton>
                            </StyledToggleButtonGroup>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '40px' }}>

                            <>

                                <div style={{ width: '49.7%', height: '60vh' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '16px',
                                            backgroundColor: '#ebebeb',
                                            height: '40px'
                                        }}
                                    >

                                        <div className="table-titles">{t('Debit')}</div>
                                    </div>


                                    {memoizedDebitTable}


                                </div>

                                <div style={{ width: '49.7%', height: '60vh' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '16px',
                                            backgroundColor: '#ebebeb',
                                            height: '40px'
                                        }}
                                    >
                                        <div className="table-titles">{t('Credit')}</div>



                                    </div>


                                    {memoizedCreditTable}


                                </div>




                            </>

                        </div>
                    </div>
                    <div style={{ margin: '120px 0 0 0' }}></div>
                </div>
                <HamburgerMenu client={client} />

                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}
                <ImportDialog
                    open={openImportDialog}
                    onClose={handleCloseImportDialog}
                    onImport={handleImport}
                />
                {(!(editedItem && paymentModal) && !(editedItem && editedPopupItem && Object.keys(editedItem).length > 0) && fetchType !== 'Matched') && (
                    <BottomDockBar
                        columns={DebitColumns}
                        handleJournalEntries={handleCreateJournalEntries}
                        handleMatchEntries={handleMatchEntries}
                        handleAutoMatch={handleAutoMatchHeaders}
                        handleMatch={handleMatch}
                        handleClearMatches={handleClearMatches}
                        externalSum={externalSum}
                        internalSum={internalSum}
                        matches={matchedPairs}
                        selectedInternalRows={selectedCreditRows}
                        selectedExternalRows={selectedDebitRows}
                        handleDeleteExternalRows={handleDeleteExternalRows}
                    />
                )}
                {/* Dialog for Create Journal Entries with Inputs */}

                <Dialog open={isCreateDialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth dir='rtl'>
                    <DialogTitle style={{ textAlign: 'right !important' }}>  {t('CreateJournalEntries')}</DialogTitle>
                    <DialogContent>
                        {createJournalEntries === true ? (
                            // Case 2: Checked is true
                            <>
                                <Typography variant="body1" gutterBottom>
                                    {t('Select AccountCard')}
                                </Typography>
                                <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px', width: '30%', margin: '0 auto' }}>
                                        <Autocomplete
                                            options={accountCardOptions}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('AccountCard')}
                                                    fullWidth
                                                    required
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        className: 'custom-input-box-sizing',
                                                    }}
                                                    sx={{
                                                        marginRight: '16px',
                                                        '.MuiInputBase-root': {
                                                            boxSizing: 'border-box !important', // Apply box-sizing to the desired class
                                                        }
                                                    }}
                                                />
                                            )}
                                            value={accountCardOptions.find(option => option.value === formValues.accountCard) || null}
                                            ListboxProps={{ dir: 'rtl' }}
                                            style={{ direction: "rtl", boxSizing: 'border-box !important' }}
                                            onChange={(event, newValue) => handleSelectChange('accountCard', newValue ? newValue.value : '')}
                                        />
                                    </div>
                                    <Typography variant="body1" gutterBottom>
                                        {t('Select SortCode')}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', width: '30%', margin: '0 auto' }}>
                                        <Autocomplete
                                            options={sortCodeIdOptions}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Code ID')}
                                                    fullWidth

                                                    InputProps={{
                                                        ...params.InputProps,
                                                        className: 'custom-input-box-sizing',
                                                    }}
                                                    sx={{
                                                        marginRight: '16px',
                                                        '.MuiInputBase-root': {
                                                            boxSizing: 'border-box !important', // Apply box-sizing to the desired class
                                                        }
                                                    }}
                                                />
                                            )}
                                            value={sortCodeIdOptions.find(option => option.value === formValues.sortCode) || null} // bind value
                                            ListboxProps={{ dir: 'rtl' }}
                                            style={{ direction: "rtl", boxSizing: 'border-box !important' }}
                                            onChange={(event, newValue) => handleSelectChange('sortCode', newValue ? newValue.value : '')}
                                        />
                                    </div>
                                    <Typography variant="body1" gutterBottom>
                                        {t('SelectMonthReport')}
                                    </Typography>


                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', width: '30%', margin: '0 auto' }}>
                                        <CustomMonthDatePickerMui
                                            Date={reportingMonthDate}
                                            setDate={setReportingMonthDate}
                                            label="MonthReportDate"
                                        />
                                    </div>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography variant="body1" gutterBottom>
                                    {t('SelectedRowsTotalBankSum')} {beautifyNumber(externalSum - internalSum)} {t('and')}
                                    {createJournalEntries === true
                                        ? t('CreateJournalEntryForEachRow')
                                        : t('CreateJournalEntryForAllRows')}.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {t('FillDetailsToProceed')}
                                </Typography>
                                <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>

                                    {/* First Row: Amount */}
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px', width: '30%', margin: '0 auto' }}>
                                        <TextField
                                            fullWidth
                                            label={t('Amount')}
                                            name="amount"
                                            type="number"
                                            onChange={handleInputChange}
                                            sx={{ marginRight: '16px' }}
                                            InputProps={{
                                                className: 'custom-input-box-sizing',
                                            }}
                                            value={formValues.amount}
                                        />
                                    </div>

                                    {/* Second Row: Credit Account and Debit Account */}
                                    <div dir='rtl' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '16px', marginTop: '16px' }}>

                                        {/* Debit Account Autocomplete */}
                                        <Autocomplete
                                            options={debitAccountOptions}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Debit Account')}
                                                    fullWidth
                                                    required
                                                    sx={{
                                                        marginRight: '16px',
                                                        '.MuiInputBase-root': {
                                                            boxSizing: 'border-box !important', // Apply box-sizing to the desired class
                                                        },
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps, // Spread the default InputProps
                                                        className: 'custom-input-box-sizing',
                                                    }}
                                                />
                                            )}
                                            value={debitAccountOptions.find(option => option.value === formValues.debitAccount) || null} // bind value
                                            ListboxProps={{ dir: 'rtl' }}
                                            style={{ direction: "rtl", boxSizing: 'border-box !important' }}
                                            sx={{ width: '30% !important' }}
                                            onChange={(event, newValue) => handleSelectChange('debitAccount', newValue ? newValue.value : '')}
                                        />

                                        <Autocomplete
                                            options={creditAccountOptions}
                                            getOptionLabel={(option) => option.label}
                                            sx={{ width: '30%' }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Credit Account')}
                                                    fullWidth
                                                    required
                                                    sx={{
                                                        marginRight: '16px',
                                                        '.MuiInputBase-root': {
                                                            boxSizing: 'border-box !important', // Apply box-sizing to the desired class
                                                        },

                                                    }}
                                                    // sx={{ marginRight: '16px' }}
                                                    InputProps={{
                                                        ...params.InputProps, // Spread the default InputProps
                                                        className: 'custom-input-box-sizing',
                                                    }}
                                                />
                                            )}
                                            value={creditAccountOptions.find(option => option.value === formValues.creditAccount) || null} // bind value
                                            ListboxProps={{ dir: 'rtl' }}
                                            style={{ direction: "rtl" }}
                                            onChange={(event, newValue) => handleSelectChange('creditAccount', newValue ? newValue.value : '')}
                                        />

                                    </div>

                                    {/* Third Row: Description and Reference */}
                                    <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '16px' }}>

                                        <TextField
                                            fullWidth
                                            label={t('Description')}
                                            name="description"
                                            onChange={handleInputChange}
                                            InputProps={{
                                                className: 'custom-input-box-sizing',
                                            }}
                                            sx={{ width: '30%' }}
                                            value={formValues.description}
                                        />
                                        <TextField
                                            fullWidth
                                            label={t('Reference')}
                                            name="reference"
                                            onChange={handleInputChange}
                                            InputProps={{
                                                className: 'custom-input-box-sizing',
                                            }}
                                            sx={{ width: '30%' }}
                                            value={formValues.reference}
                                        />
                                    </div>

                                    {/* Fourth Row: Code ID and ValueDate */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                                        <Autocomplete
                                            options={sortCodeIdOptions}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Code ID')}
                                                    fullWidth
                                                    sx={{
                                                        marginRight: '16px',
                                                        '.MuiInputBase-root': {
                                                            boxSizing: 'border-box !important', // Apply box-sizing to the desired class
                                                        }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        className: 'custom-input-box-sizing',
                                                    }}
                                                />
                                            )}
                                            ListboxProps={{ dir: 'rtl' }}
                                            style={{ direction: 'rtl' }}
                                            sx={{ width: '30%' }}
                                            onChange={(event, newValue) => handleSelectChange('sortCodeId', newValue ? newValue.value : '')}
                                            value={sortCodeIdOptions.find(option => option.value === formValues.sortCodeId) || null} // bind value
                                        />
                                        <div style={{ width: '30%', marginRight: '30px' }}>
                                            <TextField
                                                fullWidth
                                                label={t('ValueDate')}
                                                name="documentDate"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleInputChange}
                                                InputProps={{
                                                    className: 'custom-input-box-sizing',
                                                }}
                                                sx={{ width: '100%' }}
                                                value={formValues.documentDate}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', width: '30%', margin: '0 auto' }}>
                                            <CustomMonthDatePickerMui
                                                Date={formValues.reportingMonthDate} // Pass current value from formValues
                                                setDate={(value) => setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    reportingMonthDate: value, // Update the reportingMonthDate field
                                                }))} // Pass function to update formValues
                                                label="MonthReportDate" // The label for the date picker
                                            />
                                        </div>
                                    </div>

                                </Box>
                            </>
                        )}
                    </DialogContent>

                    <div className="button-row-JournalEntryMatch">
                        <button
                            className="JournalEntryMatch-create-buttons"
                            onClick={handleConfirm}
                            disabled={
                                createJournalEntries === true
                                    ? !formValues.accountCard
                                    : (!formValues.debitAccount || !formValues.creditAccount)
                            }
                            style={{
                                backgroundColor:
                                    createJournalEntries === true
                                        ? (!formValues.accountCard ? 'lightgray' : '#E57C22')  // Light gray when disabled, blue when enabled
                                        : ((!formValues.debitAccount || !formValues.creditAccount) ? 'lightgray' : '#E57C22'),
                                color:
                                    createJournalEntries === true
                                        ? (!formValues.accountCard ? '#888' : '#fff') // Gray text when disabled, white when enabled
                                        : ((!formValues.debitAccount || !formValues.creditAccount) ? '#888' : '#fff'),
                                cursor:
                                    createJournalEntries === true
                                        ? (!formValues.accountCard ? 'not-allowed' : 'pointer')
                                        : ((!formValues.debitAccount || !formValues.creditAccount) ? 'not-allowed' : 'pointer'),
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '5px',
                            }}
                        >
                            {t('Confirm')}
                        </button>
                        <button className="JournalEntryMatch-cancel-buttons" onClick={handleCloseDialog}>
                            {t('close')}
                        </button>
                    </div>
                </Dialog>

                <Dialog open={isJournalEntriesListOpen} onClose={() => setIsJournalEntriesListOpen(false)} maxWidth="md" fullWidth dir='rtl'>
                    <DialogTitle style={{ textAlign: 'right !important' }}>{t('JournalEntriesPreview')}</DialogTitle>
                    <DialogContent>
                        {journalEntriesList.map((entry, index) => (
                            <Card key={index} sx={{
                                mb: 2,
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Customize the shadow to give a 3D effect
                                borderRadius: '10px', // Add rounded corners
                                transition: 'transform 0.2s', // Smooth transition on hover
                                '&:hover': {
                                    transform: 'translateY(-5px)', // Lift the card when hovered
                                }
                            }}>
                                <CardContent>
                                    <Typography variant="h6">{t('Entry')} #{index + 1}</Typography>
                                    <Typography variant="body1">{t('amount')}: {beautifyNumber(entry.amount)}</Typography>
                                    <Typography variant="body1">
                                        {t('Debit Account')}: {entry.debitAccount} ({getAccountCardName(entry.debitAccount)})
                                    </Typography>
                                    <Typography variant="body1">
                                        {t('Credit Account')}: {entry.creditAccount} ({getAccountCardName(entry.creditAccount)})
                                    </Typography>
                                    <Typography variant="body1">{t('description')}: {entry.description}</Typography>
                                    <Typography variant="body1">{t('reference')}: {entry.reference}</Typography>
                                    <Typography variant="body1">{t('Document Date')}: {entry.documentDate}</Typography>
                                    <Typography variant="body1">{t('MonthReportDate')}: {formatMonthYearDate(entry.reportingMonthDate)}</Typography>
                                    {entry.sortCodeId && (
                                        <Typography variant="body1">{t('SortCode')}: {entry.sortCodeId} {getSortCodeName(entry.sortCodeId)}</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                    </DialogContent>


                    <div className="button-row-JournalEntryMatch">
                        <button className="JournalEntryMatch-create-buttons" onClick={handleFinalizeEntries}>
                            {t('create')}
                        </button>
                        <button className="JournalEntryMatch-cancel-buttons" onClick={() => setIsJournalEntriesListOpen(false)}>
                            {t('close')}
                        </button>
                    </div>
                </Dialog>


                {selectedMatch && (
                    <Dialog
                        open={openRemoveMatchDialog}
                        onClose={handleCloseRemoveMatchDialog}
                        aria-labelledby="confirm-dialog-title"
                        dir="rtl"
                        aria-describedby="confirm-dialog-description"
                        sx={{
                            '& .MuiDialog-paper': {
                                backgroundColor: 'white',
                                color: 'black',
                                borderRadius: '8px',
                                padding: '20px',
                            },
                        }}
                    >
                        <DialogTitle id="confirm-dialog-title" sx={{ fontSize: '20px', color: '#E57C22' }}>
                            {t("ConfirmRemoval")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="confirm-dialog-description" sx={{ color: 'black', fontSize: '16px' }}>
                                {t("ConfirmRemoveMatch")}
                                <br />
                                <strong> {t("CaseID")}:</strong> {selectedMatch.caseId}
                                <br />
                                <strong> {t("AccountCard Name")}:</strong> {selectedMatch.accountCardName}
                                <br />
                                <strong> {t("AccountCodeID")}:</strong> {selectedMatch.accountCodeId}
                                <br />
                                <strong> {t("TransactionDate")}:</strong> {formatDate(selectedMatch.transactionDate)}
                                <br />
                                <strong> {t("MatchDate")}:</strong> {formatDate(selectedMatch.matchDate)}
                                <br />
                                <strong> {t("MatchNumber")}:</strong> {selectedMatch.matchNumber}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
                            <Button onClick={handleCloseRemoveMatchDialog} sx={{ color: '#E57C22', fontWeight: 'bold' }}>
                                {t('Cancel')}
                            </Button>
                            <Button
                                onClick={handleConfirmRemove}
                                sx={{
                                    backgroundColor: '#E57C22',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '8px 16px',
                                    borderRadius: '4px',
                                    '&:hover': {
                                        backgroundColor: '#d46d1e',
                                    },
                                }}
                                autoFocus
                            >
                                {t('Remove')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </div >
            {editedItem && editedPopupItem && Object.keys(editedItem).length > 0 && (

                <EditModal
                    editedItem={editedItem}
                    fade={fade}
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                    currentIndex={currentIndex}
                    filteredAndSortedData={sortedTransactions}
                    NoDocumentsPdf={NoDocumentsPdf}
                    t={t}
                    handleAccountChange={handleAccountChange}
                    isRange={isRange}
                    isClosedReportingMonth={isClosedReportingMonth}
                    accountCardOptions={editModalsAccountCardOptions}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    CustomInput2={CustomInput2}
                    setReportingMonthDate={setReportingMonthDate}
                    handleVatRadioChange={handleVatRadioChange}
                    statusCheck={statusCheck}
                    handleRadioChange={handleRadioChange}
                    type={type}
                    editedAddAccountCard={editedAddAccountCard}
                    handleCloseEditAddNewAccountCardDocument={handleCloseEditAddNewAccountCardDocument}
                    handleEditAddAccountChange={handleEditAddAccountChange}
                    sortCodeIdOptions={sortCodeIdOptionsForEditModals}
                    debitAccountOptions={debitAccountOptions}
                    creditAccountOptions={creditAccountOptions}
                    handleUpdate={handleUpdate}
                    handleCancel={handleCancel}
                    handleDeleteClick={handleEditDeleteClick}
                    client={client}
                    token={token}
                    setAccountCardOptions={setAccountCardOptions}
                    setLoading={setLoading}
                    setEditedAddAccountCard={setEditedAddAccountCard}
                    setEditedItem={setEditedItem}
                    handleReopenEdit={handleReopenEdit}
                    showIframe={!editedJournalItem}
                    palCodes={formattedPalCodeOptions}
                />
            )}

            {(addEditableItem && paymentModal) && (
                <PaymentAcceptanceCreateEditModal
                    openInvoiceCreatorModal={openInvoiceCreatorModal}
                    handleClose={handleClose}
                    t={t}
                    itemEditing={itemEditing}
                    addNewAccountCardDocument={addNewAccountCardDocument}
                    companyId={companyId}
                    accountCodeName={accountCodeName}
                    accountCodeId={accountCodeId}
                    setAccount={setAccount}
                    Constants={Constants}
                    palCode={palCode}
                    setPalCode={setPalCode}
                    isRange={isRange}
                    addEditableItem={addEditableItem} // renamed from editedItem
                    isClosedReportingMonth={isClosedReportingMonth}
                    handleSaveEditAccountCard={handleSaveEditAccountCard}
                    handleAddAccountCard={handleAddAccountCard}
                    handleCloseAddNewAccountCardDocument={
                        handleCloseAddNewAccountCardDocument
                    }
                    accountCardOptions={originalAccountOptions}
                    selectedOption={selectedOption}
                    handleMuiChange={handleMuiChange}
                    handleEdit={handleEdit}
                    handleAccountDelete={handleAccountDelete} // renamed from handleDelete
                    handleChangeAccountType={handleChangeAccountType}
                    customStyles={customStyles}
                    setPaymentDate={setPaymentDate}
                    setReportingMonthDate={setReportingMonthDate}
                    handleDescriptionChange={handleDescriptionChange}
                    handleChangeOriginalTaxClearance={handleChangeOriginalTaxClearance}
                    handleAddEditableItemChange={handleAddEditableItemChange}
                    handleItemChange={handleItemChange}
                    handleRemoveItem={handleRemoveItem}
                    showAccountCardInputsEdit={showAccountCardInputsEdit}
                    statusCheck={statusCheck}
                    handleRadioChange={handleRadioChange}
                    handleSave={handleSave}
                    buttonDisabled={buttonDisabled}
                    paymentBeingEdited={paymentBeingEdited}
                    CustomDropdown={CustomDropdown}
                    type={type}
                    direction={direction}
                    setAddNewAccountCardDocument={setAddNewAccountCardDocument}
                    isClosedReportingMonthAndIsRange={isClosedReportingMonthAndIsRange}
                    handleAddItem={handleAddItem}
                    CustomPalCode={CustomPalCode}
                    handleAccountEdit={handleAccountEdit}
                    handlePrevious={handlePrevious}
                    currentIndex={currentIndex}
                    filteredAndSortedData={filteredAndSortedTransactions}
                    handleNext={handleNext}
                    showType={showType}
                />
            )}
        </>
    );
}


export default CardMatching;
