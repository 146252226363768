// AutocompleteCardSelect.jsx

import React from 'react';
import {
    Autocomplete,
    TextField,
    IconButton,
    Chip,
    Tooltip,
    Popper,
    Card,
    CardContent,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import editIconImage from '../../../assests/images/Icons/editIcon.svg';
import deleteIconImage from '../../../assests/images/Icons/deleteIcon.svg';
import VirtualizedListboxComponent from './VirtualizedListboxComponent';

// Styled components for custom styling
const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& *': {
        boxSizing: 'border-box  !important',
      },
    '& .MuiOutlinedInput-root': {
        borderRadius: 'var(--Spacing-md, 8px)',
        background: 'var(--bg-input, #F5F5F6)',
        '& .MuiAutocomplete-tag': {
            maxHeight: '32px',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
    '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root, &.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
    {
        background: 'var(--bg-input, #F5F5F6)',
        borderRadius: 'var(--Spacing-md, 8px)',
    },
    '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
        width: '100%',
        minHeight: '48px',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
        flexWrap: 'nowrap',
    },
    '& .MuiInputBase-root': {
        boxSizing: 'border-box !important',
    },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
    '& *': {
        boxSizing: 'border-box  !important',
      },
    '& .MuiPaper-root': {
        width: '100%',
        borderRadius: 'var(--Spacing-md, 8px)',
        background: 'var(--white, #FFF)',
        boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0.1)',
    },
    '& .MuiAutocomplete-listbox': {
        padding: theme.spacing(1),
    },
    '& .MuiAutocomplete-option': {
        borderRadius: 'var(--Spacing-sm, 4px)',
        '&[aria-selected="true"], &.Mui-focused, &:hover': {
            background: 'var(--orange, #FCFCFF) !important',
        },
        zIndex: 10,
    },
}));

// Styled Card component with hover effect
const StyledCard = styled(Card)(({ theme, isRtl }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    boxShadow: 'none',
    borderRadius: 'var(--Spacing-md, 8px)',
    border: '1px solid var(--bg-input, #F5F5F6)',
    background: 'var(--bg-tabbar, #FCFCFF)',
    justifyContent: isRtl ? 'flex-end' : 'flex-start',
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
        duration: theme.transitions.duration.short,
    }),
    '&:hover': {
        backgroundColor: 'var(--hover-bg-color, #f0f0f0)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
}));

const KeyText = styled('span')(({ theme }) => ({
    color: 'black',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
}));

const ValueText = styled('span')(({ theme }) => ({
    color: 'var(--Dark-56, rgba(0, 0, 0, 0.56))',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
}));

const AutocompleteCardSelect = ({
    options,
    selectedValues,
    onChange,
    label = 'Select Options',
    placeholder = 'Type to search',
    isMultiple = true,
    renderOption: customRenderOption,
}) => {
    const { i18n, t } = useTranslation();
    const isRtl = ['he', 'ar'].includes(i18n.language);
    // Calculate item sizes based on the number of display fields
    const fieldHeight = 40; // Adjust based on your styling
    const baseSize = 16; // Adjust based on your styling

    const itemSizes = options.map((option) => {
        const { value, onEdit, onDelete, ...displayFields } = option;
        let numFields = Object.keys(displayFields).length + 2;
        console.log(numFields);
        if(numFields <=2){
            numFields = 3;

        }
        const itemSize = baseSize + numFields * fieldHeight;
        console.log(itemSize);
        
        return itemSize;
    });
    // Handle selection changes
    const handleOnChange = (event, newValue) => {
        onChange(event, newValue);
    };

    // Default renderOption modified to display dynamic fields
    const defaultRenderOption = (optionProps, option) => {
        const { style, ...otherProps } = optionProps;
        const { value, onEdit, onDelete, ...displayFields } = option;



        return (
            <li
                {...otherProps}
                style={{
                    ...style,
                    padding: 0,
                    marginBottom: '10px',
                  }}
            >
                <StyledCard isRtl={isRtl}>
                    <CardContent style={{ padding: '8px' }}>
                        {/* Icons as buttons for actions */}
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: '8px',
                                flexDirection: isRtl ? 'row-reverse' : 'row',
                            }}
                        >
                            {onEdit && (
                                <IconButton onClick={() => onEdit(option)}>
                                    <img src={editIconImage} alt="Edit" />
                                </IconButton>
                            )}
                            {onDelete && (
                                <IconButton onClick={() => onDelete(option)}>
                                    <img src={deleteIconImage} alt="Delete" />
                                </IconButton>
                            )}
                        </div>
                        {/* Dynamically display fields */}
                        {Object.entries(displayFields).map(([key, value]) => (
                            <div
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: '8px',
                                    flexDirection: isRtl ? 'row-reverse' : 'row',
                                    alignItems: 'center',
                                }}
                            >
                                {isRtl ? (
                                    <KeyText>: {t(key)} </KeyText>
                                ) : (
                                    <KeyText> {t(key)} :</KeyText>
                                )}
                                <ValueText>{value}</ValueText>
                            </div>
                        ))}
                    </CardContent>
                </StyledCard>
            </li>
        );
    };

    // Use custom renderOption if provided
    const renderOption =  defaultRenderOption;

    // Custom renderTags function to display selected values
    const renderTags = (value, getTagProps) => {
        if (value.length === 0) {
            return null;
        }

        return value.map((option, index) => {
            const { value: val, onEdit, onDelete, ...displayFields } = option;
            const label = Object.values(displayFields).join(' - ');

            return (
                <Chip key={val} label={label} {...getTagProps({ index })} />
            );
        });
    };

    // Custom getOptionLabel function
    const getOptionLabel = (option) => {
        const { value, onEdit, onDelete, ...displayFields } = option;
        return Object.values(displayFields).join(' - ') || '';
    };

    return (
        <StyledAutocomplete
            multiple={isMultiple}
            options={options}
            value={selectedValues}
            onChange={handleOnChange}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableCloseOnSelect={isMultiple}
            PopperComponent={StyledPopper}
            renderTags={renderTags}
            renderOption={renderOption}
            ListboxComponent={VirtualizedListboxComponent}
            ListboxProps={{
                itemSizes: itemSizes,
            }}
            renderInput={(params) => {
                const MAX_PLACEHOLDER_LENGTH = 10;
                const isPlaceholderLong = placeholder.length > MAX_PLACEHOLDER_LENGTH;

                // Use the adjusted placeholder logic
                const hasValue = isMultiple
                    ? selectedValues.length > 0
                    : selectedValues !== null && selectedValues !== undefined;

                const inputPlaceholder = hasValue ? '' : placeholder;

                // Create the TextField component
                const textField = (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        placeholder={inputPlaceholder}
                    />
                );

                // Wrap the TextField with Tooltip if the placeholder is long
                return isPlaceholderLong ? (
                    <Tooltip title={placeholder}>{textField}</Tooltip>
                ) : (
                    textField
                );
            }}
        />
    );
};

export default AutocompleteCardSelect;
