const TransactionDocuments = [
    null,               // Index 0 - intentionally left null
    "הצעת מחיר",       // Index 1
    "הזמנה",           // Index 2
    "חשבון עסקה"       // Index 3
];

const CheckTypeOptions = [
    { name: "תקבול", value: "תקבול" },
    { name: "תשלום", value: "תשלום" }
];

const CaseTypeOptions = [
    { name: "עוסק מורשה", value: "עוסק מורשה" },
    { name: "חברה", value: "חברה" },
    { name: "עמותה", value: "עמותה" },
    { name: "עוסק פטור", value: "עוסק פטור" },
];

const AccountManagementOptions = [
    { name: "כפולה", value: "כפולה" },
    { name: "חד צידית", value: "חד צידית" }
];

const ReportingTypeOptions = [
    { name: "חודשי", value: "חודשי" },
    { name: "דו חודשי", value: "דו חודשי" }
];
const ReportTrackingStatus = [
    '',
    'Reported',
   'Unreported'
];

const ReportTrackingType = [
    'VATReport',
    'AdvancesReport',
];

// const AccountCardPalCode = [
//     {label: "ספקים", value: "ספקים"},
//     {label: "לקוחות", value: "לקוחות"}
// ];

const AccountCardPalCode = [
    { label: "בנקים", value: "בנקים" },
    { label: "מזומנים בקופה", value: "מזומנים בקופה" },
    { label: "פקדונות קצרי מועד", value: "פקדונות קצרי מועד" },
    { label: "לקוחות", value: "לקוחות" },
    { label: "חייבים מוסדות", value: "חייבים מוסדות" },
    { label: "חייבים שונים", value: "חייבים שונים" },
    { label: "מיסים שוטפים", value: "מיסים שוטפים" },
    { label: "מלאי סחורות", value: "מלאי סחורות" },
    { label: "השקעות במניות", value: "השקעות במניות" },
    { label: "קרקע", value: "קרקע" },
    { label: "מבנים", value: "מבנים" },
    { label: "ציוד", value: "ציוד" },
    { label: "כלי רכב", value: "כלי רכב" },
    { label: "רהוט", value: "רהוט" },
    { label: "ספקים", value: "ספקים" },
    { label: "זכאים מוסדות", value: "זכאים מוסדות" },
    { label: "זכאים אחרים", value: "זכאים אחרים" },
    { label: "הלוואות מבנקים", value: "הלוואות מבנקים" },
    { label: "הלוואות בעלי מניות", value: "הלוואות בעלי מניות" },
    { label: "הון מניות", value: "הון מניות" },
    { label: "קרנות הון", value: "קרנות הון" },
    { label: "עודפים יתרת רווח", value: "עודפים יתרת רווח" },
    { label: "מכירות סחורה", value: "מכירות סחורה" },
    { label: "הכנסות משרותים", value: "הכנסות משרותים" },
    { label: "מלאי פתיחה", value: "מלאי פתיחה" },
    { label: "קניות", value: "קניות" },
    { label: "מלאי סגירה", value: "מלאי סגירה" },
    { label: "הוצאות הפעלה", value: "הוצאות הפעלה" },
    { label: "הוצאות הנהלה וכלליות", value: "הוצאות הנהלה וכלליות" },
    { label: "הוצאות שכר", value: "הוצאות שכר" },
    { label: "הוצאות אחרות", value: "הוצאות אחרות" },
    { label: "הוצאות מיוחדות", value: "הוצאות מיוחדות" },
    { label: "הוצאות מימון", value: "הוצאות מימון" },
    { label: "הכנסות מימון", value: "הכנסות מימון" },
    { label: "רווח להעברה", value: "רווח להעברה" },
    { label: "ממכירות בארץ", value: "ממכירות בארץ" },
    { label: "הכנסות ממכירת מבנים כקבלן בונה", value: "הכנסות ממכירת מבנים כקבלן בונה" },
    { label: "ממכירות לחו\"ל", value: "ממכירות לחו\"ל" },
    { label: "הכנסות מבצוע עבודות כקבלן מבצע", value: "הכנסות מבצוע עבודות כקבלן מבצע" },
    { label: "הכנסות ממתן שירותים בארץ", value: "הכנסות ממתן שירותים בארץ" },
    { label: "הכנסות ממתן שירותים בחו\"ל", value: "הכנסות ממתן שירותים בחו\"ל" },
    { label: "הכנסות מצדדים קשורים", value: "הכנסות מצדדים קשורים" },
    { label: "הכנסות אחרות", value: "הכנסות אחרות" },
    { label: "עלות שכר עבודה ונילוות ששימשו למכירות ומתן השירותים", value: "עלות שכר עבודה ונילוות ששימשו למכירות ומתן השירותים" },
    { label: "עבודות חוץ וקבלני משנה ששימשו למכירות ומתן השירותים", value: "עבודות חוץ וקבלני משנה ששימשו למכירות ומתן השירותים" },
    { label: "קניות סחורה בארץ ששימשו למכירות ומתן השירותים", value: "קניות סחורה בארץ ששימשו למכירות ומתן השירותים" },
    { label: "קניות חומרי גלם בארץ ובחו\"ל ששימשו למכירות ומתן השירותים", value: "קניות חומרי גלם בארץ ובחו\"ל ששימשו למכירות ומתן השירותים" },
    { label: "קניות סחורה בחו\"ל ששימשו למכירות ומתן השירותים", value: "קניות סחורה בחו\"ל ששימשו למכירות ומתן השירותים" },
    { label: "הפרשי שער בגין קניות במטבע חוץ ששימשו למכירות ומתן השירותים", value: "הפרשי שער בגין קניות במטבע חוץ ששימשו למכירות ומתן השירותים" },
    { label: "הוצאות הפרשה לאחריות ששימשו למכירות ומתן השירותים", value: "הוצאות הפרשה לאחריות ששימשו למכירות ומתן השירותים" },
    { label: "קניות מצדדים קשורים ששימשו למכירות ומתן השירותים", value: "קניות מצדדים קשורים ששימשו למכירות ומתן השירותים" },
    { label: "קניות ועלויות אחרות ששימשו למכירות ומתן השירותים", value: "קניות ועלויות אחרות ששימשו למכירות ומתן השירותים" },
    { label: "מלאי בתחילת התקופה ששימש למכירות ומתן השירותים", value: "מלאי בתחילת התקופה ששימש למכירות ומתן השירותים" },
    { label: "מלאי בסוף התקופה ממכירות ומתן השירותים", value: "מלאי בסוף התקופה ממכירות ומתן השירותים" },
    { label: "מלאי תחילת תקופה - ששימש כעלויות ייצור", value: "מלאי תחילת תקופה - ששימש כעלויות ייצור" },
    { label: "שכר עבודה ונילוות  - ששימש כעלויות ייצור", value: "שכר עבודה ונילוות  - ששימש כעלויות ייצור" },
    { label: "עלות קרקעות ופיתוח אצל קבלן בונה - ששימש כעלויות ייצור", value: "עלות קרקעות ופיתוח אצל קבלן בונה - ששימש כעלויות ייצור" },
    { label: "עבודות חוץ וקבלני משנה - ששימשו כעלויות ייצור", value: "עבודות חוץ וקבלני משנה - ששימשו כעלויות ייצור" },
    { label: "הוצאות אחריות ותביעות - ששימשו כעלויות ייצור", value: "הוצאות אחריות ותביעות - ששימשו כעלויות ייצור" },
    { label: "הוצאות חרושת וחומרי בניה - ששימשו כעלויות ייצור", value: "הוצאות חרושת וחומרי בניה - ששימשו כעלויות ייצור" },
    { label: "הוצאות ציוד מתכלה - ששימשו כעלויות ייצור", value: "הוצאות ציוד מתכלה - ששימשו כעלויות ייצור" },
    { label: "הוצאות הובלה ואחסנה - ששימשו כעלויות ייצור", value: "הוצאות הובלה ואחסנה - ששימשו כעלויות ייצור" },
    { label: "הוצאות אריזה - ששימשו כעלויות ייצור", value: "הוצאות אריזה - ששימשו כעלויות ייצור" },
    { label: "הוצאות אחזקה ותיקונים - ששימשו כעלויות ייצור", value: "הוצאות אחזקה ותיקונים - ששימשו כעלויות ייצור" },
    { label: "הוצאות מחקר ופיתוח - ששימשו כעלויות ייצור", value: "הוצאות מחקר ופיתוח - ששימשו כעלויות ייצור" },
    { label: "הוצאות ביטוחים - ששימשו כעלויות ייצור", value: "הוצאות ביטוחים - ששימשו כעלויות ייצור" },
    { label: "אחזקת רכב והובלות - ששימשו כעלויות ייצור", value: "אחזקת רכב והובלות - ששימשו כעלויות ייצור" },
    { label: "דמי שכירות וניהול נכסים - ששימשו כעלויות ייצור", value: "דמי שכירות וניהול נכסים - ששימשו כעלויות ייצור" },
    { label: "מסים ואגרות - ששימשו כעלויות ייצור", value: "מסים ואגרות - ששימשו כעלויות ייצור" },
    { label: "הוצאות פחת  - ששימשו כעלויות ייצור", value: "הוצאות פחת  - ששימשו כעלויות ייצור" },
    { label: "הוצאות בגדי עבודה  - ששימשו כעלויות ייצור", value: "הוצאות בגדי עבודה  - ששימשו כעלויות ייצור" },
    { label: "עלויות יצור אחרות", value: "עלויות יצור אחרות" },
    { label: "מלאי סוף תקופה - מעלויות ייצור", value: "מלאי סוף תקופה - מעלויות ייצור" },
    { label: "שכר עבודה ונילוות - ששימשו כהוצאות מכירה", value: "שכר עבודה ונילוות - ששימשו כהוצאות מכירה" },
    { label: "הוצאות הובלה ואחסנה - ששימשו כהוצאות מכירה", value: "הוצאות הובלה ואחסנה - ששימשו כהוצאות מכירה" },
    { label: "עבודות חוץ לקבלני משנה - ששימשו כהוצאות מכירה", value: "עבודות חוץ לקבלני משנה - ששימשו כהוצאות מכירה" },
    { label: "הוצאות ביטוחים - ששימשו כהוצאות מכירה", value: "הוצאות ביטוחים - ששימשו כהוצאות מכירה" },
    { label: "עמולות ובונוסים לסוכנים עצמאים - ששימשו כהוצאות מכירה", value: "עמולות ובונוסים לסוכנים עצמאים - ששימשו כהוצאות מכירה" },
    { label: "(תמלוגים (זכויות הפצה - ששימשו כהוצאות מכירה", value: "(תמלוגים (זכויות הפצה - ששימשו כהוצאות מכירה" },
    { label: "הוצאות אריזה - ששימשו כהוצאות מכירה", value: "הוצאות אריזה - ששימשו כהוצאות מכירה" },
    { label: "הוצאות אחזקה ותיקונים - ששימשו כהוצאות מכירה", value: "הוצאות אחזקה ותיקונים - ששימשו כהוצאות מכירה" },
    { label: "הוצאות מחקר ופיתוח - ששימשו כהוצאות מכירה", value: "הוצאות מחקר ופיתוח - ששימשו כהוצאות מכירה" },
    { label: "הוצאות נסיעות - ששימשו כהוצאות מכירה", value: "הוצאות נסיעות - ששימשו כהוצאות מכירה" },
    { label: "אחזקת רכב והובלות - ששימשו כהוצאות מכירה", value: "אחזקת רכב והובלות - ששימשו כהוצאות מכירה" },
    { label: "דמי שכירות וניהול נכסים - ששימשו כהוצאות מכירה", value: "דמי שכירות וניהול נכסים - ששימשו כהוצאות מכירה" },
    { label: "הוצאות מכרזים, ירידים ותערוכות - ששימשו כהוצאות מכירה", value: "הוצאות מכרזים, ירידים ותערוכות - ששימשו כהוצאות מכירה" },
    { label: "הוצאות פחת - ששימשו כהוצאות מכירה", value: "הוצאות פחת - ששימשו כהוצאות מכירה" },
    { label: "הוצאות בגדי עבודה - ששימשו כהוצאות מכירה", value: "הוצאות בגדי עבודה - ששימשו כהוצאות מכירה" },
    { label: "הוצאות חשמל ומים - ששימשו כהוצאות מכירה", value: "הוצאות חשמל ומים - ששימשו כהוצאות מכירה" },
    { label: "עמלות וכרטיסי אשראי - ששימשו כהוצאות מכירה", value: "עמלות וכרטיסי אשראי - ששימשו כהוצאות מכירה" },
    { label: "פרסום וקידום מכירות - ששימשו כהוצאות מכירה", value: "פרסום וקידום מכירות - ששימשו כהוצאות מכירה" },
    { label: "הוצאות שונות נטו - ששימשו כהוצאות מכירה", value: "הוצאות שונות נטו - ששימשו כהוצאות מכירה" },
    { label: "שכר עבודה ונלוות - הנהלה וכלליות", value: "שכר עבודה ונלוות - הנהלה וכלליות" },
    { label: "ביטוחים - הנהלה וכלליות", value: "ביטוחים - הנהלה וכלליות" },
    { label: "עבודות חוץ וקבלני משנה - הנהלה וכלליות", value: "עבודות חוץ וקבלני משנה - הנהלה וכלליות" },
    { label: "עמלות ובונוסים לסוכנים - הנהלה וכלליות", value: "עמלות ובונוסים לסוכנים - הנהלה וכלליות" },
    { label: "הוצאות ציוד מתכלה - הנהלה וכלליות", value: "הוצאות ציוד מתכלה - הנהלה וכלליות" },
    { label: "שירותים מקצועיים - הנהלה וכלליות", value: "שירותים מקצועיים - הנהלה וכלליות" },
    { label: "הוצאות אריזה - הנהלה וכלליות", value: "הוצאות אריזה - הנהלה וכלליות" },
    { label: "הוצאות אחזקה ותיקונים - הנהלה וכלליות", value: "הוצאות אחזקה ותיקונים - הנהלה וכלליות" },
    { label: "הוצאות מחקר ופיתוח - הנהלה וכלליות", value: "הוצאות מחקר ופיתוח - הנהלה וכלליות" },
    { label: "נסיעות - הנהלה וכלליות", value: "נסיעות - הנהלה וכלליות" },
    { label: "אחזקת רכב והובלות - הנהלה וכלליות", value: "אחזקת רכב והובלות - הנהלה וכלליות" },
    { label: "דמי שכירות וניהול נכסים - הנהלה וכלליות", value: "דמי שכירות וניהול נכסים - הנהלה וכלליות" },
    { label: "מיסים ואגרות - הנהלה וכלליות", value: "מיסים ואגרות - הנהלה וכלליות" },
    { label: "פחת  - הנהלה וכלליות", value: "פחת  - הנהלה וכלליות" },
    { label: "חשמל ומים - הנהלה וכלליות", value: "חשמל ומים - הנהלה וכלליות" },
    { label: "שמירה וניקיון - הנהלה וכלליות", value: "שמירה וניקיון - הנהלה וכלליות" },
    { label: "השתלמות וספרות מקצועית - הנהלה וכלליות", value: "השתלמות וספרות מקצועית - הנהלה וכלליות" },
    { label: "חובות מסופקים ואבודים - הנהלה וכלליות", value: "חובות מסופקים ואבודים - הנהלה וכלליות" },
    { label: "פרסום וקידום מכירות - הנהלה וכלליות", value: "פרסום וקידום מכירות - הנהלה וכלליות" },
    { label: "כיבודים, מתנות, תרומות, קנסות - הנהלה וכלליות", value: "כיבודים, מתנות, תרומות, קנסות - הנהלה וכלליות" },
    { label: "הוצאות בגין צדדים קשורים - הנהלה וכלליות", value: "הוצאות בגין צדדים קשורים - הנהלה וכלליות" },
    { label: "דמי ניהול - הנהלה וכלליות", value: "דמי ניהול - הנהלה וכלליות" },
    { label: "הוצאות דואר ותקשורת - הנהלה וכלליות", value: "הוצאות דואר ותקשורת - הנהלה וכלליות" },
    { label: "נסיעות לחו\"ל - הנהלה וכלליות", value: "נסיעות לחו\"ל - הנהלה וכלליות" },
    { label: "הוצאות משפטיות - הנהלה וכלליות", value: "הוצאות משפטיות - הנהלה וכלליות" },
    { label: "משרדיות - הנהלה וכלליות", value: "משרדיות - הנהלה וכלליות" },
    { label: "בגדי עבודה - הנהלה וכלליות", value: "בגדי עבודה - הנהלה וכלליות" },
    { label: "שונות נטו וביטולי יתרות - הנהלה וכלליות" },
    { label: "הוצאות מימון לתאגידים בנקאיים", value: "הוצאות מימון לתאגידים בנקאיים" },
    { label: "הוצאות מימון לצדדים קשורים", value: "הוצאות מימון לצדדים קשורים" },
    { label: "הוצאות מימון במטבע חוץ", value: "הוצאות מימון במטבע חוץ" },
    { label: "הוצאות מימון בגין ספקים וזכאים", value: "הוצאות מימון בגין ספקים וזכאים" },
    { label: "הוצאות מימון הוצאות מימון לאחרים", value: "הוצאות מימון הוצאות מימון לאחרים" },
    { label: "הכנסות מימון מתאגידים בנקאיים", value: "הכנסות מימון מתאגידים בנקאיים" },
    { label: "הכנסות מימון מצדדים קשורים", value: "הנסות מימון מצדדים קשורים" },
    { label: "הכנסות מימון מהפרשי שער", value: "הכנסות מימון מהפרשי שער" },
    { label: "הכנסות מימון אחרות", value: "הכנסות מימון אחרות" },
    { label: "רווח הון ממימוש רכוש קבוע", value: "רווח הון ממימוש רכוש קבוע" },
    { label: "רווח הון אחר ושבח", value: "רווח הון אחר ושבח" },
    { label: "הכנסות מדמי ניהול", value: "הכנסות מדמי ניהול" },
    { label: "הכנסות מדמי ניהול מצדדים קשורים", value: "הכנסות מדמי ניהול מצדדים קשורים" },
    { label: "הכנסות מדיוידנד", value: "הכנסות מדיוידנד" },
    { label: "הכנסות מהשכרת מבנים וקרקעות", value: "הכנסות מהשכרת מבנים וקרקעות" },
    { label: "הכנסות מתמלוגים", value: "הכנסות מתמלוגים" },
    { label: "הכנסות אחרות שונות", value: "הכנסות אחרות שונות" },
    { label: "הפסד הון ממימוש רכוש קבוע", value: "הפסד הון ממימוש רכוש קבוע" },
    { label: "הפסד הון אחר", value: "הפסד הון אחר" },
    { label: "הפרשה לירידת ערך", value: "הפרשה לירידת ערך" },
    { label: "שונות", value: "שונות" },
    { label: "רווח/הפסד משותפות שלא נכללו בסעיפים אחרים", value: "רווח/הפסד משותפות שלא נכללו בסעיפים אחרים" },
    { label: "מסים שוטפים", value: "מסים שוטפים" },
    { label: "מסים נדחים", value: "מסים נדחים" },
    { label: "מסים בגין שנים קודמות", value: "מסים בגין שנים קודמות" },
    { label: "דיוידנד לחלוקה", value: "דיוידנד לחלוקה" },
    { label: "רווח להעברה", value: "רווח להעברה" },
    { label: "רווח/הפסד אקוויטי", value: "רווח/הפסד אקוויטי" },
    { label: "מזומנים בשקלים", value: "מזומנים בשקלים" },
    { label: "מזומנים במטבע חוץ", value: "מזומנים במטבע חוץ" },
    { label: "פקדונות לזמן קצר", value: "פקדונות לזמן קצר" },
    { label: "אחרות חוב ממשלתיות", value: "אחרות חוב ממשלתיות" },
    { label: "אגרות חוב קונצרניות", value: "אגרות חוב קונצרניות" },
    { label: "קרנות נאמנות", value: "קרנות נאמנות" },
    { label: "מניות של חברות ישראליות", value: "מניות של חברות ישראליות" },
    { label: "ניירות אחר סחירים אחרים", value: "ניירות אחר סחירים אחרים" },
    { label: "לקוחות בישראל", value: "לקוחות בישראל" },
    { label: "לקוחות בחו\"ל", value: "לקוחות בחו\"ל" },
    { label: "שטרות והמחאות לקבל", value: "שטרות והמחאות לקבל" },
    { label: "הכנסות לקבל", value: "הכנסות לקבל" },
    { label: "כרטיסי אשראי", value: "כרטיסי אשראי" },
    { label: "הפרשה לחובות מסופקים", value: "הפרשה לחובות מסופקים" },
    { label: "לקוחות אחרים", value: "לקוחות אחרים" },
    { label: "מקדמות לספקים ואחרים", value: "מקדמות לספקים ואחרים" },
    { label: "הוצאות מראש", value: "הוצאות מראש" },
    { label: "מס הכנסה מקדמות", value: "מס הכנסה מקדמות" },
    { label: "מוסדות ממשלתיים חייבים", value: "מוסדות ממשלתיים חייבים" },
    { label: "בעלי מניות חייבים", value: "בעלי מניות חייבים" },
    { label: "צדדים קשורים חייבים", value: "צדדים קשורים חייבים" },
    { label: "עובדים חייבים", value: "עובדים חייבים" },
    { label: "חייבים אחרים ויתרות חובה", value: "חייבים אחרים ויתרות חובה" },
    { label: "מסים נדחים לזמן קצר בגין עובדים", value: "מסים נדחים לזמן קצר בגין עובדים" },
    { label: "מסים נדחים לזמן קצר בגין הכנסות לקבל", value: "מסים נדחים לזמן קצר בגין הכנסות לקבל" },
    { label: "מסים נדחים לזמן קצר אחרים", value: "מסים נדחים לזמן קצר אחרים" },
    { label: "הלוואות שניתנו לצדדים קשורים", value: "הלוואות שניתנו לצדדים קשורים" },
    { label: "הלוואות שניתנו לאחרים", value: "הלוואות שניתנו לאחרים" },
    { label: "מלאי שיטחי מסחר ותעשיה למכירה", value: "מלאי שיטחי מסחר ותעשיה למכירה" },
    { label: "מלאי במחסני החברה", value: "מלאי במחסני החברה" },
    { label: "מלאי דירות", value: "מלאי דירות" },
    { label: "מלאי במחסני ערובה", value: "מלאי במחסני ערובה" },
    { label: "מלאי בניינים בהקמה ועבודות בביצוע-עלות ישירה", value: "מלאי בניינים בהקמה ועבודות בביצוע-עלות ישירה" },
    { label: "מלאי בדרך", value: "מלאי בדרך" },
    { label: "מלאי במשגור", value: "מלאי במשגור" },
    { label: "מלאי בתהליך", value: "מלאי בתהליך" },
    { label: "מלאי חומרי גלם", value: "מלאי חומרי גלם" },
    { label: "מלאי עבודות בביצוע לרבות עלויות שהוונו", value: "מלאי עבודות בביצוע לרבות עלויות שהוונו" },
    { label: "מלאי אחר", value: "מלאי אחר" },
    { label: "קרקע ובניינים", value: "קרקע ובניינים" },
    { label: "שיפורים במושכר", value: "שיפורים במושכר" },
    { label: "מכונות וציוד", value: "מכונות וציוד" },
    { label: "כלי רכב", value: "כלי רכב" },
    { label: "מחשבים וציוד עיבוד נתונים", value: "מחשבים וציוד עיבוד נתונים" },
    { label: "רהיטים ואביזרים", value: "רהיטים ואביזרים" },
    { label: "מלאי בסיסי", value: "מלאי בסיסי" },
    { label: "רכוש קבוע אחר", value: "רכוש קבוע אחר" },
    { label: "פחת שנצבר בניינים", value: "פחת שנצבר בניינים" },
    { label: "פחת שנצבר שיפורים במושכר", value: "פחת שנצבר שיפורים במושכר" },
    { label: "פחת שנצבר מכונות וציוד", value: "פחת שנצבר מכונות וציוד" },
    { label: "פחת שנצבר כלי רכב", value: "פחת שנצבר כלי רכב" },
    { label: "פחת שנצבר מחשבים וציוד עיבוד נתונים", value: "פחת שנצבר מחשבים וציוד עיבוד נתונים" },
    { label: "פחת שנצבר רהיטים ואביזרים", value: "פחת שנצבר רהיטים ואביזרים" },
    { label: "רכוש קבוע הפרשה לירידת ערך", value: "רכוש קבוע הפרשה לירידת ערך" },
    { label: "פחת שנצבר רכוש קבוע אחר", value: "פחת שנצבר רכוש קבוע אחר" },
    { label: "הוצאות מראש והלוואות לזמן ארוך", value: "הוצאות מראש והלוואות לזמן ארוך" },
    { label: "הוצאות מראש בגין שכירות לזמן ארוך", value: "הוצאות מראש בגין שכירות לזמן ארוך" },
    { label: "הוצאות מראש אחרות", value: "הוצאות מראש אחרות" },
    { label: "עלות השקעות בחברות מוחזקות", value: "עלות השקעות בחברות מוחזקות" },
    { label: "חלק החברה ברווח /פסד שנצב בחברות מוחזקות", value: "חלק החברה ברווח /פסד שנצבר בחברות מוחזקות" },
    { label: "דיבידנדים שחולקו ע\"י חברות מוחזקות", value: "דיבידנדים שחולקו ע\"י חברות מוחזקות" },
    { label: "הלוואות  לחברות מוחזקות", value: "הלוואות  לחברות מוחזקות" },
    { label: "שטרי הון בחברות מוחזקות", value: "שטרי הון בחברות מוחזקות" },
    { label: "השקעות בחברות אחרות", value: "השקעות בחברות אחרות" },
    { label: "הלוואות לחברות אחרות", value: "הלוואות לחברות אחרות" },
    { label: "השקעות בני\"ע סחירים של חברות אחרות", value: "השקעות בני\"ע סחירים של חברות אחרות" },
    { label: "שטרי הון של חברות אחרות", value: "שטרי הון של חברות אחרות" },
    { label: "השקעה בשותפות", value: "השקעה בשותפות" },
    { label: "השקעות אחרות", value: "השקעות אחרות" },
    { label: "מסים נדחים לזמן ארוך בגין התחייבות לפיצויים", value: "מסים נדחים לזמן ארוך בגין התחייבות לפיצויים" },
    { label: "מסים נדחים לזמן ארוך בגין רכוש קבוע", value: "מסים נדחים לזמן ארוך בגין רכוש קבוע" },
    { label: "מסים נדחים לזמן ארוך אחרים", value: "מסים נדחים לזמן ארוך אחרים" },
    { label: "מוניטין", value: "מוניטין" },
    { label: "הוצאות יסוד", value: "הוצאות יסוד" },
    { label: "פטנט וזכויות יוצרים", value: "פטנט וזכויות יוצרים" },
    { label: "הוצאות נדחות אחרות", value: "הוצאות נדחות אחרות" },
    { label: "בנקים ומשיכות יתר", value: "בנקים ומשיכות יתר" },
    { label: "הלוואות לזמן קצר", value: "הלוואות לזמן קצר" },
    { label: "חלויות שוטפות של הלוואות לזמן ארוך", value: "חלויות שוטפות של הלוואות לזמן ארוך" },
    { label: "הלוואות מתושבי חוץ", value: "הלוואות מתושבי חוץ" },
    { label: "הלוואות מצדדים קשורים", value: "הלוואות מצדדים קשורים" },
    { label: "הלוואות מאחרים", value: "הלוואות מאחרים" },
    { label: "ספקים בישראל", value: "ספקים בישראל" },
    { label: "ספקים בחו\"ל", value: "ספקים בחו\"ל" },
    { label: "שטרות והמחאות לפירעון", value: "שטרות והמחאות לפירעון" },
    { label: "ספקים ונותני שירותים אחרים", value: "ספקים ונותני שירותים אחרים" },
    { label: "עובדים", value: "עובדים" },
    { label: "הפרשות לחופשה והבראה", value: "הפרשות לחופשה והבראה" },
    { label: "מוסדות בגין עובדים", value: "מוסדות בגין עובדים" },
    { label: "מס הכנסה הפרשות בניכוי מקדמות", value: "מס הכנסה הפרשות בניכוי מקדמות" },
    { label: "מוסדות ממשלתיים אחרים", value: "מוסדות ממשלתיים אחרים" },
    { label: "הכנסות שכ\"ד מראש", value: "הכנסות שכ\"ד מראש" },
    { label: "מקדמות מלקוחות", value: "מקדמות מלקוחות" },
    { label: "הכנסות אחרות מראש", value: "הכנסות אחרות מראש" },
    { label: "הוצאות לשלם", value: "הוצאות לשלם" },
    { label: "צדדים קשורים זכאים", value: "צדדים קשורים זכאים" },
    { label: "בעלי מניות זכאים", value: "בעלי מניות זכאים" },
    { label: "הפרשה לאחריות ותיקונים", value: "הפרשה לאחריות ותיקונים" },
    { label: "הפרשה לתביעות", value: "הפרשה לתביעות" },
    { label: "הפרשות תלויות אחרות", value: "הפרשות תלויות אחרות" },
    { label: "בונוסים ומענקים לשלם", value: "בונוסים ומענקים לשלם" },
    { label: "דיבידינד לשלם", value: "דיבידינד לשלם" },
    { label: "זכאים אחרים ויתרות זכות", value: "זכאים אחרים ויתרות זכות" },
    { label: "מסים נדחים לזמן קצרבגין עובדים", value: "מסים נדחים לזמן קצרבגין עובדים" },
    { label: "מסים נדחים לזמן קצר בגין הפסדים להעברה", value: "מסים נדחים לזמן קצר בגין הפסדים להעברה" },
    { label: "מסים נדחים לזמן קצר, אחרים", value: "מסים נדחים לזמן קצר, אחרים" },
    { label: "התחייבויות לזמן ארוך מבנקים", value: "התחייבויות לזמן ארוך מבנקים" },
    { label: "הלוואות לזמן ארוך בניכוי חלויות שוטפות", value: "הלוואות לזמן ארוך בניכוי חלויות שוטפות" },
    { label: "הלוואות מצדדים קשורים", value: "הלוואות מצדדים קשורים" },
    { label: "הלוואות מתושבי חוץ", value: "הלוואות מתושבי חוץ" },
    { label: "הלוואות לזמן ארוך מאחרים", value: "הלוואות לזמן ארוך מאחרים" },
    { label: "שטרי הון לזמן ארוך", value: "שטרי הון לזמן ארוך" },
    { label: "אגרות חוב לזמן ארוך", value: "אגרות חוב לזמן ארוך" },
    { label: "התחייבויות אחרות לזמן ארוך", value: "התחייבויות אחרות לזמן ארוך" },
    { label: "עתודה לפיצויים", value: "עתודה לפיצויים" },
    { label: "יעודה לפיצויים", value: "יעודה לפיצויים" },
    { label: "הפרשות אחרות בשל סיום יחסי עובד-מעביד", value: "הפרשות אחרות בשל סיום יחסי עובד-מעביד" },
    { label: "עתודה למיסים נדחים לזמן ארוך בגין התחייבות לפיצויים", value: "עתודה למיסים נדחים לזמן ארוך בגין התחייבות לפיצויים" },
    { label: "עתודה למיסים נדחים לזמן ארוך בגין רכוש קבוע", value: "עתודה למיסים נדחים לזמן ארוך בגין רכוש קבוע" },
    { label: "עתודה למיסים נדחים לזמן ארוך אחרים", value: "עתודה למיסים נדחים לזמן ארוך אחרים" },
    { label: "הון מניות, הון בעל העסק, הון השותפות", value: "הון מניות, הון בעל העסק, הון השותפות" },
    { label: "פרמיה על מניות", value: "פרמיה על מניות" },
    { label: "קרנות הון", value: "קרנות הון" },
    { label: "תקבולים על חשבון מניות", value: "תקבולים על חשבון מניות" },
    { label: "תקבולים על חשבון אופציות", value: "תקבולים על חשבון אופציות" },
    { label: "עודפים יתרת רווח (הפסד) שנצבר", value: "עודפים יתרת רווח (הפסד) שנצבר" }
];
const PaymentMethod = [
    { name: "NonPaymentReport", value: 0 },
    { name: "AccountChargeAuthorization", value: 1 }
];

const SortCodesWithZeroVat = [
    "הכנסות פטורות",
    "ארנונה ומים",
    "מלאי סגירה",
    "מלאי פתיחה",
    "הוצאות פרטיות",
    "שכר עבודה",
    "הוצאות ביטוח לאומי",
    "הוצאות גמל",
    "הוצאות גמל פיצויים",
    "יציאה מהמלאי",
    "כניסה מהמלאי",
    "הוצאות פטורות",
    "חובה",
    "חובה/זכות",
    "זכות"
]

const SortCodesWith66Vat = [
    "הוצאות טלפון סלולרי",
    "הוצאות רכב",
    "טלפון סלולרי מע״מ",
    "הוצאות רכב פרטי"
]

const SortCodesWith16Vat = [
    "קניות הגדה",
    "מכירות הגדה",

]

const TaxAuthorityScopes = {
    "VatReport" : 'VatReport',
    "CreationNumber" : 'scope',
    "AdvancesPayment":"AdvancesPayment"
}

const TaxAuthorityRedirectUri = {
    "VatReport" : 'http://127.0.0.1:5163/api/TaxAuthority/accountantCallback',
    "CreationNumber" : 'https://www.accounting-manager.com:5163/api/TaxAuthority/callback'
}

const PalCodeType = [
    "תוצאתי",
    "מאזני",
    "מאזני רכוש קבוע",

]

const PalCodeClassification = [
    "נכסים",
    "התחיביות",
    "הון קרנות ועודפים",
    "הכנסות",
    "עלויות והוצאות",
    "יעוד הרווח",
    "התאמה למס"
]

const PaymentAcceptanceConstantsAccountcards = Object.freeze({
    GeneralCheck: '500',
    GeneralCash: '501',
    GeneralCreditCard: '502',
    GeneralBank: '515',
});

const SortCodeVatType = {
    0: 'NotParticipating',
    1: 'VatTransactions',
    2: 'VatExpenses',
    3: 'VatEquipment'
};

const BankNames = {
    10: 'בנק לאומי',
    12: 'בנק הפועלים',
    17: 'בנק מרכנתיל',
    20: 'בנק מזרחי'
};

const BankTypes = {
    10: 0,
    17 : 1,
    20 : 2,
    12 : 3,
    99 : 99
}

const BankUrls = {
    10: 'https://hb2.bankleumi.co.il/login',
    12: 'https://biz2.bankhapoalim.co.il/ng-portals/auth/he/biz-login/authenticate',
    17: 'https://start.telebank.co.il/login',
    20: 'https://www.mizrahi-tefahot.co.il/login/#/main/auth-page-he'
};

const ISRAEL_INVOICE_AMOUNT_LIMIT = 25000; // Set the appropriate value
const Constants = {
    CheckTypeOptions,
    CaseTypeOptions,
    AccountManagementOptions,
    ReportingTypeOptions,
    AccountCardPalCode,
    TransactionDocuments,
    ReportTrackingStatus,
    ReportTrackingType,
    PaymentMethod,
    SortCodesWithZeroVat,
    SortCodesWith66Vat,
    TaxAuthorityScopes,
    TaxAuthorityRedirectUri,
    SortCodesWith16Vat,
    PalCodeType,
    PalCodeClassification,
    PaymentAcceptanceConstantsAccountcards,
    SortCodeVatType,
    BankNames,
    BankUrls,
    BankTypes,
    ISRAEL_INVOICE_AMOUNT_LIMIT
};



export default Constants;