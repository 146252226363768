import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import UserService from '../../Services/user.service';
import '../ProfitAndLossReport/ProfitAndLossReport.css';
import backIcon from '../../assests/images/left-arrow.png';
import EnumsService from '../../Services/enums.service';
import { ToastContainer, toast } from "react-toastify";
import { Modal, Typography, colors, Dialog, DialogTitle, DialogContent, DialogActions, ListItem, TextField, Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { LineChart, XAxis, YAxis, LineSeries } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { beautifyNumber } from '../../Utils/FormatNumber';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Checkbox, FormGroup } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import TrialBalanceReportHtml from './TrialBalanceReportHtml/TrialBalanceReportHtml';
import AdvancedDropdown from '../AdvancedDropdown/AdvancedDropdown';
import PuffLoader from "react-spinners/PuffLoader";


import './LedgerReport.css'

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Box
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import CustomMonthDatePicker from '../CustomMonthDatePicker/CustomMonthDatePicker';
import CustomYearDatePicker from '../CustomYearDatePicker/CustomYearDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import TrialBalanceTable from './SubComponent/TrialBalanceTable';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
const chartSetting = {
    yAxis: [
        {
            label: 'rainfall (mm)',
        },
    ],
    width: 500,
    height: 300,
    sx: {
        [`.${axisClasses.right} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
        },
    },
};
registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it

// Helper function to adjust date to Israel time zone (UTC+2)
const adjustToIsraelTime = (date) => {
    const israelTimeOffset = 2 * 3600 * 1000; // 2 hours in milliseconds
    return new Date(date.getTime() + israelTimeOffset);
};

// Helper function to get the start of the year in Israel time
const getStartOfYear = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1); // Jan 1st of the given year
    return adjustToIsraelTime(startOfYear);
};

// Helper function to get the current month in Israel time
const getCurrentMonth = (date) => {
    const currentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Last day of the current month
    return adjustToIsraelTime(currentMonth);
};
const formatDate = (date) => {
    const d = new Date(date);
    const month = ('0' + (d.getMonth() + 1)).slice(-2); // Add leading zero if needed
    const year = d.getFullYear();
    return `${month}/${year}`;
};

const formatDateRangeFromString = (dateRangeString) => {
    const [startDateStr, endDateStr] = dateRangeString.split(' $ ');

    const formatSingleDate = (dateStr) => {
        const date = new Date(dateStr);
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure month is in MM format
        const year = date.getFullYear();
        return `${month}/${year}`;
    };

    const startFormatted = formatSingleDate(startDateStr);
    const endFormatted = formatSingleDate(endDateStr);

    return `${startFormatted} - ${endFormatted}`;
};

function LedgerReport() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    // const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');

    //   new MUI table imports

    const [focused, setFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isRange, setIsRange] = useState(() => {
        const storedIsRange = localStorage.getItem('_IsRange');
        return storedIsRange === "true";
    });;// Flag to toggle between single date and range


    const [sortConfig, setSortConfig] = useState({ key: 'uploadNumber', direction: 'descending', type: 'number' });
    const handleSortChange = (key, type) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };

    const customScrollbarRef = useRef();
    const tableContainerRef = useRef();

    const handleCustomScroll = (e) => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollLeft = e.target.scrollLeft;
        }
    };
    const handleTableScroll = (e) => {
        if (customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = e.target.scrollLeft;
        }
    };
    const headerStyle = {
        backgroundColor: '#f7f7f7',
        color: 'black',
        fontWeight: 'bold'
    }


    const [palCodesData, setPalCodesData] = useState([]);
    const [trialBalanceData, setTrialBalanceData] = useState(null);
    const [palDates, setPalDates] = useState([]);
    const [openRows, setOpenRows] = useState({});
    const [openReportModal, setOpenReportModal] = useState(false);
    const [palCodesOptions, setPalCodesOptions] = useState([{ key: '', label: '', value: "" }]);

    const [startDate, setStartDate] = useState(() => {
        const storedStartDateString = localStorage.getItem('_StartDate');
        const startDate = storedStartDateString ? new Date(storedStartDateString) : getStartOfYear(new Date());
        return startDate;
    });

    const [endDate, setEndDate] = useState(() => {
        const storedEndDateString = localStorage.getItem('_EndDate');
        const endDate = storedEndDateString ? new Date(storedEndDateString) : getCurrentMonth(new Date());
        return endDate;
    });

    const [startYearDate, setStartYearDate] = useState(() => {
        const storedStartYearDateString = localStorage.getItem('_StartYearDate');
        const startDate = storedStartYearDateString ? new Date(storedStartYearDateString) : getStartOfYear(new Date());
        return new Date(Date.UTC(startDate.getFullYear(), 0, 1));
    });

    const [endYearDate, setEndYearDate] = useState(() => {
        const storedEndYearDateString = localStorage.getItem('_EndYearDate');
        const endDate = storedEndYearDateString ? new Date(storedEndYearDateString) : getCurrentMonth(new Date());
        return new Date(Date.UTC(endDate.getFullYear(), 11, 31));
    });

    const [isMonthly, setIsMonthly] = useState(() => {
        return JSON.parse(localStorage.getItem('isMonthly_Ledger')) || true;
    });
    const [isDataFetched, setIsDataFetched] = useState(false);



    const fetchEnums = async (token, client) => {
        setLoading(true);
        try {
            const palCodesResponse = await EnumsService.GetPalCodesByAccountantId(client.accountantId, token);
            const palCodes = await palCodesResponse.json();
            if (palCodes.length <= 0) {
                return
            }
            const options = [
                {
                    key: 'NonPalCode',
                    value: t('NonPalCode'),
                    label: 'NonPalCode',
                },
                ...palCodes.map((category) => ({
                    key: category.palCodeId,
                    value: category.palCodeId,
                    label: category.palCodeId,
                })),
            ];

            // Update the state with the mapped options
            setPalCodesOptions(options);

        } catch (error) {
            console.error(error);
            toast.error(t('fetchEnumsError'), error);
        }
        setLoading(false);
    };

    const handleSelect = async (accountCard, palCode) => {

        navigate('/AccountCardDashboard', { state: { client, accountCardId: accountCard.cardId, accountCardName: accountCard.name, palCodeName: palCode.palCodeId, startDate, endDate } });
    }


    const handleRangeChange = () => {
        setIsMonthly(prevIsMonthly => {
            const newIsMonthly = !prevIsMonthly;
            localStorage.setItem('isMonthly_Ledger', JSON.stringify(newIsMonthly));
            return newIsMonthly;
        });
    };

    const handleEndDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        let endDateToSet;

        if (isMonthly) {
            endDateToSet = newDate;
            setEndDate(newDate);
            localStorage.setItem('_EndDate', newDate.toISOString()); // Save to localStorage
        } else {
            endDateToSet = new Date(Date.UTC(date.getFullYear(), 11, 31));
            setEndYearDate(endDateToSet);
            setEndDate(endDateToSet);
            localStorage.setItem('_EndYearDate', endDateToSet.toISOString()); // Save to localStorage
        }

        // Call fetchAccountCardDashboard after updating the end date
        const startOfYear = new Date(Date.UTC(startDate.getFullYear(), 0, 1));
        fetchPalCodesData(isMonthly ? startDate : startYearDate, endDateToSet, processedFilesCheckbox);
        fetchTrialBalanceData(isMonthly ? startDate : startYearDate, endDateToSet, processedFilesCheckbox);
    };

    const handleStartDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        let startDateToSet;

        if (isMonthly) {
            startDateToSet = newDate;
            setStartDate(newDate);
            localStorage.setItem('_StartDate', newDate.toISOString()); // Save to localStorage
        } else {
            startDateToSet = new Date(Date.UTC(date.getFullYear(), 0, 1));
            setStartYearDate(startDateToSet);
            setStartDate(startDateToSet);
            localStorage.setItem('_StartYearDate', startDateToSet.toISOString()); // Save to localStorage
        }

        // Call fetchAccountCardDashboard after updating the start date
        const endOfYear = new Date(Date.UTC(endDate.getFullYear(), 11, 31));
        fetchPalCodesData(startDateToSet, isMonthly ? endDate : endYearDate, processedFilesCheckbox);
        fetchTrialBalanceData(startDateToSet, isMonthly ? endDate : endYearDate, processedFilesCheckbox);
    };

    const [selectedSearchType, setSelectedSearchType] = useState('General');
    const [showWithZeros, setShowWithZeros] = useState(false);



    const filteredAndSortedPalCodesData = useMemo(() => {
        if (!palCodesData || palCodesData.length === 0) {
            return [];
        }
        let filteredData;


        if (selectedSearchType == 'General') {
            filteredData = palCodesData.filter((palCode) => {
                const query = searchQuery.toLowerCase();

                return palCode.accountCards.some(accountCard => {
                    const amountString = accountCard.dateAmounts.map(dateAmount => dateAmount.amount?.toString()).join(' ');
                    return (
                        palCode?.palCodeId?.toLowerCase().includes(query) ||
                        accountCard?.name?.toLowerCase().includes(query) ||
                        amountString.includes(query) ||
                        accountCard.dateAmounts.some(dateAmount =>
                            dateAmount.date?.toLowerCase().includes(query) ||
                            dateAmount.amount?.toString().includes(query)
                        )
                    );
                });
            });
        }


        if (selectedSearchType === 'BalanceClassificationMazni') {
            filteredData = palCodesData.filter((palCode) => {
                const query = searchQuery.toLowerCase();

                return (
                    palCode?.palCodeId?.toLowerCase().includes(query)
                );
            });
        }

        if (selectedSearchType === 'AccountCard') {
            filteredData = palCodesData
                .map((palCode) => {
                    const query = searchQuery.toLowerCase();

                    // Filter accountCards within each palCode
                    const filteredAccountCards = palCode.accountCards.filter(accountCard =>
                        accountCard?.name?.toLowerCase().includes(query)
                    );

                    // If there are matching accountCards, return the palCode with those filtered accountCards
                    if (filteredAccountCards.length > 0) {
                        return {
                            ...palCode,
                            accountCards: filteredAccountCards
                        };
                    }

                    // If no accountCards match, return null (to filter out later)
                    return null;
                })
                .filter(palCode => palCode !== null); // Remove palCodes without matching accountCards
        }

        if (!showWithZeros) {
            filteredData = filteredData.filter(palCode => {
                return palCode.accountCards.some(accountCard =>
                    accountCard.dateAmounts.some(dateAmount => dateAmount.amount !== 0)
                );
            });
        }

        // Sorting the filtered data based on sortConfig
        if (sortConfig.key) {
            filteredData.sort((a, b) => {
                if (sortConfig.type === 'newest') {
                    const aValue = a[sortConfig.key] || "";
                    const bValue = b[sortConfig.key] || "";

                    return sortConfig.direction === 'ascending'
                        ? new Date(aValue) - new Date(bValue)
                        : new Date(bValue) - new Date(aValue);
                } else if (sortConfig.type === 'number') {
                    return sortConfig.direction === 'ascending'
                        ? a[sortConfig.key] - b[sortConfig.key]
                        : b[sortConfig.key] - a[sortConfig.key];
                } else if (sortConfig.type === 'date') {
                    return sortConfig.direction === 'ascending'
                        ? new Date(a[sortConfig.key]) - new Date(b[sortConfig.key])
                        : new Date(b[sortConfig.key]) - new Date(a[sortConfig.key]);
                } else {
                    const aValue = a[sortConfig.key]?.toLowerCase() || "";
                    const bValue = b[sortConfig.key]?.toLowerCase() || "";

                    return sortConfig.direction === 'ascending'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }
            });
        }

        return filteredData;
    }, [palCodesData, searchQuery, sortConfig, selectedSearchType, showWithZeros]);



    const fetchPalCodesData = async (startDate, endDate, onlyProcessed = true) => {
        setLoading(true);
        try {
            // Decide which API to call based on isMonthly
            const response = await EnumsService.GetPalCodesDetails(client.caseId, client.accountantId, startDate, endDate, !isMonthly, token, onlyProcessed);

            if (response.ok) {
                const responseJson = await response.json();
                const result = responseJson.palCodes

                const dates = new Set();
                setPalCodesData(result);
                result.forEach(palCode => {
                    palCode.accountCards.forEach(accountCard => {
                        accountCard.dateAmounts.forEach(dateAmount => {
                            dates.add(dateAmount.date);
                        });
                    });
                });

                const sortedDates = Array.from(dates).sort((a, b) => new Date(a) - new Date(b));
                setPalDates(sortedDates);
            } else {
                const errorText = await response.text();
                toast.error(`${t('ErrorFetchingLedgerReportDetails')}: ${errorText}`);
            }
        } catch (error) {
            console.error("Error in API call:", error);
            toast.error(`${t('ErrorFetchingLedgerReportDetails')}: ${error.message}`);
        }
        setLoading(false);
    };

    const fetchTrialBalanceData = async (startDate, endDate, onlyProcessed = true) => {
        setLoading(true);
        try {
            // Decide which API to call based on isMonthly

            const response = await EnumsService.GetTrialBalanceDetails(client.caseId, client.accountantId, startDate, endDate, !isMonthly, token, onlyProcessed);

            if (response.ok) {
                const responseJson = await response.json();
                setTrialBalanceData(responseJson)
            } else {
                const errorText = await response.text();
                toast.error(`${t('ErrorFetchingLedgerReportDetails')}: ${errorText}`);
            }
        } catch (error) {
            console.error("Error in API call:", error);
            toast.error(`${t('ErrorFetchingLedgerReportDetails')}: ${error.message}`);
        }
        setLoading(false);
    };



    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className={`document-custom-input document-custom-input-range `} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));



    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            navigate('/client-details', { state: { client } });
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    if (location.state && location.state.client && location.state.type) {
                        setClient(location.state.client);
                        setIsDataFetched(true);
                    }
                    // Fetch financial data for the current date on initial load only
                }
                else {
                    navigate("/login");
                }

            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }
            setLoading(false);
        };


        fetchUserData();

    }, [location.state]);

    useEffect(() => {
        if (isDataFetched) {
            const storedStartDateString = localStorage.getItem('_StartDate');
            const storedEndDateString = localStorage.getItem('_EndDate');
            const storedStartYearDateString = localStorage.getItem('_StartYearDate');
            const storedEndYearDateString = localStorage.getItem('_EndYearDate');

            const startDate = storedStartDateString ? new Date(storedStartDateString) : getStartOfYear(new Date());
            const endDate = storedEndDateString ? new Date(storedEndDateString) : getCurrentMonth(new Date());
            const startYearDate = storedStartYearDateString ? new Date(storedStartYearDateString) : new Date(Date.UTC(startDate.getFullYear(), 0, 1));
            const endYearDate = storedEndYearDateString ? new Date(storedEndYearDateString) : new Date(Date.UTC(endDate.getFullYear(), 11, 31));

            setStartDate(startDate);
            setEndDate(endDate);
            setStartYearDate(startYearDate);
            setEndYearDate(endYearDate);

            fetchPalCodesData(isMonthly ? startDate : startYearDate, isMonthly ? endDate : endYearDate, processedFilesCheckbox);
            fetchTrialBalanceData(isMonthly ? startDate : startYearDate, isMonthly ? endDate : endYearDate, processedFilesCheckbox);
            fetchEnums(token, client);
        }
    }, [isDataFetched]);


    useEffect(() => {
        if (client) {
            refreshData();
        }

    }, [isMonthly]);



    const refreshData = async () => {
        if (isMonthly) {
            fetchPalCodesData(startDate, endDate, processedFilesCheckbox);
            fetchTrialBalanceData(startDate, endDate, processedFilesCheckbox);

        }
        else {
            fetchPalCodesData(startYearDate, endYearDate, processedFilesCheckbox);
            fetchTrialBalanceData(startYearDate, endYearDate, processedFilesCheckbox);

        }
    }


    const Row = ({ palCode, dateHeaders, open, handleToggleRow, onRightClick }) => {
        // High-level total sum for the palCode (sum of all accountCards)
        const palCodeTotal = palCode.accountCards.reduce((sum, accountCard) => {
            return sum + accountCard.dateAmounts.reduce((acc, dateAmount) => acc + (dateAmount ? dateAmount.amount : 0), 0);
        }, 0);

        return (
            <>
                <TableRow sx={{
                    transition: 'background-color 0.2s ease-in-out', // Smooth transition for background color
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slightly change the background color on hover
                        cursor: 'pointer', // Change cursor to pointer
                    }
                }}>
                    {/* High-level sum (total sum of the palCode row) */}
                    <TableCell align="left" style={{ color: 'color', fontWeight: 'bold' }}>
                        {beautifyNumber(palCodeTotal)}
                    </TableCell>
                    {[...dateHeaders].reverse().map(date => (
                        <TableCell key={date} align="left">
                            {beautifyNumber(palCode.accountCards.reduce((sum, accountCard) => {
                                const dateAmount = accountCard.dateAmounts.find(da => da.date === date);
                                return sum + (dateAmount ? dateAmount.amount : 0);
                            }, 0))}
                        </TableCell>
                    ))}
                    {/* Last cell for the sum of the row */}
                    <TableCell
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'left',
                            fontSize: '0.8rem',  // Adjust the font size as needed
                            fontWeight: 'bold'    // Optionally make the font bold
                        }}
                    >
                        <TableCell>
                            {palCode.palCodeId === "NonPalCode" ? t(palCode.palCodeId) : palCode.palCodeId}
                        </TableCell>
                        {open ? (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={handleToggleRow}
                            >
                                <KeyboardArrowUp />
                            </IconButton>
                        ) : (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={handleToggleRow}
                            >
                                <KeyboardArrowDown />
                            </IconButton>
                        )}
                    </TableCell>

                </TableRow>

                {(open || (selectedSearchType === 'AccountCard' && searchQuery)) && (
                    palCode.accountCards.map((accountCard, index) => {
                        // Low-level total sum for each accountCard
                        const accountCardTotal = accountCard.dateAmounts.reduce((sum, dateAmount) => sum + (dateAmount ? dateAmount.amount : 0), 0);

                        return (
                            <TableRow onClick={() => handleSelect(accountCard, palCode)}
                                onContextMenu={(e) => {
                                    e.preventDefault(); // Prevent the default right-click menu
                                    onRightClick(accountCard, palCode); // Trigger right-click handler
                                }}
                                className='open-rows rowHover'
                                sx={{
                                    ...{ boxShadow: 'inset 0px 2px 200px 0px rgba(250, 235, 215, 0.2)' },
                                    transition: 'transform 0.2s ease, box-shadow 0.1s ease', '&:hover': { transform: 'translateY(-3px)', cursor: 'pointer' }
                                }}
                                key={index}
                            >
                                {/* Low-level sum (total sum of the accountCard row) */}
                                <TableCell align="left" style={{ color: 'color', fontWeight: 'bold' }}>
                                    {beautifyNumber(accountCardTotal)}
                                </TableCell>
                                {[...dateHeaders].reverse().map(date => (
                                    <TableCell key={date} align="left">
                                        {beautifyNumber(accountCard.dateAmounts.find(da => da.date === date)?.amount) || 0}
                                    </TableCell>
                                ))}
                                <TableCell component="th" scope="row">
                                    {accountCard.name}
                                </TableCell>

                            </TableRow>
                        );
                    })
                )}
            </>
        );
    };


    const handleToggleRow = (index) => {
        setOpenRows(prevState => ({ ...prevState, [index]: !prevState[index] }));
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedAccountCard, setSelectedAccountCard] = useState(null);
    const [selectedAccountCardPalCode, setSelectedAccountCardPalCode] = useState(null);

    // Step 2: Handle right-click and open the dialog
    const handleRightClickedRow = (accountCard, palCode) => {
        const accountCardWithPalCode = { ...accountCard, palCodeId: palCode.palCodeId };
        setSelectedAccountCard(accountCardWithPalCode);
        console.log(accountCardWithPalCode.palCodeId);

        setSelectedAccountCardPalCode(accountCardWithPalCode.palCodeId);
        setOpenDialog(true);  // Open the dialog
    };

    // Step 3: Handle closing the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);  // Close the dialog
        setSelectedAccountCard(null);  // Reset the selected account card
    };

    // Step 2: Memoize the Row component
    const memoizedRows = useMemo(() => {
        return filteredAndSortedPalCodesData ? filteredAndSortedPalCodesData.map((palCode, index) => (
            <Row
                key={palCode.palCodeId}
                palCode={palCode}
                dateHeaders={palDates}
                open={!!openRows[index]}
                handleToggleRow={() => handleToggleRow(index)}
                onRightClick={handleRightClickedRow}
            />
        )) : [];
    }, [palCodesData, openRows, filteredAndSortedPalCodesData]);

    const calculateFooterTotals = (filteredPalCodes, dateHeaders) => {
        const totals = {
            grandTotal: 0,
            dateTotals: dateHeaders.reduce((acc, date) => {
                acc[date] = 0;
                return acc;
            }, {})
        };

        filteredPalCodes.forEach(palCode => {
            const palCodeTotal = palCode.accountCards.reduce((sum, accountCard) => {
                return sum + accountCard.dateAmounts.reduce((acc, dateAmount) => acc + (dateAmount ? dateAmount.amount : 0), 0);
            }, 0);

            totals.grandTotal += palCodeTotal;

            dateHeaders.forEach(date => {
                const dateTotal = palCode.accountCards.reduce((sum, accountCard) => {
                    const dateAmount = accountCard.dateAmounts.find(da => da.date === date);
                    return sum + (dateAmount ? dateAmount.amount : 0);
                }, 0);
                totals.dateTotals[date] += dateTotal;
            });
        });

        return totals;
    };
    const footerTotals = calculateFooterTotals(filteredAndSortedPalCodesData, palDates);

    const handleOpenHTMLModal = async () => {
        setOpenReportModal(true);
    };

    const handleCloseHTMLModal = () => {
        setOpenReportModal(false);
    };



    const handleChange = (event) => {
        const { name } = event.target;
        setSelectedSearchType(name);

    };

    const [alignment, setAlignment] = React.useState('left')

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };


    const [processedFilesCheckbox, setProcessedFilesCheckbox] = useState(() => {
        const savedValue = localStorage.getItem('processedFilesCheckbox');
        return savedValue === null ? true : JSON.parse(savedValue);
    });

    // Update localStorage whenever the checkbox value changes
    useEffect(() => {
        localStorage.setItem('processedFilesCheckbox', JSON.stringify(processedFilesCheckbox));
    }, [processedFilesCheckbox]);

    const handleProcessFilesCheckboxClick = async (isChecked) => {
        try {
            if (isChecked !== null) {
                if (isChecked) {
                    try {
                        await fetchPalCodesData(startDate, endDate, true);
                    } catch (error) {
                        toast.error(`${t('ErrorFetchingLedgerReportDetails')}: ${error.message}`);
                    }
                    try {
                        await fetchTrialBalanceData(startDate, endDate, true);
                    } catch (error) {
                        toast.error(`${t('ErrorFetchingLedgerReportDetails')}: ${error.message}`);
                    }
                } else {
                    try {
                        await fetchPalCodesData(startDate, endDate, false);
                    } catch (error) {
                        toast.error(`${t('ErrorFetchingLedgerReportDetails')}: ${error.message}`);
                    }
                    try {
                        await fetchTrialBalanceData(startDate, endDate, false);
                    } catch (error) {
                        toast.error(`${t('ErrorFetchingLedgerReportDetails')}: ${error.message}`);
                    }
                }
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        }

        setProcessedFilesCheckbox(isChecked);
    };


    const memoizedTrialBalanceReport = useMemo(() => {
        if (client && trialBalanceData) {

            return (
                <TrialBalanceReportHtml
                    open={openReportModal}
                    handleClose={handleCloseHTMLModal}
                    data={trialBalanceData}
                    handleSelect={handleSelect}
                    token={token}
                    client={client}  // Only pass client when it's not null
                />
            );
        }
        return null;  // Return null if client is null to avoid rendering
    }, [client, trialBalanceData, openReportModal, token, handleSelect]);

    const updateAccountCardPalCode = async (updatedAccountCard, oldCard) => {

        try {
            // Ensure empty string fields are converted to null
            Object.entries(updatedAccountCard).forEach(([key, value]) => {
                if (value === '') {
                    updatedAccountCard[key] = null;
                }
            });


            // Handle special cases for sourceDeductionAssociation and palCode
            if (updatedAccountCard.sourceDeductionAssociation && typeof updatedAccountCard.sourceDeduction === 'object' && 'key' in updatedAccountCard.sourceDeduction) {
                updatedAccountCard.sourceDeductionAssociation = updatedAccountCard.sourceDeduction['key'];
            }

            if (updatedAccountCard.palCode && typeof updatedAccountCard.palCode === 'object' && 'key' in updatedAccountCard.palCode) {
                updatedAccountCard.palCode = updatedAccountCard.palCode['key'];
            }

            const sourceDeductionAssociationAsNum = updatedAccountCard.sourceDeductionAssociation === 'NotIncluded' ? 0 : 1;

            // Call the API to update the account card
            await EnumsService.UpdateAccountCard(
                client.caseId,
                oldCard.cardId,
                oldCard.name,
                oldCard.cardId,
                updatedAccountCard.name,
                '', //account case id
                updatedAccountCard.palCodeId,
                token,
            );
            refreshData();
            toast.success(t('AccountCardUpdated'));
        } catch (error) {
            console.error('Error saving account card:', t(error));
            if (error == 'Card Not Editable') {
                toast.error(t('FailedToUpdateAccountCard') + ' ' + t('CardNotEditable'));

            } else {
                toast.error(t('FailedToUpdateAccountCard'));
            }
        }
    };


    const [palCodeChanged, setPalCodeChanged] = useState(false);
    const handlePalCodeChange = (newValue) => {
        console.log(newValue);
        if (!selectedAccountCard.isEditable) {
            toast.info(t('CardNotEditable'));
            return;
        }

        if (selectedAccountCard.palCodeId !== newValue) {
            setPalCodeChanged(true);
            setSelectedAccountCardPalCode(newValue);
        } else {
            setPalCodeChanged(false);
        }
    };

    const handleSavePalCodeChange = () => {

        if (selectedAccountCardPalCode === t('NonPalCode')) {
            toast.info(t('NonPalcodeTypeAsPalcodeError'));
            return;
        }
        const updatedAccountCard = {
            palCodeId: selectedAccountCardPalCode,
            name: selectedAccountCard.name,
        };
        updateAccountCardPalCode(updatedAccountCard, selectedAccountCard);
        setPalCodeChanged(false);
        setOpenDialog(false);
    };
    // Calculate the total amount
    const totalAmount = selectedAccountCard
        ? selectedAccountCard.dateAmounts.reduce(
            (sum, da) => sum + (da ? da.amount : 0),
            0
        )
        : 0;
    return (
        <>
            <Dialog
                dir="rtl"
                open={openDialog}
                onClose={handleCloseDialog}
                sx={{ '& .MuiDialog-paper': { width: '900px', height: '300px' } }}
            >
                <DialogTitle>{t('AccountCardDetails')}</DialogTitle>
                <DialogContent>
                    {selectedAccountCard && (
                        <Box dir="rtl">
                            <Typography variant="body1">
                                <strong>{t('AccountCard Name')}:</strong>{' '}
                                {selectedAccountCard.name}
                            </Typography>
                            <Typography variant="body1">
                                <strong>{t('TotalAmount')}:</strong>{' '}
                                {beautifyNumber(totalAmount)}
                            </Typography>
                            <Typography variant="body1">
                                <strong>{t('PalCode')}:</strong>
                            </Typography>
                            <AutocompleteSelect
                                options={palCodesOptions}
                                selectedValues={
                                    palCodesOptions.find(
                                        (option) => option.value === selectedAccountCardPalCode
                                    ) || null
                                }
                                onChange={(event, newValue) =>
                                    handlePalCodeChange(newValue ? newValue.value : null)
                                }
                                placeholder={t('SelectBalanceClassification')}
                                isMultiple={false}
                                width="100%"
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button onClick={handleCloseDialog} color="primary">
                        {t('close')}
                    </Button>
                    {palCodeChanged && (
                        <Button onClick={handleSavePalCodeChange} color="primary">
                            {t('Save')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <HamburgerMenu client={client} />

            <div className="template-page-withoutHeight">
                <div className="template-container">
                    <UpperPanelDetails />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                        <div className={`main-title ${direction}`}>{t('TrialBalance')}</div>
                    </div>
                    <div className={`title-back-container ${direction}`} style={{ marginTop: '10px' }}>
                        <button onClick={handleOpenHTMLModal} className="create-Invoice-selector" >
                            <DescriptionIcon style={{ fontSize: '24px' }} />
                            <span>{t("TrialBalance")}</span>
                        </button>

                    </div>
                    <div className={`searchFilter-bar__container ${direction}`} >
                        <div>


                            <TextField
                                fullWidth
                                placeholder={t('searchClient')}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="search-bar"
                                style={{ height: '55px' }}
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '55px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                        },
                                    },
                                    ...(direction === 'ltr'
                                        ? {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }
                                        : {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }),
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                            />

                            <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={selectedSearchType === 'General'}
                                        onChange={handleChange}
                                        name="General"
                                        color="primary"
                                    />
                                    <span>{t('General')}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={selectedSearchType === 'BalanceClassificationMazni'}
                                        onChange={handleChange}
                                        name="BalanceClassificationMazni"
                                        color="primary"
                                    />
                                    <span>{t('BalanceClassificationMazni')}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', marginRight: '8px' }}>
                                    <Checkbox
                                        checked={selectedSearchType === 'AccountCard'}
                                        onChange={handleChange}
                                        name="AccountCard"
                                        color="primary"
                                    />
                                    <span>{t('AccountCard')}</span>
                                </div>

                            </div>
                        </div>
                        {isMonthly ? (
                            <>

                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleStartDateChange(date)}
                                    date={dayjs(startDate)}
                                    label={t("StartDate")}
                                />

                                <FormControlLabel
                                    value={isMonthly}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={!isMonthly}
                                        onChange={handleRangeChange}
                                    />}
                                    label={t("AnnualReport")}
                                    labelPlacement="bottom"
                                />

                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleEndDateChange(date)}
                                    date={dayjs(endDate)}
                                    label={t("EndDate")}
                                />

                            </>
                        ) : (
                            <>
                                <CustomYearDatePicker
                                    onDateChange={(date) => handleStartDateChange(date)}
                                    date={dayjs(startYearDate)}
                                    label={t("StartDate")}
                                />

                                <FormControlLabel
                                    value={!isMonthly}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={!isMonthly}
                                        onChange={handleRangeChange}

                                    />}
                                    label={t("AnnualReport")}
                                    labelPlacement="bottom"
                                />

                                <CustomYearDatePicker
                                    onDateChange={(date) => handleEndDateChange(date)}
                                    date={dayjs(endYearDate)}
                                    label={t("EndDate")}
                                />

                            </>
                        )}
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={t('ShowWithZeros')}
                                checked={showWithZeros}
                                onChange={() => setShowWithZeros(prev => !prev)}
                                sx={{
                                    width: '100%',  // Optional: to ensure it takes up available space
                                    display: 'flex',
                                    justifyContent: 'flex-start',  // Align checkbox and label properly
                                    '.MuiFormControlLabel-label': {
                                        width: '100px',  // Set your desired fixed width for the label
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label={t('ShowUnprocessedDocuments')}
                                checked={!processedFilesCheckbox}
                                onChange={() => handleProcessFilesCheckboxClick(!processedFilesCheckbox)}
                                sx={{
                                    width: '100%',  // Optional: to ensure it takes up available space
                                    display: 'flex',
                                    justifyContent: 'flex-start',  // Align checkbox and label properly
                                    '.MuiFormControlLabel-label': {
                                        width: '170px',  // Set your desired fixed width for the label
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }
                                }}
                            />
                        </div>
                    </div>


                    {/* current table */}

                    <TrialBalanceTable
                        palDates={palDates}
                        isMonthly={isMonthly}
                        palCodesData={palCodesData}
                        footerTotals={footerTotals}
                        handleSortChange={handleSortChange}
                        handleSelect={handleSelect}
                        handleRightClickedRow={handleRightClickedRow}
                        selectedSearchType={selectedSearchType}
                        searchQuery={searchQuery}
                    />

                </div>
                {memoizedTrialBalanceReport}
                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}

            </div>

        </>
    );
}


export default LedgerReport;
