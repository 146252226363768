import React, { useState, useRef, useEffect, useMemo } from 'react';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu.jsx'; // Adjust the import path as necessary
import { useLocation } from 'react-router-dom';
import UserService from '../../Services/user.service.js';
import ClientService from '../../Services/client.service.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import { useSignalR } from '../../Contexts/signalR.js';
import ClientDocumentCountService from '../../Services/ClientDocumentCount.service.js';
import clientsIcon from "../../assests/images/Main/clients.svg";
import subAccountantsIcon from "../../assests/images/Main/subAccountants.svg";
import SubAccountantService from '../../Services/subAccountant.service.js';
import loadingAnimation from "../../assests/images/Animation.gif";
import Constants from '../../assests/Constants/constants.js';
import EnumsService from '../../Services/enums.service.js';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails.jsx';
import { beautifyNumber } from '../../Utils/FormatNumber.js';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect.jsx';
import ClientsDataGrid from './SubComponents/ClientsDataGrid.jsx';
import PuffLoader from "react-spinners/PuffLoader";
import './Main.css';


const Main = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [accountantId, setAccountantId] = useState();
  const [isAccountant, setIsAccountant] = useState();
  const [isUserDataFetched, setIsUserDataFetched] = useState(false);
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const pageSize = 500; // Set your page size
  const location = useLocation();
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const { documentUpdate } = useSignalR();
  const [clientsCount, setClientsCount] = useState(0);
  const [sortByNewest, setSortByNewest] = useState('');
  const [subAccountants, setSubAccountants] = useState([]);
  const [subAccountantsCount, setSubAccountantsCount] = useState([]);
  const [activeUsersOption, setActiveUsersOption] = useState({
    value: true,
    label: t('Active'),
  });
  const [activeUsers, setActiveUsers] = useState(true);
  const [direction, setDirection] = useState('rtl'); // Default to 'rtl'

  const accountingManagementType = useMemo(() => {
    return Constants.AccountManagementOptions?.map(option => ({
      value: option.name,
      label: option.name,
    }));
  }, [Constants.AccountManagementOptions]);

  const activeOption = [
    {
      value: true,
      label: t('Active'),
    },
    {
      value: false,
      label: t('Inactive'),
    }
  ]
  const [accountOptionSelected, setAccountOptionSelected] = useState(() => {
    const savedValues = JSON.parse(localStorage.getItem('accountOptionSelected') || '[]');
    return savedValues;
  });


  const [optionSelected, setOptionSelected] = useState(() => {
    const savedOptions = localStorage.getItem('optionSelected');
    return savedOptions ? JSON.parse(savedOptions) : [];
  });

  // Update localStorage when optionSelected changes
  useEffect(() => {
    localStorage.setItem('optionSelected', JSON.stringify(optionSelected));
  }, [optionSelected]);

  const handleAccManagmentChange = (event, newValue) => {
    setAccountOptionSelected(newValue);
    localStorage.setItem('accountOptionSelected', JSON.stringify(newValue));
  };
  const handleActiveUsersChange = (event, newValue) => {
    let isActive;
    if (!newValue) {
      isActive = newValue?.value || true;
    }
    else {
      isActive = newValue.value;
    }
    setActiveUsers(isActive)
    setActiveUsersOption(newValue);
  };

  const handleSubAccChange = (event, newValue) => {
    setOptionSelected(newValue);

  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = () => {
    setSortByNewest('');
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };


  const isRtl = ['he', 'ar'].includes(i18n.language);
  const fetchSubAccountantsData = async (accountantId, token, isFirstFetch) => {
    try {
      setLoading(true);
      const data = await SubAccountantService.GetSubAccountantByAccountantId(token, accountantId);
      if (isFirstFetch) {
        let transformedData = data.map(item => ({
          label: item.name,
          value: item.subAccountantId
        }));
        setSubAccountants(transformedData);
        setSubAccountantsCount(transformedData.length);
      } else {
        setSubAccountants([...subAccountants, ...data]);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('fetchSubAccountantsDataError'));
    }
    setLoading(false);
  };




  const fetchClientsData = async (accountantId, token, page, pageSize, isFirstFetch, isAccountant = false) => {
    try {
      setLoading(true);

      // Fetch clients
      const clientsData = await ClientService.GetClients(accountantId, token, page, pageSize);

      // Fetch document counts for the accountant
      const documentCounts = await ClientDocumentCountService.getClientDocumentCountsByAccountantId(accountantId, token, page, pageSize);

      // Conditional requests if isAccountant is true
      let clientsDataWithAccountant = [];
      let documentCountsWithAccountant = [];

      if (isAccountant) {
        // Fetch additional clients with isAccountant = true
        clientsDataWithAccountant = await ClientService.GetClients(accountantId, token, page, pageSize, false);

        // Fetch additional document counts with isAccountant = true
        documentCountsWithAccountant = await ClientDocumentCountService.getClientDocumentCountsByAccountantId(accountantId, token, page, pageSize, false);
      }

      // Create a map for easier access to total counts by caseId
      const totalCountsByCaseId = documentCounts.reduce((acc, current) => {
        acc[current.caseId] = current.totalCounts;
        return acc;
      }, {});

      const documentCount = documentCounts.reduce((acc, current) => {
        acc[current.caseId] = current.documentCounts;
        return acc;
      }, {});

      const documentIds = documentCounts.reduce((acc, current) => {
        acc[current.caseId] = current.documentIds;
        return acc;
      }, {});

      const caseIds = clientsData.clients.map(client => client.caseId);
      const balances = await EnumsService.GetBalancesByCaseIds(caseIds, token);
      console.log(balances);
      
      const balancesByCaseId = balances.reduce((acc, item) => {
        // If caseId already exists, choose the non-zero or latest balance
        if (!acc[item.caseId] || item.balance !== 0) {
            acc[item.caseId] = item.balance;
        }
        return acc;
    }, {});
      // Enrich clients with their total document counts
      const enrichedClients = clientsData.clients.map(client => ({
        ...client,
        totalCounts: totalCountsByCaseId[client.caseId] || 0, // Default to 0 if no matching count is found
        documentCounts: documentCount[client.caseId] || {},
        documentIds: documentIds[client.caseId] || {},
        balance: balancesByCaseId[client.caseId] !== undefined ? balancesByCaseId[client.caseId] : '-', // Show '-' if no balance is found
      }));

      // Merge additional clients and document counts if isAccountant is true
      if (isAccountant) {
        const additionalTotalCountsByCaseId = documentCountsWithAccountant.reduce((acc, current) => {
          acc[current.caseId] = current.totalCounts;
          return acc;
        }, {});

        const additionalDocumentCount = documentCountsWithAccountant.reduce((acc, current) => {
          acc[current.caseId] = current.documentCounts;
          return acc;
        }, {});

        const additionalDocumentIds = documentCountsWithAccountant.reduce((acc, current) => {
          acc[current.caseId] = current.documentIds;
          return acc;
        }, {});

        const additionalEnrichedClients = clientsDataWithAccountant.clients.map(client => ({
          ...client,
          totalCounts: additionalTotalCountsByCaseId[client.caseId] || 0,
          documentCounts: additionalDocumentCount[client.caseId] || {},
          documentIds: additionalDocumentIds[client.caseId] || {},
        }));

        // Merge the original and additional clients
        enrichedClients.push(...additionalEnrichedClients);
      }

      // Update state based on whether it's the first fetch
      if (isFirstFetch) {
        setClients(enrichedClients);
      } else {
        setClients(prevClients => [...prevClients, ...enrichedClients]);
      }

      setHasNext(clientsData.hasNext);
      setCurrentPage(page);
      setClientsCount(enrichedClients.length); // Transform this data if needed
    } catch (error) {
      console.error(error);
      toast.error(t('fetchClientsDataError'));
    } finally {
      setLoading(false);
    }
  };






  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true)
        const user = await UserService.getCurrentUser();
        const token = await UserService.getCurrentToken();
        const userType = await UserService.getCurrentUserType();

        if (user && token) {
          setUser(user);
          setToken(token);
          const isAccountant = userType.toString() === '2';
          setIsAccountant(isAccountant);
          setIsUserDataFetched(true); // Set true when user data is successfully fetched
          setAccountantId(user.accountantId);
          await fetchClientsData(user.accountantId, token, 1, pageSize, true, isAccountant); // Fetch clients after setting user data
          await fetchSubAccountantsData(user.accountantId, token, true);
        } else {
          navigate("/login");
        }
      } catch (error) {
        setIsUserDataFetched(false);
        navigate("/login");
      }
      setLoading(false)
    };
    fetchUserData();
  }, []);



  useEffect(() => {
    // To handle multiple updates efficiently, consider iterating over documentUpdate keys
    Object.keys(documentUpdate).forEach(updateCaseId => {
      const update = documentUpdate[updateCaseId];
      // Update clients with new totalCounts for the matching caseId
      const updatedClients = clients.map(client => {
        if (client.caseId === updateCaseId) { // Check if the client's caseId matches the update's caseId
          return {
            ...client,
            totalCounts: update.totalCounts, // Update totalCounts from documentUpdate
            documentsIds: update.documentsIds,
            documentsCount: update.documentsCount
          };
        }
        return client; // Return the client unchanged if there's no match
      });

      setClients(updatedClients); // Update the state with the modified list
    });
  }, [documentUpdate]);

  const loadMoreClients = () => {
    fetchClientsData(user.accountantId, token, currentPage + 1, pageSize, false, isAccountant);
  };

  useEffect(() => {
    // Check if the refresh state is passed and true
    if (location.state?.refresh && location.state?.accountantId && location.state?.token) {
      fetchClientsData(location.state.accountantId, location.state.token, 1, 50, true, isAccountant);
      setNeedsRefresh(false); // Reset the refresh state
    }
  }, [location.state]);



  const filteredClients = useMemo(() => {
    return clients
      .filter(client =>
        client.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.caseId.toLowerCase().includes(searchQuery.toLowerCase())
      )
      // Then, filter by whether the client's subAccountantId is in optionSelected.
      .filter(client =>
        optionSelected.length === 0 || optionSelected.length === subAccountants.length || optionSelected.some(option => option.value === client.subAccountantId)
      )
      .filter(client =>
        accountOptionSelected.length === 0 || accountOptionSelected.some(option => option.value === client.accountManagement)
      )
      .filter(client =>
        client.isActive == activeUsers
      )
      .sort((a, b) => {
        if (sortByNewest === 'asc' || sortByNewest === 'desc') {
          // Sort by totalCounts, with 'asc' or 'desc' determining the direction
          const modifier = sortByNewest === 'asc' ? 1 : -1;
          return (a.totalCounts - b.totalCounts) * modifier;
        }
        if (sortOrder === 'asc') {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      })
      .sort((a, b) => {
        if (sortByNewest === 'asc' || sortByNewest === 'desc') {
          // Sort by totalCounts, with 'asc' or 'desc' determining the direction
          const modifier = sortByNewest === 'asc' ? 1 : -1;
          return (a.totalCounts - b.totalCounts) * modifier;
        }
        if (sortOrder === 'asc') {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
  }, [clients, searchQuery, sortByNewest, sortOrder, optionSelected, accountOptionSelected, activeUsers]);

  useEffect(() => {
    setClientsCount(filteredClients.length);
  }, [filteredClients]);


  console.log(filteredClients);

  const viewClientDetails = (client) => {
    localStorage.removeItem('dismissedDialog')
    navigate('/client-details', { state: { client } });
  };

  useEffect(() => {
    // Update direction based on the current language
    if (i18n.language === 'he' || i18n.language === 'ar') {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [i18n.language]);
  return (
    <>
      <HamburgerMenu />
      {/* <Sidebar /> */}
      <div className="template-page">
        <div className="template-container">
          <UpperPanelDetails />
          <div className={`main-title ${direction}`}>{t('clientFolders')}</div>

          <div className={`main-cards ${direction}`}>
            <div style={{ display: 'flex', gap: "20px" }}>
              <div className={`main-card-container ${direction}`}>
                <div className={`main-card-icon ${direction}`}>
                  <img
                    src={clientsIcon}
                    alt="home"
                  />
                </div>
                <div className={`main-card-details ${direction}`}>
                  <div className={`main-card-details1 ${direction}`}>
                    {t('clients')}
                  </div>
                  <div className={`main-card-details2 ${direction}`}>
                    {beautifyNumber(clientsCount)}
                  </div>
                </div>
              </div>

              <div className={`main-card-container ${direction}`}>
                <div className={`main-card-icon ${direction}`}>
                  <img
                    src={subAccountantsIcon}
                    alt="home"
                  />
                </div>
                <div className={`main-card-details ${direction}`}>
                  <div className={`main-card-details1 ${direction}`}>
                    {t('SubAccountants')}
                  </div>
                  <div className={`main-card-details2 ${direction}`}>
                    {beautifyNumber(subAccountantsCount)}
                  </div>
                </div>
              </div>
            </div>
            <div className='addClient-button' onClick={() => navigate('/addClient')}>
              <span>{t("addClient")}</span>
            </div>
          </div>
          <div className={`filtersBar ${direction}`} >
            <TextField
              fullWidth
              placeholder={t('searchClient')}
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-bar"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                sx: {
                  height: '48px',
                  padding: '0 10px',
                  borderRadius: '8px',
                  background: '#F5F5F6',
                  border: '1px solid transparent',
                  '&:focus-within': {
                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                  },
                },
                ...(direction === 'ltr'
                  ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" style={{ marginRight: '10px' }}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                  : {
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start" style={{ marginLeft: '10px' }}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }),
              }}
              inputProps={{
                style: {
                  textAlign: direction === 'ltr' ? 'left' : 'right',
                },
              }}
            />
            <AutocompleteSelect
              options={subAccountants}
              selectedValues={optionSelected}
              onChange={handleSubAccChange}
              label={t("SubAccountant")}
              placeholder={t("SubAccountant")}
            />

            <AutocompleteSelect
              options={accountingManagementType}
              selectedValues={accountOptionSelected}
              onChange={handleAccManagmentChange}
              label={t("AccountManagement")}
              placeholder={t("AccountManagement")}
            />

            {isAccountant && (
              <AutocompleteSelect
                options={activeOption}
                selectedValues={activeUsersOption}
                onChange={handleActiveUsersChange}
                label={t("ActiveInactiveClients")}
                placeholder={t("ActiveInactiveClients")}
                isMultiple={false}
              />
            )}
          </div>

          <ClientsDataGrid
            filteredClients={filteredClients}
            viewClientDetails={viewClientDetails}
            activeUsers={activeUsers}
            subAccountants={subAccountants}
          />
          {/* <div className="folders-grid">
            {filteredClients.map(client => ( // Render client data
              <div key={client.caseId} className={`folder-card ${client.totalCounts > 0 ? 'highlight-border' : ''} ${!activeUsers && 'disableUser'}`} onClick={() => viewClientDetails(client)}>
                <img src={folderThumbnail} alt="Client" className="folder-thumbnail" />
                <div className="folder-info">
                  <div className={`folder-info-details ${isRtl ? 'rtl-text-align' : ''}`}>
                    <div className="client-info-text">{client.name}</div>
                    <span className="client-info-case-text">{t('clientCase')}: <span className="client-info-value">{client.caseId}</span></span>
                    <span className="client-info-text">
                      {client.totalCounts > 0 && <span className="notification-count">{client.totalCounts}</span>}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          <button
            className={`load-more ${!hasNext ? 'disabled' : ''}`}
            onClick={loadMoreClients}
            disabled={!hasNext} // This disables the button functionality when hasNext is false
          >
            {t('loadMoreClients')}
          </button>
        </div>
        <ToastContainer />
        {loading && (
          <div className="loader-container">
            <PuffLoader
              size={100}        // Larger size for better visibility
              loading={loading}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Main;
