import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Dropdown } from 'primereact/dropdown';
import AdvancedDropdown from '../AdvancedDropdown/AdvancedDropdown';
import DeleteIcon from '@mui/icons-material/Delete';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect.jsx';
import { useTranslation } from 'react-i18next';


const AddOrEditBankDialog = ({
    open,
    onClose,
    isEditingBank,
    selectedBankForCreate,
    bankOptions,
    handleChangeBankForCreate,
    bankBranch,
    handleChangeCreateBank,
    bankAccountNumber,
    cardForNewBank,
    processedCardsData,
    setCardForNewBank,
    handleAddOrEditBank,
    handleRemoveBank,
}) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();

 
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ zIndex: 1300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            PaperProps={{
                style: {
                    padding: '20px',
                    width: 'auto',
                    maxWidth: '400px',
                    margin: 'auto'
                }
            }}
        >

            <DialogTitle sx={{ textAlign: 'center' }}>
                    <div className="form-row-item-title">
                        {isEditingBank ? t('EditBankAccount') : t('SetBank')}
                    </div>
            </DialogTitle>
            <DialogContent sx={{ direction: 'ltr', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                {/* Bank Dropdown */}
                <div className="bank-form-row-item">
                    <div className="form-row-item-title">
                        {t('SelectABank')}<span className="required-asterisk">*</span>
                    </div>
                    <AutocompleteSelect
                        options={bankOptions}
                        selectedValues={selectedBankForCreate}
                        onChange={(event, value) => handleChangeBankForCreate(value)}
                        // label={t("SelectABank")}
                        placeholder={t("SelectABank")}
                        isMultiple={false}
                        width="100%"
                    />
                </div>
              

            <div className="bank-form-row-item">
                        <div className="form-row-item-title">
                            {t('BankBranch')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('BankBranch')}
                            value={bankBranch}
                            onChange={(e) => handleChangeCreateBank('bankBranch', e.target.value)}
                            className="add-client-input-style"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                            required
                        />
                    </div>
               

                {/* Bank Account Number Input */}
                {/* TextField for Bank Account Number */}
        <div className="bank-form-row-item">
            <div className="form-row-item-title">
                {t('BankNumber')}<span className="required-asterisk">*</span>
            </div>
            <TextField
                fullWidth
                placeholder={t('BankNumber')}
                value={bankAccountNumber}
                onChange={(e) => handleChangeCreateBank('bankAccountNumber', e.target.value)}
                className="add-client-input-style"
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                    className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                    sx: {
                        height: '48px',
                        padding: '0 10px',
                        borderRadius: '8px',
                        background: '#F5F5F6',
                        border: '1px solid transparent',
                        '&:focus-within': {
                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                        },
                    },
                }}
                inputProps={{
                    style: {
                        textAlign: direction === 'ltr' ? 'left' : 'right',
                    },
                }}
                required
            />
        </div>
                

        <div className="bank-form-row-item">
                    <div className="form-row-item-title">
                        {t('AccountCard')}<span className="required-asterisk">*</span>
                    </div>
                    <AutocompleteSelect
                        options={processedCardsData}
                        selectedValues={cardForNewBank}
                        onChange={(event, value) => setCardForNewBank(value)}
                        // label={t("SelectABank")}
                        placeholder={t("AccountCard")}
                        isMultiple={false}
                        width="100%"
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: '16px' }}>
                <Button
                    variant="contained"
                    onClick={() => handleAddOrEditBank()}
                    startIcon={<AddCircleOutlineIcon sx={{ ml: 1 }} />}
                    sx={{
                        backgroundColor: '#304FFF',
                        color: '#fff',
                        margin: '10px 0 0 0',
                        '&:hover': { backgroundColor: '#F9AA2A' },
                    }}
                >
                    {isEditingBank ? t('EditBankAccount') : t('SetBank')}
                </Button>
                {isEditingBank && (
                    <Button
                        variant="contained"
                        onClick={() => handleRemoveBank()} // Assuming a function that handles the remove action
                        startIcon={<DeleteIcon />}
                        sx={{
                            backgroundColor: '#ff1744', // Red color for remove button
                            color: '#fff',
                            margin: '10px 0 0 10px',
                            '&:hover': { backgroundColor: '#d50000' }
                        }}
                    >
                        {t('Remove')}
                    </Button>
                )}
            </DialogActions>


        </Dialog>
    );
};

export default AddOrEditBankDialog;
