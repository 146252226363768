import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}/Reports`;


const getReferenceSequenceReport = async (caseId, startDate, endDate, accountCardId = null, token) => {
    const response = await fetch(`${API_URL}/ReferenceSequenceReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            StartDate: startDate,
            EndDate: endDate,
            AccountCardCode: accountCardId
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const blob = await response.blob();

    // Create a URL for the file
    const fileUrl = window.URL.createObjectURL(blob);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `ReferenceSequenceReport-${caseId}.pdf`); // Use a suitable filename
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};


const ReportsService = {
    getReferenceSequenceReport,
};

export default ReportsService;