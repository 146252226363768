import React from 'react';
import warningIcon from '../../assests/images/warning.png';
import './CustomDeleteConfirmationModal.css';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import deleteAnimation from '../../assests/images/Animations/deleteAnimation.json';
import closeAnimation from '../../assests/images/Animations/closeAnimation.json';
import openAnimation from '../../assests/images/Animations/OpenMonth.json';

const CustomDeleteConfirmationModal = ({ isVisible, onClose, onDelete, confirmMessage, titleMessage, type }) => {
    const { t } = useTranslation();
    if (!isVisible) return null;

    const handleBackdropClick = (e) => {
        onClose();
    };

    const handleModalClick = (e) => {
        // Prevents the click from bubbling up to the backdrop
        e.stopPropagation();
    };

    const getAnimation = () => {
        switch (type) {
            case 'closeMonth':
                return closeAnimation;
            case 'openMonth':
                return openAnimation;
            default:
                return deleteAnimation;
        }
    };

    return (
        <div className="modal-backdrop" onClick={handleBackdropClick}>
            <div className="modal" onClick={handleModalClick}>
                <Lottie
                    animationData={getAnimation()}
                    loop={true}
                    style={{ height: 188, width: 188, margin: '0 auto' }}
                />
                <h2 className="modal-title">{t(titleMessage) || t('confirmDeleteTitle')}</h2>
                <p className="modal-message">{t(confirmMessage) || t('confirmDeleteMessage')}</p>
                <div className="modal-button-container">
                    <button onClick={onClose} className="cancel-button">{t('cancelButton')}</button>
                    <button onClick={onDelete} className={`ok-button ${type === 'open' ? 'ok-button-green' : ''}`}>
                        {t('okButton')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomDeleteConfirmationModal;
