import React, { useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import UserService from '../../Services/user.service';
import '../OtherDocuments/OtherDocument.css';
import backIcon from '../../assests/images/left-arrow.png';
import { GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EnumsService from '../../Services/enums.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSignalR } from '../../Contexts/signalR';
import 'primereact/resources/themes/saga-blue/theme.css';  // Optional: PrimeReact theme
import 'primereact/resources/primereact.min.css';           // PrimeReact CSS
import 'primeicons/primeicons.css';
import SaveIcon from '@mui/icons-material/Save'; // Save icon
import ClearIcon from '@mui/icons-material/Clear';
import PuffLoader from "react-spinners/PuffLoader";
import AdvancedDropdown from '../AdvancedDropdown/AdvancedDropdown';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MergeIcon from '@mui/icons-material/Merge';
import MergeAccountCards from './MergeAccountCards/MergeAccountCards';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import editIcon from '../../assests/images/Icons/editIcon.svg';
import deleteIcon from '../../assests/images/Icons/deleteIcon.svg';
import saveIcon from '../../assests/images/Icons/saveIcon.svg';
import cancelIcon from '../../assests/images/Icons/cancelIcon.svg';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, Download as DownloadIconMui, Settings } from '@mui/icons-material';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import { useGridApiRef } from '@mui/x-data-grid';
// MUI DataGrid imports
import {
    DataGrid,
    GridToolbar,
    GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import {
    TextField,
    Autocomplete,
    InputAdornment,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Tooltip,
    CircularProgress,
    Box,
    Popper,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, arSA, he } from 'date-fns/locale'; // Import desired locales
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { textAlign } from '@mui/system';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
import EditAccountCardModal from './SubComponents/EditAccountCardModal';
registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it

// Styled DataGrid component
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiAutocomplete-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"

    },
    '& *': {
        boxSizing: 'border-box !important',

    },
    '& .MuiDataGrid-columnHeaders': {
        opacity: 0.7,
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#304FFF',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-cell': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rgba(191, 200, 255, 0.3)',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
    // New styles for edit mode
    '& .MuiDataGrid-cell--editing': {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
        borderRight: 'none',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell:not(:last-child)': {
        borderRight: '8px solid transparent',
    },
    '& .MuiDataGrid-cell--editing': {
        padding: 0,
        backgroundColor: 'inherit',
    },
}));

const validateEmail = (email) => {
    // Simple email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

const validateNumber = (number) => {
    const re = /^[0-9\b]+$/;
    return re.test(number);
};

const AccountCardsTable = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isClientDataFetched, setIsClientDataFetched] = useState(false);

    const [focused, setFocused] = useState(false);
    const customScrollbarRef = useRef();
    const tableContainerRef = useRef();

    // Account cards data
    const [accountCardsData, setAccountCardsData] = useState([]);
    const [rows, setRows] = useState([]); // Initialize with data prop


    const [palCodesOptions, setPalCodesOptions] = useState([{ key: '', label: '', value: "" }]);

    const fileInputRef1 = useRef(null); // Reference to the first hidden file input

    const [selectedRows, setSelectedRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [isMergeModalVisible, setMergeModalVisible] = useState(false);
    const [openEditSelected, setOpenEditSelected] = useState(false);
    const [selectedPalCodeForSelectAll, setSelectedPalCodeForSelectAll] = useState('');
    const direction = i18n.dir();
    const apiRef = useGridApiRef();
    const [selectedRow, setSelectedRow] = useState(null);
    const [oldCard, setOldCard] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNew, setIsNew] = useState(false); // To differentiate between edit and add

    const handleCloseEditAddModal = () => {
        setAddNewAccount(false);
        setIsModalOpen(false)
    }
    const [cardIdOption, setCardIdOption] = useState(-1);

    useEffect(() => {

        if (accountCardsData && accountCardsData.length > 0) {
            const maxCardId = Math.max(...accountCardsData.slice(1).map(option => parseInt(option.cardId, 10)));
            setCardIdOption(maxCardId + 1);


        }
    }, [accountCardsData]);

    function EditToolbar(props) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            // const id = generateUniqueId();
            setRows((oldRows) => [
                ...oldRows,
                {
                    cardId: cardIdOption,
                    name: '',
                    palCode: null,
                    sourceDeductionAssociation: null,
                    email: '',
                    location: '',
                    phoneNumber: '',
                    accountCaseId: '',
                    isNew: true,
                    isEditable: true,
                },
            ]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [cardIdOption]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            }));
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t('Add Account')}
                </Button>
            </GridToolbarContainer>
        );
    }

    // const handleRowDoubleClick = (params) => {
    //     handleEdit(params.id, params);
    // };

    // const handleEdit = (id, params) => {
    //     console.log(params.row);
    //     setOldCard(params.row.cardId.toString());
    //     setSelectedRow(params.row);
    //     setIsNew(false); // It's an edit action
    //     setIsModalOpen(true);
    // };

    const handleSelectRows = (event, selectedIds) => {


        const updatedAccountCardsData = accountCardsData.map(card => {
            if (selectedIds.includes(card.cardId)) {
                return {
                    ...card,
                    selected: !card.selected // Toggle selected field
                };
            }
            return card;
        });

        setAccountCardsData(updatedAccountCardsData);
    };

    const [anySelected, setAnySelected] = useState(false);

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    const getLocale = () => {
        switch (i18n.language) {
            case 'ar':
                return arSA; // Arabic locale
            case 'he':
                return he; // Hebrew locale
            default:
                return enUS; // Default to English locale
        }
    };

    const fetchEnums = async (token, client) => {
        setLoading(true);
        try {
            const accountCardsResponse = await EnumsService.GetAccountCardsByCaseId(client.caseId, token);
            const palCodesResponse = await EnumsService.GetPalCodesByAccountantId(client.accountantId, token);
            const palCodes = await palCodesResponse.json();
            const accountCardsData = await accountCardsResponse.json();

            setAccountCardsData(accountCardsData);
            if (palCodes.length <= 0) {
                return
            }
            // Map the palCodeCatagories to the desired format
            const options = palCodes.map((category) => ({
                key: category.palCodeId,
                value: category.palCodeId,
                label: category.palCodeId,
            }));

            // Update the state with the mapped options
            setPalCodesOptions(options);

            setIsClientDataFetched(true);
        } catch (error) {
            console.error(error);
            toast.error(t('fetchEnumsError'), error);
            setIsClientDataFetched(false)
        }
        setLoading(false);

    };

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    // Get client data from location.state
                    if (location.state && location.state.client && location.state.type) {
                        setType(location.state.type)
                        setClient(location.state.client);
                    }
                    await fetchEnums(currentToken, location.state.client);
                }
                else {
                    navigate("/login");
                }
            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }
            setLoading(false);
        };
        fetchUserData();
    }, []);
    const filteredAndSortedData = useMemo(() => {

        if (accountCardsData.length <= 0) {
            return [];
        }

        const query = searchQuery.toLowerCase();
        // Apply filtering and scoring
        if (accountCardsData.length <= 0) {
            return [];
        }
        const scoredData = accountCardsData
            .filter((item) => {
                // Basic filtering to remove irrelevant items
                return (
                    (item?.cardId?.toString()?.includes(query)) ||
                    (item?.caseId?.toString()?.includes(query)) ||
                    (item?.accountCaseId?.toString()?.includes(query)) ||
                    (item?.name?.toLowerCase()?.includes(query)) ||
                    (item?.palCode?.toLowerCase()?.includes(query)) ||
                    (item?.client?.toString()?.includes(query)) ||
                    (item?.phoneNumber?.toString()?.includes(query)) ||
                    (item?.location?.toLowerCase()?.includes(query)) ||
                    (item?.email?.toLowerCase()?.includes(query)) ||
                    (item?.sourceDeductionAssociation?.toString()?.includes(query))
                );
            })
            .map((item) => {
                // Calculate a relevance score based on the match
                let score = 0;

                if (item?.cardId?.toString() === query) score += 10; // Exact match on cardId
                else if (item?.cardId?.toString()?.includes(query)) score += 5; // Partial match

                if (item?.caseId?.toString() === query) score += 10; // Exact match on caseId
                else if (item?.caseId?.toString()?.includes(query)) score += 5;

                if (item?.accountCaseId?.toString() === query) score += 10;
                else if (item?.accountCaseId?.toString()?.includes(query)) score += 5;

                if (item?.name?.toLowerCase() === query) score += 15; // Give more weight to name exact match
                else if (item?.name?.toLowerCase()?.includes(query)) score += 7;

                if (item?.palCode?.toLowerCase() === query) score += 10;
                else if (item?.palCode?.toLowerCase()?.includes(query)) score += 5;

                if (item?.client?.toString() === query) score += 10;
                else if (item?.client?.toString()?.includes(query)) score += 5;

                if (item?.phoneNumber?.toString() === query) score += 10;
                else if (item?.phoneNumber?.toString()?.includes(query)) score += 5;

                if (item?.location?.toLowerCase() === query) score += 8;
                else if (item?.location?.toLowerCase()?.includes(query)) score += 4;

                if (item?.email?.toLowerCase() === query) score += 10;
                else if (item?.email?.toLowerCase()?.includes(query)) score += 5;

                if (item?.sourceDeductionAssociation?.toString() === query) score += 10;
                else if (item?.sourceDeductionAssociation?.toString()?.includes(query)) score += 5;

                // Return item along with its score
                return { ...item, score };
            });

        // Sort by relevance score (descending)
        const sortedData = scoredData.sort((a, b) => b.score - a.score);

        // // Apply any additional sorting based on sortConfig
        // if (sortConfig.key) {
        //     sortedData.sort((a, b) => {
        //         const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
        //         const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined

        //         if (sortConfig.type === 'number') {
        //             return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
        //         } else if (sortConfig.type === 'date') {
        //             return sortConfig.direction === 'ascending' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
        //         } else { // default to string comparison
        //             return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        //         }
        //     });
        // }


        return sortedData;
    }, [accountCardsData, searchQuery]);

    useEffect(() => {
        const hasSelected = filteredAndSortedData.some(item => item.selected);
        setAnySelected(hasSelected);
    }, [filteredAndSortedData]);

    useEffect(() => {
        if (filteredAndSortedData) {
            setRows(filteredAndSortedData);
        }
    }, [filteredAndSortedData]);

    const uploadFileToAccountCard = async (file) => {
        try {
            setLoading(true);
            // Call the upload service method, passing the caseId, file, and auth token
            const response = await EnumsService.UploadAccountCardsFileData(client.caseId, file, token);

            if (response.ok) {
                toast.success(t("FileUploadedSuccessfullyTo"));
                // Handle success - perhaps update state or notify the user
                await fetchEnums(token, client, true);
            } else {
                // The server responded with an error status
                console.error("File upload failed with response status:", response.status);
                const errorText = await response.text();
                console.error("Error details:", errorText);
                // Handle failure - show error message to the user
                toast.error(t("ErrorUploadingFileTo"));
            }
        } catch (error) {
            // An error occurred during the fetch operation
            console.error("Error uploading file to AccountCards API:", error.message);
            toast.error(t("ErrorUploadingFileTo"));
        }
        setLoading(false);
    };

    const handleFileChange = (event, uploadFunction) => {
        const file = event.target.files[0];
        if (file) {
            uploadFunction(file); // Call the passed upload function with the selected file
        }
    };

    const handleFileButtonClick = (fileInputRef, uploadFunction) => {
        // // Reset the file input value
        fileInputRef.current.value = '';

        // Set up the onchange event handler
        fileInputRef.current.onchange = (event) => handleFileChange(event, uploadFunction);

        // Trigger the file input click
        fileInputRef.current.click();
    };

    const handleSelectionModelChange = (ids) => {
        setSelectedRows(ids);
    };

    const generateUniqueId = () => {
        return Date.now().toString();
    };
    const isRowSelectable = (params) => {
        const { row } = params;
        return row.isEditable;
    };

    // const handleEdit = (id) => () => {
    //     setRowModesModel((prevModel) => ({
    //         ...prevModel,
    //         [id]: { mode: GridRowModes.Edit },
    //     }));
    // };

    const handleEdit = (id, params) => {
        console.log(params.row);
        setOldCard(params.row.cardId.toString());
        setSelectedRow(params.row);
        setIsNew(false); // It's an edit action
        setIsModalOpen(true);
    };

    const handleRowDoubleClick = (params) => {
        if (params.row.isEditable) {
            handleEdit(params.id, params);
        }
    };

    const handleSaveClick = (id) => () => {

        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View },
        }));
    };
    const saveFunction = async (updatedRow) => {


        // // Implement your API call for saving edited data
        // try {
        //     // Example API call
        //     await api.updateRow(updatedRow);
        //     
        // } catch (error) {
        //     console.error('Error updating row:', error);
        // }
    };


    const handleCancelClick = (id) => () => {
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));

        const editedRow = rows.find((row) => row.cardId === id);
        if (editedRow.isNew) {
            setRows((prevRows) => prevRows.filter((row) => row.cardId !== id));
        }
    };

    const processRowUpdate = async (newRow, oldRow) => {
        const updatedRow = { ...newRow, isNew: false };
        // Validate email and phone number
        if (newRow.email && !validateEmail(newRow.email)) {
            toast.error(t('InvalidEmailAddress'));
            return oldRow; // Revert to old row if validation fails
        }

        if (newRow.phoneNumber && !validateNumber(newRow.phoneNumber)) {
            toast.error(t('InvalidPhoneNumber'));
            return oldRow; // Revert to old row if validation
        }
        try {
            if (newRow.isNew) {
                // Call handleSaveNewAccount for new rows
                await handleSaveNewAccount(updatedRow);
            } else {
                // Call updateAccountCard for existing rows

                await updateAccountCard(updatedRow, oldRow);
            }

            // Update the rows state with the updated row
            setRows((prevRows) =>
                prevRows.map((row) => (row.cardId === updatedRow.cardId ? updatedRow : row))
            );

            return updatedRow;
        } catch (error) {
            console.error('Error processing row update:', error);

            // Remove the row if it is new and an exception occurred
            if (newRow.isNew) {
                setRows((prevRows) => prevRows.filter((row) => row.cardId !== newRow.cardId));
            }

            // Return oldRow to revert changes in the grid
            return oldRow;
        }
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    const updateAccountCard = async (updatedAccountCard, oldCard) => {
        try {


            // Ensure empty string fields are converted to null
            Object.entries(updatedAccountCard).forEach(([key, value]) => {
                if (value === '') {
                    updatedAccountCard[key] = null;
                }
            });



            // Call the API to update the account card
            const response = await EnumsService.UpdateAccountCard(
                client.caseId,
                oldCard.cardId,
                oldCard.name,
                updatedAccountCard.cardId.toString(),
                updatedAccountCard.name,
                updatedAccountCard.caseId,
                updatedAccountCard.palCode,
                token,
                updatedAccountCard.phoneNumber,
                updatedAccountCard.location,
                updatedAccountCard.email,
                updatedAccountCard.sourceDeductionAssociation,
                oldCard.caseId
            );
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText || t('FailedToUpdateAccountCard'));
            }
            fetchEnums(token, client);
            // If no errors, show a success toast
            toast.success(t('AccountCardUpdated'));
        } catch (error) {
            console.error('Error saving account card:', error);
            // Show error toast if the update fails
            toast.error(t('FailedToUpdateAccountCard'));
        }
    };

    const handleDeleteClick = (id) => () => {
        setItemIdToDelete(id);
        setDeleteModalVisible(true);
        setRows((prevRows) => prevRows.filter((row) => row.cardId !== id));
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await EnumsService.DeleteAccountCardsbyCaseId(client.caseId, itemIdToDelete, token);

            if (response.ok) {
                toast.success(t("Account card deleted successfully"));
                setDeleteModalVisible(false);
                fetchEnums(token, client); // Fetch updated enums after the delete action
            } else {
                const errorMessage = await response.text(); // Extract the error message from the response body
                if (errorMessage.includes("Account card can't be deleted, documents with account card exist")) {
                    toast.error(t('AccountCardCannotBeDeleted'));
                } else {
                    toast.error(t('TaskFailed'));
                }
            }

        } catch (error) {
            if (error?.response?.data?.includes("Account card can't be deleted, documents with account card exist")) {
                toast.error(t('AccountCardCannotBeDeleted'));
            } else {
                toast.error(t('TaskFailed'));
            }
            console.error(t('TaskFailed'));
        }
    };

    const sourceDeductionOptions = [
        { key: 'NotIncluded', label: t('NotIncluded'), value: 0 },
        { key: 'Included', label: t('Included'), value: 1 }
    ];

    const [addNewAccount, setAddNewAccount] = useState(false);
    const [newAccountCard, setNewAccountCard] = useState({
        cardId: '',
        caseId: '',
        phoneNumber: '',
        location: '',
        email: '',
        sourceDeduction: null,
        palCode: null,
        name: '',
    });
    useEffect(() => {
        if (addNewAccount) {
            // // const id = generateUniqueId();
            // setRows((prevRows) => [
            //     {
            //         cardId: cardIdOption,
            //         name: '',
            //         palCode: null,
            //         sourceDeductionAssociation: null,
            //         email: '',
            //         location: '',
            //         phoneNumber: '',
            //         accountCaseId: '',
            //         isNew: true,
            //         isEditable: true,
            //     },
            //     ...prevRows,
            // ]);
            // setRowModesModel((prevModel) => ({
            //     ...prevModel,
            //     [cardIdOption]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            // }));
            // setAddNewAccount(false);
            setSelectedRow({
                cardId: cardIdOption,
                name: '',
                palCode: null,
                sourceDeductionAssociation: null,
                email: '',
                location: '',
                phoneNumber: '',
                accountCaseId: '',
            });
            setIsNew(true); // It's an add action
            setIsModalOpen(true);
        }
    }, [addNewAccount]);


    const handleInputChange = (field, value) => {
        setNewAccountCard((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleCancelAddAccount = () => {
        setAddNewAccount(false);
        setNewAccountCard({
            cardId: '',
            caseId: '',
            phoneNumber: '',
            location: '',
            email: '',
            sourceDeduction: null,
            palCode: null,
            name: '',
        });
    };

    const handleSaveNewAccount = async (newAccountCard) => {


        const response = await EnumsService.AddAccountCard(
            client.caseId,
            newAccountCard.cardId.toString(),
            newAccountCard.name,
            newAccountCard.caseId,
            newAccountCard.palCode,
            token,
            newAccountCard.phoneNumber,
            newAccountCard.location,
            newAccountCard.email,
            newAccountCard.sourceDeductionAssociation,
        );

        if (!response.ok) {
            // Throw an error if the response is not successful
            toast.error(t('FailedToAddAccountCard'))
            const errorText = await response.text();
            throw new Error(errorText || t('FailedToAddAccountCard'));
        }

        // If the request is successful, display a success toast
        toast.success(t('AddedAccountCardSuccessfully'));

        // Hide the form after saving
        fetchEnums(token, client);
        setAddNewAccount(false);
    };

    const handleMerge = async (newCard, oldCard) => {
        try {
            // Await the merge call
            const response = await EnumsService.MergeAccountCards(client.caseId, newCard, oldCard, token);

            if (response.ok) {
                fetchEnums(token, client);
                toast.success(t('MergeSuccessful'));
            } else {
                const errorMessage = await response.text();
                console.error('Error Merging:', errorMessage);
                toast.error(t('FailedToMerge'));
            }
        } catch (error) {
            console.error('Error Merging:', error);
            toast.error(t('FailedToMerge'));
        }
    };

    const handleOpenEditSelected = () => {
        setOpenEditSelected(true);
    };

    const handleCloseEditSelected = () => {
        setSelectedPalCodeForSelectAll('');
        setOpenEditSelected(false);
    };



    const handleEditInBulk = async () => {
        // Ensure there's at least one selected item
        if (selectedRows.length === 0) {
            return;
        }

        // Take the selected PalCode
        const NewPalCode = selectedPalCodeForSelectAll;

        // Ensure you have the CaseId available
        const CaseId = client.caseId;


        // Filter accountCardsData based on selectedRows
        // const filteredAccountCards = accountCardsData.filter(card => selectedRows.includes(card.cardId));

        try {
            // Call the EditInBulk API service
            const response = await EnumsService.EditInBulk(CaseId, selectedRows, NewPalCode, token);
            if (response.ok) {
                // Handle success response
                toast.success(t("AccountCardsUpdatedSuccessfully"));
                await fetchEnums(token, client);
            } else {
                // Handle error response
                const errorText = await response.text();
                console.error(errorText);
                toast.error(t("AccountCardsFailedToUpdate"));
            }
        } catch (error) {
            console.error(error);

            toast.error(t("AccountCardsFailedToUpdate"));
        }
        setSelectedRows([]);
        handleCloseEditSelected();
    };


    const handleModalSave = async (editedData, isNew) => {
        console.log(editedData, isNew);

        if (isNew) {
            // Call API to add new account card
            await handleSaveNewAccount(editedData);
        } else {
            // Call API to update existing account card
            const OldCard = accountCardsData.find((card) => card.cardId === oldCard);
            await updateAccountCard(editedData, OldCard);
        }
        // Refresh data
        await fetchEnums(token, client);
    };


    const columns = [
        {
            field: 'cardId',
            headerName: t('AccountCard ID'),
            textAlign: 'center',
            headerAlign: 'center',
            editable: true,
            flex: 1,
            renderEditCell: (params) => (
                <TextField
                    fullWidth
                    value={params.value || ''}
                    onChange={(e) =>
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: e.target.value,
                        })
                    }
                    variant="standard"
                    InputProps={inputPropsStyle}
                    inputProps={inputStyle}
                />
            ),
            sortComparator: (v1, v2) => {
                // Parse values to integers and then compare
                const intV1 = parseInt(v1, 10) || 0;
                const intV2 = parseInt(v2, 10) || 0;
                return intV1 - intV2;
            },
        },
        {
            field: 'name',
            textAlign: 'center',
            headerAlign: 'center',
            headerName: t('AccountCard Name'),
            editable: true,
            flex: 1,
            renderEditCell: (params) => (
                <TextField
                    fullWidth
                    value={params.value || ''}
                    onChange={(e) =>
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: e.target.value,
                        })
                    }
                    variant="standard"
                    InputProps={inputPropsStyle}
                    inputProps={inputStyle}
                />
            ),
        },
        {
            field: 'palCode',
            textAlign: 'center',
            headerAlign: 'center',
            headerName: t('PalCode'),
            editable: true,
            flex: 1,
            renderEditCell: (params) => (
                <Autocomplete
                    options={palCodesOptions}
                    getOptionLabel={(option) => option.label}
                    value={
                        palCodesOptions.find((option) => option.value === params.value) || null
                    }
                    onChange={(event, newValue) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: newValue ? newValue.value : null,
                        });
                    }}
                    renderInput={(paramsInput) => (
                        <TextField
                            {...paramsInput}
                            variant="standard"
                            InputProps={{
                                ...paramsInput.InputProps,
                                ...inputPropsStyle,
                            }}
                            inputProps={{
                                ...paramsInput.inputProps,
                                ...inputStyle,
                            }}
                        />
                    )}
                    PopperComponent={(props) => (
                        <Popper
                            {...props}
                            style={{
                                ...props.style,
                                direction: direction,
                            }}
                        />
                    )}
                />
            ),
        },
        {
            field: 'sourceDeductionAssociation',
            textAlign: 'center',
            headerAlign: 'center',
            headerName: t('SourceDeductionAssociation'),
            editable: true,
            flex: 1,
            renderCell: (params) => (
                <span>{params.value ? t('Included') : t('NotIncluded')}</span>
            ),
            renderEditCell: (params) => (
                <Autocomplete
                    options={sourceDeductionOptions}
                    getOptionLabel={(option) => option.label}
                    value={
                        sourceDeductionOptions.find((option) => option.value === params.value) || null
                    }
                    onChange={(event, newValue) => {


                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: newValue ? newValue.value : null,
                        });
                    }}
                    renderInput={(paramsInput) => (
                        <TextField
                            {...paramsInput}
                            variant="standard"
                            InputProps={{
                                ...paramsInput.InputProps,
                                ...inputPropsStyle,
                            }}
                            inputProps={{
                                ...paramsInput.inputProps,
                                ...inputStyle,
                            }}
                        />
                    )}
                    PopperComponent={(props) => (
                        <Popper
                            {...props}
                            style={{
                                ...props.style,
                                direction: direction,
                            }}
                        />
                    )}
                />
            ),
        },
        {
            field: 'email',
            textAlign: 'center',
            headerAlign: 'center',
            headerName: t('Email'),
            editable: true,
            width: 200,
            renderEditCell: (params) => (
                <TextField
                    fullWidth
                    value={params.value || ''}
                    onChange={(e) => {
                        const email = e.target.value;

                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: email,
                        });
                    }}
                    variant="standard"
                    // error={!validateEmail(params.value || '')}
                    // helperText={!validateEmail(params.value || '') ? t('InvalidEmail') : ''}
                    InputProps={inputPropsStyle}
                    inputProps={inputStyle}
                />
            ),
        },
        {
            field: 'location',
            textAlign: 'center',
            headerAlign: 'center',
            headerName: t('Location'),
            editable: true,
            flex: 1,
            renderEditCell: (params) => (
                <TextField
                    fullWidth
                    value={params.value || ''}
                    onChange={(e) =>
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: e.target.value,
                        })
                    }
                    variant="standard"
                    InputProps={inputPropsStyle}
                    inputProps={inputStyle}
                />
            ),
        },
        {
            field: 'phoneNumber',
            textAlign: 'center',
            headerAlign: 'center',
            headerName: t('PhoneNumber'),
            editable: true,
            flex: 1,
            renderEditCell: (params) => (
                <TextField
                    fullWidth
                    value={params.value || ''}
                    onChange={(e) => {
                        const phoneNumber = e.target.value;

                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: phoneNumber,
                        });
                    }}
                    variant="standard"
                    InputProps={inputPropsStyle}
                    inputProps={inputStyle}
                />
            ),
        },
        {
            field: 'accountCaseId',
            textAlign: 'center',
            headerAlign: 'center',
            headerName: t('CompanyId'),
            editable: true,
            flex: 1,
            renderEditCell: (params) => (
                <TextField
                    fullWidth
                    value={params.value || ''}
                    onChange={(e) =>
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: e.target.value,
                        })
                    }
                    variant="standard"
                    InputProps={inputPropsStyle}
                    inputProps={inputStyle}
                />
            ),
        },
        {
            field: 'actions',
            textAlign: 'center',
            headerAlign: 'center',
            type: 'actions',
            headerName: t('Actions'),
            flex: 1,
            getActions: (params) => {
                const isInEditMode =
                    rowModesModel[params.id]?.mode === GridRowModes.Edit;
                const isEditable = isRowSelectable(params); // Check if the row is selectable/editable

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<img src={saveIcon} alt="Save" />}
                            label="Save"
                            onClick={handleSaveClick(params.id)}
                        />,
                        <GridActionsCellItem
                            icon={<img src={cancelIcon} alt="Cancel" />}
                            label="Cancel"
                            onClick={handleCancelClick(params.id)}
                        />,
                    ];
                }

                return [
                    isEditable ?
                        <>

                            <GridActionsCellItem
                                icon={<img src={editIcon} alt="Edit" />}
                                label="Edit"
                                onClick={() => handleEdit(params.id, params)}
                                disabled={!isEditable} // Disable if not editable
                            />
                            <GridActionsCellItem
                                icon={<img src={deleteIcon} alt="Delete" />}
                                label="Delete"
                                onClick={handleDeleteClick(params.id)}
                                disabled={!isEditable} // Disable if not editable
                            />
                        </>
                        :
                        <>

                        </>

                ];
            },
        },
    ];


    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            navigate('/client-details', { state: { client } });
        }
    };
    return (
        <>
            <MergeAccountCards
                isVisible={isMergeModalVisible}
                onClose={() => setMergeModalVisible(false)}
                accountCardsData={accountCardsData}
                setAccountCardsData={setAccountCardsData}
                handleAccountMerge={handleMerge}
            />

            <CustomDeleteConfirmationModal
                isVisible={deleteModalVisible}
                onClose={() => setDeleteModalVisible(false)}
                onDelete={handleConfirmDelete}
            />

            <div className="template-page-withoutHeight">
                <div className="template-container">
                    <UpperPanelDetails />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                        <div className={`main-title ${direction}`}>{t(type)}</div>
                    </div>

                    <div className={`title-back-container ${direction}`}>



                        <div className='document-other-create-download-container'>
                            {(selectedRows && selectedRows.length > 0) && (
                                <button onClick={() => handleOpenEditSelected()} className="create-Invoice-selector" >
                                    <AutoAwesomeMotionIcon sx={{ ml: 1 }} />
                                    <span>{t("Edit")}</span>
                                </button>
                            )}
                            <button onClick={() => setAddNewAccount(true)} className="create-Invoice-selector" >
                                <AddCircleIcon sx={{ ml: 1 }} />
                                <span>{t("AddAccountCard")}</span>
                            </button>

                            <button onClick={() => setMergeModalVisible(true)} className="create-Invoice-selector" >
                                <MergeIcon sx={{ ml: 1 }} />
                                <span>{t("MergeAccountCards")}</span>
                            </button>
                            <input
                                type="file"
                                ref={fileInputRef1}
                                style={{ display: 'none' }} // Hide the file input
                            />
                            <button onClick={() => handleFileButtonClick(fileInputRef1, uploadFileToAccountCard)} className="create-Invoice-selector" >
                                <DriveFolderUploadIcon sx={{ ml: 1 }} />
                                <span>{t("Import account cards from file")}</span>
                            </button>

                        </div>
                    </div>
                    <div className={`searchFilter-bar__container ${direction}`} >



                        <TextField
                            fullWidth
                            placeholder={t('searchClient')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-bar"
                            style={{ height: '55px' }}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                sx: {
                                    height: '55px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                                ...(direction === 'ltr'
                                    ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }),
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                        {/* Dialog with two selects */}
                        <Dialog
                            open={openEditSelected}
                            onClose={handleCloseEditSelected}
                            sx={{
                                '& .MuiDialog-paper': {
                                    width: '400px', // Compact width
                                    padding: '16px', // Reduced padding for compact design
                                },
                            }}
                            className={`${direction}`}
                        >
                            <DialogTitle sx={{ fontSize: '16px', paddingBottom: '8px', direction: 'ltr' }}>
                                {t('ChangeFieldsForAllSelected')}
                            </DialogTitle>
                            <DialogContent sx={{ paddingTop: '8px', paddingBottom: '8px', margin: 0 }}>
                                <AutocompleteSelect
                                    options={palCodesOptions}
                                    value={
                                        palCodesOptions.find((option) => option.value === selectedPalCodeForSelectAll) || null
                                    }
                                    onChange={(event, newValue) => setSelectedPalCodeForSelectAll(newValue ? newValue.value : null)}
                                    placeholder={t("SelectBalanceClassification")}
                                    isMultiple={false}
                                    width="100%" // Custom width for compact display
                                />
                                <Box className="button-container" sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                                    <div onClick={handleCloseEditSelected} className="edit-cancel-button" sx={{ minWidth: '120px' }}>
                                        {t('Cancel')}
                                    </div>
                                    <div onClick={handleEditInBulk} className="edit-save-button" sx={{ minWidth: '120px' }}>
                                        {t('Save')}
                                    </div>
                                </Box>
                            </DialogContent>
                        </Dialog>


                    </div>

                    <div className={`${direction}`} style={{ width: '100%', height: '60vh', marginTop: '20px' }}>


                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
                            <StyledDataGrid
                                apiRef={apiRef}
                                rows={rows}
                                columns={columns}
                                checkboxSelection
                                disableSelectionOnClick
                                rowSelectionModel={selectedRows}
                                onRowSelectionModelChange={handleSelectionModelChange}
                                getRowId={(row) => row.cardId} // Use row.cardId as the row ID
                                components={{
                                    Toolbar: EditToolbar,
                                }}
                                componentsProps={{
                                    toolbar: { setRows, setRowModesModel },
                                }}
                                pagination
                                sortingOrder={['asc', 'desc']}
                                isRowSelectable={isRowSelectable} // Rows not selectable if isEditable is false
                                processRowUpdate={processRowUpdate}
                                experimentalFeatures={{ newEditingApi: true }}
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={setRowModesModel}
                                onRowDoubleClick={handleRowDoubleClick}
                                isCellEditable={(params) => isRowSelectable(params)} // Cells not editable if row is not selectable

                            />
                        </LocalizationProvider>
                    </div>

                </div>
                <HamburgerMenu client={client} />
                {isModalOpen && (
                    <EditAccountCardModal
                        open={isModalOpen}
                        onClose={handleCloseEditAddModal}
                        rowData={selectedRow}
                        isNew={isNew}
                        onSave={handleModalSave}
                        palCodesOptions={palCodesOptions}
                        sourceDeductionOptions={sourceDeductionOptions}
                        direction={direction}
                        t={t} // Pass the translation function
                        toast={toast}
                    />
                )}
                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}
            </div >
        </>
    );
});

export default AccountCardsTable;
