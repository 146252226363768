import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {
    DataGrid,
    GridToolbar,
    GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import {
    IconButton,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NoDocumentsPdf from '../../../assests/pdf/NoDocument.pdf';
import editIcon from '../../../assests/images/Icons/editIcon.svg';
import deleteIcon from '../../../assests/images/Icons/deleteIcon.svg';
import saveIcon from '../../../assests/images/Icons/saveIcon.svg';
import cancelIcon from '../../../assests/images/Icons/cancelIcon.svg';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { format } from 'date-fns';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiAutocomplete-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"

    },
    '& *': {
        boxSizing: 'border-box !important',

    },
    '& .MuiDataGrid-columnHeaders': {
        opacity: 0.7,
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#304FFF',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-cell': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rgba(191, 200, 255, 0.3)',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
    // New styles for edit mode
    '& .MuiDataGrid-cell--editing': {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
        borderRight: 'none',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell:not(:last-child)': {
        borderRight: '8px solid transparent',
    },
    '& .MuiDataGrid-cell--editing': {
        padding: 0,
        backgroundColor: 'inherit',
    },
}));

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy');
};

const OtherDocumentDataGrid = forwardRef((props, ref) => {
    const {
        t,
        data,
        client,
        openPdfInNewTab,
        handleEdit,
        handleDeleteClick,
        handleSelectRows,
        handleUpdate,
        handleCancel,
        selectOtherDocumentsOptions,
        direction,
        setCurrentRowData,
        setOpenInlineEditModal
    } = props;

    const [selectedRows, setSelectedRows] = useState([]);
    const [rows, setRows] = useState(data);
    const [rowModesModel, setRowModesModel] = useState({});
    const { i18n } = useTranslation();

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    useImperativeHandle(ref, () => ({
        deselectRows(ids) {
            setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter((rowId) => !ids.includes(rowId))
            );
        },
    }));

    const isSelected = (id) => selectedRows.includes(id);

    const handleSelectionModelChange = (newSelection) => {
        setSelectedRows(newSelection);
        handleSelectRows(newSelection);
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View },
        }));
        const updatedRow = rows.find((row) => row.id === id);
        handleUpdate(updatedRow);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));
        handleCancel();
    };

    const processRowUpdate = (newRow) => {
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? newRow : row))
        );
        return newRow;
    };

    const columns = [
        {
            field: 'document',
            headerName: t('Document'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => (
                <div
                    className="iframe-container"
                    onClick={() => openPdfInNewTab(params.row.documentUrl)}
                >
                    <iframe
                        className="zoom-iframe"
                        src={
                            params.row.documentUrl
                                ? `${params.row.documentUrl}#page=1`
                                : `${NoDocumentsPdf}#page=1`
                        }
                        width="100px"
                        height="50px"
                        title={params.row.title}
                        style={{ pointerEvents: 'none' }}
                    />
                </div>
            ),
        },

        {
            field: 'processed',
            headerName: t('DocumentProcessed'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => {
                const isIdInChecks = client.documentIds.otherDocuments.includes(params.row.id);

                return (
                    !isIdInChecks ? (
                        <p className='processed'>{t('processed')}</p>
                    ) : (
                        <p className='unprocessed'>{t('unprocessed')}</p>
                    )
                );
            },
            sortComparator: (v1, v2, params1, params2) => {
                
                const isIdInChecks1 = client.documentIds.otherDocuments.includes(params1.id);
                const isIdInChecks2 = client.documentIds.otherDocuments.includes(params2.id);

                if (isIdInChecks1 === isIdInChecks2) {
                    return 0; // Both are either processed or unprocessed
                }
                return isIdInChecks1 ? 1 : -1; // Sort 'unprocessed' after 'processed'
            },
        },
        {
            field: 'title',
            headerName: t('Title'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
        },
        {
            field: 'documentType',
            headerName: t('Document Type'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,

        },
        {
            field: 'uploadDate',
            headerName: t('Upload Date'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
            valueFormatter: (params) => formatDate(params),

        },
        {
            field: 'uploadNumber',
            headerName: t('UploadNumber'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },

        {
            field: 'actions',
            type: 'actions',
            headerName: t('Actions'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            getActions: (params) => {
                const isInEditMode =
                    rowModesModel[params.id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <IconButton onClick={handleSaveClick(params.id)}>
                            <img src={saveIcon} alt="Save" />
                        </IconButton>,
                        <IconButton onClick={handleCancelClick(params.id)}>
                            <img src={cancelIcon} alt="Cancel" />
                        </IconButton>,
                    ];
                }

                return [

                    <IconButton onClick={() => {
                        setRowModesModel((prevModel) => ({
                            ...prevModel,
                            [params.id]: { mode: GridRowModes.Edit },
                        }));
                        handleEdit(params.row);
                    }}>
                        <img src={editIcon} alt="Edit" />
                    </IconButton>,

                    <IconButton onClick={() => handleDeleteClick(params.row.id, params.row.documentType)}>
                        <img src={deleteIcon} alt="Delete" />
                    </IconButton>
                ];
            },
        },
    ];
    // Function to handle double-click to enter edit mode and set editedItem
    const handleCellDoubleClick = (params) => {
        setCurrentRowData(params.row);
        setOpenInlineEditModal(true);
    };
    return (
        <div className={`${direction}`} style={{ width: '100%', height: '60vh' }}>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={handleSelectionModelChange}
                getRowId={(row) => row.id}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                pagination
                sortingOrder={['asc', 'desc']}
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                rowModesModel={rowModesModel}
                onRowModesModelChange={setRowModesModel}
                onCellDoubleClick={handleCellDoubleClick}
            />
        </div>
    );
});

export default OtherDocumentDataGrid;
