import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './HamburgerMenu.css'; // Import the CSS styles
import logo from '../../assests/images/login/logo.svg';
import clients from '../../assests/images/Hamburger/clients.svg';
import accountants from '../../assests/images/Hamburger/accountants.svg';
import reports from '../../assests/images/Hamburger/reports.svg';
import home from '../../assests/images/Hamburger/home.svg';
import profile from '../../assests/images/Hamburger/person.svg';
import logOut from '../../assests/images/Hamburger/logout.svg';
import salesIcon from '../../assests/images/Icons/sales1.svg';
import bankIcon from '../../assests/images/Icons/bank.svg';
import crmIcon from '../../assests/images/Icons/crm.svg';
import accountingManagementIcon from '../../assests/images/Icons/accountingManagment1.svg';
import cardMatchingIcon from '../../assests/images/Icons/cardMatching.svg';
import PuffLoader from "react-spinners/PuffLoader";


// Item Icons
import accountCardsDetailsIcon from '../../assests/images/Icons/accountCard.svg';
import incomesIcon from '../../assests/images/Icons/income.svg';
import expensesIcon from '../../assests/images/Icons/expense.svg';
import paymentAcceptanceIcon from '../../assests/images/Icons/paymentAcceptance.svg';
import journalEntryIcon from '../../assests/images/Icons/journalEntry.svg';
import otherDocumentIcon from '../../assests/images/Icons/otherDocument.svg';
import bankMatchingIcon from '../../assests/images/Icons/matching.svg';
import MatchingIcon from '../../assests/images/Icons/matching1.svg';
import sortCodeReportIcon from '../../assests/images/Icons/sortcodeReport.svg';
import accountCardsTableIcon from '../../assests/images/Icons/ledger.svg';
import ledgerReportIcon from '../../assests/images/Icons/balance.svg';
import referenceSequenceIcon from '../../assests/images/Icons/referenceCheck.svg';
import generateDocIcon from '../../assests/images/Icons/generateDocument.svg';
import collectionsIcon from '../../assests/images/Icons/collection.svg';
import advancesReportIcon from '../../assests/images/Icons/reports.svg';
import pcnFileIcon from '../../assests/images/Icons/reports.svg';
import vatReportIcon from '../../assests/images/Icons/reports.svg';
import transactionDocumentsIcon from '../../assests/images/Icons/financial.svg';
import profitAndLossIcon from '../../assests/images/Icons/profitAndLoss.svg';
import reportsIcon from '../../assests/images/Icons/reports1.svg';
import reports1Icon from '../../assests/images/Icons/reports.svg';

import { useNavigate } from 'react-router-dom';
import AuthService from '../../Services/auth.service';
import UserService from '../../Services/user.service';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import ClientService from '../../Services/client.service';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formatDate_MM_YYYY } from '../../Utils/FormatDate';
import CustomMonthDatePicker from '../CustomMonthDatePicker/CustomMonthDatePicker';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
import CustomYearDatePicker from '../CustomYearDatePicker/CustomYearDatePicker';
import ReportsService from '../../Services/reports.service';
import CircularProgress from '@mui/material/CircularProgress';
import EnumsService from '../../Services/enums.service';

// New imports for Radio Buttons
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const formatDate = (date) => {
    return dayjs(date).toDate();
};

function HamburgerMenu({ client }) {
    const { i18n, t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const direction = i18n.dir();
    const navigate = useNavigate();
    const [isClientMenuOpen, setIsClientMenuOpen] = useState(false);
    const [expandedSections, setExpandedSections] = useState({});
    const [subMenuItem, setSubMenuItem] = useState(null);
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [selectedReportType, setSelectedReportType] = useState('');
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [date, setDate] = useState(dayjs());
    const [isDateRange, setIsDateRange] = useState(false); // New state variable
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);
    const [accountVisibleInReference, setAccountVisibleInReference] = useState(false);
    const [selectedAccountCard, setSelectedAccountCard] = useState(null);
    const [selectedReferenceAccountCard, setSelectedReferenceAccountCard] = useState('');
    const [AccountCardOptions, setAccountCardOptions] = useState([]);

    const fetchEnums = async (token, client, isSetLoading = false) => {
        try {
            if (isSetLoading) {
                setLoading(true);
            }

            // Fetch account cards
            const accountsResponse = await EnumsService.GetAccountCardsByCaseId(
                client.caseId,
                token
            );
            const accountsData = await accountsResponse.json();
            setAccountCardOptions(accountsData);
        } catch (error) {
            console.error('Error fetching enum data:', error);
            toast.error(t('fetchEnumsFailure', { errorText: error.message }));
        } finally {
            if (isSetLoading) {
                setLoading(false);
            }
        }
    };

    const formattedAccountCardOptions = AccountCardOptions?.map((option) => ({
        value: option.cardId,
        label: `${option.cardId} - ${option.name}`,
        cardId: option.cardId,
        name: option.name,
        companyId: option.accountCaseId,
        palCode: option.palCode,
    }));

    useEffect(() => {
        // Check for saved language in localStorage
        const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'he' if no language is stored
        i18n.changeLanguage(currentLanguage);
    }, [i18n]);

    useEffect(() => {
        const currentToken = UserService.getCurrentToken();
        if (currentToken) {
            setToken(currentToken);
        }
    }, []);

    useEffect(() => {
        if (client && token) {
            fetchEnums(token, client, false);
        }
    }, [client, token]);

    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    const handleLogout = async () => {
        try {
            await AuthService.logout();
            navigate('/login');
        } catch (error) {
            // Optionally handle any errors
        }
    };

    const checkLogoutTime = async () => {
        try {
            const Token = await UserService.getCurrentToken();
            const validTokenDate = await AuthService.CheckTokenValidation(Token);
            if (!validTokenDate.isValid) {
                toast.info(t('YouNeedToLogInAgain'));
                handleLogout();
            }
        } catch (error) {
            toast.info(t('YouNeedToLogInAgain'));
            handleLogout();
        }
    };

    useEffect(() => {
        checkLogoutTime();
    }, []);

    const handleGoClientDetails = () => {
        if (client) {
            navigate('/client-details', { state: { client } });
        }
    };

    // Toggle the expansion of a section
    const handleSectionClick = (sectionTitle) => {
        setSubMenuItem(null)
        setExpandedSections((prev) => ({
            ...prev,
            [sectionTitle]: !prev[sectionTitle],
        }));
    };

    const openDateModal = (reportType) => {
        setSelectedReportType(reportType);
        setIsDateModalOpen(true);
    };

    const closeDateModal = () => {
        setIsDateModalOpen(false);
        setSelectedReportType('');
    };

    const handleDownloadReport = async () => {
        setIsDateModalOpen(false);
        setLoading(true);
        try {
            const formattedStartDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);
            const formattedDate = formatDate(date);

            // Decide which dates to use based on isDateRange
            let start, end;
            if (isDateRange) {
                start = formattedStartDate;
                end = formattedEndDate;
            } else {
                start = formattedDate;
                end = formattedDate;
            }

            switch (selectedReportType) {
                case 'ProfitAndLoss':
                    await ClientService.ProfitAndLossDetailsYearlyReport(
                        client.caseId,
                        client.accountantId,
                        start,
                        end,
                        token
                    );
                    break;
                case 'WithholdingTax':
                    await ClientService.WithholdingTaxReport(
                        client.caseId,
                        client.accountantId,
                        start,
                        end,
                        token
                    );
                    break;
                case 'VAT':
                    await ClientService.VATReport(
                        client.caseId,
                        client.accountantId,
                        start,
                        end,
                        token
                    );
                    break;
                case 'PCN':
                    await ClientService.DownloadPCNReport(
                        client.caseId,
                        start,
                        end,
                        token
                    );
                    // Delay between downloads if necessary
                    await delay(500);
                    await ClientService.DownloadPCNPDF(
                        client.caseId,
                        start,
                        end,
                        token
                    );
                    break;
                default:
                    break;
            }
            toast.success(t('reportDownloadSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('reportDownloadFailure'));
        }
        setLoading(false);
    };

    // Reference Sequence Modal functions
    const openReferenceSequenceModal = () => {
        console.log('here');
        setIsReferenceModalOpen(true);
    };

    const closeReferenceSequenceModal = () => {
        setIsReferenceModalOpen(false);
        setAccountVisibleInReference(false);
        setSelectedAccountCard(null);
        setSelectedReferenceAccountCard('');
    };

    const handleAccountVisibleInReferenceChange = (event) => {
        setAccountVisibleInReference(event.target.checked);
    };

    const handleSelectedAccountCardChange = (event, value) => {
        setSelectedReferenceAccountCard(value?.value || '');
        setSelectedAccountCard(value);
    };

    const handleDownloadReferenceSequence = async () => {
        setLoading(true);
        setIsReferenceModalOpen(false);
        const formattedDate = formatDate(date);
        try {
            if (selectedReferenceAccountCard) {
                await ReportsService.getReferenceSequenceReport(
                    client.caseId,
                    formattedDate,
                    formattedDate,
                    selectedReferenceAccountCard,
                    token
                );
            } else {
                await ReportsService.getReferenceSequenceReport(
                    client.caseId,
                    formattedDate,
                    formattedDate,
                    '',
                    token
                );
            }
            toast.success(t('reportDownloadSuccess'));
        } catch (error) {
            console.error(error.message);

            if (error.message === 'לא קיים הכנסות בשנה ולקרטיס') {
                toast.error(t('reportDownloadFailure') + ': ' + error.message);
            } else {
                toast.error(t('reportDownloadFailure'));
            }
        }
        closeReferenceSequenceModal();
        setLoading(false);
    };

    // Navigation functions
    const viewAccountCardsTable = (type) => {
        navigate('/AccountCardsTable', { state: { type, client } });
    };

    const viewDocumentDetails = (type) => {
        navigate('/Document', { state: { type, client } });
    };

    const viewPaymentAcceptanceDetails = (type) => {
        navigate('/PaymentAcceptance', { state: { type, client } });
    };

    const viewJournalEntryDetails = (type) => {
        navigate('/JournalEntry', { state: { type, client } });
    };

    const viewOtherDocumentDetails = (type) => {
        navigate('/OtherDocument', { state: { type, client } });
    };

    const viewProfitAndLossDetails = (type) => {
        navigate('/ProfitAndLossReport', { state: { type, client } });
    };

    const viewLedgerReport = (type) => {
        navigate('/LedgerReport', { state: { type, client } });
    };

    const viewTransactionDocuments = (type) => {
        navigate('/TransactionDocuments', { state: { type, client } });
    };

    const viewBankTransactions = (type) => {
        navigate('/BankTransactions', { state: { type, client } });
    };

    const viewCollectionPage = (type) => {
        navigate('/CollectionPage', { state: { type, client } });
    };

    const viewCardMatching = (type) => {
        navigate('/CardMatching', { state: { type, client } });
    };

    const viewDocumentCreate = (screen, type) => {
        navigate(screen, { state: { client, type, } });
    };

    // Modified handleItemClick function
    const handleItemClick = (itemOnClick, sectionTitle) => () => {
        if (itemOnClick) {
            itemOnClick();
        }
        setExpandedSections((prev) => ({
            ...prev,
            [sectionTitle]: false,
        }));
    };

    // Define your items here
    const accountingItems = [
        {
            label: t('AccountCardsTable'),
            icon: accountCardsDetailsIcon,
            onClick: () => viewAccountCardsTable('AccountCardsTable'),
        },
        {
            label: t('Incomes'),
            icon: incomesIcon,
            onClick: () => viewDocumentDetails('Incomes'),
            count: client?.documentCounts?.incomes ?? 0,
        },
        {
            label: t('Expenses'),
            icon: expensesIcon,
            onClick: () => viewDocumentDetails('Expenses'),
            count: client?.documentCounts?.expenses ?? 0,
        },
        {
            label: t('PaymentAcceptance'),
            icon: paymentAcceptanceIcon,
            onClick: () => viewPaymentAcceptanceDetails('PaymentAcceptances'),
            count: client?.documentCounts?.checks ?? 0,
        },
        {
            label: t('JournalEntry'),
            icon: journalEntryIcon,
            onClick: () => viewJournalEntryDetails('JournalEntries'),
            count: client?.documentCounts?.journalEntries ?? 0,
        },

    ];

    const reportsItems = [
        {
            label: t('SortCodeReport'),
            icon: sortCodeReportIcon,
            onClick: () => viewProfitAndLossDetails('ProfitAndLossReport'),
        },
        {
            label: t('LedgerReport'),
            icon: accountCardsTableIcon,
            onClick: () => viewLedgerReport('LedgerReport'),
        },
        {
            label: t('ReferenceSequence'),
            icon: referenceSequenceIcon,
            onClick: openReferenceSequenceModal,
        },
        {
            label: t('ProfitAndLossReport'),
            icon: profitAndLossIcon,
            onClick: () => openDateModal('ProfitAndLoss'),

        },
        {
            label: t('ReportsForAuthority'),
            icon: reports1Icon,
            onClick: () => { },
            subItems: [
                {
                    label: t('AdvancesReport'),
                    icon: advancesReportIcon,
                    onClick: () => openDateModal('WithholdingTax'),
                },
                {
                    label: t('PCNFile'),
                    icon: pcnFileIcon,
                    onClick: () => openDateModal('PCN'),
                },
                {
                    label: t('VATReport'),
                    icon: vatReportIcon,
                    onClick: () => openDateModal('VAT'),
                },
            ],
        },
    ];

    const salesItems = [
        { label: t('TransactionDocuments'), icon: transactionDocumentsIcon, onClick: () => viewTransactionDocuments("TransactionDocuments") },
        { label: t('TaxInvoice'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'TaxInvoice') },
        { label: t('ReceiptTax'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate('/ReceiptTax', 'ReceiptTax') },
        { label: t('CreditNote'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'CreditNote') },
        { label: t('Receipt'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate('/ReceiptTax', 'Receipt') },
        { label: t('Transaction'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'Transaction') },
        { label: t('Quotation'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'Quotation') },
        { label: t('Order'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'Order') },
    ];

    const crmItems = [
        {
            label: t('Collections'),
            icon: collectionsIcon,
            onClick: () => viewCollectionPage('CollectionPage'),
        },
        {
            label: t('OtherDocuments'),
            icon: otherDocumentIcon,
            onClick: () => viewOtherDocumentDetails('OtherDocuments'),
            count: client?.documentCounts?.otherDocuments ?? 0,
        },
    ];

    const bankItems = [
        { label: t('BankMatching'), icon: bankIcon, onClick: () => viewBankTransactions("BankMatching") },
        { label: t('CardMatching'), icon: cardMatchingIcon, onClick: () => viewCardMatching("CardMatching") },
    ];

    const clientMenuSections = [
        {
            title: t('AccountingManagement'),
            icon: accountingManagementIcon,
            items: accountingItems,
        },
        {
            title: t('Reports'),
            icon: reportsIcon,
            items: reportsItems,
        },
        {
            title: t('Sales'),
            icon: salesIcon,
            items: salesItems,
        },
        {
            title: t('CRM'),
            icon: crmIcon,
            items: crmItems,
        },
        {
            title: t('Matching'),
            icon: MatchingIcon,
            items: bankItems,
        },
    ];

    // Modal styles
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 300,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: 24,
        padding: 4,
    };

    const handleStartReferenceYearDate = async (date) => {
        setDate(date);
    }

    return (
        <>
            <div
                className={`sidebar ${isOpen ? 'open' : ''} ${direction}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className={`sidebar-header ${direction}`} onClick={() => navigate('/Main')}>
                    <img src={logo} alt="Logo" className="sidebar-logo" />
                </div>
                <div className="sidebar-items">
                    <div
                        className={`sidebar-item ${isOpen && 'sidebar-item-main'} ${direction}`}
                        style={!isOpen ? { justifyContent: 'center' } : {}}
                        onClick={() => navigate('/Main')}
                    >
                        <div className="sidebar-item-content">
                            <img
                                src={home}
                                alt="home"
                                width={24}
                                height={24}
                                className={!isOpen ? 'icon-closed' : ''}
                            />
                            {isOpen && <span>{t('clientDashboard')}</span>}
                        </div>
                    </div>

                    <div
                        className={`sidebar-item ${direction}`}
                        style={!isOpen ? { justifyContent: 'center' } : {}}
                        onClick={() => navigate('/SubAccountants')}
                    >
                        <div className="sidebar-item-content">
                            <img src={accountants} alt="accountants" width={24} height={24} />
                            {isOpen && <span>{t('SubAccountantDashboard')}</span>}
                        </div>
                    </div>

                    <div
                        className={`sidebar-item ${direction}`}
                        style={!isOpen ? { justifyContent: 'center' } : {}}
                        onClick={() => navigate('/ReportTracking')}
                    >
                        <div className="sidebar-item-content">
                            <img src={reports} alt="reports" width={24} height={24} />
                            {isOpen && <span>{t('ReportTracking')}</span>}
                        </div>
                    </div>
                    <div
                        className={`sidebar-item ${direction}`}
                        style={!isOpen ? { justifyContent: 'center' } : {}}
                        onClick={() => navigate('/Accountant-details')}
                    >
                        <div className="sidebar-item-content">
                            <img src={profile} alt="profile" width={34} height={34} />
                            {isOpen && <span>{t('profile')}</span>}
                        </div>
                    </div>

                    {client && (
                        <>
                            <div
                                className={`sidebar-item ${direction}`}
                                style={!isOpen ? { justifyContent: 'center' } : {}}
                                onClick={() => setIsClientMenuOpen(!isClientMenuOpen)}
                            >
                                <div className="sidebar-item-content">
                                    <img src={salesIcon} alt="client" width={34} height={34} />
                                    {isOpen && <span>{client.name}</span>}
                                </div>
                                {isOpen && (
                                    <div className="sidebar-item-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            className={isClientMenuOpen ? 'rotated' : ''}
                                        >
                                            <path
                                                d="M7.41 8.79492L12 13.3749L16.59 8.79492L18 10.2049L12 16.2049L6 10.2049L7.41 8.79492Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>

                            {isClientMenuOpen && isOpen && (
                                <>
                                    {/* First item: Client Page */}
                                    <div
                                        className={`submenu-item ${direction}`}
                                        onClick={() => {
                                            handleGoClientDetails();
                                            setIsClientMenuOpen(false); // Close client menu after click
                                        }}
                                    >
                                        <div className="sidebar-item-content">
                                            <img src={profile} alt="client page" width={24} height={24} />
                                            <span>{t('ClientPage')}</span>
                                        </div>
                                    </div>

                                    {/* Render ClientActionCards for each section */}
                                    {clientMenuSections.map((section, index) => (
                                        <div key={index} className="submenu-section">
                                            <div
                                                className={`submenu-item ${direction}`}
                                                onMouseEnter={() => handleSectionClick(section.title)}
                                                onMouseLeave={() => handleSectionClick(section.title)}
                                            >
                                                <div className="submenu-item-content">
                                                    <img src={section.icon} alt={section.title} width={24} height={24} />
                                                    <span>{section.title}</span>
                                                </div>
                                                {/* Arrow SVG */}
                                                <div className="submenu-item-arrow">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="25"
                                                        viewBox="0 0 24 25"
                                                        fill="none"
                                                        className={expandedSections[section.title] ? 'rotated' : ''}
                                                    >
                                                        <path
                                                            d="M7.41 8.79492L12 13.3749L16.59 8.79492L18 10.2049L12 16.2049L6 10.2049L7.41 8.79492Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                                {/* Flyout Submenu */}
                                                {expandedSections[section.title] && (
                                                    <div className={`flyout-submenu ${direction}`}>
                                                        <div className={`flyout-submenu-main ${direction}`}>
                                                            {section.items.map((item, idx) => (
                                                                <div
                                                                    key={idx}
                                                                    className={`flyout-submenu-item ${direction}`}
                                                                    // onClick={
                                                                    //     item.onClick
                                                                    //         ? handleItemClick(item.onClick, section.title)
                                                                    //         : undefined
                                                                    // }
                                                                    onClick={() => {
                                                                        if (item.subItems) {
                                                                            setSubMenuItem((prevItem) => {
                                                                                // If prevItem is not empty and matches the current item, reset it
                                                                                if (prevItem) {
                                                                                    return null;
                                                                                }
                                                                                // Otherwise, set it as the current item
                                                                                return item;
                                                                            });
                                                                        } else if (item.onClick) {
                                                                            handleItemClick(item.onClick, section.title)();
                                                                            setExpandedSections({});
                                                                            setSubMenuItem(null);
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className={`flyout-submenu-item-main ${direction}`}>

                                                                        <div className="flyout-submenu-item-content">
                                                                            <img src={item.icon} alt={item.label} width={24} height={24} />
                                                                            <span>{item.label}</span>
                                                                        </div>
                                                                        {item.count !== null && item.count !== undefined && (
                                                                            <div className="item-notification-count">{item.count}</div>
                                                                        )}
                                                                    </div>
                                                                    {item.subItems && (
                                                                        <div
                                                                            className="submenu-item-arrow"
                                                                            dir={direction} // dynamically set the direction
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                className="submenu-arrow-icon"
                                                                            >
                                                                                <path
                                                                                    d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z"
                                                                                    fill="#000"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    )}
                                                                    {/* SubSubItems */}
                                                                    {/* {item.subItems && (
                                                                    <div className={`flyout-subsubmenu ${direction}`}>
                                                                        {item.subItems.map((subItem, subIdx) => (
                                                                            <div
                                                                                key={subIdx}
                                                                                className={`flyout-subsubmenu-item ${direction}`}
                                                                                onClick={
                                                                                    subItem.onClick
                                                                                        ? handleItemClick(subItem.onClick, section.title)
                                                                                        : undefined
                                                                                }
                                                                            >
                                                                                <div className="flyout-subsubmenu-item-content">
                                                                                    <img
                                                                                        src={subItem.icon}
                                                                                        alt={subItem.label}
                                                                                        width={20}
                                                                                        height={20}
                                                                                    />
                                                                                    <span>{subItem.label}</span>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )} */}
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {

                                                            subMenuItem && subMenuItem.subItems &&
                                                            <div className={`flyout-subsubmenu ${direction}`}>
                                                                {subMenuItem.subItems.map((subItem, subIdx) => (
                                                                    <div
                                                                        key={subIdx}
                                                                        className={`flyout-subsubmenu-item ${direction}`}
                                                                        onClick={
                                                                            subItem.onClick
                                                                                ? handleItemClick(subItem.onClick, section.title)
                                                                                : undefined
                                                                        }
                                                                        onMouseLeave={() => setSubMenuItem({})}
                                                                        onMouseEnter={() => setSubMenuItem(subMenuItem)}
                                                                    >
                                                                        <div className="flyout-subsubmenu-item-content">
                                                                            <img
                                                                                src={subItem.icon}
                                                                                alt={subItem.label}
                                                                                width={20}
                                                                                height={20}
                                                                            />
                                                                            <span>{subItem.label}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>

                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className={`logout-item sidebar-item ${direction}`} onClick={handleLogout}>
                    <div className="sidebar-item-content">
                        <img src={logOut} alt="logout" width={24} height={24} />
                        {isOpen && <span>{t('logOut')}</span>}
                    </div>
                </div>
            </div>

            {/* Date Selection Modal */}
            <Modal
                open={isDateModalOpen}
                onClose={closeDateModal}
                aria-labelledby="date-modal-title"
                aria-describedby="date-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box sx={modalStyle} className={`modal-container ${direction}`}>
                    <h2 id="date-modal-title">{t('SelectDateRange')}</h2>

                    {/* Date Option Selection */}
                    <FormControl component="fieldset" sx={{ mb: 2 }}>
                        <FormLabel component="legend">{t('SelectDateOption')}</FormLabel>
                        <RadioGroup
                            aria-label="date-option"
                            name="date-option"
                            value={isDateRange ? 'dateRange' : 'singleDate'}
                            onChange={(event) => setIsDateRange(event.target.value === 'dateRange')}
                            row
                        >
                            <FormControlLabel
                                value="singleDate"
                                control={<Radio />}
                                label={t('SingleDate')}
                            />
                            <FormControlLabel
                                value="dateRange"
                                control={<Radio />}
                                label={t('DateRange')}
                            />
                        </RadioGroup>
                    </FormControl>

                    {isDateRange ? (
                        // For date range selection
                        <div className="date-picker-container">
                            <CustomMonthDatePicker
                                onDateChange={(date) => setStartDate(date)}
                                date={dayjs(startDate)}
                                label={t('StartDate')}
                                height="55px"
                            />
                            <CustomMonthDatePicker
                                onDateChange={(date) => setEndDate(date)}
                                date={dayjs(endDate)}
                                label={t('EndDate')}
                                height="55px"
                            />
                        </div>
                    ) : (
                        // For single date selection
                        <div className="date-picker-container">
                            <CustomMonthDatePicker
                                onDateChange={(date) => setDate(date)}
                                date={dayjs(date)}
                                label={t('date')}
                                height="55px"
                            />
                        </div>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained" onClick={handleDownloadReport}>
                            {t('Download')}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Reference Sequence Modal */}
            <Modal
                open={isReferenceModalOpen}
                onClose={closeReferenceSequenceModal}
                aria-labelledby="reference-modal-title"
                aria-describedby="reference-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box className={`reference-sequence-modal ${direction}`}>
                    <strong style={{
                        color: "var(--black-90, rgba(0, 0, 0, 0.90))",
                        textAlign: "center",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                    }}>
                        {t('DownloadReferenceSequenceReport')}
                    </strong>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={accountVisibleInReference}
                                    onChange={handleAccountVisibleInReferenceChange}
                                />
                            }
                            label={t('ByAccountCard')}
                        />

                        <CustomYearDatePicker
                            onDateChange={(date) => handleStartReferenceYearDate(date)}
                            date={dayjs(date)}
                            label={t("Year")}
                        />
                    </div>
                    {accountVisibleInReference && (
                        <AutocompleteSelect
                            options={formattedAccountCardOptions}
                            selectedValues={selectedAccountCard}
                            onChange={handleSelectedAccountCardChange}
                            label={t("Select AccountCard")}
                            placeholder={t("Select AccountCard")}
                            isMultiple={false}
                            width="100%"
                        />
                    )}
                    <Button
                        variant="contained"
                        onClick={handleDownloadReferenceSequence}
                        sx={{
                            width: '80%',
                            height: '44px',
                            mt: 1,
                            backgroundColor: '#304FFF',
                            borderRadius: '8px',
                            boxShadow: "0px 6px 6px 0px rgba(0, 0, 0, 0.08)",
                            color: '#fff',
                            '&:hover': { backgroundColor: '#1565c0' }
                        }}
                    >
                        {t('DownloadFile')}
                    </Button>
                </Box>
            </Modal>


            {loading && (
                <div className="loader-container">
                    <PuffLoader
                        size={100}
                        loading={loading}
                    />
                </div>
            )}
        </>
    );
}

export default HamburgerMenu;
