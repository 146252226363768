export function beautifyNumber(num) {
    if (!num || num === undefined || num === null || num === '') {
        num = 0;
    }

    const negative = num < 0;
    
    
    let fixedNum = Math.abs(num);
    let formattedString;

    if (fixedNum % 1 === 0) { // Check if the number is whole
        formattedString = fixedNum.toLocaleString('en-US', { maximumFractionDigits: 0 });
    } else {
        formattedString = fixedNum.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    let addedSpaces = 10 - formattedString.length;

    if (addedSpaces < 0) {
        addedSpaces = 0;
    }
    if (num === 0) {
        formattedString = '0.00'
    }
    if (negative && (formattedString.trim() !== '0.00' && formattedString.trim() !== '0')) {
        return `(${formattedString})`.padEnd(10, ' ');
    } else {
        return formattedString.padEnd(10, ' ');
    }
}