import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Components/Login/Login.jsx';
import Main from './Components/Main/Main.jsx';
import ClientDetails from './Components/ClientDetails/ClientDetails.jsx';
import AccountantDetails from './Components/AccountantDetails/AccountantDetails.jsx';
import Document from './Components/Document/Document.jsx';
import TransactionDocuments from './Components/TransactionDocuments/TransactionDocuments.jsx';
import OtherDocument from './Components/OtherDocuments/OtherDocument.jsx'
import Checks from './Components/Checks/Check.js'
import PaymentAcceptance from './Components/PaymentAcceptance/PaymentAcceptance.jsx'
import BankMatching from './Components/BankMatching/BankMatching.jsx'
import SubAccountant from './Components/SubAccountants/SubAccountants.jsx';
import { SignalRProvider } from './Contexts/signalR.js';
import ErrorPage from './Components/ErrorPage/ErrorPage.js';
import ProfitAndLossReport from './Components/ProfitAndLossReport/ProfitAndLossReport.jsx';
import SortCodeReport from './Components/SortCodeReport/SortCodeReport.jsx';
import CollectionPage from './Components/CollectionPage/CollectionPage.jsx';
import './Services/translation.service';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'; // Your global styles
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeflex/primeflex.css';
import { StyledEngineProvider } from '@mui/material/styles';
import { PrimeReactProvider } from 'primereact/api';
import { createTheme, ThemeProvider, Theme, useTheme } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import { arSD, heIL, enUS } from '@mui/x-data-grid/locales';
import createCache from '@emotion/cache';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReportTracking from './Components/ReportTracking/ReportTracking.jsx';
import LedgerReport from './Components/LedgerReport/LedgerReport.jsx';
import AccountCardDashboard from './Components/AccountCardDashboard/AccountCardDashboard.jsx';
import AccountCardsTable from './Components/AccountCardsTable/AccountCardsTable.jsx';
import JournalEntry from './Components/JournalEntryies/JournalEntry.jsx';
import AddClient from './Components/Widgets/AddClient/AddClient.jsx';
import CreateInvoice from './Components/Widgets/CreateInvoice/CreateInvoice.jsx';
import CreateReceipt from './Components/Widgets/CreateInvoice/CreateReceipt.jsx';
import { FileProvider } from './Contexts/FileContext.js';
import CardMatching from './Components/CardMatching/CardMatching.jsx';

function App() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');

  // const initChatConf = async () => {
  //   await ChatService.InitChat();

  // }
  useEffect(() => {
    // Check for saved language in localStorage
    const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'en' if no language is stored

    i18n.changeLanguage(currentLanguage);
    // initChatConf();
  }, []);

  useEffect(() => {
    const currentLanguage = localStorage.getItem('language') || 'he';
    setSelectedLanguage(currentLanguage);
  }, [i18n.language]);

  const existingTheme = useTheme();

  const theme = useMemo(() => {
    let locale;
    let direction = 'ltr'; // Default direction

    switch (selectedLanguage) {
      case 'ar':
        locale = arSD;
        direction = 'rtl';
        break;
      case 'he':
        locale = heIL;
        direction = 'rtl';
        break;
      case 'en':
      default:
        locale = enUS;
        direction = 'ltr';
        break;
    }

    return createTheme({}, locale, existingTheme, { direction });
  }, [selectedLanguage, existingTheme]);

  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer] // Removed rtlPlugin for strict LTR styling
  });
  return (
    <SignalRProvider>
      <FileProvider>
        <PrimeReactProvider>
          <StyledEngineProvider injectFirst>
            <CacheProvider value={selectedLanguage === 'en' ? cacheLtr : cacheRtl}>
              <ThemeProvider theme={theme}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                <Router>
                  <div className="App">
                    <Routes>
                      <Route path="/login" element={<Login />} />
                      <Route path="/Document" element={<Document />} />
                      <Route path="/Checks" element={<Checks />} />
                      <Route path="/OtherDocument" element={<OtherDocument />} />
                      <Route path="/client-details" element={<ClientDetails />} />
                      <Route path="/Accountant-details" element={<AccountantDetails />} />
                      <Route path="/Main" element={<Main />} />
                      <Route path="/SubAccountants" element={<SubAccountant />} />
                      <Route path="/ProfitAndLossReport" element={<ProfitAndLossReport />} />
                      <Route path="/SortCodeReport" element={<SortCodeReport />} />
                      <Route path="/TransactionDocuments" element={<TransactionDocuments />} />
                      <Route path="/PaymentAcceptance" element={<PaymentAcceptance />} />
                      <Route path="/ReportTracking" element={<ReportTracking />} />
                      <Route path="/LedgerReport" element={<LedgerReport />} />
                      <Route path="/AccountCardDashboard" element={<AccountCardDashboard />} />
                      <Route path="/AccountCardsTable" element={<AccountCardsTable />} />
                      <Route path="/JournalEntry" element={<JournalEntry />} />
                      <Route path="/BankTransactions" element={<BankMatching />} />
                      <Route path="/AddClient" element={<AddClient />} />
                      <Route path="/CreateInvoice" element={<CreateInvoice />} />
                      <Route path="/ReceiptTax" element={<CreateReceipt />} />
                      <Route path="/CollectionPage" element={<CollectionPage />} />
                      <Route path="/CardMatching" element={<CardMatching />} />

                      {/* <Route path="/Chat" element={<Chat />} /> */}
                      <Route path="/" element={<Login />} />
                      <Route path="*" element={<ErrorPage />} />
                    </Routes>
                  </div>
                </Router>
                {/* </LocalizationProvider> */}
              </ThemeProvider>
            </CacheProvider>
          </StyledEngineProvider>
        </PrimeReactProvider>
      </FileProvider>
    </SignalRProvider>
  );
}

export default App;
