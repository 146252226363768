import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Constants from '../../../assests/Constants/constants';

const AccountantDetailsModals = ({
    isPopupOpen,
    value,
    handleValueChange,
    isEditMode,
    handleSaveChange,
    handleClose,
    contentKey,
    //custome data option
    formattedSortCodesGroupNamesOptions,
    formattedSortCodeVatType,
    formattedPalCodeCategoryOptions,
    formattedPalCodeAccountingGroupOptions
}) => {
    const { i18n, t } = useTranslation();
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'

    console.log(formattedPalCodeAccountingGroupOptions);

    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const customStyles = {
        container: provided => ({
            ...provided,
            width: '100% !important'
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '48px',
            width: '100% !important',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: 'none',
            boxShadow: 'none',
            padding: '0 10px',
            textAlign: 'left',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black'
        }),
        singleValue: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#F9AA2A'
                : state.isFocused
                    ? '#ebebeb'
                    : null,
            color: 'black',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'darkgray' : 'black',
            '&:hover': {
                color: 'darkgray',
            },
        }),
    };

    const contentConfig = {
        OtherDocumentsType: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("Edit OtherDocumentsType") : t("New OtherDocumentsType")}
                </h2>
            ),
            bodyContent: (
                <div>
                    <div className="form-row-item-title">
                        {t('Document Type')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('Document Type')}
                        value={value}
                        onChange={(e) => handleValueChange(e.target.value)}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                    />
                </div>
            ),
        },
        SortingCode: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditSortCode") : t("NewSortCode")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* SortingCode ID */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('SortCode Id')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SortCode Id')}
                            value={value.sortCodeId || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeId: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* SortCode Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('SortCode Name')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SortCode Name')}
                            value={value.sortCodeName || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeName: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* SortCode Group */}
                    <div className="form-row-item profile-full-width" style={{ marginTop: '30px' }}>
                        <div className="form-row-item-title">
                            {t('SelectSortCodeGroupName')}
                        </div>
                        <Select
                            placeholder={t('SelectSortCodeGroupName')}
                            options={formattedSortCodesGroupNamesOptions}
                            isSearchable={true}
                            value={formattedSortCodesGroupNamesOptions.find(item => item.value === value.sortCodeGroup) || null}
                            onChange={(selectedOption) => handleValueChange({ ...value, sortCodeGroup: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>

                    {/* SortCode VAT Type */}
                    <div className="form-row-item profile-full-width" style={{ marginTop: '30px' }}>
                        <div className="form-row-item-title">
                            {t('SelectSortCodeVatType')}<span className="required-asterisk">*</span>
                        </div>
                        <Select
                            placeholder={t('SelectSortCodeVatType')}
                            options={formattedSortCodeVatType}
                            isSearchable={true}
                            value={formattedSortCodeVatType.find(item => item.value === value.sortCodeVatType) || null}
                            onChange={(selectedOption) => handleValueChange({ ...value, sortCodeVatType: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                    {/* Debit Account */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('Debit Account')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('Debit Account')}
                            value={value.sortCodeDebit || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeDebit: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>

                    {/* Credit Account */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('Credit Account')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('Credit Account')}
                            value={value.sortCodeCredit || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeCredit: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>




                </div>
            ),
        },
        SortingCodeGroup: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditSortCodeGroup") : t("AddSortCodeGroupName")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* SortCode Group Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('sortCodeGroupName')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('sortCodeGroupName')}
                            value={value.sortCodeGroupName || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeGroupName: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* SortCode Group List Number */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('SortCodeGroupListNumber')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SortCodeGroupListNumber')}
                            value={value.sortCodeGroupListNumber || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeGroupListNumber: e.target.value })}
                            variant="standard"
                            type="number"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Profit Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('ProfitName')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('ProfitName')}
                            value={value.profitName || ''}
                            onChange={(e) => handleValueChange({ ...value, profitName: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                </div>
            ),
        },
        PalCode: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditBalanceClassification") : t("NewBalanceClassification")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* Balance Classification Type */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('BalanceClassificationType')}
                        </div>
                        <Select
                            placeholder={t('BalanceClassificationType')}
                            options={Constants.PalCodeType.map(type => ({
                                value: type,
                                label: t(type),
                            }))}
                            value={
                                Constants.PalCodeType.map(type => ({
                                    value: type,
                                    label: t(type),
                                })).find(option => option.value === value.palCodeType) || null
                            }
                            onChange={(selectedOption) => handleValueChange({ ...value, palCodeType: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                    {/* Balance Classification Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('BalanceClassificationName')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('BalanceClassificationName')}
                            value={value.palCodeId || ''}
                            onChange={(e) => handleValueChange({ ...value, palCodeId: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Balance Classification Category */}
                    <div className="form-row-item profile-full-width" style={{ marginTop: '30px' }}>
                        <div className="form-row-item-title">
                            {t('SelectBalanceClassificationCategory')}
                        </div>
                        <Select
                            placeholder={t('SelectBalanceClassificationCategory')}
                            options={formattedPalCodeCategoryOptions.map((item) => ({
                                name: item.palCodeCategoryId,
                                label: item.palCodeCategoryId,
                                value: item.palCodeCategoryId,
                            }))}
                            isSearchable={true}
                            value={formattedPalCodeCategoryOptions.map((item) => ({
                                name: item.palCodeCategoryId,
                                label: item.palCodeCategoryId,
                                value: item.palCodeCategoryId,
                            })).find(item => item.value === value.palCodeCategory) || null}
                            onChange={(selectedOption) => handleValueChange({ ...value, palCodeCategory: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                </div>
            ),
        },
        PalCodeCategory: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditBalanceItem") : t("NewBalanceItem")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* Balance Classification Category */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('BalanceClassificationCategory')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('BalanceClassificationCategory')}
                            value={value.palCodeCategoryId || ''}
                            onChange={(e) => handleValueChange({ ...value, palCodeCategoryId: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Serial Number */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('SerialNumber')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SerialNumber')}
                            value={value.orderNumber || ''}
                            onChange={(e) => handleValueChange({ ...value, orderNumber: e.target.value })}
                            variant="standard"
                            type="number"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Accounting Group */}
                    <div className="form-row-item profile-full-width" style={{ marginTop: '30px' }}>
                        <div className="form-row-item-title">
                            {t('SelectAccountingGroup')}
                        </div>
                        <Select
                            placeholder={t('SelectAccountingGroup')}
                            options={formattedPalCodeAccountingGroupOptions.map((item) => ({
                                name: item.accountingGroupName,
                                label: item.accountingGroupName,
                                value: item.accountingGroupName,
                            }))}
                            isSearchable={true}
                            value={formattedPalCodeAccountingGroupOptions.map((item) => ({
                                name: item.accountingGroupName,
                                label: item.accountingGroupName,
                                value: item.accountingGroupName,
                            })).find(item => item.value === value.accountingGroup) || null}
                            onChange={(selectedOption) => handleValueChange({ ...value, accountingGroup: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                </div>
            ),
        },
        AccountingGroup: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditAccountingGroup") : t("NewAccountingGroup")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* Attribute */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('Attribute')}
                        </div>
                        <Select
                            placeholder={t('Attribute')}
                            options={Constants.PalCodeClassification.map(type => ({
                                value: type,
                                label: t(type),
                            }))}
                            value={
                                Constants.PalCodeClassification.map(type => ({
                                    value: type,
                                    label: t(type),
                                })).find(option => option.value === value.accountingGroupClassifaction) || null
                            }
                            onChange={(selectedOption) => handleValueChange({ ...value, accountingGroupClassifaction: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                    {/* Accounting Group Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('AccountingGroup')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('AccountingGroup')}
                            value={value.accountingGroupName || ''}
                            onChange={(e) => handleValueChange({ ...value, accountingGroupName: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Serial Number */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('SerialNumber')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SerialNumber')}
                            value={value.orderNumber || ''}
                            onChange={(e) => handleValueChange({ ...value, orderNumber: e.target.value })}
                            variant="standard"
                            type="number"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                </div>
            ),
        },
    };

    const { headerContent, bodyContent } = contentConfig[contentKey] || {};

    if (!isPopupOpen) return null;

    return (
        <div className="sub-popup-hamburger-overlay" onClick={handleClose}>
            <div className="sub-add-client__container" onClick={(e) => e.stopPropagation()}>
                <div className={`sub-add-client-content ${direction}`}>
                    {/* Dynamic Header Content */}
                    <div className="title-and-buttons">
                        {headerContent}
                    </div>

                    {/* Dynamic Body Content */}
                    <div className="form-row-item subAcc-full-width">
                        {typeof bodyContent === 'function' ? bodyContent({ value, handleValueChange, direction }) : bodyContent}
                    </div>

                    {/* Buttons */}
                    <div className="sub-button-container">
                        <button className="sub-close-subAcc-content-button" onClick={handleClose}>
                            {t('close')}
                        </button>
                        <button
                            className="sub-add-subAcc-content-button"
                            onClick={handleSaveChange}
                        >
                            {t('save')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountantDetailsModals;
