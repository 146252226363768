import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import DocumentService from '../../Services/documents.service';
import UserService from '../../Services/user.service';
import PuffLoader from "react-spinners/PuffLoader";
import '../Document/Document.css';
import './JournalEntry.css';
import backIcon from '../../assests/images/left-arrow.png';
import calendarIcon from '../../assests/images/calendar.png';
import EnumsService from '../../Services/enums.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal'
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from "../../assests/images/downloadIcon.png";
import NoDocumentsPdf from "../../assests/pdf/NoDocument.pdf";
import { useSignalR } from '../../Contexts/signalR';
import SignaturePad from 'react-signature-canvas';
import ClientDocumentCountService from '../../Services/ClientDocumentCount.service';
import saveIcon from "../../assests/images/save.png";
import cancelIcon from "../../assests/images/cancel.png";
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import ClientService from '../../Services/client.service';
import addIcon from "../../assests/images/plusO.png";
import loadingAnimation from "../../assests/images/Animation.gif";
import DraggableModal from '../DraggableModal/DraggableModal';
import DenseTable from '../DenseTable/DenseTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save'; // Save icon
import ClearIcon from '@mui/icons-material/Clear';
// MUI imports
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, Download as DownloadIconMui, Settings } from '@mui/icons-material';
import {
    Button, Dialog, DialogTitle, DialogContent, Box, DialogActions, Typography, TextField, Checkbox,
    InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    ToggleButton, ToggleButtonGroup, Switch, CircularProgress, IconButton
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import EditModal from '../EditModal/EditModal';
import CustomMonthDatePicker from '../CustomMonthDatePicker/CustomMonthDatePicker';
import { beautifyNumber } from '../../Utils/FormatNumber';
import DateInput from '../DateInput/DateInput';
import Constants from '../../assests/Constants/constants';
import styled from '@mui/system/styled';
import zIndex from '@mui/material/styles/zIndex';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import JournalEntryDataGrid from './SubComponents/JournalEntryDataGrid';
import JournalEntryDraggableDialog from './SubComponents/JournalEntryDraggableDialog';
const StyledDialogTitle = styled(DialogTitle)({
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#025366',
});

const StyledDialogContent = styled(DialogContent)({
    textAlign: 'center',
    padding: '20px 30px',
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: 'center',
    paddingBottom: '20px',
});

const StyledButton = styled(Button)({
    margin: '0 8px',
    padding: '10px 20px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#E57C22',
    '&:hover': {
        backgroundColor: '#d9731a',
    },
});


const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
        ...theme.applyStyles('dark', {
            backgroundColor: '#30404d',
        }),
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        ...theme.applyStyles('dark', {
            background: 'rgba(57,75,89,.5)',
        }),
    },
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
        backgroundColor: '#394b59',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
    return (
        <Checkbox
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

const OptionButton = styled(Button)({
    margin: '10px 8px',
    padding: '12px 25px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#025366',
    backgroundColor: '#f5f5f5',
    border: '1px solid #E57C22',
    width: 'auto',  // Allows the button to shrink when there are many buttons
    maxWidth: '200px',  // Set a maximum width to ensure consistency
    flexGrow: 1,  // Allows buttons to fill available space equally
    '&:hover': {
        backgroundColor: '#E57C22',
        color: '#fff',
    },
});

const ActionButton = styled(Button)({
    backgroundColor: '#304FFF', // Consistent background with other elements
    borderRadius: 4, // Same border-radius as MUI TextField and Switch
    color: 'white',
    height: '55px', // Same height as other input elements
    padding: '0 16px', // Consistent padding
    fontSize: '0.875rem', // Matching font size with TextField
    textTransform: 'none',
    boxSizing: 'border-box',
    '&:hover': {
        backgroundColor: '#F9AA2A', // Slightly darker on hover
    },
    '&:disabled': {
        backgroundColor: '#E0E0E0',
        color: '#BDBDBD',
    },
});
setDefaultLocale('en-GB'); // Set the default locale to use it
const headers = ['Dessert (100g serving)', 'Calories', 'Fat (g)'];

const rows = [
    { name: 'Frozen yoghurt', calories: 159, fat: 6.0 },

];





function JournalEntry() {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);
    //invoice modal for both invoices
    const [chooseInvoiceModal, setChooseInvoiceModal] = useState(false);
    const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
    const [invoiceTypeInModal, setInvoiceTypeInModal] = useState(false);



    const [signatureReferenceInvoiceModalVisible, setSignatureReferenceInvoiceModalVisible] = useState(false);
    const [currentInvoiceCreatingType, setCurrentInvoiceCreatingType] = useState('');
    const [documentDate, setDocumentDate] = useState(new Date());
    const [reportingMonthDateCreate, setReportingMonthDateCreate] = useState(new Date());
    const [documentCost, setDocumentCost] = useState('');
    const [documentVat, setDocumentVat] = useState(100);
    const [documentReference, setDocumentReference] = useState('');
    const [sortCodeId, setCodeId] = useState('');
    const [accountCodeName, setAccountCodeName] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [accountCodeId, setAccountCodeId] = useState('');
    const [description, setDescription] = useState('');
    const [documents, setDocuments] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [okMessage, setOkMessage] = useState('');
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [focused, setFocused] = useState(false);
    const [alignment, setAlignment] = useState('web');
    const dataGridRef = useRef();
    const modalCreateDocumentRef = useRef(); // Reference to the modal content
    const modalInvoiceCreateDocumentRef = useRef();
    const signatureReferenceInvoiceModalVisibleRef = useRef();
    const [sortCodeIdOptions, setSortCodeIdOptions] = useState([]);
    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] = useState([]);
    const [clientItems, setClientItems] = useState([]);
    const [formattedPalCodeOptions, setFormattedPalCodeOptions] = useState([]);

    const [creditAccountOptions, setCreditAccountOptions] = useState([]);
    const [debitAccountOptions, setDebitAccountOptions] = useState([]);
    const [creditAccountOption, setCreditAccountOption] = useState('');
    const [debitAccountOption, setDebitAccountOption] = useState('');
    const location = useLocation();
    const [editingRow, setEditingRow] = useState(null);
    const [editedItem, setEditedItem] = useState({});
    const [oldAccountName, setOldAccountName] = useState({});
    const [oldAccountCaseId, setOldAccountCaseId] = useState({});
    const [oldAccountCardId, setOldAccountCardId] = useState({});
    const [editedPopupItem, setEditedPopupItem] = useState(true);
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [originalAccountCardId, setOriginalAccountCardId] = useState('');
    const [sortDate, setSortDate] = useState(() => {
        const storedDateString = localStorage.getItem('Document' + location.state.type + '_Date');
        return storedDateString ? new Date(storedDateString) : new Date();
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');
    const [currentIndex, setCurrentIndex] = useState(-1);

    const [startDate, setStartDate] = useState(() => {
        const storedDateString = localStorage.getItem('Document' + location.state.type + '_StartDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // Start date for range
    const [endDate, setEndDate] = useState(() => {
        const storedDateString = localStorage.getItem('Document' + location.state.type + '_EndDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // End date for range


    const [isRange, setIsRange] = useState(() => {
        const storedIsRange = localStorage.getItem('Document' + location.state.type + '_IsRange');
        return storedIsRange === "true";
    });;
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadStartDate, setDownloadStartDate] = useState(null); // Start date for range
    const [downloadEndDate, setDownloadEndDate] = useState(null);
    const [downloadAmount, setDownloadAmount] = useState();
    const [downloadAccountCodeId, setDownloadAccountCodeId] = useState([]);
    const [isDateEnabled, setIsDateEnabled] = useState(false);
    const { documentUpdate } = useSignalR();
    const [statusCheck, setStatusCheck] = useState(false);
    const [isClosedReportingMonth, setIsClosedReportingMonth] = useState(false);
    const [isFirstUserFetched, setIsFirstUserFetched] = useState(false);
    const [actionType, setActionType] = useState('delete');
    // invoice Details // 
    const sigPad = useRef({});
    const [invoiceDescription, setInvoiceDescription] = useState('');
    const [invoiceReference, setInvoiceReference] = useState('');
    const [invoiceClientName, setInvoiceClientName] = useState('');
    const [invoiceClientCaseId, setInvoiceClientCaseId] = useState('');
    const [invoiceClientLocation, setInvoiceClientLocation] = useState('');
    const [invoiceClientEmail, setInvoiceClientEmail] = useState('');
    const [invoiceClientPhoneNumber, setInvoiceClientPhoneNumber] = useState('');
    const [invoiceSignature, setInvoiceSignature] = useState('');
    const [invoiceAccountCodeId, setInvoiceAccountCodeId] = useState('');
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [addNewItem, setAddNewItem] = useState(false);
    const [addNewAccountCard, setAddNewAccountCard] = useState(false);
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [addNewSignatureReference, setAddNewSignatureReference] = useState(false);
    const [newItems, setNewItems] = useState([]); // To store new items
    const [addItemFlags, setAddItemFlags] = useState([]); // Array of booleans to track add new item mode per index
    const [priceIncludingVAT, setPriceIncludingVAT] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'uploadNumber', direction: 'descending', type: 'number' });
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs(sortDate));
    const [autoFilling, setAutoFilling] = useState(true);
    const [fade, setFade] = useState(false);
    const [denseTableHeader, setDenseTableHeader] = useState([]);
    const [denseTableRows, setDenseTableRows] = useState([]);
    const [denseTableCreditDebitHeader, setDenseTableCreditDebitHeader] = useState([]);
    const [denseTableCreditDebitRows, setDenseTableCreditDebitRows] = useState([]);
    const handleSignatureChange = () => {
        if (sigPad.current) {
            const signatureImage = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            setInvoiceSignature(signatureImage);
        }
    };
    // const [toastPosition, setToastPosition] = useState('')
    const [selectedRows, setSelectedRows] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [addNewJournalEntry, setAddNewJournalEntry] = useState(false);
    const [addItem, setAddItem] = useState({
        accountCodeName: '',       // Account code name
        accountCaseId: '',         // Account case ID
        accountCodeId: '',         // Account case ID
        reference: '',             // Reference field
        description: '',           // Description of the entry
        uploadDate: '',            // Upload date (formatted)
        documentDate: new Date(),          // Document date (formatted)
        amount: '',                 // Amount (numeric)
        vat: 0,                    // VAT percentage
        creditAccount: '',         // Credit account
        debitAccount: '',          // Debit account
        sortCodeId: '',            // Sort code ID
        processed: false,          // Processed/unprocessed status
    });
    const [currentRowData, setCurrentRowData] = useState({});
    const [openInlineEditModal, setOpenInlineEditModal] = useState(false);
    const [isAddMode, setIsAddMode] = useState(false);
    const handleDialogClose = () => {
        setOpenInlineEditModal(false);
        setCurrentRowData(null);
        setIsAddMode(false);
        setAddNewJournalEntry(false);
    };
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'

    const handleDialogSave = (formData) => {
        console.log(formData);

        if (isAddMode) {
            handleNormalSave(formData);
        } else {
            handleUpdate(formData);
        }
    };


    const handleOpenDialog = (formData, isAddMode) => {
        setCurrentRowData(formData);
        setIsAddMode(isAddMode);
        setOpenInlineEditModal(true);
    }

    useEffect(() => {
        if (addNewJournalEntry) {
            handleOpenDialog({}, true)
        }
    }, [addNewJournalEntry]);

    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);
    const setOpenOption = () => setOpenOptions(true);
    const closeOptions = () => {
        setOpenOptions(false);
        handleDeselect(selectedRows);
        setSelectedRows([]); // Unselect all rows
        setIsAllSelected(false); // Uncheck the "Select all" checkbox
    };
    const isFormValid = Boolean(addItem.amount);

    const handleInputChange = (field, value) => {

        if (field === 'accountCodeName') {
            setAddItem(prevState => ({
                ...prevState,
                accountCodeName: value.value,
                accountCaseId: value.accountCaseId,
                accountCodeId: value.value1
            }));

        } else {
            setAddItem(prevState => ({
                ...prevState,
                [field]: value
            }));
        }


    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;



    const handleSelectRow = (event, item) => {
        const selectedIndex = selectedRows.findIndex(selected => selected.id === item.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);
    };


    const handleSelectAllClick = (event) => {
        const checked = event.target.checked;

        setIsAllSelected(checked);

        if (checked) {
            // Filter items based on the condition before selecting
            const newSelecteds = filteredAndSortedData
                .filter((item) => {
                    // Apply the same condition used for displaying checkboxes
                    const condition = client.accountManagement === Constants.AccountManagementOptions[0].name
                        ? item.sortCodeId && item.creditAccount && item.debitAccount
                        : item.sortCodeId;
                    return condition;
                })
                .map((item) => item.id);
            setSelectedRows(newSelecteds);
            setAllSelected(true);
        } else {
            setSelectedRows([]);
            setAllSelected(false);
        }
    };

    const handleOptionSelect = async (option) => {

        if (selectedRows && selectedRows.length > 0) {
            if (option === 'done') {
                await handleDoneIdsClick(selectedRows);
            } else if (option === 'notDone') {
                await handleNotDoneIdsClick(selectedRows);
            }
        }

        closeOptions();
    };


    useEffect(() => {
        if (currentIndex != -1) {
            setFade(true);
            const timer = setTimeout(() => setFade(false), 500);
            return () => clearTimeout(timer);
        }
    }, [currentIndex]);






    // Update an existing item
    const handleItemChange = (index, field, value) => {
        if (addItemFlags[index]) {
            setNewItems(prevNewItems => {
                const newItemsCopy = [...prevNewItems];
                newItemsCopy[index] = {
                    ...newItemsCopy[index],
                    [field]: value,
                    caseId: client.caseId // Assuming caseId is available here
                };
                return newItemsCopy;
            });
        }
        if (field === "discount" && (parseInt(value) > 100 || parseInt(value) < 0)) {
            return;
        }
        setInvoiceItems(prevItems => {
            const updatedItems = prevItems.map((item, idx) => idx === index ? { ...item, [field]: value } : item);
            return updatedItems;
        });
    };


    // Remove an item
    const handleRemoveItem = (index) => {
        const newItems = invoiceItems.filter((_, idx) => idx !== index);
        setInvoiceItems(newItems);
    };

    const handleListItemClick = (month) => {
        const newDate = sortDate.month(month - 1);  // month is 0-indexed in JavaScript
        setSelectedDate(newDate);
        setSortDate(newDate);
        setOpen(false);
    };

    const CloseReportingMonth = async (status) => {
        setLoading(true);
        try {
            const response = await ClientService.UpdateReportingMonth(client.caseId, sortDate, status, token);
            if (response.ok) {
                toast.success(
                    status ? t('MonthReportClosedSuccess') : t('MonthReportOpenedSuccess'));
                setIsClosedReportingMonth(status)
            } else {
                const errorText = await response.text();
                console.error(t('MonthReportNotClosed'), errorText);
                toast.error(
                    status ? `${t('MonthReportNotClosed')}: ${errorText}` : `${t('MonthReportNotOpened')}: ${errorText}`,
                );
            }
        } catch (error) {
            console.error(t('MonthReportNotClosed'), error);
            toast.error(status ? `${t('MonthReportNotClosed')}: ${error}` : `${t('MonthReportNotOpened')}: ${error}`,
            );
        } finally {
            setLoading(false); // Stop loading regardless of the outcome
            setIsModalVisible(false)
        }
    };

    const handleDeleteClick = (itemId) => {
        setItemIdToDelete(itemId);
        setIsModalVisible(true);
    };

    const handleEditDeleteClick = (itemId) => {
        setEditedPopupItem(false);
        setEditedItem({});
        handleDeleteClick(itemId);
    };

    const handleDoneClick = async (itemId) => {
        setLoading(true);

        try {
            const response = await ClientDocumentCountService.removeDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleNotDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleDoneIdsClick = async (itemIds) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.removeDocumentsByTypeAndIds(client.caseId, client.accountantId, type, itemIds, token);
            if (response.ok) {
                await fetchDocumentsNotification(client, token);
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentsNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentsNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleNotDoneIdsClick = async (itemIds) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentsByTypeAndIds(client.caseId, client.accountantId, type, itemIds, token);
            if (response.ok) {
                await fetchDocumentsNotification(client, token);
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentsNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentsNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleDeselect = (ids) => {
        if (dataGridRef.current) {
            dataGridRef.current.deselectRows(ids); // Call deselectRow on the ref
        }
    };
    const handleStartDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('Document' + location.state.type + '_StartDate', newDate)
        setStartDate(newDate);
        if (isRange) {
            await fetchDataOnDateRangeChange(new Date(newDate), new Date(endDate));
        }
    };

    const handleEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('Document' + location.state.type + '_EndDate', endDate)
        setEndDate(endDate);
        if (isRange) {
            await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate));
        }
    };

    const handleDownloadStartDateChange = (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setDownloadStartDate(newDate);
    };

    const handleDownloadEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setDownloadEndDate(endDate);
    };


    async function fetchDataOnDateRangeChange(startDate, endDate) {

        setLoading(true)
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        try {
            const response = await DocumentService.GetDocumentbyDateRange(type, client.caseId, formattedStartDate, formattedEndDate, token);
            // let purchaseOfEquipmentExpensesVatResponse;
            if (response.ok) {

                const data = await response.json(); // Parse the JSON in the response

                data.forEach(item => {
                    item.vatAmount = item.vat === 16 ? (item?.amount / 1.16) * 0.17 : ((item?.amount / 1.17) * 0.17 * (item?.vat / 100));
                    item.netAmount = item?.amount - item.vatAmount;
                });
                setData(data);
                extractVatByType(data);
                // toast.success(t('documentFetchSuccess'), {
                //     position: "top-left"
                // });
            } else {
                const errorText = await response.text();
                console.error(errorText);
                toast.error(t('documentFetchError', { errorText }));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('documentFetchError', { errorText: error.message }));
        }

        setLoading(false)
    }

    const handleConfirm = async () => {
        if (actionType === 'delete') {
            await handleConfirmDelete();
        } else if (actionType === 'closeMonth') {
            await CloseReportingMonth(true);
        } else if (actionType === 'openMonth') {
            await CloseReportingMonth(false);
        }
    };

    const getConfirmMessage = () => {
        switch (actionType) {
            case 'closeMonth':
                return t('AreYouSureCloseMonth');
            case 'openMonth':
                return t('AreYouSureOpenMonth');
            default:
                return t('confirmDeleteMessage');

        }
    };

    const getTitleMessage = () => {
        switch (actionType) {

            case 'closeMonth':
                return t('ConfirmCloseMonth');
            case 'openMonth':
                return t('ConfirmOpenMonth');
            default:
                return t('confirmDeleteTitle');
        }
    };

    const handleConfirmDelete = async () => {
        // Perform delete operation
        setLoading(true);
        setIsModalVisible(false);
        try {
            const response = await DocumentService.DeleteDocument(type, itemIdToDelete, token);
            if (response.ok) {
                if (isRange) {
                    await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate))
                }
                else
                    await fetchDocuments(new Date(sortDate));

                // Show success toast
                toast.success(t("documentDeleteSuccess"));
            } else {
                // Handle any errors or display an error toast
                const errorResponse = await response.json();
                console.error('Error deleting document:', errorResponse);

                // Show error toast
                toast.error(t("documentDeleteError"));
            }
        } catch (error) {
            console.error('Error deleting document:', error);

            // Show error toast
            toast.error(t("documentDeleteError"));
        }
        setLoading(false);
    };


    // const handleEdit = (item, isPopup = true) => {
    //     setEditingRow(item.id);
    //     setEditedItem({ ...item }); // create a copy of the item for editing
    //     let statusChecked = type == "Incomes" ? client.documentIds.incomes.includes(item.id) : client.documentIds.expenses.includes(item.id);
    //     setStatusCheck(!statusChecked);
    //     setEditedPopupItem(isPopup);

    // };

    const handleEdit = (item, index, isPopup = true) => {
        setEditingRow(item.id);
        item.docType = type;
        console.log(item.docType);

        setEditedItem({ ...item });
        setCurrentIndex(index);
        let statusChecked = client.documentIds.journalEntries.includes(item.id);
        setStatusCheck(!statusChecked);
        setEditedPopupItem(isPopup);
    };

    const handleReopenEdit = async (item, index) => {
        await fetchEnums(user, token, client);
        handleEdit(item, index);
    };


    const handlePrevious = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
            let statusChecked = client.documentIds.journalEntries.includes(filteredAndSortedData[newIndex].id);
            setStatusCheck(!statusChecked);
            filteredAndSortedData[newIndex].docType = type;
            setEditedItem({ ...filteredAndSortedData[newIndex] });

        }
    };

    const handleNext = () => {
        if (currentIndex < filteredAndSortedData.length - 1) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            let statusChecked = client.documentIds.journalEntries.includes(filteredAndSortedData[newIndex].id);
            setStatusCheck(!statusChecked);
            filteredAndSortedData[newIndex].docType = type;
            setEditedItem({ ...filteredAndSortedData[newIndex] });
        }
    };

    const handleToggleButtonChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const handleChange = (field, value) => {
        const updatedValue = value;
        console.log(field, value);

        if (field === 'sortCodeId') {

            let newVat = 100;
            const name = sortCodeIdOptions.find(
                (option) => option.sortCodeId === value
            )?.sortCodeName

            // Extract the name without the parentheses
            const nameWithoutParentheses = name.replace(/\s*\(.*?\)\s*/, '').trim();

            // Check for a number within parentheses
            const match = name.match(/\((\d+)%?/);
            let customVat = match ? parseInt(match[1], 10) : null;
            console.log(customVat);

            const sortCode = sortCodeIdOptions.filter(sc => sc.sortCodeId === updatedValue)[0];

            // Determine the VAT based on the name without parentheses
            if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
                newVat = 66;
            } else if (Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) || sortCode.sortCodeVatType === 0) {
                newVat = 0;

            } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
                newVat = 16;
            } else if (customVat !== null) {
                // If a custom VAT was found, use it
                newVat = customVat === 66 ? 66 : customVat;
            } else {
                // Default VAT if no special conditions are met
                newVat = 100;
            }

            setEditedItem({
                ...editedItem,
                vat: newVat,
                [field]: updatedValue,
                creditAccount: editedItem.creditAccount ? sortCode.creditAccount || editedItem.creditAccount : editedItem.creditAccount,
                debitAccount: editedItem.debitAccount ? sortCode.debitAccount || editedItem.debitAccount : editedItem.debitAccount,
            });

        } else {
            // For fields other than sortCodeId, just update the edited item

            setEditedItem({
                ...editedItem,
                [field]: updatedValue,
            });
        }
    };


    const handleRadioChange = (e) => {

        if (e.target.value?.toString() === 'done') {
            if (client.accountManagement === Constants.AccountManagementOptions[0].name && (!editedItem.sortCodeId || !editedItem.creditAccount || !editedItem.debitAccount)) { /// means "כפולה"
                toast.info(t('DocumentMustHaveSortCodeCreditDebitAccountToMarkAsProcessed'));
                return
            }
            else if (!editedItem.sortCodeId) {
                toast.info(t('DocumentMustHaveSortCodeToMarkAsProcessed'));
                return
            }
            setStatusCheck(true);
        }
        if (e.target.value?.toString() === 'notDone') {
            setStatusCheck(false);
        }
        ;
        setEditedItem({ ...editedItem, ["status"]: e.target.value });
    };

    const handleVatRadioChange = (e) => {
        setEditedItem({ ...editedItem, ["vat"]: parseFloat(e.target.value) });
    };




    const handleDateChange = (e, field) => {
        setEditedItem({ ...editedItem, [field]: e });
    };
    const setReportingMonthDate = async (selectedDate, field) => {
        if (!selectedDate) {
            return;
        }
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
        if (response1.ok) {
            const isClosedReportingMonth = await response1.json();
            if (isClosedReportingMonth) {
                toast.info(t('MonthReportAlreadyClosedTryAgain'));
                return
            }
            else {
                if (modalVisible) {
                    setReportingMonthDateCreate(newDate);
                }
                else {

                    setEditedItem({ ...editedItem, [field]: newDate });
                }
            }
        }
    }

    const handleChangeNew = (selectedOption) => {
        if (selectedOption.value1 === "AddItem") {
            setCreditAccountOption("");
            setDebitAccountOption("");
            setAccountCodeName("");
            setAccountCodeId("");
            setCompanyId("");
            setAddNewAccountCardDocument(true);
        }
        else {
            setAccountCodeName(selectedOption.value);
            setCompanyId(selectedOption.accountCaseId);
            const accountCard = accountCardOptions.find(option => option.name === selectedOption.value);
            setAccountCodeId(accountCard.cardId);
            type === 'Incomes' ? setDebitAccountOption(accountCard.cardId) : setCreditAccountOption(accountCard.cardId)
        }
    };

    const handleInvoiceAccount = (selectedOption) => {
        if (selectedOption.value1 === 'AddItem') {
            setAddNewAccountCard(true);
        } else {
            setInvoiceClientName(selectedOption.value);
            const accountCard = accountCardOptions.find(option => option.name === selectedOption.value);
            setInvoiceAccountCodeId(accountCard.cardId);
        }
    };

    const handleInvoiceItem = (index, selectedOption) => {
        if (selectedOption.value === 'add-item') {
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = true;
            setAddItemFlags(updatedFlags);
        } else {
            const accountCard = clientItems.find(option => option.makat === selectedOption.value);
            handleItemChange(index, 'makat', accountCard.makat);
            handleItemChange(index, 'name', accountCard.name);
            // Reset flag in case it was set
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = false;
            setAddItemFlags(updatedFlags);
        }
    };


    const handleUpdate = async (editedItem) => {

        setLoading(true);
        try {
            const response = await DocumentService.UpdateDocument(type, token, editedItem);
            if (editedItem.status == 'done') {
                await handleDoneClick(editedItem.id);
                // handleSortChange('id', 'newest', 'descending');
            }
            else if (editedItem.status == 'notDone') {
                await handleNotDoneClick(editedItem.id)
            }

            if (editedAddAccountCard) {

                await handleAccountCardSave(editedItem.accountCodeId, editedItem.accountCodeName, editedItem.accountCaseId);
                await fetchEnums(user, token, client);

            }

            if (response.ok) {

                if (isRange) {
                    await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate))
                }
                else
                    await fetchDocuments(new Date(sortDate));
                // Show success toast
                toast.success(t("documentUpdateSuccess"));
                handleCancel();
            } else {

                const errorText = await response.text();
                // Handle error

                // Show error toast
                console.error(errorText);

                toast.error(t("documentUpdateError"));
            }
        } catch (error) {
            // Handle error

            // Show error toast
            setEditingRow(null);
            setEditedItem({});
            toast.error(t("documentUpdateError"));
        }
        // setEditingRow(null);
        // setEditedItem({});
        setEditedAddAccountCard(false);
        setLoading(false);
        // Refresh or update your data list
    };


    const handleCancel = () => {
        setEditedPopupItem(false);
        setEditingRow(null);
        setEditedItem({});
        setEditedAddAccountCard(false);
    };


    const handleDocumentUpload = (event) => {
        setDocuments(Array.from(event.target.files));
    };

    const formatDate = dateString => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const formatDateNew = dateString => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatMonthYearDate = dateString => {
        const date = new Date(dateString);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${month}/${year}`;
    };

    const closeCreateModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (modalCreateDocumentRef.current && !modalCreateDocumentRef.current.contains(e.target)) {
            handleClose();
        }
    }

    const closeCreateInvoiceModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (modalInvoiceCreateDocumentRef.current && !modalInvoiceCreateDocumentRef.current.contains(e.target)) {
            handleInvoiceClose();
        }
    }
    const closeSignatureInvoiceModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (signatureReferenceInvoiceModalVisibleRef.current && !signatureReferenceInvoiceModalVisibleRef.current.contains(e.target)) {
            handleSignatureReferenceClose();
        }
    }
    const handleClose = async () => {
        setModalVisible(false);
        setErrorMessage('');
        setOkMessage('');
        setDocumentCost("");
        setDocumentReference("")
        setCodeId("")
        setAccountCodeName("")
        setCreditAccountOption("")
        setDebitAccountOption("")
        setDescription("")
    }

    const handleInvoiceClose = async () => {
        setInvoiceDescription('');
        setInvoiceClientName('');
        setInvoiceClientCaseId('');
        setInvoiceClientLocation('');
        setInvoiceClientEmail('');
        setInvoiceAccountCodeId('');
        setInvoiceItems([]);
        setInvoiceDate(new Date());
        setInvoiceSignature('');
        setInvoiceReference('');
        setInvoiceClientPhoneNumber('');
        setAddNewAccountCard('');
        setPriceIncludingVAT(false);
        setInvoiceModalVisible(false);
        setChooseInvoiceModal(false);
    }

    const handleSignatureReferenceClose = async () => {
        setInvoiceSignature('');
        setInvoiceReference('');
        setSignatureReferenceInvoiceModalVisible(false);
    }

    const handleSignatureReferenceSave = async () => {
        setSignatureReferenceInvoiceModalVisible(false)
        setAddNewSignatureReference(true);
        setInvoiceModalVisible(true);
        setChooseInvoiceModal(true);
    }
    // const allFieldsFilled = sortCodeId && documentReference && documentCost && documentDate && creditAccountOption && debitAccountOption && accountCodeName && accountCodeId && description && photo
    const allFieldsFilled = (!autoFilling && documentCost) || (autoFilling && documents.length > 0);
    const signatureReferenceFilled = invoiceSignature && invoiceReference;
    const allClientItemFieldsFilled = invoiceClientName && invoiceClientCaseId && invoiceItems && invoiceItems.length > 0 && invoiceItems.every(item => {
        const hasRequiredFields = item.name && item.makat && item.price && item.discount >= 0; // Check if name, makat, and price are provided
        item.quantity = item.quantity || 1; // Default quantity to 1 if not provided
        item.discount = item.discount || 0; // Default discount to 1 if not provided
        return hasRequiredFields;
    });

    const handleSave = async () => {
        handleNormalSave();
    };

    const handleCancelAddJournalEntries = () => {
        setAddNewJournalEntry(false);
        setAddItem({
            accountCodeName: '',       // Account code name
            accountCaseId: '',         // Account case ID
            accountCodeId: '',         // Account case ID
            reference: '',             // Reference field
            description: '',           // Description of the entry
            uploadDate: '',            // Upload date (formatted)
            documentDate: new Date(),          // Document date (formatted)
            amount: 0,                 // Amount (numeric)
            vat: 0,                    // VAT percentage
            creditAccount: '',         // Credit account
            debitAccount: '',          // Debit account
            sortCodeId: '',            // Sort code ID
            processed: false,          // Processed/unprocessed status
        });
    };
    const handleNormalSave = async (addItem) => {


        // Validate the amount field or other required fields
        if (addItem.income === null) {
            setErrorMessage(t('errorFieldsNotFilled'));
            return;
        }

        setLoading(true);

        // Prepare JSON payload
        const payload = {
            caseId: client.caseId,
            accountantId: client.accountantId,
            sortCodeId: addItem.sortCodeId,
            reference: addItem.reference,
            description: addItem.description,
            amount: addItem.amount,
            documentDate: new Date(documentDate).toISOString(),
            reportingMonthDate: new Date(sortDate).toISOString(),
            creditAccount: addItem.creditAccount,
            debitAccount: addItem.debitAccount,
            vat: addItem.vat,
            confirmationNumber: addItem.confirmationNumber,
            accountCaseId: addItem.accountCaseId,
            // Add any other necessary fields here
        };

        try {
            const response = await DocumentService.UploadDocumentPayload(type, payload, token);

            if (response.ok) {
                if (addNewAccountCardDocument) {
                    await handleAccountCardSave(accountCodeId, accountCodeName);
                    await fetchEnums(user, token, client);
                }
                toast.success(t('documentUploadSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentUploadError', { errorText: 'Error uploading document' }));
        }

        setAddNewJournalEntry(false);
        setAddItem({
            accountCodeName: '',       // Account code name
            accountCaseId: '',         // Account case ID
            reference: '',             // Reference field
            description: '',           // Description of the entry
            uploadDate: '',            // Upload date (formatted)
            documentDate: new Date(),          // Document date (formatted)
            amount: '',                 // Amount (numeric)
            vat: 0,                    // VAT percentage
            creditAccount: '',         // Credit account
            debitAccount: '',          // Debit account
            sortCodeId: '',            // Sort code ID
            processed: false,          // Processed/unprocessed status
        });

        await fetchDocuments(new Date(sortDate));
        await fetchDocumentsNotification(client, token);
        handleSortChange('uploadDate', 'date');
        setLoading(false);
    };




    const handleOCRSave = async () => {
        if (!allFieldsFilled) {
            setErrorMessage(t('errorFieldsNotFilled'));
            return; // Stop execution if fields are not filled
        }
        setLoading(true);

        const formData = new FormData();
        formData.append('CaseId', client.caseId);
        formData.append('AccountantId', user.accountantId);
        formData.append('ReportingMonthDate', new Date(sortDate).toISOString());
        formData.append('DocumentFileType', type);
        formData.append('VAT', documentVat)
        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });


        try {
            const response = await DocumentService.UploadOCRDocument(type, formData, token);
            if (response.ok) {
                toast.success(t('documentUploadSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentUploadError', { errorText: 'Error uploading document' }));
        }
        setModalVisible(false);
        setDocuments([])
        await fetchDocuments(new Date(sortDate));
        await fetchDocumentsNotification(client, token)
        await fetchEnums(user, token, client);
        handleSortChange('uploadDate', 'date');
        setLoading(false);
    };

    const updateLocationState = async (newClientData) => {

        const updatedClientData = {
            ...location.state.client,
            ...newClientData
        };

        // Navigate with the updated client data and replace the current state
        setIsFirstUserFetched(false);
        navigate(location.pathname, {
            replace: true,
            state: { client: updatedClientData, type, sortDate }
        });
    };

    const handleSaveSignature = async () => {
        // const response1 = await ClientService.UpdateClient(token, { invoiceSignature });
        // if (!response1.ok) {
        //     throw new Error(`HTTP error! status: ${response1.status}`);
        // }

        const Client = await ClientService.GetClient(token, client.caseId);
        updateLocationState(Client);

    }
    const handleAccountCardSave = async (AccountCodeId, AccountCodeName, CompanyId) => {

        let response2;
        if (type === 'Incomes') {

            response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, CompanyId, "לקוחות", token);

        } else {

            response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, CompanyId, "ספקים", token);
        }
        if (!response2.ok) {

            throw new Error(`HTTP error! status: ${response2.status}`);
        }
    };

    const saveNewItemsToDatabase = async () => {
        // Filter out undefined items
        const filteredItems = newItems.filter(item => item !== undefined);

        // Assuming EnumsService.AddClientItems expects an array of items
        const response = await EnumsService.AddClientItems(filteredItems, token);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Reset newItems after saving
        setNewItems([]);
        // Optionally reset addItemFlags if necessary
        setAddItemFlags([]);

        return response;
    };
    const InvoiceModalTypes = [
        { controller: "Incomes", endPoint: "GenerateIncomePdf", type: "", TransactionDocumentType: "", TransactionDocumentTitle: "", title: "generateInvoice" },
        { controller: "Incomes", endPoint: "GenerateRefundIncomePdf", type: "", TransactionDocumentType: "", TransactionDocumentTitle: "", title: "generateCreditNote" },
    ]


    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        try {
            // Initiate all fetch requests in parallel
            const [
                codeIdResponse,
                accountsResponse,
                clientItemsResponse,
                palCodesResponse
            ] = await Promise.all([
                EnumsService.GetSortCodesByAccountantId(client.accountantId, token),
                EnumsService.GetAccountCardsByCaseId(client.caseId, token),
                EnumsService.GetClientItemsByCaseId(client.caseId, token),
                EnumsService.GetPalCodesByAccountantId(client.accountantId, token)
            ]);

            // Process sortCodeIdOptions if response is okay
            if (codeIdResponse.ok) {
                const codeIdData = await codeIdResponse.json();
                console.log(codeIdData);

                setSortCodeIdOptions(codeIdData); // Assuming codeIdData is already in the correct format
            }

            // Process account options if response is okay
            if (accountsResponse.ok) {
                const accountsData = await accountsResponse.json();
                setCreditAccountOptions(accountsData);
                setDebitAccountOptions(accountsData); // Assuming both options are the same
                setAccountCardEditDownloadOptions(accountsData);
                const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountsData];
                setAccountCardOptions(accountCardOptions);
            }

            // Process client items if response is okay
            if (clientItemsResponse.ok) {
                const clientItemsData = await clientItemsResponse.json();
                clientItemsData.unshift({ makat: 'add-item', name: t('AddItem') });
                setClientItems(clientItemsData);
            }

            // Process palCodes if response is okay
            if (palCodesResponse.ok) {
                const palCodesData = await palCodesResponse.json();
                const formattedPalCodes = palCodesData.map(palCode => ({
                    label: palCode.palCodeId,
                    value: palCode.palCodeId
                }));
                setFormattedPalCodeOptions(formattedPalCodes);
            }

            setIsFirstUserFetched(true);
            // toast.success(t('enumFetchSuccess'), {
            //     position: "top-left"
            // });
        } catch (error) {
            console.error("Error fetching enum data:", error);
            toast.error(t('enumFetchError', { errorText: error.message }));
            // Handle the error appropriately
        } finally {
            setLoading(false);
        }
    };



    const fetchDocumentsNotification = async (client, token) => {
        const documentCounts = await ClientDocumentCountService.getClientDocumentCountByCaseId(client.caseId, token);

        // Enrich clients with their total document counts
        const enrichedClient = {
            ...client,
            totalCounts: documentCounts.totalCounts || 0, // Default to 0 if no matching count is found
            documentCounts: documentCounts.documentCounts || {},
            documentIds: documentCounts.documentIds || {},
        };
        setInvoiceSignature(enrichedClient.signature);
        setClient(enrichedClient);
    }
    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {

                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    if (location.state && location.state.client && location.state.type) {

                        setType(location.state.type);
                        await fetchDocumentsNotification(location.state.client, currentToken);
                        await fetchEnums(currentUser, currentToken, location.state.client);
                    }
                    // Fetch financial data for the current date on initial load only
                }
                else {
                    navigate("/login");
                }


            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }

        };


        fetchUserData();
        setLoading(false);
    }, [location.state.client]);

    useEffect(() => {
        // To handle multiple updates efficiently, consider iterating over documentUpdate keys
        Object.keys(documentUpdate).forEach(updateCaseId => {
            const update = documentUpdate[updateCaseId];
            // Update clients with new totalCounts for the matching caseId

            if (client?.caseId === updateCaseId) { // Check if the client's caseId matches the update's caseId
                const updatedClients = {
                    ...client,
                    totalCounts: update.totalCounts, // Update totalCounts from documentUpdate
                    documentIds: update.documentsIds,
                    documentsCount: update.documentsCount
                };
                setClient(updatedClients); // Update the state with the modified lzist
            }

        });
    }, [documentUpdate]);

    const extractVatByType = (data) => {

        setDenseTableCreditDebitHeader([t("Difference"), t("TotalCreditAccount"), t("TotalDebitAccount")]);

        let creditSum = 0;
        let debitSum = 0;
        data.forEach(item => {
            if (item.creditAccount) {
                creditSum += item.amount;
            }
            if (item.debitAccount) {
                debitSum += item.amount;
            }
        });

        let diff = debitSum - creditSum;
        setDenseTableCreditDebitRows([{ Difference: beautifyNumber(diff), TotalCreditAccount: beautifyNumber(creditSum), TotalDebitAccount: beautifyNumber(debitSum) }]);

    };
    const fetchDocuments = async (selectedDate) => {
        setLoading(true);
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
        try {

            const response = await DocumentService.GetDocumentbyDate(type, client.caseId, newDate, token)
            const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);

            if (response.ok && response1.ok) {

                const data = await response.json(); // Parse the JSON in the response

                const isClosedReportingMonth = await response1.json();

                setIsClosedReportingMonth(isClosedReportingMonth);
                data.forEach(item => {
                    item.vatAmount = ((item?.amount / 1.17) * 0.17 * (item?.vat / 100));
                    item.netAmount = item?.amount - ((item?.amount / 1.17) * 0.17 * (item?.vat / 100));

                });
                setData(data);
                extractVatByType(data);

            } else {
                const errorText = await response.text();
                console.error(errorText);
                toast.error(t('documentFetchError', { errorText }));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('documentFetchError', { errorText: error.message }));
        }
        setLoading(false);
    };


    const handleDownloadDocuments = async () => {
        setLoading(true);
        try {
            const dto = {
                CaseId: client.caseId,
                Date: downloadStartDate,
                EndDate: downloadEndDate,
                AccountCodeIds: downloadAccountCodeId,
                Amount: downloadAmount
            };

            const blob = await DocumentService.DownloadCustomDocuments(type, token, dto); // Assuming you have token state or prop for authorization
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip'); // or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            // toast.success(t('documentDownloadSuccess'));
        } catch (error) {
            console.error('Error downloading documents:', error);
            toast.error(t('documentDownloadError', { errorText: error.message }));
        }
        setDownloadStartDate(null);
        setDownloadEndDate(null);
        setDownloadAmount();
        setDownloadAccountCodeId([]);
        setShowDownloadModal(false)
        setLoading(false);
    };

    const handleSetInvoiceDate = async (date) => {
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, date, token);
        const isClosedReportingMonth = await response1.json();
        if (isClosedReportingMonth) {
            toast.info(t('MonthReportAlreadyClosedTryAgain'));
            return
        }
        setInvoiceDate(date)
    }


    useEffect(() => {
        if (isFirstUserFetched) {
            if (!isRange)
                fetchDocuments(sortDate);
            else
                fetchDataOnDateRangeChange(startDate, endDate)
        }
    }, [isFirstUserFetched]);

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className={`document-custom-input ${isRange ? "document-custom-input-range" : ""}`} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));


    const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="document-custom-input1" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="edit-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));



    const CustomDownloadInput = React.forwardRef(({ value, placeholderText, onClick }, ref) => {
        return (
            <button className="download-document-custom-input" onClick={onClick} ref={ref}>
                {value || placeholderText}
            </button>
        );
    });

    const [openedIframeUrl, setOpenedIframeUrl] = useState('');
    const [bigIframeModalOpen, setBigIframeModalOpen] = useState(false);
    const openPdfInNewTab = (pdfUrl) => {
        // window.open(pdfUrl, '_blank');
        setOpenedIframeUrl(pdfUrl ? pdfUrl : NoDocumentsPdf);
        setBigIframeModalOpen(true);
    };
    const handleCloseIframeModal = () => {
        setBigIframeModalOpen(false);
        setOpenedIframeUrl('');
    };


    const handleSortCodeChange = (selectedOption) => {
        // Set the sortCodeId to the value from the selected option
        setCodeId(selectedOption.value);

        // Extract the name without the parentheses
        const nameWithoutParentheses = selectedOption.name.replace(/\s*\(.*?\)\s*/, '').trim();

        // Check for a number within parentheses
        const match = selectedOption.name.match(/\((\d+)%?/);
        let customVat = match ? parseInt(match[1], 10) : null;

        const sortCode = sortCodeIdOptions.filter(sc => sc.sortCodeId === selectedOption.value)[0];

        // Determine the VAT based on the name without parentheses
        if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
            setDocumentVat(66);
        } else if (Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) || sortCode.sortCodeVatType === 0) {
            setDocumentVat(0);
        } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
            setDocumentVat(16);
        }
        else if (customVat !== null) {
            // If a custom VAT was found, use it
            setDocumentVat(customVat === 66 ? 66 : customVat);
        } else {
            // Default VAT if no special conditions are met
            setDocumentVat(100);
        }
    };


    const handleSortChange = (key, type, Direction = '') => {
        let direction = Direction || 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };

    const filteredAndSortedData = useMemo(() => {
        if (data.length < 0) {
            return;
        }
        const sortedData = data.filter((item) => {
            const query = searchQuery.toLowerCase();
            const amountString = item?.amount?.toString(); // Safely access item.amount
            return (
                item?.accountCodeName?.toLowerCase()?.includes(query) || // Safely access item.title
                amountString?.includes(query) ||
                formatDate(item?.documentDate)?.includes(query) ||
                item?.reference?.includes(query) ||
                item?.sortCodeId?.includes(query) ||
                item?.description?.includes(query) ||
                item?.uploadNumber?.toString()?.includes(query)
            );
        });
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (sortConfig.type === 'newest') {
                    const highlightedIds = type === 'Incomes' ? client.documentIds.incomes : client.documentIds.expenses;
                    const aIsHighlighted = highlightedIds.includes(a.id);
                    const bIsHighlighted = highlightedIds.includes(b.id);

                    if (sortConfig.direction === 'ascending') {
                        // Ascending: Non-highlighted (older) items first
                        if (!aIsHighlighted && bIsHighlighted) {
                            return -1; // 'a' should come before 'b' (a is older, hence "lower")
                        } else if (aIsHighlighted && !bIsHighlighted) {
                            return 1;  // 'b' should come before 'a' (b is older, hence "lower")
                        }
                    } else {
                        // Descending: Highlighted (newer) items first
                        if (aIsHighlighted && !bIsHighlighted) {
                            return -1; // 'a' should come before 'b' (a is newer, hence "higher")
                        } else if (!aIsHighlighted && bIsHighlighted) {
                            return 1;  // 'b' should come before 'a' (b is newer, hence "higher")
                        }
                    }
                } else {
                    const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
                    const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined
                    if (sortConfig.type === 'number') {
                        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                    } else if (sortConfig.type === 'date') {
                        return sortConfig.direction === 'ascending' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
                    } else { // default to string comparison
                        return sortConfig.direction === 'ascending' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
                    }
                }
            });
        }

        return sortedData;
    }, [data, searchQuery, sortConfig]);

    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            navigate('/client-details', { state: { client } });
        }
    };

    const handleSelectRows = (ids) => {
        console.log(ids);
        if (ids && ids.length === 0) {
            setSelectedRows([]);
            return
        }
        let newSelected = [...selectedRows]; // Start with the current selected IDs

        ids.forEach((id) => {
            // Find the item in filteredAndSortedData that matches the selected id
            const item = filteredAndSortedData.find((row) => row.id === id);
            if (!item) return; // Skip if the item with the given id isn't found

            // Apply the same condition used for displaying checkboxes
            const condition = client.accountManagement === Constants.AccountManagementOptions[0].name
                ? item.sortCodeId && item.creditAccount && item.debitAccount
                : item.sortCodeId;

            if (!condition) return; // Skip if the item doesn't meet the condition

            // Check if the id is already in selectedRows
            const selectedIndex = newSelected.indexOf(id);

            // Add or remove the id based on its presence in selectedRows
            if (selectedIndex === -1) {
                // ID is not selected, so add it
                newSelected.push(id);
            } else {
                // ID is selected, so remove it
                newSelected.splice(selectedIndex, 1);
            }
        });
        console.log(newSelected);

        setSelectedRows(newSelected);
    };

    const handleAccountChange = (selectedOption, field, CustomDropdownFlag = false) => {
        setCompanyId(selectedOption.accountCaseId);
        if (field === 'debitAccount' || field === 'creditAccount') {
            if (selectedOption.value < 0) {
                setOriginalAccountCardId(editedItem.cardId)
            }
            setEditedItem({
                ...editedItem,
                [field]: selectedOption.value  // Use bracket notation to dynamically set the field
            });
        } else if (field === 'accountCodeId') {
            if (selectedOption.value1 === 'AddItem') {
                setEditedAddAccountCard(true);
                setOldAccountName(editedItem.accountCodeName);
                setOldAccountCaseId(editedItem.accountCaseId);
                setOldAccountCardId(editedItem.accountCodeId);
                setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '', accountCaseId: '' });
            }
            else {
                const accountCardEdit = accountCardEditDownloadOptions.find(option =>
                    option.cardId === (CustomDropdownFlag ? selectedOption.cardId : selectedOption.value)
                );
                const name = accountCardEdit?.name;
                const accountCaseId = accountCardEdit?.accountCaseId;
                const cardId = CustomDropdownFlag ? selectedOption.cardId : selectedOption.value;
                setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: cardId, accountCaseId: accountCaseId })
            }
        }
    };


    const handleSetSortDate = (date) => {
        const newDate = new Date(date.getFullYear(), date.getMonth(), 10);
        localStorage.setItem('Document' + location.state.type + '_Date', newDate);
        setSortDate(newDate);
        fetchDocuments(newDate);
    }

    const closeDownloadModal = () => {
        setShowDownloadModal(false);
    };

    const handleRangeChange = async () => {
        !isRange ? await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate)) : await fetchDocuments(new Date(sortDate));
        setIsRange(!isRange);
        localStorage.setItem('Document' + location.state.type + '_IsRange', !isRange);
    }

    const handleOpenInvoiceCreator = () => {
        setChooseInvoiceModal(true);
        // if (client.signature && client.invoicesNumbers) {
        //     setChooseInvoiceModal(true);
        //     //setInvoiceModalVisible(true);
        // }
        // else {
        //     setSignatureReferenceInvoiceModalVisible(true);
        // }
    };

    const handleOpenSpecificInvoiceCreator = (type) => {

        // setCurrentInvoiceCreatingType(type);
        setInvoiceTypeInModal(type);
        if (client.signature && client.invoicesNumbers) {
            setInvoiceTypeInModal(type);
            setChooseInvoiceModal(false);
            setInvoiceModalVisible(true);
        }
        else {
            setSignatureReferenceInvoiceModalVisible(true);
        }
    };

    const handleCloseAddNewAccountCardDocument = () => {
        setAccountCodeId("");
        setAccountCodeName("");
        setCompanyId("");
        setAddNewAccountCardDocument(false);
    };

    const handleCloseEditAddNewAccountCardDocument = () => {
        setEditedItem({ ...editedItem, accountCodeName: oldAccountName, accountCodeId: oldAccountCardId, accountCaseId: oldAccountCaseId });
        setEditedAddAccountCard(false);
    };

    const handleEditAddAccountChange = (selectedOption, field) => {
        if (field === 'AccountCodeId') {
            type === 'Incomes' ? setEditedItem({ ...editedItem, accountCodeId: selectedOption, debitAccount: selectedOption }) : setEditedItem({ ...editedItem, accountCodeId: selectedOption, creditAccount: selectedOption });
        }
        else if (field === 'AccountCodeName') {

            setEditedItem({ ...editedItem, accountCodeName: selectedOption })
        }
        else {
            setEditedItem({ ...editedItem, accountCaseId: selectedOption })

        }
    };

    const handlePriceIncludingVATChange = (event) => {
        setPriceIncludingVAT(event.target.value === 'true');
    };



    //table functions
    const headerStyle = {
        backgroundColor: '#f7f7f7',
        color: 'black',
        fontWeight: 'bold',
        zIndex: '10'
    }

    const tableContainerRef = useRef();
    const customScrollbarRef = useRef();



    const handleCustomScroll = (e) => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const handleTableScroll = (e) => {
        if (customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const synchronizeScroll = () => {
        if (tableContainerRef.current && customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = tableContainerRef.current.scrollLeft;

        }
    };

    useEffect(() => {
        synchronizeScroll();
    }, [filteredAndSortedData]);

    useEffect(() => {
        if (tableContainerRef.current && customScrollbarRef.current) {
            customScrollbarRef.current.firstChild.style.width = `${tableContainerRef.current.scrollWidth}px`;
        }
    }, [filteredAndSortedData, tableContainerRef.current?.scrollWidth]);


    const RefreshPage = async () => {
        handleCancel();
        if (isRange)
            await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate))
        else
            await fetchDocuments(new Date(sortDate));

        await fetchDocumentsNotification(client, token)
        await fetchEnums(user, token, client);
    }

    const [isEditingARow, setIsEditingARow] = useState(false);
    const Row = ({
        item,
        index,
    }) => {

        const [isEditing, setIsEditing] = useState(false);

        let isIdInChecks = client.documentIds.journalEntries.includes(item.id);
        const isItemSelected = isSelected(item.id);
        const undealtStyle = {
            boxShadow: isIdInChecks
                ? ''  // red with 50% opacity
                : 'inset 0px 2px 200px 0px rgba(57, 196, 94, 0.2)',  // #39c45e with 50% opacity
            borderRadius: '0px', // Adjust the radius as needed
        };
        const handleDoubleClick = () => {
            // setIsEditing(true); // Enable editing mode on double-click
            // setIsEditingARow(true);
            // handleEdit(item, index, false);
        };

        const handleCancel = () => {
            setIsEditing(false);
            setIsEditingARow(false);

        };

        // Render editable row when isEditing is true
        return isEditing ? (
            <TableRow key={item.id} sx={{ ...undealtStyle }} selected={isItemSelected}>
                {/* <TableCell align="left" onClick={() => openPdfInNewTab(item.documentUrl)}>
                    <div className="iframe-container">
                        <iframe
                            className="zoom-iframe"
                            src={item.documentUrl ? `${item.documentUrl}#page=1` : `${NoDocumentsPdf}#page=1`}
                            width="100px"
                            height="50px"
                            id={item.id}
                            title={item.title}
                            style={{ pointerEvents: 'none' }}
                        />
                    </div>
                </TableCell> */}
                <>
                    <TableCell align="right">
                        {!isIdInChecks ? (
                            <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold' }}>{t('processed')}</p>
                        ) : (
                            <p style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>{t('unprocessed')}</p>
                        )}
                    </TableCell>

                    {/* Select for Account Code Name */}
                    {/* <TableCell align="right">
                        <Select
                            options={accountCardOptions.map(option => ({
                                value: option.name,
                                label: option?.accountCaseId ? `${option.name} - ${option?.accountCaseId}` : option.name,
                                accountCaseId: option.accountCaseId || ''
                            }))}
                            value={accountCardOptions
                                .filter(option => option.name === editedItem.accountCodeName)
                                .map(option => ({
                                    value: option.name,
                                    label: option?.accountCaseId ? `${option.name} - ${option?.accountCaseId}` : option.name,
                                    accountCaseId: option.accountCaseId || ''
                                }))[0]}
                            onChange={(selectedOption) => handleAccountChange(selectedOption, 'accountCodeName')}
                            isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                            placeholder={t('SelectClientName')}
                        />
                    </TableCell> */}

                    {/* Account Case ID */}
                    <TableCell align="right">

                        <div className='td-container-2'>
                            <input
                                type="text"
                                className='edit-input-field-2'
                                value={editedItem.accountCaseId || ''}
                                onChange={(e) => handleChange(e, 'accountCaseId')}
                                disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                            />
                        </div>
                    </TableCell>

                    {/* Reference */}
                    <TableCell align="right">
                        <div className='td-container-2'>
                            <input
                                type="text"
                                className='edit-input-field-2'
                                value={editedItem.reference || ''}
                                onChange={(e) => handleChange(e, 'reference')}
                                disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                            />
                        </div>
                    </TableCell>

                    {/* Confirmation Number */}
                    <TableCell align="right">
                        <div className='td-container'>
                            <input
                                type="text"
                                className='edit-input-field-2'
                                value={editedItem.confirmationNumber || ''}
                                onChange={(e) => handleChange(e, 'confirmationNumber')}
                                disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                            />
                        </div>
                    </TableCell>
                    {/* Description */}
                    <TableCell align="right">
                        <div className='td-container'>
                            <input
                                type="text"
                                className='edit-input-field-2'
                                value={editedItem.description || ''}
                                onChange={(e) => handleChange(e, 'description')}
                                disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                            />
                        </div>
                    </TableCell>

                    {isRange && (
                        <TableCell align="right">
                            {item?.reportingMonthDate ? formatDateNew(item.reportingMonthDate) : '-'}
                        </TableCell>
                    )}
                    <TableCell align="right">{formatDateNew(item?.uploadDate)}</TableCell>
                    <TableCell align="right">{formatDateNew(item?.documentDate)}</TableCell>

                    {/* Amount */}
                    <TableCell align="right">
                        <div className='td-container'>
                            <input
                                type="number"
                                className='edit-input-field-2'
                                value={editedItem.amount || ''}
                                onChange={(e) => handleChange(e, 'amount')}
                                disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                            />
                        </div>
                    </TableCell>
                    {/* VAT */}
                    <TableCell align="right">
                        <div className='td-container' style={{ display: 'flex', flexDirection: 'row', height: '60%', justifyContent: 'center', alignItems: 'center' }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="position"
                                defaultValue="top"
                                onChange={handleVatRadioChange}
                                style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
                            >
                                <FormControlLabel
                                    value={16}
                                    control={<Radio />}
                                    label={t('AccountingTax')}
                                    labelPlacement="top"
                                    checked={editedItem.vat === 16}
                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                />
                                <FormControlLabel
                                    value={100}
                                    control={<Radio />}
                                    label="100"
                                    labelPlacement="top"
                                    checked={editedItem.vat === 100}
                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                />
                                <FormControlLabel
                                    value={66}
                                    control={<Radio />}
                                    label="66"
                                    labelPlacement="top"
                                    checked={editedItem.vat === 66}
                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                />
                                <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="0"
                                    labelPlacement="top"
                                    checked={editedItem.vat === 0}
                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                />
                            </RadioGroup>
                        </div>
                    </TableCell>


                    <TableCell align="right">
                        <div className='td-container'>
                            {item.vatAmount !== null ? parseFloat(item.vatAmount).toFixed(2).toLocaleString() : '0.00'}
                        </div>
                    </TableCell>

                    <TableCell align="right">
                        <div className='td-container'>
                            {item.netAmount !== null ? parseFloat(item.netAmount).toFixed(2).toLocaleString() : '0.00'}
                        </div>
                    </TableCell>
                    {/* Credit Account */}
                    <TableCell align="right">
                        <Select
                            options={creditAccountOptions.map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))}
                            value={creditAccountOptions
                                .filter(option => option.cardId === editedItem.creditAccount)
                                .map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))[0]}
                            onChange={(selectedOption) => handleChange({ target: { value: selectedOption.value } }, 'creditAccount')}
                            placeholder={t("Select AccountCard")}
                            isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                        />
                    </TableCell>

                    {/* Debit Account */}
                    <TableCell align="right">
                        <Select
                            options={debitAccountOptions.map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))}
                            value={debitAccountOptions
                                .filter(option => option.cardId === editedItem.debitAccount)
                                .map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))[0]}
                            onChange={(selectedOption) => handleChange({ target: { value: selectedOption.value } }, 'debitAccount')}
                            placeholder={t("Select AccountCard")}
                            isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                        />
                    </TableCell>

                    {/* Sort Code */}
                    <TableCell align="right">
                        <Select
                            options={sortCodeIdOptions.map(option => ({ value: option.sortCodeId, label: `${option.sortCodeId} - ${option.sortCodeName}` }))}
                            value={sortCodeIdOptions
                                .filter(option => option.sortCodeId === editedItem.sortCodeId)
                                .map(option => ({ value: option.sortCodeId, label: `${option.sortCodeName} - ${option.sortCodeId}` }))[0]}
                            onChange={(selectedOption) => handleChange(selectedOption, 'sortCodeId')}
                            placeholder={t("Select SortCode")}
                            isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                        />
                    </TableCell>

                    <TableCell align="right">{item.uploadNumber}</TableCell>

                    <TableCell align="right">
                        <div className='row-buttons-container'>
                            <button className='row-buttons' onClick={() => handleUpdate(item.id)}>
                                <img src={saveIcon} alt="Save" className="doc-details-icon" />
                            </button>
                            <button className='row-buttons' onClick={handleCancel}>
                                <img src={cancelIcon} alt="Cancel" className="doc-details-icon" />
                            </button>
                        </div>
                    </TableCell>
                </>
            </TableRow>
        ) : (
            <TableRow key={item.id} sx={{ ...undealtStyle }} selected={isItemSelected} onDoubleClick={handleDoubleClick}>
                {/* View Mode */}
                {!addNewJournalEntry ? (<>
                    <TableCell align="right">
                        {!isIdInChecks ? (
                            <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold' }}>{t('processed')}</p>
                        ) : (
                            <p style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>{t('unprocessed')}</p>
                        )}
                    </TableCell>
                    <TableCell align="right">{item?.accountCaseId}</TableCell>
                    <TableCell align="right">{item?.reference}</TableCell>
                    <TableCell align="right">{item?.confirmationNumber}</TableCell>

                    <TableCell align="right">{item?.description}</TableCell>
                    {isRange && (
                        <TableCell align="right">
                            {item?.reportingMonthDate ? formatDateNew(item.reportingMonthDate) : '-'}
                        </TableCell>
                    )}
                    <TableCell align="right">{formatDateNew(item?.uploadDate)}</TableCell>
                    <TableCell align="right">{formatDateNew(item?.documentDate)}</TableCell>
                    <TableCell align="right" className="totalPrice-new">
                        <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold' }}>
                            {item?.amount !== null ? beautifyNumber(item.amount) : '0.00'}
                        </p>
                    </TableCell>
                    <TableCell align="right">{beautifyNumber(item?.vat || 0)}%</TableCell>
                    <TableCell align="right">{beautifyNumber(item?.vatAmount || 0)}</TableCell>
                    <TableCell align="right">{beautifyNumber(item?.netAmount || 0)}</TableCell>
                    <TableCell align="right">{item.creditAccount} - {creditAccountOptions.find(option => option.cardId === item.creditAccount)?.name || item.creditAccount}</TableCell>
                    <TableCell align="right">{item.debitAccount} - {creditAccountOptions.find(option => option.cardId === item.debitAccount)?.name || item.debitAccount}</TableCell>
                    <TableCell align="right">{item.sortCodeId} - {sortCodeIdOptions.find(option => option.sortCodeId === item.sortCodeId)?.sortCodeName || item.sortCodeId}</TableCell>
                    <TableCell align="right">{item.uploadNumber}</TableCell>

                    <TableCell>
                        {(client.accountManagement === Constants.AccountManagementOptions[0].name
                            ? item.sortCodeId && item.creditAccount && item.debitAccount
                            : item.sortCodeId) && (
                                <div>
                                    <Checkbox
                                        checked={isItemSelected}
                                        onChange={(event) => handleSelectRow(event, item.id)}
                                        sx={{
                                            color: '#E57C22',
                                            '&.Mui-checked': {
                                                color: '#E57C22',
                                            },
                                            display: 'inline-table'
                                        }}
                                    />
                                </div>
                            )}
                        <div className='actions_container'>
                            <button className='row-buttons' onClick={() => handleEdit(item, index)}>
                                <EditIcon className="doc-details-icon" style={{ color: '#E57C22' }} />
                            </button>
                            {(isRange ? !item?.isClosedReportingMonth : !isClosedReportingMonth) && (
                                <button className='row-buttons' onClick={() => handleDeleteClick(item.id)}>
                                    <DeleteIcon className="doc-details-icon" style={{ color: '#E57C22' }} />
                                </button>
                            )}
                        </div>
                    </TableCell>
                </>
                ) : (<>
                    <TableCell align="right">{item?.confirmationNumber}</TableCell>
                    <TableCell align="right">{item?.accountCaseId}</TableCell>
                    <TableCell align="right">{item?.reference}</TableCell>
                    <TableCell align="right">{item?.description}</TableCell>
                    <TableCell align="right">{formatDateNew(item?.documentDate)}</TableCell>
                    <TableCell align="right" className="totalPrice-new">
                        <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold' }}>
                            {item?.amount !== null ? beautifyNumber(item.amount) : '0.00'}
                        </p>
                    </TableCell>
                    <TableCell align="right">{beautifyNumber(item?.vat || 0)}%</TableCell>
                    <TableCell align="right">{item.creditAccount} - {creditAccountOptions.find(option => option.cardId === item.creditAccount)?.name || item.creditAccount}</TableCell>
                    <TableCell align="right">{item.debitAccount} - {creditAccountOptions.find(option => option.cardId === item.debitAccount)?.name || item.debitAccount}</TableCell>
                    <TableCell align="right">{item.sortCodeId} - {sortCodeIdOptions.find(option => option.sortCodeId === item.sortCodeId)?.sortCodeName || item.sortCodeId}</TableCell>
                    <TableCell align="right"></TableCell>



                </>)}
            </TableRow>
        );
    };

    const [selectedValue, setSelectedValue] = useState(null);

    // Function to handle the checkbox click and set the selected value
    const handleCheckboxChange = (value) => {
        //handleInputChange
        if (value === 'accountingTax') {
            handleInputChange('vat', 16)
        }
        handleInputChange('vat', value)
        setSelectedValue(value);  // Update the state with the selected value
    };

    // Memoized rows using the modified Row component
    const memoizedRows = useMemo(() => {

        return filteredAndSortedData.map((item, index) => (
            <Row item={item} index={index} />
        ));
    }, [filteredAndSortedData, addNewJournalEntry, selectedRows]);

    const commonStyles = {
        height: '40px',            // Set the height
        width: '160px',
        backgroundColor: '#fff',   // Set background to white
        padding: '0 10px'          // Adjust padding
    };

    return (
        <>
            <DraggableModal
                bigIframeModalOpen={bigIframeModalOpen}
                openedIframeUrl={openedIframeUrl}
                handleCloseIframeModal={handleCloseIframeModal}
            />
            <HamburgerMenu client={client} />

            <div className="template-page-withoutHeight">
                <div className="template-container">
                    <UpperPanelDetails />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                        <div className={`main-title ${direction}`}>{t(type)}</div>
                    </div>







                    <div className={`title-back-container ${direction}`}>



                        <div className='document-other-create-download-container'>
                            <button onClick={() => setAddNewJournalEntry(true)} className="create-Invoice-selector" >
                                <AddCircleIcon sx={{ ml: 1 }} />
                                <span>{t("AddNewJournalEntry")}</span>
                            </button>
                            {!isRange && <div className="MonthReportButton" onClick={() => {
                                setActionType(isClosedReportingMonth ? 'openMonth' : 'closeMonth');
                                setIsModalVisible(true);
                            }}>
                                {isClosedReportingMonth ? t("OpenMonthReport") : t("CloseMonthReport")}
                            </div>
                            }


                        </div>
                    </div>
                    <div className={`searchFilter-bar__container ${direction}`} >
                        <ActionButton
                            variant="contained"
                            disabled={selectedRows.length === 0}
                            onClick={setOpenOption}
                            className='custom-input-box-sizing'
                        >
                            {t('OpenOptions')}
                        </ActionButton>
                        <TextField
                            fullWidth
                            placeholder={t('searchClient')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-bar"
                            style={{ height: '55px' }}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                sx: {
                                    height: '55px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                                ...(direction === 'ltr'
                                    ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }),
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                        {isRange ? (
                            <>
                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleStartDateChange(date)}
                                    date={dayjs(startDate)}
                                    label={t("StartDate")}
                                />

                                <FormControlLabel
                                    value={isRange}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={isRange}
                                        onChange={handleRangeChange}
                                    />}
                                    label={t("DateRange")}
                                    labelPlacement="bottom"
                                />

                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleEndDateChange(date)}
                                    date={dayjs(endDate)}
                                    label={t("EndDate")}
                                />
                            </>
                        ) : (
                            <>
                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleSetSortDate(date)}
                                    date={dayjs(sortDate)}
                                    label={t("date")}
                                />

                                <FormControlLabel
                                    value={!isRange}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={isRange}
                                        onChange={handleRangeChange}

                                    />}
                                    label={t("DateRange")}
                                    labelPlacement="bottom"
                                />
                            </>
                        )}
                    </div>
                    <div className='document-Dense-container' style={{ width: '50%', margin: "10px auto" }} >

                        <DenseTable headers={denseTableCreditDebitHeader} rows={denseTableCreditDebitRows} width='100%' styleFlag

                            headerStyle={{
                                opacity: 0.6,
                                background: 'var(--orange, #F9AA2A)',
                                textAlign: 'center',
                                minHeight: '50px'
                            }}
                            rowStyle={{
                                borderTop: '1px solid rgba(191, 191, 193, 0.56)',
                                background: 'var(--secondary-12, rgba(254, 247, 234, 0.12))',
                                minHeight: '50px'
                            }}
                        />




                    </div>
                    <Dialog open={openOptions} onClose={closeOptions}>
                        <StyledDialogTitle>{t('SelectOption')}</StyledDialogTitle>
                        <StyledDialogContent>
                            <Typography variant="body2" color="#025366">{t('PleaseSelectOptionForSelectedRows')}</Typography>
                            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" mt={2}>
                                <OptionButton onClick={() => handleOptionSelect('notDone')}>
                                    {t('unprocessed')}
                                </OptionButton>
                                <OptionButton onClick={() => handleOptionSelect('done')}>
                                    {t('processed')}
                                </OptionButton>
                                {/* Add more OptionButtons here if needed */}
                            </Box>
                        </StyledDialogContent>
                        <StyledDialogActions>
                            <StyledButton onClick={closeOptions}>
                                {t('close')}
                            </StyledButton>
                        </StyledDialogActions>
                    </Dialog>

                    {/* <div className='table-container' style={{ overflowX: 'hidden' }} >

                        <TableContainer component={Paper} sx={{ height: '60vh', overflow: 'auto' }} ref={tableContainerRef}>
                            <Table aria-label="collapsible table" stickyHeader className="sticky-header" >
                                <TableHead ref={tableContainerRef} onScroll={handleTableScroll} >
                                    {addNewJournalEntry ? (
                                        <TableRow sx={headerStyle}>


                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('confirmationNumber', 'string')}>
                                                {t('ConfirmationNumber')}
                                                {sortConfig.key === 'confirmationNumber' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('accountCaseId', 'string')}>
                                                {t('CompanyId')}
                                                {sortConfig.key === 'accountCaseId' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('reference', 'number')}>
                                                {t('Reference')}
                                                {sortConfig.key === 'reference' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('description', 'string')}>
                                                {t('Description')}
                                                {sortConfig.key === 'description' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>



                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('documentDate', 'date')}>
                                                {t('ValueDate')}
                                                {sortConfig.key === 'documentDate' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('amount', 'number')}>
                                                {t('Amount')}
                                                {sortConfig.key === 'amount' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('vat', 'number')}>
                                                {t('VAT')}
                                                {sortConfig.key === 'vat' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('creditAccount', 'number')}>
                                                {t('Credit Account')}
                                                {sortConfig.key === 'creditAccount' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('debitAccount', 'number')}>
                                                {t('Debit Account')}
                                                {sortConfig.key === 'debitAccount' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH textRight' onClick={() => handleSortChange('sortCodeId', 'number')}>
                                                {t('Code ID')}
                                                {sortConfig.key === 'sortCodeId' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>

                                            <TableCell sx={headerStyle} className='headerTH textRight' align="right">
                                                {t('Actions')}
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow sx={headerStyle}>
                                            <TableCell sx={headerStyle} align="right" className='headerTH'
                                                onClick={() => {
                                                    handleSortChange('id', 'newest'); 
                                                }}
                                            >
                                                {t('DocumentProcessed')}
                                                {sortConfig.type === 'newest' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('accountCaseId', 'string')}>
                                                {t('CompanyId')}
                                                {sortConfig.key === 'accountCaseId' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('reference', 'number')}>
                                                {t('Reference')}
                                                {sortConfig.key === 'reference' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('confirmationNumber', 'number')}>
                                                {t('ConfirmationNumber')}
                                                {sortConfig.key === 'confirmationNumber' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('description', 'string')}>
                                                {t('Description')}
                                                {sortConfig.key === 'description' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            {
                                                isRange &&
                                                <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('reportingMonthDate', 'date')}>
                                                    {t('MonthReportDate')}
                                                    {sortConfig.key === 'reportingMonthDate' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                                </TableCell>
                                            }
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('uploadDate', 'date')}>
                                                {t('Upload Date')}
                                                {sortConfig.key === 'uploadDate' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('documentDate', 'date')}>
                                                {t('ValueDate')}
                                                {sortConfig.key === 'documentDate' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('amount', 'number')}>
                                                {t('Amount')}
                                                {sortConfig.key === 'amount' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('vat', 'number')}>
                                                {t('VATPercentage')}
                                                {sortConfig.key === 'vat' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('vatAmount', 'number')}>
                                                {t('VATAmount')}
                                                {sortConfig.key === 'vatAmount' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('netAmount', 'number')}>
                                                {t('NetAmount')}
                                                {sortConfig.key === 'netAmount' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('creditAccount', 'number')}>
                                                {t('Credit Account')}
                                                {sortConfig.key === 'creditAccount' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('debitAccount', 'number')}>
                                                {t('Debit Account')}
                                                {sortConfig.key === 'debitAccount' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                            <TableCell sx={headerStyle} align="right" className='headerTH' onClick={() => handleSortChange('sortCodeId', 'number')}>
                                                {t('Code ID')}
                                                {sortConfig.key === 'sortCodeId' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>


                                            <TableCell sx={{ ...headerStyle, maxWidth: '30px' }} align="right" className='headerTH' onClick={() => handleSortChange('uploadNumber', 'number')}>
                                                {t('UploadNumber')}
                                                {sortConfig.key === 'uploadNumber' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>

                                            <TableCell sx={headerStyle} align="right" >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isAllSelected}
                                                            onChange={(event) => handleSelectAllClick(event)}
                                                            inputProps={{ 'aria-label': 'Select all rows' }}
                                                            sx={{
                                                                color: '#E57C22',
                                                                '&.Mui-checked': {
                                                                    color: '#E57C22',
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={t("Select all")}
                                                    labelPlacement="start"
                                                    sx={{
                                                        marginLeft: 0,
                                                        marginRight: '8px',
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>

                                    )}
                                    {addNewJournalEntry && (
                                        <TableRow sx={headerStyle}>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} className='journalEntryInput'>
                                                <TextField
                                                    value={addItem.confirmationNumber}
                                                    onChange={(e) => handleInputChange('confirmationNumber', e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    dir="rtl"
                                                    placeholder={t('ConfirmationNumber')}
                                                    InputProps={{
                                                        sx: {
                                                            backgroundColor: '#fff', 
                                                            height: '40px',

                                                        },
                                                    }}

                                                />
                                            </TableCell>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} className='journalEntryInput'>
                                                <TextField
                                                    value={addItem.accountCaseId}
                                                    onChange={(e) => handleInputChange('accountCaseId', e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    dir="rtl"
                                                    placeholder={t('CompanyId')}
                                                    InputProps={{
                                                        sx: {
                                                            backgroundColor: '#fff',
                                                            height: '40px',
                                                        },
                                                    }}

                                                />
                                            </TableCell>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} className='journalEntryInput'>
                                                <TextField
                                                    value={addItem.reference}
                                                    onChange={(e) => handleInputChange('reference', e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    dir="rtl"
                                                    placeholder={t('Reference')}
                                                    InputProps={{
                                                        sx: {
                                                            backgroundColor: '#fff', 
                                                            height: '40px',
                                                        },
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} className='journalEntryInput'>
                                                <TextField
                                                    value={addItem.description}
                                                    onChange={(e) => handleInputChange('description', e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    dir="rtl"
                                                    placeholder={t('Description')}
                                                    InputProps={{
                                                        sx: {
                                                            backgroundColor: '#fff', 
                                                            height: '40px',
                                                        },
                                                    }}
                                                />
                                            </TableCell>



                                            <TableCell align="right" sx={{ backgroundColor: '#f7f7f7' }} className='journalEntryInput'>
                                                <div className="edit-date-input-container">
                                                    <ReactDatePicker
                                                        selected={new Date(addItem.documentDate)}
                                                        onChange={(e) => handleInputChange('documentDate', e)}
                                                        dateFormat="dd-MM-yyyy"
                                                        className="date-input"
                                                        customInput={
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                dir="rtl"
                                                                placeholder="Select Date"
                                                                InputProps={{
                                                                    sx: commonStyles   
                                                                }}
                                                            />
                                                        }
                                                        disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                    />
                                                </div>
                                            </TableCell>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} className='journalEntryInput'>
                                                <TextField
                                                    value={addItem.amount}
                                                    onChange={(e) => handleInputChange('amount', e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    dir="rtl"
                                                    placeholder={t('Amount')}
                                                    InputProps={{
                                                        sx: {
                                                            backgroundColor: '#fff', 
                                                            height: '40px',
                                                            '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                                borderRadius: '4px',
                                                            },
                                                            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: 'red',   
                                                                borderRadius: '4px',  
                                                            },
                                                        },
                                                    }}
                                                    required
                                                    error={!addItem.amount}
                                                    helperText={!addItem.amount ? t('ThisFieldIsRequired') : ''}
                                                    FormHelperTextProps={{
                                                        sx: {
                                                            color: 'red',
                                                            fontSize: '12px',
                                                            marginLeft: '0px',
                                                        },
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell align="right" sx={{ backgroundColor: '#f7f7f7' }} className='journalEntryInput'>

                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div className="checkbox-container">
                                                        <BpCheckbox
                                                            checked={selectedValue === "100"}  
                                                            onChange={() => handleCheckboxChange("100")} 
                                                        />
                                                        <Typography sx={{ fontSize: 10 }}>100</Typography>
                                                    </div>

                                                    <div className="checkbox-container">
                                                        <BpCheckbox
                                                            checked={selectedValue === "66"} 
                                                            onChange={() => handleCheckboxChange("66")}  
                                                        />
                                                        <Typography sx={{ fontSize: 10 }}>66</Typography>
                                                    </div>

                                                    <div className="checkbox-container">
                                                        <BpCheckbox
                                                            checked={selectedValue === "0"}  // Checked if selectedValue is "0"
                                                            onChange={() => handleCheckboxChange("0")}  // Set selectedValue to "0"
                                                        />
                                                        <Typography sx={{ fontSize: 10 }}>0</Typography>
                                                    </div>

                                                    <div className="checkbox-container">
                                                        <BpCheckbox
                                                            checked={selectedValue === "accountingTax"}  // Checked if selectedValue is "accountingTax"
                                                            onChange={() => handleCheckboxChange("accountingTax")}  // Set selectedValue to "accountingTax"
                                                        />
                                                        <Typography sx={{ fontSize: 10 }}>{t('AccountingTax')}</Typography>
                                                    </div>
                                                </div>

                                            </TableCell>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} className='journalEntryInput'>
                                                <Select
                                                    classNamePrefix="custom-select"
                                                    value={creditAccountOptions
                                                        .filter(option => option.cardId === creditAccountOption)
                                                        .map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))[0]}
                                                    options={creditAccountOptions.map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))}
                                                    onChange={(selectedOption) => handleInputChange('creditAccount', selectedOption.value)}
                                                    placeholder={t("Select AccountCard")}
                                                    fullWidth
                                                />
                                            </TableCell>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} className='journalEntryInput'>
                                                <Select
                                                    classNamePrefix="custom-select"
                                                    value={debitAccountOptions
                                                        .filter(option => option.cardId === debitAccountOption)
                                                        .map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))[0]}
                                                    options={debitAccountOptions.map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))}
                                                    onChange={(selectedOption) => handleInputChange('debitAccount', selectedOption.value)}
                                                    placeholder={t("Select AccountCard")}
                                                    fullWidth
                                                />
                                            </TableCell>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} className='journalEntryInput'>
                                                <Select
                                                    classNamePrefix="custom-select"
                                                    value={sortCodeIdOptions
                                                        .filter(option => option.sortCodeId === addItem.sortCodeId)
                                                        .map(option => ({ value: option.sortCodeId, label: `${option.sortCodeId} - ${option.sortCodeName}`, name: option.sortCodeName }))[0]} options={sortCodeIdOptions.map(option => ({ value: option.sortCodeId, label: `${option.sortCodeId} - ${option.sortCodeName}` }))}
                                                    onChange={(selectedOption) => handleInputChange('sortCodeId', selectedOption.value)}
                                                    placeholder={t("Select SortCode")}
                                                    fullWidth
                                                />
                                            </TableCell>

                                            <TableCell sx={{ backgroundColor: '#f7f7f7', zIndex: 1 }} >
                                                <Box display="flex" alignItems="center">
                                                    <IconButton onClick={handleSave} color="primary" disabled={!isFormValid}>
                                                        <SaveIcon />
                                                    </IconButton>

                                                    <IconButton onClick={handleCancelAddJournalEntries} color="secondary">
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}


                                </TableHead>
                                <TableBody>

                                    {memoizedRows}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div> */}
                    <JournalEntryDataGrid
                        t={t}
                        client={client}
                        data={data}
                        isRange={isRange}
                        isClosedReportingMonth={isClosedReportingMonth}
                        accountCardOptions={accountCardOptions}
                        creditAccountOptions={creditAccountOptions}
                        debitAccountOptions={debitAccountOptions}
                        sortCodeIdOptions={sortCodeIdOptions}
                        formatDateNew={formatDateNew}
                        formatMonthYearDate={formatMonthYearDate}
                        beautifyNumber={beautifyNumber}
                        Constants={Constants}
                        handleDeleteClick={handleDeleteClick}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                        direction={direction}
                        setEditedItem={setEditedItem}
                        editedItem={editedItem}
                        setEditedPopupItem={setEditedPopupItem}
                        handleEdit={handleEdit}
                        setCurrentRowData={setCurrentRowData}
                        setOpenInlineEditModal={setOpenInlineEditModal}
                        handleSelectRows={handleSelectRows}
                        addNewJournalEntry={addNewJournalEntry}
                        setAddNewJournalEntry={setAddNewJournalEntry}
                        openPdfInNewTab={openPdfInNewTab}
                        handleOpenDialog={handleOpenDialog}
                    />

                </div>

                <CustomDeleteConfirmationModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    onDelete={handleConfirm}
                    confirmMessage={getConfirmMessage()}
                    titleMessage={getTitleMessage()}
                    type={actionType}
                />
                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}

                {editedItem && editedPopupItem && Object.keys(editedItem).length > 0 && (

                    <EditModal
                        editedItem={editedItem}
                        fade={fade}
                        handlePrevious={handlePrevious}
                        handleNext={handleNext}
                        currentIndex={currentIndex}
                        filteredAndSortedData={filteredAndSortedData}
                        NoDocumentsPdf={NoDocumentsPdf}
                        t={t}
                        handleAccountChange={handleAccountChange}
                        isRange={isRange}
                        isClosedReportingMonth={isClosedReportingMonth}
                        accountCardOptions={accountCardOptions}
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        CustomInput2={CustomInput2}
                        setReportingMonthDate={setReportingMonthDate}
                        handleVatRadioChange={handleVatRadioChange}
                        statusCheck={statusCheck}
                        handleRadioChange={handleRadioChange}
                        type={type}
                        editedAddAccountCard={editedAddAccountCard}
                        handleCloseEditAddNewAccountCardDocument={handleCloseEditAddNewAccountCardDocument}
                        handleEditAddAccountChange={handleEditAddAccountChange}
                        sortCodeIdOptions={sortCodeIdOptions}
                        debitAccountOptions={debitAccountOptions}
                        creditAccountOptions={creditAccountOptions}
                        handleUpdate={handleUpdate}
                        handleCancel={handleCancel}
                        handleDeleteClick={handleEditDeleteClick}
                        client={client}
                        token={token}
                        setAccountCardOptions={setAccountCardOptions}
                        setLoading={setLoading}
                        setEditedAddAccountCard={setEditedAddAccountCard}
                        setEditedItem={setEditedItem}
                        handleReopenEdit={handleReopenEdit}
                        palCodes={formattedPalCodeOptions}
                        RefreshPage={RefreshPage}
                        showIframe={false}
                        fetchEnums={fetchEnums}
                    />

                )}
                {/* <JournalEntryDraggableDialog
                    open={openInlineEditModal}
                    onClose={handleDialogClose}
                    data={currentRowData}
                    onSave={handleDialogSave}
                    accountCardOptions={accountCardOptions}
                    creditAccountOptions={creditAccountOptions}
                    debitAccountOptions={debitAccountOptions}
                    sortCodeIdOptions={sortCodeIdOptions}
                    Constants={Constants}
                    direction={direction}
                    isAddMode={isAddMode}
                    isClosedReportingMonth={isClosedReportingMonth}
                /> */}
            </div>
        </>

    );
}


export default JournalEntry;
