import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';



const headerStyle = {
  opacity: 0.6,
  background: 'var(--orange, #F9AA2A)',
  textAlign: 'center',
  minHeight: '50px'
};

// rowStyle={{
//   borderTop: '1px solid rgba(191, 191, 193, 0.56)',
//   background: 'var(--secondary-12, rgba(254, 247, 234, 0.12))',
//   minHeight: '50px'
// }}
// table functions
const CellGreenStyle = {
  color: 'green',
  fontSize: '16px',
  fontWeight: 'bold',
};

const CellRedStyle = {
  color: 'red',
  fontSize: '16px',
  fontWeight: 'bold',
};

export default function ClickAbleDenseTable({
  headers,
  content,
  styleFlag = false,
  width = '70%',
  height = '100px',
  numericrows = true,
  reverse = false,
  align = 'right',
  setSelectedRow,  // This is now passed as a prop
  selectedRowIndex, // This is the selected row index passed as a prop
  openEdit // The openEdit function to be called on right-click
}) {
  const { t } = useTranslation();
  const [selectedHeaderIndex, setSelectedHeaderIndex] = useState(null); // Track selected header

  const processedHeaders = reverse ? [...headers].reverse() : headers;

  const processedContent = reverse
    ? content.map((row) => [...row].reverse()) // Reverse each row
    : content;

  const handleRowClick = (rowIndex) => {
    setSelectedRow(rowIndex); // Pass the selected row index to the parent component
  };

  const handleHeaderClick = (headerIndex) => {
    setSelectedHeaderIndex(headerIndex); // Set selected header index
  };

  const handleRightClick = (event, rowIndex) => {
    event.preventDefault(); // Prevent the default context menu
    openEdit(rowIndex); // Call the openEdit function with the row index
  };


  return (
    <TableContainer component={Paper} sx={{ width: width, height: height, overflowX: 'hidden', overflowY: 'scroll', borderRadius: '10px' }}>
      <Table size="small" aria-label="a dense table" stickyHeader>
        <TableHead>
          <TableRow>
            {processedHeaders.map((header, headerIndex) => (
              <TableCell
                key={header}
                align={align}
                onClick={() => handleHeaderClick(headerIndex)} // Add click handler for header selection
                sx={{
                  ...headerStyle,
                  // backgroundColor: selectedHeaderIndex === headerIndex ? '#d3d3d3' : '#ebebeb', // Change header background if selected
                  cursor: 'pointer', // Make header clickable
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {processedContent.map((row, rowIndex) => (
            <Tooltip key={rowIndex} title={t('RightClickToEdit')} placement="top" arrow>
              <TableRow
                onClick={() => handleRowClick(rowIndex)} // Add click handler for row selection
                onContextMenu={(event) => handleRightClick(event, rowIndex)} // Send the entire row data on right-click
                sx={{
                  backgroundColor: selectedRowIndex === rowIndex ? '#f0f0f0' : 'inherit', // Change background if selected
                  cursor: 'pointer', // Add pointer cursor for clickable rows
                }}
              >
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    align="center"
                    sx={styleFlag && cellIndex === 0 && (cell.trim() !== '0' && cell.trim() !== '0.00') ? { backgroundColor: '#f0675d' } : null}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ClickAbleDenseTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.arrayOf(PropTypes.array).isRequired, // Updated to array of arrays for content
  styleFlag: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  numericrows: PropTypes.bool,
  reverse: PropTypes.bool,
  setSelectedRow: PropTypes.func.isRequired, // Ensure a function is passed to set the selected row
  selectedRowIndex: PropTypes.number, // Index of the selected row
  openEdit: PropTypes.func.isRequired // The function to call on right-click
};
