import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}`;

const UploadDocument = async (type, formData, auth) => {
    const response = await fetch(`${API_URL}/${type}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
        },
        body: formData,
    });
    return response;
};

const UploadDocumentPayload = async (type, payload, auth) => {
    const response = await fetch(`${API_URL}/${type}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json",  // Make sure to set JSON content type
        },
        body: JSON.stringify(payload),  // Convert the payload to a JSON string
    });
    
    return response;
};

const UploadDocumentPayloads = async (type, payload, auth) => {
    const response = await fetch(`${API_URL}/${type}/CreateMultiple`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json",  // Make sure to set JSON content type
        },
        body: JSON.stringify(payload),  // Convert the payload to a JSON string
    });
    
    return response;
};

const UploadOCRDocument = async (type, formData, auth) => {
    const response = await fetch(`${API_URL}/${type}/CreateByOCR`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
        },
        body: formData,
    });
    return response
};

const GetDocumentbyDate = async (type, CaseId, date, auth) => {
    const response = await fetch(`${API_URL}/${type}/byDate`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date })
    });

    return response;
};

const GetDocumentbyDateRange = async (type, CaseId, date, EndDate, auth) => {
    const response = await fetch(`${API_URL}/${type}/byDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date, EndDate })
    });

    return response;
};

const GetAmountChecksType = async (type, CaseId, CheckType, Date, auth) => {
    const response = await fetch(`${API_URL}/${type}/byCheckTypeAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, CheckType, Date })
    });
    const data = await response.json();
    return data;
};

const GetAmountChecksRangeType = async (type, CaseId, CheckType, Date, EndDate, auth) => {
    const response = await fetch(`${API_URL}/${type}/byCheckTypeRangeAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, CheckType, Date, EndDate })
    });
    const data = await response.json();
    return data;
};


const GetWithholdingTaxType = async (type, CaseId, PaymentAcceptanceType, Date, auth) => {
    const response = await fetch(`${API_URL}/${type}/byAcceptanceTypeAmountWithholdingTax`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, PaymentAcceptanceType, Date })
    });
    return response;
};

const GetWithholdingRangeType = async (type, CaseId, PaymentAcceptanceType, Date, EndDate, auth) => {
    const response = await fetch(`${API_URL}/${type}/byAcceptanceTypeRangeWithholdingTax`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, PaymentAcceptanceType, Date, EndDate })
    });
    return response;
};

const GetAmountDocumentsbyDate = async (type, CaseId, date, auth) => {
    const response = await fetch(`${API_URL}/${type}/byDateAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date })
    });

    return response;
};

const GetPurchaseOfEquipmentAmountDocumentsbyDate = async (type, CaseId, date, auth) => {
    const response = await fetch(`${API_URL}/${type}/byPurchaseOfEquipmentsortCodesDateAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date })
    });

    return response;
};

const GetVatAmountDocumentsbyDate = async (type, CaseId, date, auth) => {
    const response = await fetch(`${API_URL}/${type}/byDateVatAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date })
    });

    return response;
};

const GetPurchaseOfEquipmentVatAmountDocumentsbyDate = async (type, CaseId, date, auth) => {
    const response = await fetch(`${API_URL}/${type}/byPurchaseOfEquipmentDateVatAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date })
    });

    return response;
};

const GetAmountDocumentsbyDateRange = async (type, CaseId, date, EndDate, auth) => {
    const response = await fetch(`${API_URL}/${type}/byDateRangeAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date, EndDate })
    });

    return response;
};

const GetPurchaseOfEquipmentAmountDocumentsbyDateRange = async (type, CaseId, date, EndDate, auth) => {
    const response = await fetch(`${API_URL}/${type}/byPurchaseOfEquipmentDateRangeAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date, EndDate })
    });

    return response;
};

const GetVatAmountDocumentsbyDateRange = async (type, CaseId, date, EndDate, auth) => {
    const response = await fetch(`${API_URL}/${type}/byDateRangeVATAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date, EndDate })
    });

    return response;
};

const GetPurchaseOfEquipmentVatAmountDocumentsbyDateRange = async (type, CaseId, date, EndDate, auth) => {
    const response = await fetch(`${API_URL}/${type}/byPurchaseOfEquipmentDateRangeVATAmount`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date, EndDate })
    });

    return response;
};

const DeleteDocument = async (type, id, auth) => {
    const response = await fetch(`${API_URL}/${type}/Delete/${id}`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const UpdateDocument = async (type, auth, Document) => {
    // Log the contents of FormData
    const response = await fetch(`${API_URL}/${type}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...Document })
    });
    console.log(response);
    
    return response;
};

const DownloadCustomDocuments = async (type, auth, dto) => {
    const response = await fetch(`${API_URL}/${type}/DownloadCustom`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(dto)
    });

    if (response.ok) {
        // Assuming the server sends back a URL to download the zip file
        // Or directly handles the file download on the client side
        return await response.blob(); // This assumes the response is a file to be downloaded
    } else {
        throw new Error('Failed to download documents');
    }
};

const GetOtherDocumentbyType = async (type, CaseId, DocumentType, auth) => {
    const response = await fetch(`${API_URL}/${type}/byType`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, DocumentType })
    });

    return response;
};

const CreateInvoice = async (type, Document, auth) => {
    const response = await fetch(`${API_URL}/${type}/GenerateIncomePdf`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...Document })
    });

    if (response.ok) {
        return response.blob();  // Returns a Blob instead of the usual JSON
    } else {
        throw new Error('Failed to fetch the PDF');
    }
};

const CreateNewInvoice = async (controller, endpoint, Document, type, title, TransactionDocumentType, auth) => {
    try {
        const response = await fetch(`${API_URL}/${controller}/${endpoint}`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json"
            },
            body: title && TransactionDocumentType ? JSON.stringify({ ...Document, type, title, TransactionDocumentType }) : JSON.stringify({ ...Document, type })
        });

        if (response.ok) {
            const blob = await response.blob();  // Returns a Blob instead of the usual JSON

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `IncomeReport-${Document.CaseId}.pdf`); // Assuming the CaseId is unique and valid for filename
            document.body.appendChild(link);
            link.click();

            // Clean up and revoke URL
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            return blob;
        } else {
            throw new Error('Failed to fetch the PDF');
        }
    } catch (error) {
        console.error('Error creating invoice:', error);
        throw new Error('Error creating invoice');
    }
}

const CreateInvoiceCustomized = async (controller, endpoint, Document, type, title, TransactionDocumentType, auth) => {
    try {
        const response = await fetch(`${API_URL}/${controller}/${endpoint}`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json"
            },
            body: title && TransactionDocumentType ? JSON.stringify({ ...Document, type, title, TransactionDocumentType }) : JSON.stringify({ ...Document, type })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch the PDF');
        }

        const blob = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(blob);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `IncomeReport-${Document.CaseId}.pdf`); // Fixed filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error creating invoice and downloading file:', error);
        alert('Error creating invoice and downloading file. Please try again.');
    }
};

const CreateInvoiceWithDocuments = async (controller, endpoint, formData, caseId, auth) => {
    console.log(controller);
    console.log(endpoint);
    console.log(caseId);
    
    try {
        const response = await fetch(`${API_URL}/${controller}/${endpoint}`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                // 'Content-Type': 'multipart/form-data',
                // 'Accept': 'application/json'
            },
            body: formData
        });

        if (!response.ok) {
            response.json().then(errorDetails => {
                console.error('Failed to fetch the PDF', errorDetails);
            });
            throw new Error('Failed to fetch the PDF');
        }


        // Get the file content from the response
        const fileContent = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'receipt.pdf'); // Set the correct file name here
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return fileContent;
    } catch (error) {
        console.error('Error creating invoice and opening file:', error);
        throw new Error('Error creating invoice and opening file');
    }
};

const GetDocumentById = async (type, CaseId, documentId, token) => {
    const response = await fetch(`${API_URL}/${type}/GetById`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, documentId})
    });
    return response;
};

const TransferDocument = async (dto, auth) => {
    const response = await fetch(`${API_URL}/Documents/TransferDocument`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(dto),
    });

    return response;
};

const GetInternalByAccountCodeIdDateRange = async ( CaseId, Date, EndDate, accountCodeId,auth) => {
    const response = await fetch(`${API_URL}/Documents/byDateRangeAccountCodeId`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, Date, EndDate,accountCodeId })
    });

    return response;
};

const GetInternalByAccountCodeIdDateRangeWithCreditAndDebit = async ( CaseId, Date, EndDate, accountCodeId,auth) => {
    const response = await fetch(`${API_URL}/Documents/byDateRangeAccountCodeIdWithCreditAndDebit`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, Date, EndDate,accountCodeId })
    });

    return response;
};

const CreateDraftInvoice = async (controller, endpoint, Document, type, title, TransactionDocumentType, auth) => {
    try {
        const response = await fetch(`${API_URL}/${controller}/${endpoint}`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json"
            },
            body: title && TransactionDocumentType ? JSON.stringify({ ...Document, type, title, TransactionDocumentType }) : JSON.stringify({ ...Document, type })
        });

        if (!response.ok) {
            const errorDetails = await response.json();
            console.error('Failed to fetch the PDF', errorDetails);
            throw new Error('Failed to fetch the PDF');
        }

        // Retrieve the PDF as a blob
        const blob = await response.blob();

        // Generate a temporary URL for the blob and initiate a download
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `DraftIncomeReport-${Document.CaseId}.pdf`;
        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the object URL and removing the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

        console.log('Draft invoice created and downloaded successfully');
        return blob;
    } catch (error) {
        console.error('Error creating draft invoice:', error);
        throw new Error('Error creating draft invoice');
    }
};


const DocumentService = {
    UploadDocument,
    GetDocumentbyDate,
    GetAmountDocumentsbyDate,
    UpdateDocument,
    DeleteDocument,
    GetAmountChecksType,
    GetAmountDocumentsbyDateRange,
    GetAmountChecksRangeType,
    GetDocumentbyDateRange,
    DownloadCustomDocuments,
    GetVatAmountDocumentsbyDateRange,
    GetVatAmountDocumentsbyDate,
    GetOtherDocumentbyType,
    CreateInvoice,
    UploadOCRDocument,
    GetPurchaseOfEquipmentVatAmountDocumentsbyDateRange,
    GetPurchaseOfEquipmentVatAmountDocumentsbyDate,
    GetPurchaseOfEquipmentAmountDocumentsbyDate,
    GetPurchaseOfEquipmentAmountDocumentsbyDateRange,
    CreateNewInvoice,
    CreateInvoiceWithDocuments,
    GetWithholdingTaxType,
    GetWithholdingRangeType,
    GetDocumentById,
    TransferDocument,
    UploadDocumentPayload,
    GetInternalByAccountCodeIdDateRange,
    UploadDocumentPayloads,
    GetInternalByAccountCodeIdDateRangeWithCreditAndDebit,
    CreateDraftInvoice
};

export default DocumentService;